// src/assets/AdminAuthContext.jsx
import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AdminAuthContext = createContext(null);

const AdminAuthProvider = ({ children }) => {
    const [allAIAdmin, setAllAIAdmin] = useState();
    const [currentAIAdmin, setCurrentAIAdmin] = useState();
    const [allAIUsers, setAllAIUsers] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [allScheduledData, setAllScheduledData] = useState();
    const [allMessage, setAllMessage] = useState();
    const [allNews, setAllNews] = useState();
    const [allPayments, setAllPayments] = useState();
    const [allTransaction, setAllTransaction] = useState();
    const [allTestPayments, setAllTestPayments] = useState();
    const [allTester, setAllTester] = useState();
    const [expireToken, setExpireToken] = useState(false);
    const AdminToken = localStorage?.getItem('AiAdmin');
    
    useEffect(() => {
        fetch(`https://backend.whalesai.com/api/v1/admin`)
            .then((res) => res.json())
            .then((data) => setAllAIAdmin(data));
    }, []);

    const getAllAdmin = async () => {
        setIsLoading(true);
        const response = await axios.get('https://backend.whalesai.com/api/v1/admin');
        setAllAIAdmin(response.data); 
        setIsLoading(false);
    };

    const getAllUser = async () => {
        setIsLoading(true);
        const response = await axios.get('https://backend.whalesai.com/api/dashboard/user_signup-signin');
        // setAllAIUsers(response?.data?.result); 
        
        const testers = response.data.result.filter(user => user?.role === 'tester');
        const users = response.data.result.filter(user => user?.role === 'user');

        setAllAIUsers(users);

        setAllTester(testers);

        setIsLoading(false);
    };

    console.log('all tester data', allTester);

    const getAllScheduledData = async () => {
        setIsLoading(true);
        const response = await axios.get('https://backend.whalesai.com/api/v1/social-schedule/schedules');
        setAllScheduledData(response?.data);
        setIsLoading(false);
    }
    const getAllNewsData = async () => {
        setIsLoading(true);
        const response = await axios.get('https://backend.whalesai.com/api/v1/admin/news/get/all');
        setAllNews(response?.data?.reverse());
        setIsLoading(false);
    }

    const getAllPayments = async () => {
        
        setIsLoading(true);
        const response = await axios.get('https://backend.whalesai.com/api/v1/earnings-payment');
        setAllPayments(response?.data?.earningsPayments);
        setIsLoading(false);
    }
    const getAllTransaction = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://backend.whalesai.com/api/v1/payment');
            // Reverse the transaction data
            const reversedTransactions = response?.data?.result?.reverse();
            setAllTransaction(reversedTransactions);
        } catch (error) {
            console.error("Error fetching transactions:", error);
        } finally {
            setIsLoading(false);
        }
    };
    // const getAllTransaction = async () => {
    //     setIsLoading(true);
    //     try {
    //         // Fetch transactions from the API
    //         const response = await axios.get('https://backend.whalesai.com/api/v1/payment');
            
    //         // Reverse the transaction data, or initialize as an empty array if undefined
    //         const reversedTransactions = response?.data?.result?.reverse() || [];
            
    //         // Check if `allTester` is loaded with data
    //         if (!allTester || allTester?.length === 0) {
    //             console.warn("No testers found to match with transactions.");
    //             // If there are no testers, set all transactions as `allTransaction`
    //             setAllTransaction(reversedTransactions);
    //             return;
    //         }
            
    //         // Extract tester emails for filtering
    //         const testerEmails = allTester.map(tester => tester.email);
    
    //         // Separate transactions by checking if they match any tester's email
    //         const testerPayments = reversedTransactions.filter(transaction => 
    //             testerEmails.includes(transaction.email)
    //         );
    //         const otherTransactions = reversedTransactions.filter(transaction => 
    //             !testerEmails.includes(transaction.email)
    //         );
    
    //         // Set filtered transactions into state
    //         setAllTestPayments(testerPayments);
    //         setAllTransaction(otherTransactions);
    
    //         // Log for verification
    //         console.log('Tester Payments:', testerPayments);
    //         console.log('Other Transactions:', otherTransactions);
    
    //     } catch (error) {
    //         console.error("Error fetching transactions:", error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // };
    
    
    
    
    const getAllMessage = async () => {
        try {
            setIsLoading(true);
            const response = await axios.get('https://backend.whalesai.com/api/v1/contact-us/getdata');
    
            // Ensure data is an array before reversing
            const messages = response?.data;
    
            if (Array.isArray(messages)) {
                const reversedMessages = messages.reverse(); // Reverse the array
                setAllMessage(reversedMessages); // Set the reversed data
            } else {
                console.error("Expected an array but received:", messages);
            }
        } catch (error) {
            console.error('Error fetching messages:', error);
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        if (AdminToken) {
            getCurentAdmin();
        } else {
        }
    }, [AdminToken]);

    // useEffect(() => {
    //     getAllUser();
    //     getAllScheduledData();
    //     getAllMessage();
    //     getAllNewsData();
    //     getAllPayments();
    //     getAllTransaction();
    // } ,[]);
    useEffect(() => {
        const fetchData = async () => {
            await getAllUser(); // Ensure `allTester` is populated first
            await getAllTransaction(); // Now fetch transactions
            await getAllScheduledData();
            await getAllMessage();
            await getAllNewsData();
            await getAllPayments();
        };
    
        fetchData();
    }, []);
    

    console.log('Payments====>>>>', allTestPayments, allTransaction);
    
    const getCurentAdmin = async () => {
        setIsLoading(true);    
        try {
            const response = await axios.get('https://backend.whalesai.com/api/v1/admin/admin', {
                headers: {
                    Authorization: `Bearer ${AdminToken}`, 
                },
            });
            console.log('From fetching Current Admin ====>>>>', response);
            setCurrentAIAdmin(response?.data?.admin);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error("Unauthorized: Invalid or expired token");
                setExpireToken(true);

                // Optionally, handle token expiration (e.g., logout or refresh token)
            } else {
                console.error("Error fetching current admin:", error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const getSpecificUser = async (id) => {
        setIsLoading(true);
        const response = await axios.get(`https://backend.whalesai.com/api/dashboard/user_signup-signin/get/${id}`);
        console.log('from the context ======>>>> ', response?.data?.result?.name);
        setIsLoading(false);
        return response?.data?.result?.name;
    }
    useEffect( () => {
        if(!currentAIAdmin && !AdminToken) {
            localStorage.removeItem('AiAdmin');
            // window.location.href = '/admin/login';
        }
        // getSpecificUser('6672b34dc76943c10226e123');
    },[])

    console.log('All Payments Data ====>>>', allPayments);



    const value = {
       allAIAdmin,
       allAIUsers,
       currentAIAdmin,
       allScheduledData,
       allMessage,
       allNews,
       setAllNews,
       allPayments,
       allTransaction,
       allTestPayments,
       allTester,
       setCurrentAIAdmin,
       isLoading,
       AdminToken,
       expireToken,
       setExpireToken,
       getAllAdmin,
       getCurentAdmin,
       getAllUser,
       getAllScheduledData,
       getSpecificUser,
       getAllMessage,
       getAllNewsData,
       getAllPayments,
       getAllTransaction,
    };

    return (
        <AdminAuthContext.Provider value={value}>
            { children}
            {/* !loading && */}
        </AdminAuthContext.Provider>
    );
};

export default AdminAuthProvider;