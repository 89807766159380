import React from 'react';
import PageHeading from '../../Components/PageHeading/PageHeading';
import './Savings.css';

const Savings = () => {
    return (
        <div >
            <PageHeading title='SAVINGS'  />

            <div className='layout'>
            <div className='short-description'>
                <p>Explain in one line how much you can save when you use our SMMM AI</p>
            </div>
            </div>
        </div>
    );
};

export default Savings;