import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
// import Footer from "../Footer/Footer";
// import Navbar from "../Navbar/Navbar";

const Main = () => {
  // THIS IS FOR ADDING OR REMOVING THE FOOTER OR THE NAV ACCORDING TO ROUTES
  
  const { pathname } = useLocation();
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(true);

  const checkScrollPosition = () => {
    if (!showScrollUp && window.pageYOffset > 400) {
      setShowScrollUp(true);
    } else if (showScrollUp && window.pageYOffset <= 400) {
      setShowScrollUp(false);
    }

    if (
      !showScrollDown &&
      window.innerHeight + window.pageYOffset < document.body.offsetHeight - 400
    ) {
      setShowScrollDown(true);
    } else if (
      showScrollDown &&
      window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 400
    ) {
      setShowScrollDown(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, [showScrollUp, showScrollDown]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);






  console.log("pathname", pathname);

  let content = "";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const videoai = useLocation().pathname.includes("/video-ai-app" || "/video-ai-app/:token");
  const companionai = useLocation().pathname.includes("/consultant-ai-app" || "/consultant-ai-app/:token");
  const PaymentMethods = useLocation().pathname.includes("/payment-methods-app" || "/payment-methods-app/:token");
  const makevideo = useLocation().pathname.includes("/make-video-app" || "/make-video-app/:token");
  const payment = useLocation().pathname.includes("/payment-app" || "/payment-app/:token");
  const transaction = useLocation().pathname.includes("/transaction-app" || "/transaction-app/:token");
  const BuyCredits = useLocation().pathname.includes("/purchase-app" || "/purchase-app/:token");


  const cobainLocalHeader =
    videoai ||
    companionai ||
    makevideo ||
    payment ||
    transaction ||
    BuyCredits ||
    PaymentMethods;


  const cobainLocalFooter =
    videoai ||
    companionai ||
    makevideo ||
    payment ||
    transaction ||
    BuyCredits ||
    PaymentMethods;





  content = (
    <div>
      {!cobainLocalHeader && <Navbar />}
      <div>
        <Outlet />
      </div>
      {!cobainLocalFooter && <Footer />}

      {showScrollUp ? (
        <FaArrowAltCircleUp
          onClick={scrollTop}
          className="position-fixed"
          style={{
            bottom: "20px",
            right: "20px",
            padding: "10px",
            backgroundColor: "#fd7e14",
            color: "white",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1000,
            fontSize: "3rem",
          }}
        />
      ) : (
        <FaArrowAltCircleDown
          onClick={scrollBottom}
          className="position-fixed"
          style={{
            bottom: "20px",
            right: "20px",
            padding: "10px",
            backgroundColor: "#6c757d",
            color: "white",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1000,
            fontSize: "3rem",
          }}
        />
      )}
    </div>
  );

  return <div>{content}</div>;
};

export default Main;
