import React from "react";
import ReactMarkdown from "react-markdown";

const MHSC = ({ inputText, isGenerating, text, presetSelected, wordRef }) => {
  return (
    <>
      <p class="preset-title">Micro Horror Story Creator</p>
      {inputText ? (
        <>
          <>
            <p class="text-muted">{inputText}</p>
            {isGenerating == true ? (
              <p
                class="text- mb-0 mt-2"
                style={{
                  fontSize: "14px",
                  color: "black",
                }}
              >
                Processing...
              </p>
            ) : (
              <>
                {text && (
                  <p
                    style={{
                      color: "black",
                    }}
                    class="text- mb-0 text-"
                  >
                    {wordRef.current && (
                      <>
                        <p style={{ color: "black" }} className="mb-0">
                          Two-Sentence Horror Story:
                        </p>
                        <p style={{ color: "black" }}>
                          <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                      </>
                    )}
                  </p>
                )}
              </>
            )}
          </>
        </>
      ) : (
        <>
          <p className="text-muted" style={{ color: "black",fontSize:"14px" }}>Breakfast</p>

          <p style={{ color: "black",fontSize:"14px" }}>
            Two-Sentence Horror Story: He always stops crying when I pour the
            milk on his cereal. I just have to remember not to let him see his
            face on the carton.
          </p>
        </>
      )}
    </>
  );
};

export default MHSC;
