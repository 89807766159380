import React, { useEffect, useState } from "react";
import MocUp from "../../Components/PageHeading/MocUp";
import "./ContentPost.css";
import FacebookPost from "./FacebookPost";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SpeechBubbleTopCenter, SpeechBubbleTopLeft } from "../SpeechBubble/Bubble";

const ContentPost = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  const [showModal, setShowModal] = useState(false);

  const showAlert = () => {
    Swal.fire({
      // title: "Content Posted Successfully!",
      text: `The content has been posted to your Facebook page.`,
      icon: "success",
      timer: 3000,
      showConfirmButton: true,
      background: "#011225",
      color: "white",
    });
  };

  useEffect(() => {
    window.scroll(0, 0);

    const loginTimer = setTimeout(() => {
      setShowModal(true);
    }, 3000);

    return () => clearTimeout(loginTimer);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      setShowModal(false);
    }, 6000);
    return () => clearTimeout(loginTimer);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      showAlert();
    }, 7000);
    return () => clearTimeout(loginTimer);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    const loginTimer = setTimeout(() => {
      // showAlert();
      navigate('/how-it-works-facebook-page');
    }, 10000);
    return () => clearTimeout(loginTimer);
  }, []);

  return (
    <div>
      <MocUp
        title="Social Media Post"
        description="Contents Posted in Social Media Platforms"
        step="Step 6:"
      />

      <FacebookPost setShowModal={setShowModal} showModal={showModal} />

      <section
        className="container mx-auto mt-5"
        style={{ height: "400px", color: "white" }}
      >
        <div className="post-card">
          <div
            className="p-3 rounded position-relative"
            style={{ background: "#FFFFFF", color: "black" }}
          >
            <p>
              {" "}
              <span style={{ fontWeight: "bold" }}>Platform:</span> Facebook
              Page{" "}
            </p>

            <p>
              {" "}
              <span style={{ fontWeight: "bold" }}>
                Country:
              </span> Singapore{" "}
            </p>
            <p className="">
              {" "}
              <span style={{ fontWeight: "bold" }}>Content Type:</span> 1:1
              Image with text and hashtags{" "}
            </p>

            <p className="">
              {" "}
              <span style={{ fontWeight: "bold" }}>Time Of Posting:</span> 9:00
              (24 hours){" "}
            </p>

            <p className="">
              {" "}
              <span style={{ fontWeight: "bold" }}>Date:</span> {formattedDate}
            </p>
            <button
              onClick={() => {
                setShowModal(true);
                // showAlert();
              }}
              className="rounded py-2 mb-2"
              style={{
                background: "#25678E",
                width: "100%",
                color: "white",
                border: "none",
                fontWeight: "bold",
                boxShadow: "revert",
              }}
            >
              POST
            </button>
            <div className="position-absolute">
              <SpeechBubbleTopLeft>
                 Enable effortless social media posting for enhanced reach!
              </SpeechBubbleTopLeft>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContentPost;
