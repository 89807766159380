import React, { useState, useEffect, useContext, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./video-ai.css";
import moneyExchangeIcon from "../../Assets/Images/transaction-data.png";
import Form from "react-bootstrap/Form";
import ProductDescription from "./ProductDescription";
import AddYourScript from "./AddYourScript";
import JSZip from "jszip";
import axios from "axios";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import swal from "sweetalert";
import SampleImageModal from "./SampleImageModal";
import SampleImageModal1 from "./SampleImageModal1";
import SampleImageModal2 from "./SampleImageModal2";
import DesignButton from "./DesignButton";
import { FaRegCopy } from "react-icons/fa";
import Swal from "sweetalert2";
// import { saveAs } from 'file-saver';
// import canvg from 'canvg';

function generateRandomString(length) {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charsetLength = charset.length;

  let randomString = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charsetLength);
    randomString += charset[randomIndex];
  }

  return randomString;
}

const downloadImagesAsZip = async (imageUrls, zipName) => {
  const zip = new JSZip();

  // Fetch images and add them to the zip file
  const addImageToZip = async (url, filename) => {
    const response = await axios.get(url, {
      responseType: "arraybuffer",
    });
    zip.file(filename, response.data, { binary: true });
  };
  console.log(imageUrls, "imageUrlsimageUrls");
  // Create promises for fetching and zipping each image
  const promises = imageUrls.map((url, index) => {
    return addImageToZip(url, `image_${index + 1}.png`);
  });

  // Wait for all promises to resolve
  await Promise.all(promises);

  // Generate the zip file asynchronously
  const zipBlob = await zip.generateAsync({ type: "blob" });

  // Create a download link and click it
  const link = document.createElement("a");
  link.href = URL.createObjectURL(zipBlob);
  link.download = `${zipName + generateRandomString(5)}.zip`;
  link.click();

  // Clean up
  URL.revokeObjectURL(link.href);
};

const stepOnePresets = [
  "Quote",
  // 'History',
  "Motivational Message",
  "Poetry",
  "Short Story",
  "Recipe",
  // 'Advertisement',
  // 'Add Your Script',
];

const QuotePresets = [
  "Inspirational quote",
  "Motivational quote",
  "Thought-Provoking quote",
];

const HistoryPresets = [
  "Historical event for today",
  "Biographies of historical figures",
  "Timelines of significant periods",
];

const motivationalPresets = [
  "Encouraging Word",
  "Affirmation",
  "Motivational Speech",
];

const PoetryPresets = ["Love", "Family", "Life"];

const shortStoriesPresets = [
  "Literary Fiction",
  "Mystery",
  "Science Fiction",
  "Fantasy",
  "Horror",
  "Romance",
  "Historical Fiction",
  "Thriller/Suspense",
  "Adventure",
  "Humor/Comedy",
  "Magical Realism",
  "Crime",
  "Gothic",
  "Flash Fiction",
];

const RecipesPresets = [
  "Appetizer",
  "Main Course",
  "Side Dish",
  "Dessert",
  "Beverage",
  "Bread",
  "Soup",
  "Salad",
  "Snack",
];

const TestVideoImage05 = () => {
  const { AIuser, tokenLocal, userRefetch, setUserRefetch } =
    useContext(UserAuthContext);
  const creditTolcalString = AIuser?.credits
    ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
    : "0";
  const [userName, setUserName] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const [imageUrls, setImageUrls] = useState({
    1: null,
    2: null,
    3: null,
    4: [null, null, null],
  });
  const [imageUrls1, setImageUrls1] = useState({
    1: null,
    2: null,
    3: null,
    4: [null, null, null],
    5: [null, null, null],
    6: [null, null, null],
  });
  const [reply, setReply] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [show, setshow] = useState(false);
  const [inputText, setInputText] = useState("");
  const [text, setText] = useState("");
  const [segments, setSegments] = useState("");
  const [text1, setText1] = useState(
    "Are you a parent looking for a way to help your child excel in school? Look no further than Learning Room! Our virtual environment provides a safe and engaging way for students from kindergarten to high school to learn and practice the skills they need to succeed. With our comprehensive curriculum, interactive games, and experienced instructors, your child will be well-prepared for their future. Join us today and give your child the tools they need to reach their full potential!"
  );
  const [show1, setshow1] = useState(false);
  const [isDownloading, setIsDownloading] = useState([false, false, false]);
  const [imageFormats, setImageFormats] = useState({
    1: "png",
    2: "png",
    3: "png",
    4: ["png", "png", "png"],
  });
  const [activeTab, setActiveTab] = useState("4");
  const [isDark, setIsDark] = useState(false);
  const [isRandomDesign, setIsRandomDesign] = useState(false);
  const [isRandomFont, setIsRandomFont] = useState(false);
  const [isRandomBackground, setIsRandomBackground] = useState(false);
  const [isRandomLine, setIsRandomLine] = useState(false);
  const [replyText, setReplyText] = useState();
  const [replyHashtag, setReplyHashtag] = useState();
  const childRef = useRef();
  console.log(text, "info......");

  const callChildFunction = () => {
    if (childRef.current) {
      childRef.current.handleClearData();
    }
  };

  const handleSubmitCredits = async (points) => {
    const deductedCredits = AIuser?.credits - points;
    // console.log("question", question, conversation);
    setIsDark(false);
    setIsRandomDesign(false);
    setIsRandomBackground(false);
    setIsRandomFont(false);
    setIsRandomLine(false);
    await axios
      .put(
        "https://backend.whalesai.com/api/dashboard/user_signup-signin/update-credits",
        { email: AIuser?.email, credits: deductedCredits },
        {
          headers: {
            authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserRefetch(!userRefetch);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxChange = (event) => {
    setIsDark(event.target.checked);
  };
  const handleCheckboxChange1 = (event) => {
    setIsRandomDesign(event.target.checked);
  };
  const handleCheckboxChange2 = (event) => {
    setIsRandomBackground(event.target.checked);
  };
  const handleCheckboxChange3 = (event) => {
    setIsRandomFont(event.target.checked);
  };
  const handleCheckboxChange4 = (event) => {
    setIsRandomLine(event.target.checked);
  };

  const types = ["Quote", "History", "Motivational Message", "Poetry"];

  const getRandomType = () => {
    return types[Math.floor(Math.random() * types.length)];
  };

  const [buttonText, setButtonText] = useState(getRandomType());

  const [preset, setPreset] = useState("");
  const [innerPreset, setInnerPreset] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setshow(false);
    setshow1(false);
  }, [innerPreset, text, text1]);

  useEffect(() => {
    if (AIuser?.name) {
      setUserName(AIuser?.name);
    }
  }, [AIuser]);

  useEffect(() => {
    // const randomIndex = Math.floor(Math.random() * stepOnePresets.length);
    setPreset(stepOnePresets[0]);
  }, []);

  useEffect(() => {
    if (preset) {
      let randomIndex;
      switch (preset) {
        case "Quote":
          randomIndex = Math.floor(Math.random() * QuotePresets.length);
          setInnerPreset(QuotePresets[0]);
          setshow(false);
          setshow1(false);
          break;
        case "History":
          randomIndex = Math.floor(Math.random() * HistoryPresets.length);
          setInnerPreset(HistoryPresets[0]);
          setshow(false);
          setshow1(false);
          break;
        case "Motivational Message":
          randomIndex = Math.floor(Math.random() * motivationalPresets.length);
          setInnerPreset(motivationalPresets[0]);
          setshow(false);
          setshow1(false);
          break;
        case "Poetry":
          randomIndex = Math.floor(Math.random() * PoetryPresets.length);
          setInnerPreset(PoetryPresets[0]);
          setshow(false);
          setshow1(false);
          break;
        case "Short Story":
          randomIndex = Math.floor(Math.random() * shortStoriesPresets.length);
          setInnerPreset(shortStoriesPresets[0]);
          setshow(false);
          setshow1(false);
          break;
        case "Recipe":
          randomIndex = Math.floor(Math.random() * RecipesPresets.length);
          setInnerPreset(RecipesPresets[0]);
          setshow(false);
          setshow1(false);
          break;
        case "Advertisement":
          setshow(false);
          setshow1(false);
          break;
        case "Add Your Script":
          setshow(false);
          setshow1(false);
          break;
        default:
          break;
      }
    }
  }, [preset]);

  // useEffect(() => {
  //     setButtonText(getRandomType());
  // }, [activeTab]);

  function generateTransactionId(length = 8) {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  const generateImagePreset = async (dimension, tab, preset, inner) => {
    setIsLoading(true);
    console.log("preset=>", preset, "inner=>", inner);
    try {
      if (AIuser.credits > 0) {
        console.log(`${preset}${inner}`, "reset,inner");
        const response = await fetch(
          `https://backend.whalesai.com/api/v1/img/generate10?dimension=${dimension}&question=${preset}&innerQues=${inner}&dark=${isDark}&randomDesign=${isRandomDesign}&randomFont=${isRandomFont}&randomBackground=${isRandomBackground}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch image");
        }

        const data = await response.json();
        console.log(
          "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa111111111111111111111111111111111111111111 ============> :",
          data
        );

        console.log(data, "responce...");
        setReplyText(data?.reply);
        setReplyHashtag(data?.textwithHashTag);
        if (data.success) {
          if (dimension === "all") {
            setImageUrls((prevState) => ({
              ...prevState,
              [tab]: data.imageUrl,
            }));
            setReply(data.reply);
            setImageFormats((prevState) => ({
              ...prevState,
              [tab]: ["png", "png", "png"],
            }));
          } else {
            setImageUrls((prevState) => ({
              ...prevState,
              [tab]: data.imageUrl,
            }));
            setReply(data.reply);
            setImageFormats((prevState) => ({
              ...prevState,
              [tab]: "png",
            }));
          }
          const points = (data.imageUrl.length * 10) / 3;
          handleSubmitCredits(points);
          const transactionRes = await axios.post(
            "https://backend.whalesai.com/api/v1/credits-transaction/create",
            {
              aiType: "Post Ai",
              postDataType: "postAi-01",
              transactionId: generateTransactionId(8),
              credit: points,
              preset: preset,
              userId: AIuser?._id,
              postData1: data,
            }
          );
        } else {
          // throw new Error('Failed to generate image');
          swal({
            // title: "S",
            text: "Failed To Generate Image. Network Error!",
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
          });
        }
      } else {
        swal({
          // title: "S",
          text: "You dont have enough credit!",
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      }
    } catch (error) {
      swal({
        // title: "S",
        text: "Failed To Generate Image. Network Error!",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    } finally {
      setIsLoading(false);
      setshow(true);
    }
  };

  const generateImage = async (dimension, tab, prese, inner) => {
    setIsLoading1(true);
    try {
      if (AIuser.credits > 0) {
        console.log(
          `https://backend.whalesai.com/api/v1/img/generate11?dimension=${dimension}&question=${prese}&innerQues=${inner}`,
          "outter"
        );
        const response = await fetch(
          `https://backend.whalesai.com/api/v1/img/generate11?dimension=${dimension}&&question=${prese}&&innerQues=${inner}&dark=${isDark}&randomDesign=${isRandomDesign}&randomFont=${isRandomFont}&randomBackground=${isRandomBackground}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch image");
        }
        const data = await response.json();
        console.log(data);
        console.log(
          "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa2222222222222222222222222222222222 ============> :",
          data
        );

        setReplyHashtag(data?.textwithHashTag);
        if (data.success) {
          if (dimension === "all") {
            setReply(data.reply);
            setImageUrls1((prevState) => ({
              ...prevState,
              [tab]: data.imageUrls,
              ["5"]: data.imageUrls1,
              ["6"]: data.imageUrls2,
            }));
            setImageFormats((prevState) => ({
              ...prevState,
              [tab]: ["png", "png", "png"],
            }));
          } else {
            setReply(data.reply);
            setImageUrls1((prevState) => ({
              ...prevState,
              [tab]: data.imageUrls,
              ["5"]: data.imageUrls1,
              ["6"]: data.imageUrls2,
            }));
            setImageFormats((prevState) => ({
              ...prevState,
              [tab]: "png",
            }));
          }
          setSegments(data?.segments);
          const points = data.imageUrls.length * 10;
          handleSubmitCredits(points);
          const transactionRes = await axios.post(
            "https://backend.whalesai.com/api/v1/credits-transaction/create",
            {
              aiType: "Post Ai",
              postDataType: "postAi-02",
              transactionId: generateTransactionId(8),
              credit: points,
              preset: prese,
              userId: AIuser?._id,
              postData2: data,
            }
          );
        } else {
          swal({
            // title: "S",
            text: "Failed To Generate Image. Network Error!",
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
          });
        }
      } else {
        swal({
          // title: "S",
          text: "You dont have enough credit!",
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      }
    } catch (error) {
      swal({
        // title: "S",
        text: "Failed To Generate Image. Network Error!",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
    } finally {
      setIsLoading1(false);
      setshow1(true);
    }
  };
  const generateImageOwn = async (dimension, tab, prese, inner) => {
    try {
      if (AIuser.credits > 0) {
        setIsLoading1(true);
        console.log(prese, "presly");
        const response = await axios.post(
          `https://backend.whalesai.com/api/v1/img/generate12?dimension=${dimension}&&question=${prese}&&innerQues=${inner}&dark=${isDark}&randomDesign=${isRandomDesign}&randomFont=${isRandomFont}&randomBackground=${isRandomBackground}`,
          { question: prese },
          {
            headers: {
              authorization: `Bearer bs2zDM01DMMEgt33CrKZt8x4abs2zDM01DMMEgt33Cr6atzL3CJdExpP4x4abs2zDM01DMMEgt33Cr`,
            },
          }
        );
        // if (!response.ok) {
        //     throw new Error('Failed to fetch image');
        // }
        const data = await response.data;

        setReplyHashtag(data?.textwithHashTag);

        console.log(
          "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa3333333333333333333333333333333333333333333 ============> :",
          data
        );
        if (data) {
          if (dimension === "all") {
            console.log(
              {
                [tab]: data.imageUrls,
              },
              "data.imageUrl"
            );
            setImageUrls1((prevState) => ({
              ...prevState,
              [tab]: data.imageUrls,
              ["5"]: data.imageUrls1,
              ["6"]: data.imageUrls2,
            }));
            setImageFormats((prevState) => ({
              ...prevState,
              [tab]: ["png", "png", "png"],
            }));
          } else {
            setImageUrls1((prevState) => ({
              ...prevState,
              [tab]: data.imageUrls,
              ["5"]: data.imageUrls1,
              ["6"]: data.imageUrls2,
            }));
            setImageFormats((prevState) => ({
              ...prevState,
              [tab]: "png",
            }));
          }
          const points = data.imageUrls.length * 10;
          handleSubmitCredits(points);
          const transactionRes = await axios.post(
            "https://backend.whalesai.com/api/v1/credits-transaction/create",
            {
              aiType: "Post Ai",
              postDataType: "postAi-02",
              transactionId: generateTransactionId(8),
              credit: points,
              preset: prese,
              userId: AIuser?._id,
              postData2: data,
            }
          );
        } else {
          swal({
            // title: "S",
            text: "Failed To Generate Image. Network Error!",
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
          });
        }
      } else {
        swal({
          // title: "S",
          text: "You dont have enough credit!",
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      }
    } catch (error) {
      console.log(error);
      // swal({
      //   // title: "S",
      //   text: "Failed To Generate Image. Network Error!",
      //   icon: "warning",
      //   button: "OK!",
      //   className: "modal_class_success",
      // });
    } finally {
      setIsLoading1(false);
      setshow1(true);
    }
  };

  useEffect(() => {
    if (location.state?.aiType == "Post Ai") {
      if (location.state?.postDataType == "postAi-01") {
        setReplyText(location.state?.postData1?.reply);
        setReplyHashtag(location.state?.postData1?.textwithHashTag);
        console.log("dataaaaaaaaaaaaaaaaaaa=======>", location.state?.preset);
        console.log("dataaaaaaaaaaaaaaaaaaa=======>", preset);

        setImageUrls((prevState) => ({
          ...prevState,
          ["4"]: location.state?.postData1?.imageUrl,
        }));
        setReply(location.state?.postData1?.reply);
        setImageFormats((prevState) => ({
          ...prevState,
          ["4"]: ["png", "png", "png"],
        }));
      } else if (location.state?.postDataType == "postAi-02") {
        setIsLoading1(false);
        setReplyHashtag(location.state?.postData2?.textwithHashTag);
        setReplyText(location.state?.postData2?.reply);

        setImageUrls1((prevState) => ({
          ...prevState,
          ["4"]: location.state?.postData2?.imageUrls,
          ["5"]: location.state?.postData2?.imageUrls1,
          ["6"]: location.state?.postData2?.imageUrls2,
        }));
        setReply(location.state?.postData2?.reply);

        setImageFormats((prevState) => ({
          ...prevState,
          ["4"]: ["png", "png", "png"],
        }));
      }
    }
  }, [location.state]);

  const getFileNameFromUrl = (url) => {
    const basePath = "https://backend.whalesai.com/public/";
    if (url.startsWith(basePath)) {
      return url.substring(basePath.length);
    }
    return null;
  };

  function generateRandomAlphanumeric() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      result += chars[randomIndex];
    }
    return result;
  }

  const sendAsVideo = async (dimension, images) => {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = `<p></p> <p class="text-white">We are generating the videos for you. We will send the video links to <span class="text-warning">${AIuser?.email}</span>. It might take up to 10 mins. <br/> You can create more videos or you can wait till the present video is generated.</p> `;

    swal({
      content: wrapper,
      icon: "success",
      button: "Close",
      className: "modal_class_success",
    });
    // .then(() => {
    // Update the button text to "Refreshing the page for you"
    // swal({
    //   icon: "success",
    //   button: {
    //     text: "Refreshing the page for you",
    //     className: "bg-danger",
    //   },
    //   className: "modal_class_success",
    //   closeOnClickOutside: false, // Prevent closing by clicking outside
    // });

    // Reload the page after a short delay to allow the user to see the updated text
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000); // Adjust the delay as needed
    // });

    const bodyContent = {
      email: AIuser?.email,
      userId: AIuser?._id,
      isDark: isDark,
      transactionId: generateRandomAlphanumeric(),
      description: images,
      segments: segments,
    };

    try {
      const response = await fetch(
        `https://backend.whalesai.com/api/v1/img/generate-video1?dimension=${dimension}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyContent),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to generate video");
      }

      const data = await response.json();
      if (data.message === "Video created successfully") {
        // alert("video created successfully");

        setIsLoading(false);
      } else {
        throw new Error("Failed to generate video");
      }
    } catch (error) {
      console.error("Error generating video:", error);
    }
  };
  const downloadImage = (urlIndex, imgUrl) => async () => {
    const fileName = getFileNameFromUrl(imgUrl);

    if (typeof imageUrls["4"][urlIndex] === "string") {
      setIsDownloading((prevState) => {
        const newState = [...prevState];
        newState[urlIndex] = true;
        return newState;
      });

      try {
        const url = imageUrls["4"][urlIndex];
        // downloadSvgAsPng(url)
        const response = await fetch(url);
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName
          ? fileName
          : `generated_image_${urlIndex + 1}.png`;
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
      } catch (error) {
        console.error("Error downloading image:", error);
      } finally {
        setIsDownloading((prevState) => {
          const newState = [...prevState];
          newState[urlIndex] = false;
          return newState;
        });
      }
    }
  };

  const handleSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const handleClick = () => {
    setButtonText(getRandomType());
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(replyHashtag)
      .then(() => {
        // Show SweetAlert on success
        swal({
          icon: "success",
          // title: 'Copied!',
          text: "The Text with HashTag has been copied to your clipboard.",
          // timer: 1500,
          showConfirmButton: false,
          className: "modal_class_success",
        });
      })
      .catch((err) => {
        // Show SweetAlert on error
        swal({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          className: "modal_class_success",
        });
      });
  };

  console.log(imageUrls, " image info");
  console.log(location.state?.preset, " preset info");
  console.log(location.state?.setShow, " setShow info");

  {
    /* Will Implement later: Start */
  }
  const [selectedOption, setSelectedOption] = useState("");
  // const [isDark, setIsDark] = useState(false);
  const [isGradient, setIsGradient] = useState(false);
  const [isRandomColor, setIsRandomColor] = useState(false);
  const [isBoth, setIsBoth] = useState(false);
  const [videoPosition, setVideoPosition] = useState(0);

  const handleCheckGradient = () => {
    setIsGradient(true);
    setIsRandomColor(false);
    setIsBoth(false);
    // setIsDark(false);
    setVideoPosition(3);
  };

  const handleCheckRandomColor = () => {
    setIsRandomColor(true);
    setIsBoth(false);
    // setIsDark(false);
    setIsGradient(false);
    setVideoPosition(4);
  };

  const handleCheckBothGR = () => {
    setIsBoth(true);
    // setIsDark(false);
    setIsGradient(false);
    setIsRandomColor(false);
    setVideoPosition(2);
  };

  const handleOptionChange = (e) => {
    const value = e?.target?.value;

    // console.log("vvvvvvvvvvvvv", value);
    setSelectedOption(value);

    switch (value) {
      case "Gradient":
        handleCheckGradient();
        break;
      case "Random Colors":
        handleCheckRandomColor();
        break;
      case "Random Color Gradients":
        handleCheckBothGR();
        break;
      case "Classic Black and White":
        handleCheckboxChange();
        break;
      default:
        setVideoPosition(0);
        break;
    }
  };

  {
    /* Will Implement later: End */
  }

  return (
    <div>
      <PageHeading
        title={"POST AI"}
        description={
          "The Post AI effortlessly generate engaging social media posts."
        }
      />
      {/* <div className="ai-login"> */}
      <div
        style={{
          maxWidth: "972px",
          paddingInline: "12px",
          marginInline: "auto",
        }}
        className=""
      >
        <div className="container py-5">
          {/* <div className="CreditDiv mt-3 d-flex flex-column gap-2"> */}
          <div className="d-flex align-items-center justify-content-center centralize-credits-btn-sm mt-2">
            <div className="">
              <div>
                <button className="credits-button">
                  CREDITS : {creditTolcalString}
                </button>
                <button
                  className="buy-credits-button"
                  onClick={() => navigate("/purchase")}
                >
                  BUY CREDITS
                </button>
                <button
                  onClick={() => navigate("/usage")}
                  className="money-exchange-button"
                >
                  <img src={moneyExchangeIcon} alt="image" />
                </button>
              </div>
              <p
                style={{ fontSize: "8px" }}
                className="text-white mt-2 creditText text-center"
              >
                10 Credits per image
                {/* Every request is 50 credits. */}
              </p>
            </div>
          </div>
          <div
            className="ai-image-credit-tex text-white text-uppercase text-start"
            style={{
              top: 17,
              width: "100%",
              marginBottom: "0px",
              marginTop: "-20px",
            }}
          >
            {/* <Tabs
                        id="fill-tab-example"
                        className="mb-3"
                        fill
                        activeKey={activeTab}
                        onSelect={handleSelect}
                    > */}

            {/* <Tab eventKey="4" title={<span style={{ color: activeTab === '4' ? 'black' : 'yellow' }}>All Formats</span>} className="ImggTextt"> */}
            <div>
              <div className="text-center">
                {/* <div className="d-flex justify-content-center justify-content-md-start">
              <a
                href="#"
                className="rounded"
                style={{
                  textAlign: 'center',
                  background: 'black',
                  color: 'white',
                  textDecoration: 'none',
                  padding: '8px 20px',
                  marginTop: '11px',
                }}
              >
                <b>Step 01</b>
              </a>
            </div> */}
                <div className="selectDiv">
                  <div className="selector-container postais">
                    <select
                      className="selector"
                      name="preset"
                      id=""
                      value={preset}
                      onChange={(e) => setPreset(e.target.value)}
                    >
                      {stepOnePresets?.map((presets) => {
                        return (
                          <option
                            key={presets}
                            value={presets}
                            className="textai-option"
                          >
                            {presets}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="">
                  {preset === "Quote" && (
                    <div>
                      <SelectBox
                        presets={QuotePresets}
                        setInnerPreset={setInnerPreset}
                        innerPreset={innerPreset}
                      />
                      {/* <DesignButton
                        isDark={isDark}
                        isRandomBackground={isRandomBackground}
                        isRandomFont={isRandomFont}
                        isRandomLine={isRandomLine}
                        isRandomDesign={isRandomDesign}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckboxChange1={handleCheckboxChange1}
                        handleCheckboxChange2={handleCheckboxChange2}
                        handleCheckboxChange3={handleCheckboxChange3}
                        handleCheckboxChange4={handleCheckboxChange4}
                        setModalShow={setModalShow}
                        setModalShow1={setModalShow1}
                        setModalShow2={setModalShow2}
                        setModalShow3={setModalShow3}
                        setModalShow4={setModalShow4}
                      ></DesignButton> */}

                      {/* Will Implement later: Start */}

                      <div
                        className="d-flex align-items-center justify-content-center text-center mt-4"
                        onClick={() => {
                          Swal.fire({
                            title: "Coming Soon !",
                            // text: "This feature will be available soon!",
                            icon: "info",
                            confirmButtonText: "OK",
                            background: '#011225'
                          });
                        }}
                        style={{ cursor: "pointer" }} // Add pointer cursor for clickable effect
                      >
                        <select
                          className="form-select form-select-sm text-uppercase"
                          value={selectedOption}
                          onChange={handleOptionChange}
                          style={{ width: "220px", pointerEvents: "none" }} // Prevent interaction with the select element
                          disabled
                        >
                          <option value="">Please select</option>
                          <option value="Gradient">Gradient</option>
                          <option value="Random Colors">Colors</option>
                          <option value="Random Color Gradients">
                            Color Gradients
                          </option>
                          <option value="Classic Black and White">
                            B & W
                          </option>
                        </select>
                        {videoPosition == 0 ? (
                          " "
                        ) : (
                          <IconButton
                            style={{ color: "#ffa21f" }}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the SweetAlert from triggering
                              setModalShow(true);
                            }}
                            title="See sample video"
                            className="mt-0"
                          >
                            <InfoIcon />
                          </IconButton>
                        )}
                      </div>

                      {/* Will Implement later: End */}

                      <div className="mt-4 text-center">
                        <button
                          onClick={() => {
                            setInnerPreset(QuotePresets[0]);
                            setshow(false);
                            setshow1(false);
                          }}
                          className="clear-button rounded"
                        >
                          Clear
                        </button>

                        <button
                          disabled={isLoading}
                          onClick={() =>
                            generateImagePreset("all", "4", preset, innerPreset)
                          }
                          className={`${isLoading ? "generate-buttonDisabled" : "generate-button"} rounded`}
                        >
                          {isLoading ? "PROCESSING…" : "Generate"}
                        </button>
                      </div>
                      {/* <button disabled={isLoading}  className={`btn ${isLoading?"btn-danger":"btn-success"} btnFont buttonWidth my-2 `}onClick={() => generateImagePreset('all', '4', preset,innerPreset)}>
                  {isLoading?"PROCESSING…":"Generate"}
                                </button> */}
                    </div>
                  )}
                  {/* history */}
                  {preset === "History" && (
                    <div>
                      <SelectBox
                        presets={HistoryPresets}
                        setInnerPreset={setInnerPreset}
                        innerPreset={innerPreset}
                      />
                      <DesignButton
                        isDark={isDark}
                        isRandomBackground={isRandomBackground}
                        isRandomFont={isRandomFont}
                        isRandomLine={isRandomLine}
                        isRandomDesign={isRandomDesign}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckboxChange1={handleCheckboxChange1}
                        handleCheckboxChange2={handleCheckboxChange2}
                        handleCheckboxChange3={handleCheckboxChange3}
                        handleCheckboxChange4={handleCheckboxChange4}
                        setModalShow={setModalShow}
                        setModalShow1={setModalShow1}
                        setModalShow2={setModalShow2}
                        setModalShow3={setModalShow3}
                        setModalShow4={setModalShow4}
                      ></DesignButton>

                      <div className="mt-2 text-center">
                        <button
                          onClick={() => {
                            setInnerPreset(HistoryPresets[0]);
                            setshow(false);
                            setshow1(false);
                          }}
                          className="clear-button rounded"
                        >
                          Clear
                        </button>

                        <button
                          disabled={isLoading}
                          onClick={() =>
                            generateImagePreset("all", "4", preset, innerPreset)
                          }
                          className={`${isLoading ? "generate-buttonDisabled" : "generate-button"} rounded`}
                        >
                          {isLoading ? "PROCESSING…" : "Generate"}
                        </button>
                      </div>
                    </div>
                  )}
                  {/* Motivational Messages */}
                  {preset === "Motivational Message" && (
                    <div>
                      <SelectBox
                        presets={motivationalPresets}
                        setInnerPreset={setInnerPreset}
                        innerPreset={innerPreset}
                      />
                      <DesignButton
                        isDark={isDark}
                        isRandomBackground={isRandomBackground}
                        isRandomFont={isRandomFont}
                        isRandomLine={isRandomLine}
                        isRandomDesign={isRandomDesign}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckboxChange1={handleCheckboxChange1}
                        handleCheckboxChange2={handleCheckboxChange2}
                        handleCheckboxChange3={handleCheckboxChange3}
                        handleCheckboxChange4={handleCheckboxChange4}
                        setModalShow={setModalShow}
                        setModalShow1={setModalShow1}
                        setModalShow2={setModalShow2}
                        setModalShow3={setModalShow3}
                        setModalShow4={setModalShow4}
                      ></DesignButton>
                      <div className="mt-2 text-center">
                        <button
                          onClick={() => {
                            setInnerPreset(motivationalPresets[0]);
                            setshow(false);
                            setshow1(false);
                          }}
                          className="clear-button rounded"
                        >
                          Clear
                        </button>

                        <button
                          disabled={isLoading}
                          onClick={() =>
                            generateImagePreset("all", "4", preset, innerPreset)
                          }
                          className={`${isLoading ? "generate-buttonDisabled" : "generate-button"} rounded`}
                        >
                          {isLoading ? "PROCESSING…" : "Generate"}
                        </button>
                      </div>
                    </div>
                  )}
                  {/* Poetry */}
                  {preset === "Poetry" && (
                    <div>
                      <SelectBox
                        presets={PoetryPresets}
                        setInnerPreset={setInnerPreset}
                        innerPreset={innerPreset}
                      />
                      <DesignButton
                        isDark={isDark}
                        isRandomBackground={isRandomBackground}
                        isRandomFont={isRandomFont}
                        isRandomLine={isRandomLine}
                        isRandomDesign={isRandomDesign}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckboxChange1={handleCheckboxChange1}
                        handleCheckboxChange2={handleCheckboxChange2}
                        handleCheckboxChange3={handleCheckboxChange3}
                        handleCheckboxChange4={handleCheckboxChange4}
                        setModalShow={setModalShow}
                        setModalShow1={setModalShow1}
                        setModalShow2={setModalShow2}
                        setModalShow3={setModalShow3}
                        setModalShow4={setModalShow4}
                      ></DesignButton>
                      <div className="mt-2 text-center">
                        <button
                          onClick={() => {
                            setInnerPreset(PoetryPresets[0]);
                            setshow(false);
                            setshow1(false);
                          }}
                          className="clear-button rounded"
                        >
                          Clear
                        </button>

                        <button
                          disabled={isLoading}
                          onClick={() =>
                            generateImagePreset("all", "4", preset, innerPreset)
                          }
                          className={`${isLoading ? "generate-buttonDisabled" : "generate-button"} rounded`}
                        >
                          {isLoading ? "PROCESSING…" : "Generate"}
                        </button>
                      </div>
                    </div>
                  )}
                  {/* Short Stories */}
                  {preset === "Short Story" && (
                    <div>
                      <SelectBox
                        presets={shortStoriesPresets}
                        setInnerPreset={setInnerPreset}
                        innerPreset={innerPreset}
                      />
                      <DesignButton
                        isDark={isDark}
                        isRandomBackground={isRandomBackground}
                        isRandomFont={isRandomFont}
                        isRandomLine={isRandomLine}
                        isRandomDesign={isRandomDesign}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckboxChange1={handleCheckboxChange1}
                        handleCheckboxChange2={handleCheckboxChange2}
                        handleCheckboxChange3={handleCheckboxChange3}
                        handleCheckboxChange4={handleCheckboxChange4}
                        setModalShow={setModalShow}
                        setModalShow1={setModalShow1}
                        setModalShow2={setModalShow2}
                        setModalShow3={setModalShow3}
                        setModalShow4={setModalShow4}
                      ></DesignButton>
                      <div className="mt-2 text-center">
                        <button
                          onClick={() => {
                            setInnerPreset(shortStoriesPresets[0]);
                            setshow(false);
                            setshow1(false);
                          }}
                          className="clear-button rounded"
                        >
                          Clear
                        </button>

                        <button
                          disabled={isLoading1}
                          onClick={() =>
                            generateImage("all", "4", preset, innerPreset)
                          }
                          className={`${isLoading1 ? "generate-buttonDisabled" : "generate-button"} rounded`}
                        >
                          {isLoading1 ? "PROCESSING…" : "Generate"}
                        </button>
                      </div>
                    </div>
                  )}
                  {/* Poetry */}
                  {preset === "Recipe" && (
                    <div>
                      <SelectBox
                        presets={RecipesPresets}
                        setInnerPreset={setInnerPreset}
                        innerPreset={innerPreset}
                      />
                      <DesignButton
                        isDark={isDark}
                        isRandomBackground={isRandomBackground}
                        isRandomFont={isRandomFont}
                        isRandomLine={isRandomLine}
                        isRandomDesign={isRandomDesign}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckboxChange1={handleCheckboxChange1}
                        handleCheckboxChange2={handleCheckboxChange2}
                        handleCheckboxChange3={handleCheckboxChange3}
                        handleCheckboxChange4={handleCheckboxChange4}
                        setModalShow={setModalShow}
                        setModalShow1={setModalShow1}
                        setModalShow2={setModalShow2}
                        setModalShow3={setModalShow3}
                        setModalShow4={setModalShow4}
                      ></DesignButton>
                      <div className="mt-2 text-center">
                        <button
                          onClick={() => {
                            setInnerPreset(RecipesPresets[0]);
                            setshow(false);
                            setshow1(false);
                          }}
                          className="clear-button rounded"
                        >
                          Clear
                        </button>

                        <button
                          disabled={isLoading1}
                          onClick={() =>
                            generateImage("all", "4", preset, innerPreset)
                          }
                          className={`${isLoading1 ? "generate-buttonDisabled" : "generate-button"} rounded`}
                        >
                          {isLoading1 ? "PROCESSING…" : "Generate"}
                        </button>
                      </div>
                    </div>
                  )}
                  {preset === "Advertisement" && (
                    <div>
                      <ProductDescription
                        refs={childRef}
                        isLoading2={isLoading2}
                        setIsLoading2={setIsLoading2}
                        inputText={inputText}
                        setInputText={setInputText}
                        texts={text1}
                        setTexts={setText1}
                      />
                      <DesignButton
                        isDark={isDark}
                        isRandomBackground={isRandomBackground}
                        isRandomFont={isRandomFont}
                        isRandomLine={isRandomLine}
                        isRandomDesign={isRandomDesign}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckboxChange1={handleCheckboxChange1}
                        handleCheckboxChange2={handleCheckboxChange2}
                        handleCheckboxChange3={handleCheckboxChange3}
                        handleCheckboxChange4={handleCheckboxChange4}
                        setModalShow={setModalShow}
                        setModalShow1={setModalShow1}
                        setModalShow2={setModalShow2}
                        setModalShow3={setModalShow3}
                        setModalShow4={setModalShow4}
                      ></DesignButton>
                      <div className="mt-2 text-center">
                        <button
                          onClick={() => {
                            callChildFunction();
                            setshow(false);
                            setshow1(false);
                          }}
                          className="clear-button rounded"
                        >
                          Clear
                        </button>

                        <button
                          disabled={isLoading1 || isLoading2}
                          onClick={() =>
                            generateImageOwn("all", "4", text1, "")
                          }
                          className={`${isLoading1 || isLoading2 ? "generate-buttonDisabled" : "generate-button"} rounded`}
                        >
                          {isLoading1 ? "PROCESSING…" : "Generate"}
                        </button>
                      </div>
                      {/* <button disabled={isLoading1 || isLoading2}  className={`btn ${isLoading1?"btn-danger":"btn-success"} btnFont buttonWidth my-2 `}onClick={() =>generateImageOwn('all', '4', text1,"")}>
                  {isLoading1?"PROCESSING…":"Generate"}
                                </button> */}
                    </div>
                  )}
                  {/* Add Your Script */}
                  {preset === "Add Your Script" && (
                    <div>
                      {" "}
                      <AddYourScript text={text} setText={setText} />
                      <DesignButton
                        isDark={isDark}
                        isRandomBackground={isRandomBackground}
                        isRandomFont={isRandomFont}
                        isRandomLine={isRandomLine}
                        isRandomDesign={isRandomDesign}
                        handleCheckboxChange={handleCheckboxChange}
                        handleCheckboxChange1={handleCheckboxChange1}
                        handleCheckboxChange2={handleCheckboxChange2}
                        handleCheckboxChange3={handleCheckboxChange3}
                        handleCheckboxChange4={handleCheckboxChange4}
                        setModalShow={setModalShow}
                        setModalShow1={setModalShow1}
                        setModalShow2={setModalShow2}
                        setModalShow3={setModalShow3}
                        setModalShow4={setModalShow4}
                      ></DesignButton>
                      <div className="mt-2 text-center">
                        <button
                          onClick={() => {
                            setText("");
                            setshow(false);
                            setshow1(false);
                          }}
                          className="clear-button rounded"
                        >
                          Clear
                        </button>

                        <button
                          disabled={isLoading1 || isLoading2}
                          onClick={() => generateImageOwn("all", "4", text, "")}
                          className={`${isLoading1 || isLoading2 ? "generate-buttonDisabled" : "generate-button"} rounded`}
                        >
                          {isLoading1 ? "PROCESSING…" : "Generate"}
                        </button>
                      </div>
                      {/* <button disabled={isLoading1 || text.length>1600}  className={`btn ${isLoading1?"btn-danger":"btn-success"} btnFont buttonWidth my-2`}onClick={() =>generateImageOwn('all', '4', text,"")}>
                  {isLoading1?"PROCESSING…":"Generate"}
                                </button> */}
                    </div>
                  )}
                </div>
              </div>

              <p className="mb-3"></p>

              <div className="animate text-capitalize text-md-center mt-4 text-lg-center text-center d-flex justify-content-center force-small-font">
                Please do not navigate away from the browser. <br /> Doing so
                will stop the process.
              </div>

              {/* <button className="btn btn-danger btnFont" onClick={handleClick}>
                                   <i className="fa fa-refresh"></i>
                                </button> */}

              {show && (
                <div>
                  {(preset == "Quote" ||
                    "History" ||
                    "Motivational Message" ||
                    "Poetry") &&
                  isLoading ? (
                    <div>...</div>
                  ) : (
                    <div className="mt-2">
                      {imageUrls["4"][0] && (
                        <div className="mb-1">
                          <div
                            className="pngsvgImgdiv"
                            style={{ width: "300px", height: "300px" }}
                          >
                            <img
                              src={imageUrls["4"][0]}
                              alt="SVG Image 1"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>

                          <button
                            className="btn btn-primary mt-2"
                            onClick={downloadImage(0, imageUrls["4"][0])}
                            disabled={isDownloading[0]}
                          >
                            {isDownloading[0]
                              ? "Downloading..."
                              : "Download 1:1"}
                          </button>
                          <h6 className="text-start mt-2 mb-4">
                            1:1 - 1080 X 1080 px (Instagram, Facebook, Twitter,
                            LinkedIn and Pinterest)
                          </h6>
                        </div>
                      )}
                      <hr className="my-4" />
                      {imageUrls["4"][1] && (
                        <div className="mb-1">
                          <div
                            className="pngsvgImgdiv"
                            style={{ width: "300px", height: "auto" }}
                          >
                            <img
                              src={imageUrls["4"][1]}
                              alt="SVG Image 2"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>

                          <button
                            className="btn btn-primary mt-2"
                            onClick={downloadImage(1, imageUrls["4"][1])}
                            disabled={isDownloading[1]}
                          >
                            {isDownloading[1]
                              ? "Downloading..."
                              : "Download 16:9"}
                          </button>
                          <h6 className="text-start mt-2 mb-4">
                            16:9 - 1920 x 1080 px (YouTube, Instagram, Facebook,
                            Twitter, LinkedIn and Pinterest)
                          </h6>
                        </div>
                      )}
                      <hr className="my-4" />
                      {imageUrls["4"][2] && (
                        <div className="mb-1">
                          <div
                            className="pngsvgImgdiv"
                            style={{ width: "300px", height: "520px" }}
                          >
                            <img
                              src={imageUrls["4"][2]}
                              alt="SVG Image 3"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>

                          <button
                            className="btn btn-primary mt-2"
                            onClick={downloadImage(2, imageUrls["4"][2])}
                            disabled={isDownloading[2]}
                          >
                            {isDownloading[2]
                              ? "Downloading..."
                              : "Download 9:16"}
                          </button>
                          <h6 className="text-start mt-2 mb-4">
                            9: 16 - 1080 x 1920 px (Instagram, Facebook,
                            Snapchat, TikTok, YouTube and Pinterest)
                          </h6>
                          <form className="form-container">
                            <div className="form-item">
                              <textarea
                                className="generate-text-input mt-3 p-1 bg-white text-dark mb-2"
                                id="description"
                                name="description"
                                value={replyHashtag}
                                // onChange={handleChangeDescription}
                                // className="textarea-box"
                              />
                            </div>
                          </form>
                          <button
                            onClick={handleCopy}
                            className="d-flex mx-auto justify-content-center align-items-center px-4 py-2 gap-2 text-white btn btn-success"
                            style={{
                              width: "110px",
                              borderRadius: "5px",
                              fontSize: "16px",
                              color: "black",
                            }}
                          >
                            Copy
                            <FaRegCopy />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {location.state?.setShow && (
                <div>
                  {(location.state.preset == "Quote" ||
                    "History" ||
                    "Motivational Message" ||
                    "Poetry") &&
                  isLoading ? (
                    <div>...</div>
                  ) : (
                    <div className="mt-2">
                      {imageUrls["4"][0] && (
                        <div className="mb-1">
                          <div
                            className="pngsvgImgdiv"
                            style={{ width: "300px", height: "300px" }}
                          >
                            <img
                              src={imageUrls["4"][0]}
                              alt="SVG Image 1"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>

                          <button
                            className="btn btn-primary mt-2"
                            onClick={downloadImage(0, imageUrls["4"][0])}
                            disabled={isDownloading[0]}
                          >
                            {isDownloading[0]
                              ? "Downloading..."
                              : "Download 1:1"}
                          </button>
                          <h6 className="text-start mt-2 mb-4">
                            1:1 - 1080 X 1080 px (Instagram, Facebook, Twitter,
                            LinkedIn and Pinterest)
                          </h6>
                        </div>
                      )}
                      <hr className="my-4" />
                      {imageUrls["4"][1] && (
                        <div className="mb-1">
                          <div
                            className="pngsvgImgdiv"
                            style={{ width: "300px", height: "auto" }}
                          >
                            <img
                              src={imageUrls["4"][1]}
                              alt="SVG Image 2"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>

                          <button
                            className="btn btn-primary mt-2"
                            onClick={downloadImage(1, imageUrls["4"][1])}
                            disabled={isDownloading[1]}
                          >
                            {isDownloading[1]
                              ? "Downloading..."
                              : "Download 16:9"}
                          </button>
                          <h6 className="text-start mt-2 mb-4">
                            16:9 - 1920 x 1080 px (YouTube, Instagram, Facebook,
                            Twitter, LinkedIn and Pinterest)
                          </h6>
                        </div>
                      )}
                      <hr className="my-4" />
                      {imageUrls["4"][2] && (
                        <div className="mb-1">
                          <div
                            className="pngsvgImgdiv"
                            style={{ width: "300px", height: "520px" }}
                          >
                            <img
                              src={imageUrls["4"][2]}
                              alt="SVG Image 3"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>

                          <button
                            className="btn btn-primary mt-2"
                            onClick={downloadImage(2, imageUrls["4"][2])}
                            disabled={isDownloading[2]}
                          >
                            {isDownloading[2]
                              ? "Downloading..."
                              : "Download 9:16"}
                          </button>
                          <h6 className="text-start mt-2 mb-4">
                            9: 16 - 1080 x 1920 px (Instagram, Facebook,
                            Snapchat, TikTok, YouTube and Pinterest)
                          </h6>
                          <form className="form-container">
                            <div className="form-item">
                              <textarea
                                className="generate-text-input mt-3 p-1 bg-white text-dark mb-2"
                                id="description"
                                name="description"
                                value={replyHashtag}
                                // onChange={handleChangeDescription}
                                // className="textarea-box"
                              />
                            </div>
                          </form>
                          <button
                            onClick={handleCopy}
                            className="d-flex mx-auto justify-content-center align-items-center px-4 py-2 gap-2 text-white btn btn-success"
                            style={{
                              width: "110px",
                              borderRadius: "5px",
                              fontSize: "16px",
                              color: "black",
                            }}
                          >
                            Copy
                            <FaRegCopy />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {show1 && (
                <div>
                  {" "}
                  {(!preset ||
                    !location.state?.preset == "Short Story" ||
                    "Recipe" ||
                    "Advertisement" ||
                    "Add Your Script") &&
                  isLoading1 ? (
                    <div>...</div>
                  ) : (
                    <div className="mt-2">
                      {imageUrls1["4"][0] && (
                        <div className="">
                          <div className="row">
                            {imageUrls1["4"]?.map((res) => (
                              <div className="col-12 col-lg-4 mb-2">
                                {" "}
                                <div
                                  className="pngsvgImgdiv"
                                  style={{ width: "300px", height: "300px" }}
                                >
                                  <img
                                    src={res}
                                    alt="SVG Image 1"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="d-flex gap-2 flex-wrap">
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() =>
                                downloadImagesAsZip(imageUrls1["4"], "1-1-5")
                              }
                              disabled={isDownloading[0]}
                            >
                              {isDownloading[0]
                                ? "Downloading..."
                                : "Download 1:1 Images as zip"}
                            </button>
                            <button
                              className="btn btn-warning mt-2"
                              onClick={() => {
                                sendAsVideo("1080x1080", imageUrls1["4"]);
                              }}
                            >
                              {"Send as 1:1 Video to email"}
                            </button>
                          </div>
                          <h6 className="text-start mt-2 mb-4">
                            1:1 - 1080 X 1080 px (Instagram, Facebook, Twitter,
                            LinkedIn and Pinterest)
                          </h6>
                        </div>
                      )}
                      <hr className="my-4" />
                      {imageUrls1["5"][0] && (
                        <div className="">
                          <div className="row">
                            {imageUrls1["5"]?.map((res) => (
                              <div className="col-12 col-lg-4 mb-2">
                                {" "}
                                <div
                                  className="pngsvgImgdiv"
                                  style={{ width: "300px", height: "auto" }}
                                >
                                  <img
                                    src={res}
                                    alt="SVG Image 1"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="d-flex gap-2 flex-wrap">
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() =>
                                downloadImagesAsZip(imageUrls1["5"], "16-9-5")
                              }
                              disabled={isDownloading[1]}
                            >
                              {isDownloading[0]
                                ? "Downloading..."
                                : "Download 16:9 Images as zip"}
                            </button>
                            <button
                              className="btn btn-warning mt-2"
                              onClick={() => {
                                sendAsVideo("1920x1080", imageUrls1["5"]);
                              }}
                            >
                              {"Send as 16:9 Video to email"}
                            </button>
                          </div>
                          <h6 className="text-start mt-2 mb-4">
                            16:9 - 1920 x 1080 px (YouTube, Instagram, Facebook,
                            Twitter, LinkedIn and Pinterest)
                          </h6>
                        </div>
                      )}

                      <hr className="my-4" />
                      {imageUrls1["6"][0] && (
                        <div className="">
                          <div className="row">
                            {imageUrls1["6"]?.map((res) => (
                              <div className="col-12 col-lg-4 mb-2">
                                {" "}
                                <div
                                  className="pngsvgImgdiv"
                                  style={{ width: "300px", height: "520px" }}
                                >
                                  <img
                                    src={res}
                                    alt="SVG Image 1"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="d-flex gap-2 flex-wrap">
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() =>
                                downloadImagesAsZip(imageUrls1["6"], "9-16-5")
                              }
                              disabled={isDownloading[2]}
                            >
                              {isDownloading[0]
                                ? "Downloading..."
                                : "Download 9:16 Images as zip "}
                            </button>
                            <button
                              className="btn btn-warning mt-2"
                              onClick={() => {
                                sendAsVideo("1080x1920", imageUrls1["6"]);
                              }}
                            >
                              {"Send as 9:16 Video to email"}
                            </button>
                          </div>
                          <h6 className="text-start mt-2 mb-4">
                            9: 16 - 1080 x 1920 px (Instagram, Facebook,
                            Snapchat, TikTok, YouTube and Pinterest)
                          </h6>
                          <form className="form-container">
                            <div className="form-item">
                              <textarea
                                className="generate-text-input mt-3 p-1 bg-white text-dark mb-2"
                                id="description"
                                name="description"
                                value={replyHashtag}
                                // onChange={handleChangeDescription}
                                // className="textarea-box"
                              />
                            </div>
                          </form>
                          <button
                            onClick={handleCopy}
                            className="d-flex mx-auto justify-content-center align-items-center px-4 py-2 gap-2 text-white btn btn-success"
                            style={{
                              width: "110px",
                              borderRadius: "5px",
                              fontSize: "16px",
                              color: "black",
                            }}
                          >
                            Copy
                            <FaRegCopy />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {location.state?.setShow && (
                <div>
                  {" "}
                  {(!location.state?.preset == "Short Story" ||
                    "Recipe" ||
                    "Advertisement" ||
                    "Add Your Script") &&
                  isLoading1 ? (
                    <div>...</div>
                  ) : (
                    <div className="mt-2">
                      {imageUrls1["4"][0] && (
                        <div className="">
                          <div className="row">
                            {imageUrls1["4"]?.map((res) => (
                              <div className="col-12 col-lg-4 mb-2">
                                {" "}
                                <div
                                  className="pngsvgImgdiv"
                                  style={{ width: "300px", height: "300px" }}
                                >
                                  <img
                                    src={res}
                                    alt="SVG Image 1"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="d-flex gap-2 flex-wrap">
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() =>
                                downloadImagesAsZip(imageUrls1["4"], "1-1-5")
                              }
                              disabled={isDownloading[0]}
                            >
                              {isDownloading[0]
                                ? "Downloading..."
                                : "Download 1:1 Images as zip"}
                            </button>
                            <button
                              className="btn btn-warning mt-2"
                              onClick={() => {
                                sendAsVideo("1080x1080", imageUrls1["4"]);
                              }}
                            >
                              {"Send as 1:1 Video to email"}
                            </button>
                          </div>
                          <h6 className="text-start mt-2 mb-4">
                            1:1 - 1080 X 1080 px (Instagram, Facebook, Twitter,
                            LinkedIn and Pinterest)
                          </h6>
                        </div>
                      )}
                      <hr className="my-4" />
                      {imageUrls1["5"][0] && (
                        <div className="">
                          <div className="row">
                            {imageUrls1["5"]?.map((res) => (
                              <div className="col-12 col-lg-4 mb-2">
                                {" "}
                                <div
                                  className="pngsvgImgdiv"
                                  style={{ width: "300px", height: "auto" }}
                                >
                                  <img
                                    src={res}
                                    alt="SVG Image 1"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="d-flex gap-2 flex-wrap">
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() =>
                                downloadImagesAsZip(imageUrls1["5"], "16-9-5")
                              }
                              disabled={isDownloading[1]}
                            >
                              {isDownloading[0]
                                ? "Downloading..."
                                : "Download 16:9 Images as zip"}
                            </button>
                            <button
                              className="btn btn-warning mt-2"
                              onClick={() => {
                                sendAsVideo("1920x1080", imageUrls1["5"]);
                              }}
                            >
                              {"Send as 16:9 Video to email"}
                            </button>
                          </div>
                          <h6 className="text-start mt-2 mb-4">
                            16:9 - 1920 x 1080 px (YouTube, Instagram, Facebook,
                            Twitter, LinkedIn and Pinterest)
                          </h6>
                        </div>
                      )}

                      <hr className="my-4" />
                      {imageUrls1["6"][0] && (
                        <div className="">
                          <div className="row">
                            {imageUrls1["6"]?.map((res) => (
                              <div className="col-12 col-lg-4 mb-2">
                                {" "}
                                <div
                                  className="pngsvgImgdiv"
                                  style={{ width: "300px", height: "520px" }}
                                >
                                  <img
                                    src={res}
                                    alt="SVG Image 1"
                                    style={{ width: "100%", height: "100%" }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="d-flex gap-2 flex-wrap">
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() =>
                                downloadImagesAsZip(imageUrls1["6"], "9-16-5")
                              }
                              disabled={isDownloading[2]}
                            >
                              {isDownloading[0]
                                ? "Downloading..."
                                : "Download 9:16 Images as zip "}
                            </button>
                            <button
                              className="btn btn-warning mt-2"
                              onClick={() => {
                                sendAsVideo("1080x1920", imageUrls1["6"]);
                              }}
                            >
                              {"Send as 9:16 Video to email"}
                            </button>
                          </div>
                          <h6 className="text-start mt-2 mb-4">
                            9: 16 - 1080 x 1920 px (Instagram, Facebook,
                            Snapchat, TikTok, YouTube and Pinterest)
                          </h6>
                          <form className="form-container">
                            <div className="form-item">
                              <textarea
                                className="generate-text-input mt-3 p-1 bg-white text-dark mb-2"
                                id="description"
                                name="description"
                                value={replyHashtag}
                                // onChange={handleChangeDescription}
                                // className="textarea-box"
                              />
                            </div>
                          </form>
                          <button
                            onClick={handleCopy}
                            className="d-flex mx-auto justify-content-center align-items-center px-4 py-2 gap-2 text-white btn btn-success"
                            style={{
                              width: "110px",
                              borderRadius: "5px",
                              fontSize: "16px",
                              color: "black",
                            }}
                          >
                            Copy
                            <FaRegCopy />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {/* {(!isLoading1 || !isLoading) && (show) &&
                    <div>
                  
                    {(preset == 'Quote') && <div><p>Prompt: Generate a one liner {innerPreset}</p><p style={{whiteSpace:"pre-line",textTransform:"none",textTransform:"none"}}>REPLY: {reply }</p></div>}
                  
                  </div>}
                  {(!isLoading1 || !isLoading) && (show) && <div>
                 
                    {(preset == 'Poetry') && <div><p>Prompt: Generate a one liner Poetry of {innerPreset}</p><p style={{whiteSpace:"pre-line",textTransform:"none"}}>REPLY: {reply}</p></div>}
                  
                  </div>}
                  {(!isLoading1 || !isLoading) && (show) && <div>
                  
                    {(preset ==  'Motivational Message') && <div><p>Prompt: Generate a one liner Motivational Message with {innerPreset}</p><p style={{whiteSpace:"pre-line",textTransform:"none"}}>REPLY: {reply}</p></div>}
                  
                  </div>}
                  {(!isLoading1 || !isLoading) && (show) && <div>
                  
                    {(preset == 'History') && <div><p>Prompt: Generate a one liner History Information {innerPreset=="Timelines of significant periods"?"of":"with"} {innerPreset}</p><p style={{whiteSpace:"pre-line",textTransform:"none"}}>REPLY: {reply }</p></div>}
                  
                  </div>}

                  {(!isLoading1 || !isLoading) && (show1 ) && <p>
                    
                    {(preset=='Short Story') && <div><p>Prompt: Generate a short story with the following requirements:
            1. Genre: {innerPreset}
            2. Spilt in paragraphs with 160 characters per paragraph
            3. Atleast 1200 number of characters
            4. Total number of characters: 1600
            5. Interesting Title
                    </p><p style={{ whiteSpace: "pre-line" }}>REPLY: {reply}</p></div>}
                  </p>}

                  {(!isLoading1 || !isLoading) && (show1 ) && <p>
                   
                      {(preset == 'Recipe') && <div><p>Prompt: Generate a short recipe with the following requirements:
            1. Type:  {innerPreset}
            2. Spilt in paragraphs with 160 characters per paragraph 
            3. Atleast 1200 number of characters
            4. Total number of characters: 1600
            5. Interesting Recipe Titleipe Title
                    </p><p style={{ whiteSpace: "pre-line" }}>REPLY: {reply}</p></div>}
                  </p>}

                  {(!isLoading1 || !isLoading) && (show1) && <p>
                  
                  {(preset == 'Advertisement') && <div><p>Prompt: Write a creative ad for the following product to run on Social Media: ${inputText}</p><p style={{whiteSpace:"pre-line",textTransform:"none"}}>REPLY: {reply }</p></div>} 
                    {(preset == 'Add Your Script') && <div><p>Your Script: {text}</p><p style={{whiteSpace:"pre-line",textTransform:"none"}}>REPLY: {reply}</p></div>} 
                  </p>} */}
              <SampleImageModal
                setModalShow={setModalShow}
                modalShow={modalShow}
              />
              <SampleImageModal1
                setModalShow={setModalShow1}
                modalShow={modalShow1}
              />
              <SampleImageModal2
                setModalShow={setModalShow2}
                modalShow={modalShow2}
              />
              <SampleImageModal2
                setModalShow={setModalShow3}
                modalShow={modalShow3}
              />
              <SampleImageModal2
                setModalShow={setModalShow4}
                modalShow={modalShow4}
              />
            </div>
            {/* </Tab>
                    </Tabs> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestVideoImage05;

const SelectBox = ({ presets, setInnerPreset, innerPreset }) => {
  const handleChange = (e) => {
    setInnerPreset(e.target.value);
  };
  return (
    <div className="selectDiv">
      <div className="selector-container w-100 postais">
        <select
          className="selector"
          name="preset"
          id=""
          value={innerPreset}
          onChange={handleChange}
        >
          {presets?.map((presets) => {
            return (
              <option key={presets} value={presets} className="textai-option">
                {" "}
                {presets}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};
