import React from "react";
import ReactMarkdown from "react-markdown";
import SUsample from "./SUsample";
import { FaCheckSquare } from "react-icons/fa";

const SU = ({ inputText, isGenerating, text, presetSelected, wordRef }) => {


  console.log("statussssssssssssssssssssssssssss==============>>>>>>", isGenerating)
  console.log("texttttttttttt==============>>>>>>", inputText)
  const formattedText = inputText.replace(/\n\n/g, '<br><br>').replace(/\n/g, '<br>');
  return (
    <>
      <p class="preset-title">Summarize</p>
      {inputText ? (
          <>
            <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
            {/* <p class="text-muted">{inputText}</p> */}
            <p className="text-muted" dangerouslySetInnerHTML={{ __html: formattedText }}></p>

            {isGenerating == true ? (
              <p
                class="text- mb-0 mt-2"
                style={{
                  fontSize: "14px",
                  color: "#A020F0",
                }}
              >
                Processing...
              </p>
            ) : (
              <>
                {text && (
                  <p
                    style={{
                      color: "black",
                    }}
                    class="text- mb-0 text-"
                  >
                    {wordRef.current && (
                      <>
                      <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
                        <p style={{ color: "black" }}>
                          <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                      </>
                    )}
                  </p>
                )}
              </>
            )}
          </>
      ) : (
        <>
          {/* <p className="text-muted" style={{ fontSize:"14px" }}>
          Artificial Intelligence (AI) is a transformative field of computer science focused on creating machines that simulate human intelligence, enabling them to perform tasks such as decision-making, pattern recognition, and language processing. With advancements in machine learning, deep learning, natural language processing, and computer vision, AI systems are now integrated into various sectors, including healthcare, finance, and transportation. In healthcare, AI aids in diagnostics and personalized treatments by analyzing medical data with precision, while in finance, it enhances fraud detection and automates trading. AI also powers virtual assistants, autonomous vehicles, and recommendation systems, continually learning and improving from data. Despite its current limitations in achieving human-level general intelligence, AI holds vast potential for future innovation, reshaping industries and everyday life as it continues to evolve.
          </p>

          <p
     style={{ color: "black",fontSize:"14px" }}
          >
            The Transformative Power of Artificial Intelligence: Advancements, Applications, and Potential.
          </p> */}
          <SUsample/>

          <p style={{ color: "black",fontSize:"14px" }}>
            <ReactMarkdown>{presetSelected.label}</ReactMarkdown>
          </p>
        </>
      )}
    </>
  );
};

export default SU;
