import React from "react";

const SFG = ({ inputText, isGenerating, text, presetSelected }) => {
  return (
    <>
      <p class="preset-title">Summarize For a 2nd Grader</p>

      {inputText ? (
        <>
          <p class="text-muted">{inputText}</p>

          {isGenerating == true ? (
            <p
              class="text- mb-0 mt-2"
              style={{
                fontSize: "14px",
                color: "black",
              }}
            >
              Processing...
            </p>
          ) : (
            <>
              {text && (
                <p
                  style={{
                    color: "black",
                  }}
                  class="text- mb-0 text-"
                >
                  {text}
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <p class="text-muted" style={{ fontSize:"14px" }}>{presetSelected?.label}</p>
          <p
          style={{ color: "black",fontSize:"14px" }}
          >
            Jupiter is the fifth planet from the Sun. It is very big and bright,
            and has been known since ancient times. It was named after the Roman
            god Jupiter and it is so bright that it can cast shadows. It is the
            third brightest object in the night sky after the Moon and Venus.
          </p>
        </>
      )}
    </>
  );
};

export default SFG;
