import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  Typography,
  Box,
  DialogContent,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useContext, useState } from "react";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import Swal from "sweetalert2";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ViewComic({ setComicModal, comicModal, oneNews }) {
  
  const { getAllNewsData } = useContext(AdminAuthContext);
  const [isWritting, setIsWritting] = useState(false);
  const [isComic, setIsComic] = useState(false);
  const [newSummary, setNewSummary] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [comicSummary, setComicSummary] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [editedComic, setEditedComic] = useState('');

  const handleClose = () => {
    setComicModal(false);
    setNewSummary("");
    setIsEditing(false);
    setEditedComic('')
    // setComicSummary("");
  };

  React.useEffect( ()  => {
    setComicSummary(oneNews?.comicSummary)
    getAllNewsData();
  }, [oneNews])

  const handleReWrite = async () => {
    // setNewSummary('');
    setIsComic(false);
    setIsWritting(true);
    try {
      const response = await axios.put(
        `https://backend.whalesai.com/api/v1/admin/news/rewrite/${oneNews?._id}`,
        {
          summary: oneNews?.summary,
        }
      );
      console.log("response===========>>>>>>>", response);
      setNewSummary(response?.data?.news?.summary);
      getAllNewsData();
      setIsWritting(false);
    } catch (error) {
      console.error("Error updating the news summary:", error);
    }
  };

  const handleComic = async () => {
    setIsWritting(false);
    setIsComic(true);
    try {
      const response = await axios.put(
        `https://backend.whalesai.com/api/v1/admin/news/rewrite/comic/${oneNews?._id}`,
        {
          summary: oneNews?.summary,
        }
      );
      console.log("response===========>>>>>>>", response);
      setNewSummary(response?.data?.news?.summary);
      getAllNewsData();
      setIsComic(false);
    } catch (error) {
      console.error("Error updating the news summary:", error);
    }
  };

  console.log("One news::::::::::", newSummary);
  console.log(isComic, "comic", isWritting);

  const handleCopy = () => {
    // Create a string with the title and newSummary
    const textToCopy = `${oneNews?.title || "No Title Available"}\n\n${newSummary || oneNews?.summary || ""}`;

    // Copy the text to the clipboard
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Show SweetAlert2 after successfully copying
        setComicModal(false);
        Swal.fire({
          // title: 'Copied!',
          text: "The news details have been copied to your clipboard.",
          background: "#011225",
          color: "#fff",
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "#1c76e0", // Optional: Customize button color
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        Swal.fire({
          title: "Error!",
          text: "Failed to copy the text. Please try again.",
          background: "#011225",
          color: "#fff",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#1c76e0", // Optional: Customize button color
        });
      });
  };

  //   const formatParagraph = (text) => {
  //     const formattedText = text
  //       .replace(/(\*\*|##)(.*?)\1/g, (match, marker, content) => `<br/><strong>${content}</strong>`);
  //     return formattedText;
  //   };

  //working good
  // const formatParagraph = (text) => {
  //     const formattedText = text
  //       // Add line breaks before "MALE:" or "FEMALE:" if found
  //       .replace(/(MALE|FEMALE|Male|Female)/g, (match) => `<br/><strong>${match}</strong>`)
  //       // Add line breaks and bold formatting for ** or ## markers around text
  //       .replace(/(\*\*|##)(.*?)\1/g, (match, marker, content) => `<br/><strong>${content}</strong>`);

  //     return formattedText;
  // };
  // const formatParagraph = (text) => {
  //   const formattedText = text
  //     .replace(/Panel \d+:/g, "")
  //     .replace(/Panel \d+/g, "")
  //     .replace(/---/g, "")
  //     .replace(
  //       /(MALE|FEMALE|Male|Female)([^:]*):(.+)/g,
  //       (match, gender, extraText, rest) =>
  //         `<br/><strong>${gender}${extraText}:</strong> ${rest}`
  //     )
  //     .replace(
  //       /(\*\*|##)(.*?)\1/g,
  //       (match, marker, content) => `<br/><strong>${content}</strong>`
  //     );

  //   return formattedText;
  // };
  const formatParagraph = (text) => {
    const formattedText = text
      .replace(/Panel \d+:/g, "")
      .replace(/Panel \d+/g, "")
      .replace(/---/g, "")
      .replace(
              /(MALE|FEMALE|Male|Female)([^:]*):(.+)/g,
              (match, gender, extraText, rest) =>
                `<br/><strong>${gender}${extraText}:</strong> ${rest}`
            )
      .replace(
        /(MALE|FEMALE|Male|Female)\s+Character:/g, // This regex will match 'Male Character:' or 'Female Character:'
        (match, gender) =>
          `<br/><strong>${gender}:</strong>`  // Keep only 'Male:' or 'Female:' without 'Character'
      )
      .replace(
        /(\*\*|##)(.*?)\1/g,
        (match, marker, content) => `<br/><strong>${content}</strong>`
      );
  
    return formattedText;
  };
  
  
  

  console.log('Its Changing......',oneNews);

  const handleEdit = async () => {
    setEditedComic('')
    setIsSaving(true);
    try {
      const response = await axios.put(
        `https://backend.whalesai.com/api/v1/admin/news/rewrite/comic/edit/${oneNews?._id}`,
        { summary: comicSummary }
      );
      console.log("Edit successful:", response.data);
      setEditedComic(response?.data?.summary);
      getAllNewsData();
      setIsEditing(false);
      setIsSaving(false);
    } catch (error) {
      console.error("Error editing summary:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
    // handleEdit();
  }

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={comicModal}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Comic Strip Details</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="d-flex justify-content-end mb-1 mt-0">
            <button
              onClick={() => {
                handleCopy();
              }}
              className="btn btn-warning"
            >
              Copy
            </button>
          </div>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 1, textAlign: "left" }}
            >
              {oneNews?.title || "No Title Available"}
            </Typography>

            {isEditing ? (
              //   <textarea
              //   value={oneNews?.comicSummary || ""}
              //   onChange={(e) => setComicSummary(e.target.value)}
              //   style={{
              //     width: "100%",
              //     minHeight: "250px",
              //     padding: "10px",
              //     fontSize: "16px",
              //     borderRadius: '8px',
              //     marginBottom: '10px'
              //   }}
              // />
              <textarea
                value={comicSummary}
                onChange={(e) => setComicSummary(e.target.value)} // Update the value as the user types
                style={{
                  width: "100%",
                  minHeight: "250px",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              />
            ) : editedComic ? (
              <Typography
                variant="body2"
                sx={{ color: "#555", mb: 2, textAlign: "left" }}
              >
                <strong></strong>
                {editedComic
                  ?.split("\n\n")
                  .map((paragraph, index) => (
                    <span
                      key={index}
                      // className={index % 2 === 0 ? "align-left" : "align-right"}
                      className={
                        editedComic?.includes("Panel")
                          ? index % 2 === 0
                            ? "align-right"
                            : "align-left"
                          : index % 2 === 0
                            ? "align-left"
                            : "align-right"
                      }
                      // style={{ textAlign: index % 2 === 0 ? "left" : "right" }}
                      dangerouslySetInnerHTML={{
                        __html: formatParagraph(paragraph),
                      }}
                    />
                    // paragraph[0]
                  ))}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{ color: "#555", mb: 2, textAlign: "left" }}
              >
                <strong></strong>
                {isWritting ? (
                  <p className="text-center my-5">
                    <strong>Re-Writing . . .</strong>
                  </p>
                ) : (
                  <div>
                    {isComic ? (
                      <p className="text-center my-5">
                        <strong>Re-Writing Comic Strip . . .</strong>
                      </p>
                    ) : (
                      oneNews?.comicSummary
                        ?.split("\n\n")
                        .map((paragraph, index) => (
                          <span
                            key={index}
                            // className={index % 2 === 0 ? "align-left" : "align-right"}
                            className={
                              oneNews?.comicSummary.includes("Panel")
                                ? index % 2 === 0
                                  ? "align-right"
                                  : "align-left"
                                : index % 2 === 0
                                  ? "align-left"
                                  : "align-right"
                            }
                            // style={{ textAlign: index % 2 === 0 ? "left" : "right" }}
                            dangerouslySetInnerHTML={{
                              __html: formatParagraph(paragraph),
                            }}
                          />
                          // paragraph[0]
                        ))
                    )}
                  </div>
                )}
              </Typography>
            )}

            {/* {newSummary ? (
              <Typography
                variant="body2"
                sx={{ color: "#555", mb: 2, textAlign: "left" }}
              >
                <strong></strong>
                {
                oneNews?.comicSummary?.split('\n\n').map((paragraph, index) => (
                  <span
                  key={index}
                  // className={index % 2 === 0 ? "align-left" : "align-right"}
                  className={oneNews?.comicSummary.includes("Panel")
                    ? (index % 2 === 0 ? "align-right" : "align-left")
                    : (index % 2 === 0 ? "align-left" : "align-right")}
                  // style={{ textAlign: index % 2 === 0 ? "left" : "right" }}
                    dangerouslySetInnerHTML={{ __html: formatParagraph(paragraph) }}
                  />
                  // paragraph[0]
                ))}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{ color: "#555", mb: 2, textAlign: "left" }}
              >
                <strong></strong>
                {isWritting ? (
                  <p className="text-center my-5">
                    <strong>Re-Writing . . .</strong>
                  </p>
                ) : 
                <div>
                  {
                    isComic? <p className="text-center my-5">
                    <strong>Re-Writing Comic Strip . . .</strong>
                  </p> : (
                      oneNews?.comicSummary?.split('\n\n').map((paragraph, index) => (
                        <span
                        key={index}
                        // className={index % 2 === 0 ? "align-left" : "align-right"}
                        className={oneNews?.comicSummary.includes("Panel")
                          ? (index % 2 === 0 ? "align-right" : "align-left")
                          : (index % 2 === 0 ? "align-left" : "align-right")}
                        // style={{ textAlign: index % 2 === 0 ? "left" : "right" }}
                          dangerouslySetInnerHTML={{ __html: formatParagraph(paragraph) }}
                        />
                        // paragraph[0]
                      ))
                    )
                  }
                </div>
                
                }
              </Typography>
            )} */}

            <Box display="flex" justifyContent="space-between">
              {isEditing ? (
                <div>
                  <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#157347" }}
                  onClick={handleEdit}
                >
                  {isSaving ? 'SAVING...' : 'SAVE'}
                </Button>
                <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#FFcA2C", marginLeft: '10px', color: 'black' }}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                CANCEL
              </Button>
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#157347" }}
                  onClick={handleComic}
                >
                  RE-WRITE COMIC STRIP
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#BB2D3B" }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>

            <Box display="flex" justifyContent="space-between">
              {/* <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#3080AC", marginTop: '10px' }}
                onClick={handleEdit}
              >
                Edit
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: isEditing ? "gray" : "#3080AC",
                  marginTop: "10px",
                }}
                onClick={handleEditClick}
                disabled={isEditing} // Disable button when isEditing is true
              >
                Edit
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
