import React, { useState, useEffect, useContext } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import Swal from "sweetalert2";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import axios from "axios";

const ContactUs = () => {
  const [otpSent, setOtpSent] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { AIuser } = useContext(UserAuthContext);
  const [userCountry, setUserCountry] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    verificationCode: "",
    message: "",
    country: AIuser?.country,
  });
  const [OTP, setOTP] = useState();
  const [VerificationToken, setVerificationToken] = useState();

  console.log("User", userCountry);

  useEffect(() => {
    window.scrollTo(0, 0);

    axios
      .get("https://api.ipify.org/?format=json")
      .then((response) => {
        const ipAddress = response.data.ip;
        axios
          .get(`https://ipapi.co/${ipAddress}/json/`)
          .then((response) => {
            setUserCountry(response.data.country_name);
            console.log(
              "dataaaaaaaaaaaaaaaaaaaaaaa ========> :::",
              response.data.country_name
            );
          })
          .catch((error) => {
            console.error("Error fetching user country:", error);
            setUserCountry("Unknown");
          });
      })
      .catch((error) => {
        console.error("Error fetching user IP address:", error);
        setUserCountry("Unknown");
      });
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSendOtp = async () => {
    if (!formData.email) {
      Swal.fire({
        text: "Please enter a valid email before sending OTP.",
        icon: "warning",
        background: "#011225",
        color: "white",
        allowOutsideClick: false,
      });
      return;
    }

    try {
      const response = await fetch(
        "https://backend.whalesai.com/api/v1/contact-us/send-email-verification-code",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: formData.email }),
        }
      );

      const data = await response.json();
      setOTP(data?.otp);
      setVerificationToken(data?.token);
      console.log('Token:::::', data?.token);

      if (response.ok) {
        setOtpSent(true);
        setCountdown(120);
        Swal.fire({
          title: "OTP Sent",
          text: "Please check your email for the OTP code.",
          icon: "success",
          background: "#011225",
          color: "white",
          allowOutsideClick: false,
        });

        const interval = setInterval(() => {
          setCountdown((prev) => {
            if (prev === 1) {
              clearInterval(interval);
            }
            return prev - 1;
          });
        }, 1000);
      } else {
        Swal.fire({
          title: "Error",
          text: data.message || "Failed to send OTP",
          icon: "error",
          background: "#011225",
          color: "white",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while sending OTP.",
        icon: "error",
        background: "#011225",
        color: "white",
        allowOutsideClick: false,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.message) {
      Swal.fire({
        text: "Please fill in all required fields.",
        icon: "warning",
        background: "#011225",
        color: "white",
        allowOutsideClick: false,
      });
      return;
    }

    if (!formData.verificationCode) {
      Swal.fire({
        text: "Please enter email verification code.",
        icon: "warning",
        background: "#011225",
        color: "white",
        allowOutsideClick: false,
      });
      return;
    }

    try {
      const response = await fetch(
        "https://backend.whalesai.com/api/v1/contact-us/save-full-data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${VerificationToken}`,
          },
          body: JSON.stringify({
            name: formData.name,
            emailVerificationCode: formData.verificationCode,
            message: formData.message,
            subject: formData.subject,
            country: userCountry,
          }),
        }
      );

      const data = await response.json();

      console.log("After submitting the form The respnse is::::::", response);
      console.log("The data is::::::", data);

      if (response.ok) {
        Swal.fire({
          text: "Your message has been successfully sent.",
          icon: "success",
          background: "#011225",
          color: "white",
          allowOutsideClick: false,
        });
        setFormData({
          name: "",
          verificationCode: "",
          message: "",
          email: "",
          subject: "",
        });
        setOtpSent(false);
        setCountdown(0);
      } else {
        Swal.fire({
          text: data.message || "Failed to send your message.",
          icon: "error",
          background: "#011225",
          color: "white",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error("Error submitting the message:", error);
      Swal.fire({
        text: "An error occurred while sending your message.",
        icon: "error",
        background: "#011225",
        color: "white",
        allowOutsideClick: false,
      });
    }
  };

  
// const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     // Validate required fields
//     if (!formData.name || !formData.message) {
//       Swal.fire({
//         text: "Please fill in all required fields.",
//         icon: "warning",
//         background: "#011225",
//         color: "white",
//         allowOutsideClick: false,
//       });
//       return;
//     }
  
//     if (!formData.verificationCode) {
//       Swal.fire({
//         text: "Please enter email verification code.",
//         icon: "warning",
//         background: "#011225",
//         color: "white",
//         allowOutsideClick: false,
//       });
//       return;
//     }
  
//     // Prepare data for submission
//     const requestBody = {
//       name: formData.name,
//       emailVerificationCode: formData.verificationCode,
//       message: formData.message,
//       subject: formData.subject,
//       country: userCountry,
//     };

//     console.log("Sending request with data:", requestBody);
  
//     try {
//       const response = await fetch(
//         "https://backend.whalesai.com/api/v1/contact-us/save-full-data",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${VerificationToken}`, // Check if VerificationToken is available
//           },
//           body: JSON.stringify(requestBody),
//         }
//       );
  
//       // Check for successful response
//       if (!response.ok) {
//         const data = await response.json();
//         console.error("Error response:", data);
//         Swal.fire({
//           text: data.message || "Failed to send your message.",
//           icon: "error",
//           background: "#011225",
//           color: "white",
//           allowOutsideClick: false,
//         });
//         return;
//       }
  
//       // If response is okay, show success message
//       Swal.fire({
//         text: "Your message has been successfully sent.",
//         icon: "success",
//         background: "#011225",
//         color: "white",
//         allowOutsideClick: false,
//       });
  
//       // Reset form data
//       setFormData({
//         name: "",
//         verificationCode: "",
//         message: "",
//         email: "",
//         subject: "",
//       });
//       setOtpSent(false);
//       setCountdown(0);
//     } catch (error) {
//         console.error("Error submitting the message:", error.message);
//         Swal.fire({
//           text: "An error occurred while sending your message: " + error.message,
//           icon: "error",
//           background: "#011225",
//           color: "white",
//           allowOutsideClick: false,
//         });
//       }      
//   };


  
  const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  console.log("Data===>>", formData);

  return (
    <div>
      <PageHeading title="Contact us" />

      <section
        className="container mx-auto account-container pb-5 px-4"
        style={{ color: "white" }}
      >
        <form className="contact-form">
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3" style={{ position: "relative" }}>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              required
              value={formData?.email?.toLocaleLowerCase()}
              onChange={handleChange}
            />
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                position: "absolute",
                top: "50%",
                right: "0",
                transform: "translateY(-50%)",
              }}
              onClick={handleSendOtp}
              disabled={countdown > 0} 
            >
              {countdown > 0
                ? `Resend OTP in ${formatCountdown(countdown)}`
                : otpSent
                  ? "Resend OTP"
                  : "Send OTP"}
            </button>
          </div>

          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              id="verificationCode"
              required
              placeholder="Email Verification Code"
              value={formData.verificationCode}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="subject"
              required
              placeholder="Subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <textarea
              className="form-control"
              id="message"
              rows="5"
              required
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>

          <button
            onClick={handleSubmit}
            type="submit"
            className="btn btn-primary"
          >
            Submit
          </button>
        </form>
      </section>
    </div>
  );
};

export default ContactUs;
