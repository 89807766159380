import React, { useEffect } from "react";
import "./PaymentMethods.css";
import PageHeading from "../../Components/PageHeading/PageHeading";
import {
  CardElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";


import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useContext } from "react";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import axios from "axios";
import { AddCircleOutlined, ArrowDropUpOutlined } from "@mui/icons-material";

import swal from "sweetalert";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import PaymentMethodForm from "./PaymentMethodForm";
import CardLists from "./CardLists";
const stripePromise = loadStripe(
  "pk_test_51L0pRfJbegYKTJYPu7wYHHQHTkdeBJeEj0PibmVp39Do9lGIK2Fdq7RC4vZa4yOzZSlHxa7dY6oYPPySQaBWmpPy00BLMhz0Yk"
);

const usePaymentMethodForm = () => {
  const { AIuser, setUserRefetch, userRefetch } = useContext(UserAuthContext);

  const stripe = useStripe();
  
  const elements = useElements();
  const [refetch, setrefetch] = useState(false);
  const [loader, setLoader] = useState(false);

  const [showCardForm, setShowCardForm] = useState(false);
  const [cardError, setCardError] = useState("");
  const [userDetails, setuserDetails] = useState({
    name: AIuser?.name,
    email: AIuser?.email,
    country: "",
    city: "",
  });





  

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setCardError("");

      setLoader(true);


      const cardElement = elements.getElement(CardElement);


      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          address: {
            country: userDetails?.country,
            city: userDetails?.city,
          },
          name: AIuser?.name,
          email: AIuser?.email,
        },
      });


      if (error) {
        console.log("[error]", error);
        setCardError(error.message);
        setLoader(false);
      } else {
        const response = await axios.post(
          "https://backend.whalesai.com/api/v1/payment-method/save-card",
          {
            // insert the user id with the payment method object

            userId: AIuser._id,
            paymentMethod,
          }
        );
        if (response) {
          setCardError("");
          setrefetch(!refetch);
          setLoader(false);
          setShowCardForm(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      toast.error(error?.response?.data?.error);
    }
  };

  

  return {
    handleSubmit,
    refetch,
    setrefetch,
    AIuser,
    userDetails,
    setuserDetails,
    loader,
    setLoader,
    showCardForm,
    setShowCardForm,
    cardError,
    setCardError,
    userRefetch,
    setUserRefetch,
  };
};

const PaymentMethods = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [savedCards, setSavedCards] = useState([]);
  const [paying, setPaying] = useState(false);
  const {
    handleSubmit,
    refetch,
    setrefetch,
    AIuser,
    userDetails,
    setuserDetails,
    loader,
    setLoader,
    showCardForm,
    setShowCardForm,
    cardError,
    setCardError,
    userRefetch,
    setUserRefetch,
  } = usePaymentMethodForm();

  const location = useLocation();
  const navigate = useNavigate();

  const price = location?.state?.price;
  const realPrice = location?.state?.realPrice;
  const credits = location?.state?.credits;
  const refEmail = location?.state?.refEmail;
  const ownerId = location?.state?.ownerId;
  const date = location?.state?.date;
  const data = location?.state?.data;

  const handleAddPaymentMethod = () => {
    setShowCardForm(true);
    setCardError("");
  };

  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(
        `https://backend.whalesai.com/api/v1/payment-method/${AIuser?._id}`
      );
      // console.log(response);
      setSavedCards(response?.data?.data.reverse());
    };
    getData();
  }, [refetch, AIuser?._id]);

  const handleCardClick = async (card) => {
    try {
      swal({
        text: `Are you sure you want to purchase ${credits} credits for USD ${price}?`,
        icon: "warning",
        buttons: ["Cancel", "Yes"],
        className: "modal_class_success",
      }).then(async (value) => {
        if (value) {
          setPaying(true);
          const response = await axios.post(
            "https://backend.whalesai.com/api/v1/payment-method/pay",
            {
              cardData: card,
              amount: price,
              realPrice,
              credits,
              refEmail,
              ownerId,
              date,
              data:data
            }
          );
          console.log("response", response);

          if (response.data.status === "success") {
            setPaying(false);
            setUserRefetch(!userRefetch);
            navigate("/purchase-history", {
              state: { success: true, credits: response.data.credits },
            });
            // navigate(-1);
          }
        }
      });

      // Process the payment response as needed
    } catch (error) {
      setPaying(false);
      console.error("Error processing payment:", error);
      toast.error(error?.response?.data?.error);
    }
  };


  const handleDeleteCard = async (cardId) => {
    try {
      const response = await axios.delete(
        `https://backend.whalesai.com/api/v1/payment-method/${cardId}`
      );

      if (response) {
        toast.success("Card removed successfully!");
        setrefetch(!refetch);
      }

      // Process the payment response as needed
    } catch (error) {
      console.error("Error processing payment:", error);
      toast.error("Something went wrong! Please try again later.");
    }
  };

  return (
    <div>
      <PageHeading title={"PAYMENT METHODS"} />
      <div
        className="container account-container pb-5"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="mx-auto "
          style={{
            maxWidth: "400px",
          }}
        >
          <button
            onClick={() =>
              showCardForm == false
                ? handleAddPaymentMethod()
                : setShowCardForm(false)
            }
            className="add-payment-btn text-uppercase"
          >
            {showCardForm ? (
              <ArrowDropUpOutlined className="payment-add-icon me-2 fs-2" />
            ) : (
              <AddCircleOutlined className="payment-add-icon me-2" />
            )}
            Add Payment Method
          </button>
          {showCardForm && (
            <PaymentMethodForm
              handleSubmit={handleSubmit}
              setuserDetails={setuserDetails}
              userDetails={userDetails}
              cardError={cardError}
              loader={loader}
            />
          )}

          <CardLists
            savedCards={savedCards}
            price={price}
            handleDeleteCard={handleDeleteCard}
            paying={paying}
            handleCardClick={handleCardClick}
          />
        </div>
      </div>
    </div>
  );
};

const WrappedPaymentMethodsPage = () => (
  <Elements stripe={stripePromise}>
    <PaymentMethods />
  </Elements>
);
export default WrappedPaymentMethodsPage;
