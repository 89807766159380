import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import poster1 from "../../Pages/Gallery/Image/cv2.PNG";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SampleLargeComic({ modalLargeComicVideo, setModalLargeComicVideo }) {
  const handleClose = () => setModalLargeComicVideo(false);
  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalLargeComicVideo}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Sample</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,

                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <video
            controls
            style={{ width: "100%" }}
            src="https://backend.whalesai.com/public/hiwvideos/1920-1080-comic-video.mp4"
            autoPlay
            preload="auto"
            poster={poster1}
            className=""
            type="video/mp4"
          />
          <Box
            sx={{
              marginTop: 2,
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="body1">
            Explore the fascinating and diverse world of insects, where tiny creatures play vital roles in ecosystems across the globe. #InsectWorld #Biodiversity #NatureWonders #Entomology #EcosystemRoles
            </Typography>
          </Box>
        </DialogContent>
        
      </Box>
    </BootstrapDialog>
  );
}
