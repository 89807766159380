import React from "react";
import ReactMarkdown from "react-markdown";

const PUD = ({
  inputText,
  isGenerating,
  text,
  html,
  presetSelected,
  wordRef,
}) => {
  return (
    <>
      <p className="preset-title">Parse Unstructured Data</p>
      {inputText ? (
        <>
          <p class="text-muted">{inputText}</p>

          {isGenerating == true ? (
            <p
              class="text- mb-0 mt-2"
              style={{
                fontSize: "14px",
                color: "black",
              }}
            >
              Processing...
            </p>
          ) : (
            <>
              {text && (
                <p
                  style={{
                    color: "black",
                  }}
                  class="text- mb-0 text-"
                >
                  {/* <ReactMarkdown>{text}</ReactMarkdown> */}
                  <div
                    style={{
                      overflowX: "auto",
                      color: "black",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: html,
                    }}
                  />
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {wordRef.current && (
            <p className="text-muted">
              <ReactMarkdown>{presetSelected?.label}</ReactMarkdown>
            </p>
          )}
          <div>
            <table class="table text-dark">
              <thead style={{ borderColor: "grey" }}>
                <tr>
                  <th>Fruit</th>
                  <th>Color</th>
                  <th>Taste</th>
                </tr>
              </thead>
              <tbody style={{ borderColor: "grey" }}>
                <tr>
                  <td>Neoskizzles</td>
                  <td>Purple</td>
                  <td>Candy</td>
                </tr>
                <tr>
                  <td>Loheckles</td>
                  <td>Grayish Blue</td>
                  <td>Tart, like a lemon</td>
                </tr>
                <tr>
                  <td>Pounits</td>
                  <td>Bright Green</td>
                  <td>Savory</td>
                </tr>
                <tr>
                  <td>Loopnovas</td>
                  <td>Neon Pink</td>
                  <td>Cotton Candy</td>
                </tr>
                <tr>
                  <td>Glowls</td>
                  <td>Pale Orange</td>
                  <td>Sour, Bitter, Acidic, Caustic</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default PUD;
