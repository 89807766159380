import React, { Fragment, useContext, useEffect, useState } from "react";
// import ImageIcon from "../../../assets/images/imageAi/image-icon.png";
import { BsCardImage, BsFileImage, BsFillImageFill } from "react-icons/bs";
import moneyExchangeIcon from "../../Assets/Images/transaction-data.png";
import { Button, Switch } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, Col, Row } from "antd";
import SelectTable from "./SelectTable";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { formatTime } from "../../utils/formatTimer";
import CreditsButton from "../../utils/CreditsButton";

const FormatSelect = () => {
  const { AIuser, initialTimer, setInitialTimer } = useContext(UserAuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const creditTolcalString = AIuser?.credits
  ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
  : "0";

  const format = localStorage.getItem("format");
  const [selectedCheckboxIndex, setSelectedCheckboxIndex] = useState(
    format ? parseInt(format) : 0
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [timer, setTimer] = useState(initialTimer);

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
      setInitialTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // const handleNavigate = () => {
  //   navigate("/generated-video", {
  //     state: {
  //       text: location?.state?.text,
  //       paragraphs: location.state.paragraphs,
  //       inputText: location?.state?.inputText,
  //       format: selectedCheckboxIndex,
  //     },
  //   });
  // };

  // const handleBack = () => {
  //   navigate("/format-script", {
  //     state: {
  //       text: location?.state?.text,
  //       inputText: location?.state?.inputText,
  //       paragraphs: location?.state.paragraphs,
  //       format: selectedCheckboxIndex,
  //       isBack: true,
  //     },
  //   });
  // };

  useEffect(() => {
    // wait 5 seconds and navigate to videoai page
    setTimeout(() => {
      navigate("/generated-video", {
        state: {
          text: location?.state?.text,
          paragraphs: location.state.paragraphs,
          transactionId: location?.state?.transactionId,
          format: selectedCheckboxIndex,
        },
      });
    }, 5000);
  }, [
    location.state?.inputText,
    location.state?.text,
    location.state?.paragraphs,
    selectedCheckboxIndex,
    navigate,
    location?.state?.transactionId,
  ]);

  return (
    <Fragment>
      <PageHeading title={"TEXT VIDEO AI"} />
      <div className="img-ai-container container pb-5">
        <div
          style={{
            maxWidth: "872px",
            paddingInline: "12px",
            marginInline: "auto",
          }}
        >
          <div className="d-flex align-items-center justify-content-center centralize-credits-btn-sm">
            <div className="d-flex ">
              <CreditsButton />
            </div>
          </div>

          <p className="text-white text-center mt-2 mb-0 centralize-credits-btn-sm">
            Every request is 500 credits.
          </p>
          <div>
            {/* <button className="videoai_btn1format" onClick={handleBack}>
              Back
            </button> */}
            {timer && (
              <div className="d-flex justify-content-center mt-3">
                <button className="credits-button text-center text-uppercase mt-sm-0 mt-2 me-sm-2 me-0">
                  {formatTime(timer).hours}
                  {": "}
                  {formatTime(timer).minutes}
                  {":"}
                  {formatTime(timer).seconds}
                </button>
              </div>
            )}
            <p
              style={{ fontSize: "13px" }}
              className="text-warning text-center pt-2"
            >
              Please do not move away from this browser.<br className="videoai-br-hide" /> Processing will stop.
            </p>
            {/* <button
              className="videoai_btnformat"
              onClick={() => handleNavigate()}
            >
              Step 4: Organize
            </button> */}
          </div>
        </div>
        <div className="container pt-3">
          {/* <div className="mx-auto" style={{ maxWidth: "850px" }}>
            <p
              className="text-warning centralize-credits-btn-sm"
              style={{ color: "" }}
            >
              Step 3: <span className="text-white ms-1">Select the format.</span>{" "}
            </p>
          </div> */}
          <div style={{ maxWidth: "850px" }} className=" mx-auto">
            <div className="d-flex  align-items-start justify-content-center">
              <SelectTable
                selectedCheckboxIndex={selectedCheckboxIndex}
                setSelectedCheckboxIndex={setSelectedCheckboxIndex}
              />
            </div>
          </div>
          <div className="formatVidDiv mt-3">
            {/* <button className="videoai_btn1format" onClick={handleBack}>
              Back
            </button> */}

            {/* {timer && (
              <button className="videoai_btn1format text-center text-dark text-uppercase bg-light">
                {formatTime(timer).hours}
                {": "}
                {formatTime(timer).minutes}
                {":"}
                {formatTime(timer).seconds}
              </button>
            )} */}

            {/* <button
              className="videoai_btnformat"
              onClick={() => handleNavigate()}
            >
              Step 4: Organize
            </button> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormatSelect;
