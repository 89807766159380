import React from "react";
import { channel } from "../VideoAi/api";

const ScheduleChannel = () => {
  return (
    <div className="schedule-channel-bg schedule-steps  py-5 text-uppercase ">
      <div className="container">
        <h5 className="text-center schedule-title-style  mb-4">Channels</h5>

        <div className="schedule-step-boxes">
          <div className="row">
            {channel.map((step) => (
              <div className="col-lg-3 col-md-4 col-6 mb-4  d-flex justify-content-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <p className="schedule-box-number text-center">{step.id}</p>
                  </div>
                  <div className="schedule-step-box  px-3">
                    <p className="schedule-box-text text-center">{step.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleChannel;
