// src/Components/CustomModal.js

import React from "react";
import "./CustomModal.css"; // Create this CSS file for custom styling

const CustomModal = ({ title, message, onConfirm, onCancel, credit }) => {
  return (
    <div className="modal-custom-overlay">
      <div className="modal-custom-content">
        <h3>{title}</h3>
        <p className="" style={{ color: "#1DF6F5" }}>
          You have: {credit} credits
        </p>
        <p>{message}</p>
        <div className="modal-custom-buttons">
          <button className="btn-custom-cancel" onClick={onConfirm}>
            No
          </button>
          <button className="btn-custom-confirm" onClick={onCancel}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
