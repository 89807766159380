import React, { useContext, useEffect, useState } from "react";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import Spineer from "../../Components/CustomSpineer/Spineer";
import { Delete } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import { Link } from "react-router-dom";
import "./index.css";
import axios from "axios";
import usePagination from "../../hooks/UsePagination";
import SearchBar from "../AdminUser/SearchBar";
import SpecificSchedule from "./SpecificSchedule";
import Swal from "sweetalert2";

const ScheduledContent = () => {
  const { getAllScheduledData, allScheduledData, getSpecificUser } =
    useContext(AdminAuthContext);
  const [oneSchedule, setOneSchedule] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [userNames, setUserNames] = useState({});
  const [todaysContent, setTodaysContent] = useState({});
  const [postingTime, setPostingTime] = useState({});
  const [generatedContent, setGeneratedContent] = useState();
  const [generatedHashTag, setGeneratedHashTag] = useState();
  const [name, setName] = useState();
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;

  const today = new Date();
  today.setDate(today.getDate() + 1);
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${dd}-${mm}-${yyyy}`;

  // const filteredSchedule = allScheduledData?.filter((item) =>
  //     item?.platform?.toLowerCase()?.includes(searchQuery) ||
  //     item?.email?.toLowerCase()?.includes(searchQuery) ||
  //     item?.country?.toLowerCase()?.includes(searchQuery)
  //   );
  const filteredSchedule = allScheduledData?.filter((item) => {
    const contentType = todaysContent[item._id] || "";
    return (
      item?.platform?.toLowerCase()?.includes(searchQuery) ||
      item?.email?.toLowerCase()?.includes(searchQuery) ||
      item?.country?.toLowerCase()?.includes(searchQuery) ||
      contentType.toLowerCase().includes(searchQuery)
    );
  });
  let count = Math.ceil(filteredSchedule?.length / PER_PAGE);
  let perPageData = usePagination(filteredSchedule, PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };

  console.log("content type======>>>>", todaysContent);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  useEffect(() => {
    const fetchUserNames = async () => {
      if (allScheduledData && allScheduledData.length > 0) {
        const names = {};
        for (let content of allScheduledData) {
          try {
            const response = await axios.get(
              `https://backend.whalesai.com/api/dashboard/user_signup-signin/get/${content?.userId}`
            );
            const userName = response?.data?.result?.name;
            if (userName) {
              names[content.userId] = userName;
            } else {
              names[content.userId] = "DSL";
            }
          } catch (error) {
            names[content.userId] = "DSL";
          }
        }
        setUserNames(names);
      }
    };
    fetchUserNames();
  }, [allScheduledData]);

  useEffect(() => {
    const fetchContent = async () => {
      if (allScheduledData && allScheduledData.length > 0) {
        const contents = {};
        const times = {};
        const generatedContent = {};
        const hashtag = {};
        for (let data of allScheduledData) {
          try {
            // console.log("Fetching user for content data :", data);
            let scheduleCategories = data?.contentCategory;
            // console.log('date from schedule====>>>>', scheduleCategories);
            for (let category of scheduleCategories) {
              // console.log('every content category ==== >>>>>', category);
              if (category.date == formattedDate) {
                contents[data?._id] = category?.content;
                times[data?._id] = category?.timeOfPosting;
                generatedContent[data?._id] = category?.generatedContent;
                hashtag[data?._id] = category?.hashtags;
              }
            }
          } catch (error) {
            contents[data._id] = "Own Content";
            times[data?._id] = "09:00";
          }
        }
        setTodaysContent(contents);
        setPostingTime(times);
        setGeneratedContent(generatedContent);
        setGeneratedHashTag(hashtag);
      }
    };
    fetchContent();
  }, [allScheduledData, formattedDate]);

  const handleOpen = (user, userName) => {
    setOneSchedule(user);
    setName(userName);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this schedule?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `https://backend.whalesai.com/api/v1/social-schedule/schedules/${id}`,
          {
            method: "DELETE",
          }
        )
          .then((response) => {
            console.log("delete response ====> ", response);
            if (response.ok) {
              getAllScheduledData();
              Swal.fire({
                html: `<p class='text-break text-white'>Schedule Deleted.</p>`,
                icon: "success",
                customClass: "modal_class_success",
              });
            } else {
              getAllScheduledData();
              Swal.fire({
                text: "Failed to delete the schedule.",
                icon: "error",
                background: "#011225",
              });
            }
          })
          .catch((error) => {
            console.log("error from catch", error);
            // getAllAdmin();
            getAllScheduledData();
            Swal.fire({
              text: "An error occurred while deleting the schedule.",
              icon: "error",
              background: "#011225",
            });
          });
      }
    });
  };

  return (
    <div className="container-fluid text-white">
      <SpecificSchedule
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        oneSchedule={oneSchedule}
        name={name}
      />
      <h5 className="text-start mb-3 font-weight-bold">SCHEDULED CONTENTS</h5>
      <div className="mb-3">
        <SearchBar handleSearch={handleSearch} />
      </div>

      <div
        className="pt-5 rounded my-2 mb-5 pb-3 table-width-class"
        style={{ backgroundColor: "#3080AC" }}
      >
        <div className="my-3"></div>
        <div className="table-responsive overflow-auto ">
          <table
            className="table table-hover"
            style={{ backgroundColor: "#3080AC" }}
          >
            <thead className="bg-[#1a1c339a] table-text-size">
              <tr>
                <th scope="col">S/no</th>
                <th scope="col" className="">
                  Email
                </th>
                <th scope="col" className="">
                  Country
                </th>
                <th scope="col" className="">
                  Platform
                </th>
                <th scope="col">
                  Date & Time{" "}
                  <span style={{ fontSize: "10px" }}>(SG Time)</span>
                </th>
                <th scope="col" className="">
                  Content Type
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>

            <tbody
              className="table-text"
              style={{ backgroundColor: "#3080AC" }}
            >
              {allScheduledData ? (
                perPageData?.currentData()?.map((content, index) => (
                  <tr key={content._id}>
                    <td className="py-4 px-4">{(page - 1) * 10 + index + 1}</td>
                    <td className="py-4 ">{content.email}</td>
                    <td className="py-4 ">{content.country}</td>
                    <td className="py-4 ">
                      {/* {content?.platform == "Twitter"
                        ? "Twitter/X"
                        :  (content?.platform == 'LinkedIn')? 'LinkedIn Page' : content?.platform } */}
                      {content?.platform == "Twitter"
                        ? "Twitter/X"
                        : content?.platform == "LinkedIn"
                          ? "LinkedIn Page"
                          : content?.platform == "Facebook"? 
                          todaysContent[content._id]?.includes('Video') || todaysContent[content._id]?.includes('Image')
                              ? "Facebook Group"
                              : "Facebook Page"
                            : content?.platform}
                    </td>
                    <td className="py-3">
                      {formattedDate}
                      <br />
                      {postingTime[content._id] || "Loading..."}
                    </td>
                    <td className="py-4 ">
                      {todaysContent[content._id] || "Loading..."}
                    </td>
                    <td className="text-center">
                      <div className="btn-group gap-2" role="group">
                        <Link
                          onClick={() => {
                            handleOpen(content, userNames[content.userId]);
                          }}
                          className="btn rounded-circle btn-sm icon-body btn-success"
                        >
                          <RemoveRedEyeIcon className="action-icon" />
                        </Link>
                        <button
                          className="btn rounded-circle btn-sm icon-body btn-danger"
                          onClick={() => handleDelete(content._id)}
                        >
                          <Delete className="action-icon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="text-center"
                    colSpan={window.innerWidth <= 768 ? "6" : "10"}
                  >
                    <Spineer />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <CustomPagination
          page={page}
          count={count}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default ScheduledContent;
