import React from "react";
import './Three.css'
import img1 from './Image/post1.jpg'
import img2 from './Image/post2.jpg'
import img3 from './Image/post3.jpg'



const Three = () => {
  
  const AI1 = [
    {
      img: img1,
      level: '1:1 - 1080 X 1080 PX',
    },
    {
      img: img2,
      level: '16:9 - 1920 X 1080 PX',
    },
    {
      img: img3,
      level: '9: 16 - 1080 X 1920 PX',
    },
  ]


  // const AI2 = [
  //   {
  //     img: post4,
  //     level: '1:1 - 1080 X 1080 PX',
  //   },
  //   {
  //     img: post5,
  //     level: '16:9 - 1920 X 1080 PX',
  //   },
  //   {
  //     img: post6,
  //     level: '9: 16 - 1080 X 1920 PX',
  //   },
  // ]

  // const AI3 = [
  //   {
  //     img: post7,
  //     level: '1:1 - 1080 X 1080 PX',
  //   },
  //   {
  //     img: post8,
  //     level: '16:9 - 1920 X 1080 PX',
  //   },
  //   {
  //     img: post9,
  //     level: '9: 16 - 1080 X 1920 PX',
  //   },
  // ]

  return (
    <div className="text-white">
      <div>
        <p className="postAI-title"><span className="fw-bold">Quote</span> : Motivational quote</p>
        <div className="postAI-image">
              {AI1.map((item, index) => (
                <div
                key={index}
                >
                  <img
                  src={item.img}
                  alt={`Item ${index + 1}`}
                //   style={{ width: "256px", height: "256px", margin: "10px" }}
                />
                <p className="mt-2 mb-4">{item.level}</p>
                </div>
              ))}
        </div>
        {/* <p className="postAI-title"><span className="fw-bold">Quote</span> : Inspirational quote</p>
        <div className="postAI-image">
              {AI2.map((item, index) => (
                <div
                key={index}
                >
                  <img
                  src={item.img}
                  alt={`Item ${index + 1}`}
                //   style={{ width: "256px", height: "256px", margin: "10px" }}
                />
                <p className="mt-2 mb-4">{item.level}</p>
                </div>
              ))}
        </div>

        <p className="postAI-title"><span className="fw-bold">Quote</span> : Thought-Provoking quote</p>
        <div className="postAI-image">
              {AI3.map((item, index) => (
                <div
                key={index}
                >
                  <img
                  src={item.img}
                  alt={`Item ${index + 1}`}
                //   style={{ width: "256px", height: "256px", margin: "10px" }}
                />
                <p className="mt-2 mb-4">{item.level}</p>
                </div>
              ))}
        </div> */}


      </div>
    </div>
  );
};

export default Three;
