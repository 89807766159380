import { CardActionArea, CardContent, Container, Grid } from "@mui/material";
import DataCard from "../DataCard";
import './Method.css';
import {
  DescriptionText,
  Method2Button,
  Method2Container,
  MethodButtonContainer,
  MethodButtonText,
  StartButton,
} from "./style";
import { method2Data } from "../data/method2";
import { useNavigate } from "react-router-dom";

const Method2 = () => {
  const navigate = useNavigate();

  return (
    <Method2Container>
      <Container>
        <MethodButtonContainer>
          <Method2Button onClick={() => navigate("/let-us-do-it-for-you")}>
            <CardActionArea>
              <CardContent className="method-heading-two" sx={{ textAlign: "center" }}>
                <MethodButtonText variant="h6">Method 2</MethodButtonText>
                <MethodButtonText variant="h6">
                  Let us do for you
                </MethodButtonText>
              </CardContent>
            </CardActionArea>
          </Method2Button>
        </MethodButtonContainer>
        <DescriptionText variant="body1">
          
        </DescriptionText>
        <p className="text-center">
        Our SMMM uses AI to generate and post content automatically after
        business analysis, with payment options available monthly or yearly.
        </p>
        <MethodButtonContainer>
          <StartButton onClick={() => navigate("/let-us-do-it-for-you")}>
            Get Started
          </StartButton>
        </MethodButtonContainer>

        <Grid container spacing={2} mt={6}>
          {method2Data.map((data) => (
            <Grid key={data.id} item xs={12} sm={6} md={4} lg={3}>
              <DataCard
                title={data.title}
                description={data.description}
                bgColor={"#006C62"}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Method2Container>
  );
};

export default Method2;
