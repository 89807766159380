import React, { useState, useEffect } from "react";

const QuotesSlider = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const getImages = async () => {
      const images = await require.context(
        `/public/image/quotes`,
        false,
        /\.(png|jpe?g|svg)$/
      );

      const imageList = images
        .keys()
        .sort((a, b) => {
          const aNum = parseInt(a.match(/(\d+)/)[0]);
          const bNum = parseInt(b.match(/(\d+)/)[0]);
          return aNum - bNum;
        })
        .map((image) => {
          const imageName = /\/([^/]+)\.\w+$/.exec(image)[1]; // Extract the image name from the module name
          return `/image/quotes/${imageName}.png`;
        });

      setImages(imageList);
    };
    getImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the index to move to the next image
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 7000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="slider pt-4">
      <div className="d-flex justify-content-center">
        <img
          src={images[currentImageIndex]}
          alt="Slider"
          className="quotes-image"
        />
      </div>
      <p style={{ fontSize: "13px" }} className="text-white text-center mb-0 pt-2">
        We are building the video for you.
      </p>
      <p style={{ fontSize: "13px" }} className="text-white text-center mb-0 ">Please hold on for awhile.</p>
      {/* <p className="text-white text-center mb-0 ">Do not close this browser.</p> */}
    </div>
  );
};

export default QuotesSlider;
