export const method1Data = [
  {
    id: crypto.randomUUID(),
    title: "Cost Efficiency",
    description: [
      "Pay-per-Use Model: Only pay for the content you generate, eliminating subscription fees or upfront costs. This ensures you only spend money when you actually use the service.",
      "Budget Control: Easily manage your content creation budget by generating content as needed, without any hidden costs.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Creative Freedom",
    description: [
      "Diverse Content Creation: Use our AI to generate a wide range of content, from blog posts and social media updates to marketing copy and product descriptions.",
      "Tailored Outputs: Customize the generated content to fit your specific needs and brand voice, ensuring uniqueness and relevance.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Time Savings",
    description: [
      "Instant Generation: Quickly produce high-quality content, reducing the time spent on brainstorming, writing, and editing.",
      "Efficiency in Workflow: Streamline your content creation process, allowing you to focus on other important aspects of your business.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Quality and Consistency",
    description: [
      "High-Quality Outputs: Our AI is designed to generate well-written, engaging, and accurate content that meets professional standards.",
      "Consistent Tone and Style: Maintain a consistent tone and style across all your content, enhancing your brand’s voice and image.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Scalability",
    description: [
      "Adaptable Usage: Whether you need one article or a hundred social media posts, our AI can scale with your content needs.",
      "Growth Support: As your content needs grow, our AI can accommodate larger volumes without compromising quality or speed.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Innovation",
    description: [
      "Fresh Ideas: Generate new and innovative content ideas that you might not have thought of, helping to keep your audience engaged and interested.",
      "Creative Boost: Use the AI as a creative partner to explore different angles and approaches in your content strategy.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Accessibility",
    description: [
      "User-Friendly Interface:: Easy-to-use platform that requires no advanced technical skills, making content creation accessible to everyone.",
      "24/7 Availability: Generate content anytime, anywhere, without the limitations of working hours or time zones.",
    ],
  },
  {
    id: crypto.randomUUID(),
    title: "Support and Improvement",
    description: [
      "Continuous Learning: Our AI continuously learns and improves from user interactions, ensuring that the quality of content generation gets better over time.",
      "Customer Support: Access to customer support for any issues or questions, ensuring a smooth and efficient content creation experience.",
    ],
  },
];
