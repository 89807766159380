import React from 'react';
import { useLocation } from 'react-router-dom';

const ImagePost = () => {
    const location = useLocation();
    const { img, checkedPlatform } = location.state || {};
    return (
        <div className='text-white'>
            image post. 
            <h1>Target Page</h1>
            <p>Received img: {img}</p>
            <p>Received checkedPlatform: {checkedPlatform?.join(', ')}</p>
      {/* Add your UI elements based on received props */}
        </div>
    );
};

export default ImagePost;