import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import usePagination from "../../hooks/UsePagination";
import "./index.css";
import SearchBar from "../AdminUser/SearchBar";
import SpecificUser from "./SpecificUser";
import Swal from "sweetalert2";
import Spineer from "../../Components/CustomSpineer/Spineer";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import axios from "axios";
import { Tooltip } from "bootstrap";

const AdminGeneralUser = () => {
  const { allAIUsers, getAllUser } = useContext(AdminAuthContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [oneUser, setOneUser] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  // const [sortOrder, setSortOrder] = useState("asc");

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
 // State for the current sort field
const [sortOrder, setSortOrder] = useState("desc");  // State for sort order

  // const filteredAdmins = allAIUsers
  // ?.filter((admin) =>
  //   admin?.name?.toLowerCase()?.includes(searchQuery)
  // )
  // ?.sort((a, b) => {
  //   if (sortOrder === "asc") {
  //     return a.name.localeCompare(b.name); // Ascending order
  //   } else {
  //     return b.name.localeCompare(a.name); // Descending order
  //   }
  // });

  const [sortColumn, setSortColumn] = useState("date");

  const filteredAdmins = allAIUsers?.filter((admin) => {
    const match = admin?.name?.toLowerCase()?.includes(searchQuery) ;

    return match;
  })




  let count = Math.ceil(filteredAdmins?.length / PER_PAGE);
  let perPageData = usePagination(filteredAdmins, PER_PAGE);


  // extra 
  const sortedAdmins = filteredAdmins?.sort((a, b) => {
    // Helper function to parse the date string into a valid Date object
    const parseDate = (dateString) => {
      const [date, time] = dateString.split(' ');
      const [day, month, year] = date.split('/');
      const [hours, minutes, seconds] = time.split(':');
      return new Date(year, month - 1, day, hours, minutes, seconds); // Month is 0-based
    };
  
    const columnToSortBy = {
      date: parseDate(a.createdAt) - parseDate(b.createdAt),
      name: a.name.localeCompare(b.name),
      email: a.email.localeCompare(b.email),
    };
  
    const order = sortOrder === "asc" ? 1 : -1;
  
    return columnToSortBy[sortColumn] * order;
  });


  const handleSort = (column) => {
    if (sortColumn === column) {
      // Toggle the order if the same column is clicked again
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      // If a new column is clicked, set it as the new sort column and default to ascending
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };

  // tool tip
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
    );
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  const handleOpen = (user) => {
    // alert(user?._id);
    setOneUser(user);
    setModalOpen(true);
  };

  const handleuserDelete = (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this user?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `https://backend.whalesai.com/api/dashboard/user_signup-signin/${id}`,
          {
            method: "DELETE",
            headers: {
              // Authorization: `Bearer ${AdminToken}`,
            },
          }
        )
          .then((response) => {
            console.log("delete response ====> ", response);
            if (response.ok) {
              getAllUser();
              Swal.fire({
                html: `<p class='text-break text-white'>User Deleted.</p>`,
                icon: "success",
                customClass: "modal_class_success",
              });
            } else {
              getAllUser();
              Swal.fire({
                text: "Failed to delete the user.",
                icon: "error",
                background: "#011225",
              });
            }
          })
          .catch((error) => {
            console.log("error from catch", error);
            getAllUser();
            Swal.fire({
              text: "An error occurred while deleting the user.",
              icon: "error",
              background: "#011225",
            });
          });
      }
    });
  };

  const handleMakeTester = async (id) => {
    Swal.fire({
      text: "Are you sure you want to make this user a Tester?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `https://backend.whalesai.com/api/dashboard/user_signup-signin/role/${id}`,
            {
              role: "tester",
            }
          );

          if (response.status === 200) {
            Swal.fire({
              html: `<p class='text-break text-white'>User role updated to Tester.</p>`,
              icon: "success",
              customClass: "modal_class_success",
            });

            getAllUser();
          } else {
            Swal.fire({
              text: "Failed to update user role.",
              icon: "error",
              background: "#011225",
            });
          }
        } catch (error) {
          Swal.fire({
            text: "An error occurred while updating the user role.",
            icon: "error",
            background: "#011225",
          });
          console.error("Error while updating user role:", error);
        }
      }
    });
  };



 
  console.log('All AI users====>>',allAIUsers);

  return (
    <div className="container-fluid text-white">
      <SpecificUser
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        oneUser={oneUser}
      />
      <h5 className="text-start mb-3 font-weight-bold">USERS </h5>

      <div className="d-flex justify-content-between align-items-center">
        <SearchBar handleSearch={handleSearch} />
      </div>

      <div
        className="table-responsive pt-3 rounded my-2 mb-5"
        style={{ backgroundColor: "#3080AC", paddingBottom: "10px" }}
      >
        <div className="mx-3">
          <div className="d-flex justify-content-start gap-2 mb-2 ml-3">
            <p
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="pb-0 total-user"
            >
              TOTAL USERS: {allAIUsers?.length}
            </p>
          </div>
        </div>
        <div className="table-responsive overflow-auto">
          <table
            className="table table-hover"
            style={{ backgroundColor: "#3080AC" }}
          >
            <thead className="bg-[#1a1c339a] table-text-size">
              <tr>
                <th scope="col">S/no</th>
                {/* <th
                  scope="col"
                  onClick={() =>
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                  }
                 
                  style={{ cursor: "pointer" }}
                >
                  Username 
                </th> */}
                 <th
                  scope="col"
                  onClick={() => handleSort("name")}
                  style={{ cursor: "pointer" }}
                >
                  Username
                </th>
                <th 
                  // onClick={() => handleSort("email")}
                  // style={{ cursor: "pointer" }} 
                  scope="col" className="d-none d-md-table-cell">
                  Email
                </th>
                {/* <th
                // onClick={() => handleSort("createdAt")}
                style={{ cursor: "pointer",  verticalAlign: "middle"}}
                scope="col" className="d-none d-md-table-cell">
                  Date & Time (SG Time)
                </th> */}
                <th
                  scope="col"
                  onClick={() => handleSort("date")}
                  style={{ cursor: "pointer", verticalAlign: "middle" }}
                  className="d-none d-md-table-cell"
                >
                  Date & Time (SG Time)
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              className="table-text-size"
              style={{ backgroundColor: "#3080AC" }}
            >
              {allAIUsers ? (
                perPageData?.currentData()?.map((admin, index) => (
                  <tr key={admin._id}>
                    <td className="py-4 px-4">{(page - 1) * 10 + index + 1}</td>

                    <td className="py-4" style={{ textTransform: "lowercase" }}>
                      {admin.name}
                    </td>
                    <td className="py-4 d-none d-md-table-cell">
                      {admin.email}
                    </td>
                    <td className="py-4 d-none d-md-table-cell">
                      {admin.createdAt?.slice(0,9).replace(/\//g, '-')} <br/>
                      {admin.createdAt?.slice(9, 24).replace(/\//g, '-')}
                    </td>
                    <td className="text-center alignment">
                      <div className="btn-group gap-2" role="group">
                        {/* <button
                          className="btn rounded-circle btn-sm icon-body btn-warning"
                          onClick={() => handleMakeTester(admin._id)}
                        >
                          <TaskAltIcon className="action-icon" />
                        </button> */}
                        <button
                          className="btn rounded-circle btn-sm icon-body btn-warning"
                          onClick={() => handleMakeTester(admin._id)}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top" // Specifies that the tooltip should appear above the button
                          title="Make Tester Account"
                        >
                          <TaskAltIcon className="action-icon" />
                        </button>

                        <Link
                          onClick={() => {
                            handleOpen(admin);
                          }}
                          className="btn rounded-circle btn-sm icon-body btn-success"
                        >
                          <RemoveRedEyeIcon className="action-icon" />
                        </Link>
                        <button
                          className="btn rounded-circle btn-sm icon-body btn-danger"
                          onClick={() => handleuserDelete(admin._id)}
                        >
                          <Delete className="action-icon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="text-center"
                    colSpan={window.innerWidth <= 768 ? "4" : "6"}
                  >
                    <Spineer />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CustomPagination
          page={page}
          count={count}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default AdminGeneralUser;