import React, { useState } from "react";
import { CardElement } from "@stripe/react-stripe-js";
import visaIcon from "./visa.png";

const cardElementStyles = {
  base: {
    fontSize: "16px",
    color: "#32325d",
    fontFamily: "Arial, sans-serif",
    "::placeholder": {
      color: "#aab7c4",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

const PaymentMethodFormMocUp = ({ cardError, loader, setIsOK, isOK }) => {
  const [myCountry, setMyCountry] = useState({
    country: "Singapore",
    countryCode: "SG",
  });

  const [userDetails, setUserDetails] = useState({
    name: "",
    city: "",
    country: "Singapore",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    isOK(true);
  }

  const handleOnChangeCountries = (e) => {
    setMyCountry({
      country: e.target.value,
      countryCode: e.target.value,
    });
    setUserDetails({
      ...userDetails,
      country: e.target.value,
    });
  };

  const [cardDetails, setCardDetails] = useState("4242");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [zipCode, setZipCode] = useState("");

  const handleCardInputChange = (e) => {
    setCardDetails(e.target.value);
  };

  const handleExpiryChange = (e) => {
    setExpiryDate(e.target.value);
  };

  const handleCvcChange = (e) => {
    setCvc(e.target.value);
  };

  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value);
  };

  return (
    <div className="payment-card mt-2">
      <form className="row g-3" onSubmit={handleSubmit}>
        <div className="col-12">
          <label htmlFor="inputEmail4" className="form-label">
            Name as per card
          </label>
          <input
            required
            type="name"
            className="form-control"
            id="inputEmail4"
            // value={userDetails.name}
            defaultValue={'Visa Demo'}
            onChange={(e) => setUserDetails({ ...userDetails, name: e.target.value })}
          />
        </div>
        <div className="col-12">
          <label htmlFor="inputEmail4" className="form-label">
            Select your country
          </label>
          <select
            className="form-select"
            aria-label="Default select example"
            value={myCountry.country}
            onChange={handleOnChangeCountries}
          >
            <option value="Singapore">Singapore</option>
            <option value="USA">USA</option>
            <option value="Canada">Canada</option>
          </select>
        </div>

        <div className="col-12">
          <label htmlFor="inputAddress" className="form-label">
            Address
          </label>
          <input
            required
            type="text"
            className="form-control"
            id="inputAddress"
            defaultValue={'2 Ang Mo Kio Ave 10, Singapore 569739'}
            placeholder=""
            onChange={(e) => setUserDetails({ ...userDetails, city: e.target.value })}
          />
        </div>
        <div className="col-12">
          <label htmlFor="inputAddress" className="form-label">
            Zip/Postal
          </label>
          <input required type="number" className="form-control" id="zipcode" placeholder="" defaultValue={'22100'} />
        </div>
        {/* <div className="col-12">
          <label htmlFor="inputAddress" className="form-label">
            Card Details
          </label>
          <CardElement options={{ style: cardElementStyles }} />
          {cardError && (
            <p
              style={{
                color: "rgb(231, 31, 31)",
                fontSize: "13px",
                fontWeight: "bold",
                margin: "4px 0 0 0", // margin top 4px and bottom 0
              }}
            >
              {cardError}
            </p>
          )}
        </div> */}


        <label htmlFor="inputAddress" className="form-label mb-0 pb-0">
            Card Details
          </label>
      <div className="d-flex align-items-center mt-0 pb-0">

      <img src={visaIcon} alt="Visa" style={{ width: "40px", marginRight: "10px" }} />
      <input
        type="text"
        className="form-control"
        value={cardDetails}
        onChange={handleCardInputChange}
        placeholder="4242 4242 4242 4242"
        style={{
          fontSize: "16px",
          color: "#32325d",
          // border: "1px solid #ccc",
          border: 'none',
          padding: "10px",
          borderRadius: "4px",
          width: "40%",
          marginRight: "2px",
        }}
      />
      <input
        type="text"
        className="form-control"
        // value={expiryDate}
        onChange={handleExpiryChange}
        placeholder="MM/YY"
        defaultValue={'05/26'}
        style={{
          width: "30%",
          marginRight: "2px",
          border: 'none',
        }}
      />
      <input
        type="text"
        className="form-control"
        // value={cvc}
        onChange={handleCvcChange}
        placeholder="CVC"
        defaultValue={'123'}
        style={{
          width: "20%",
          marginRight: "2px",
          border: 'none',
        }}
      />
      <input
        type="text"
        className="form-control"
        // value={zipCode}
        defaultValue={'22100'}
        onChange={handleZipCodeChange}
        placeholder="ZIP"
        style={{
          width: "30%",
          border: 'none',
        }}
      />
      {cardError && (
        <p
          style={{
            color: "rgb(231, 31, 31)",
            fontSize: "13px",
            fontWeight: "bold",
            margin: "4px 0 0 0",
            width: "100%",
          }}
        >
          {cardError}
        </p>
      )}
    </div>

        <hr className="mb-0" />

        <div className="col-12">
          <button type="submit" className="btn btn-primary w-100" disabled={loader}>
            {loader ? "SAVING..." : "SAVE CARD"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentMethodFormMocUp;