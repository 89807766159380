import { useContext } from "react";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";

// Function to fetch unique news
const useUniqueNews = () => {
    const { allNews } = useContext(AdminAuthContext);

    const uniqueNewsMap = new Map();

    allNews?.forEach(news => {
        if (!uniqueNewsMap.has(news.title)) {
            uniqueNewsMap.set(news.title, {
                category: news.category,
                title: news.title,
                summary: news.summary,
            });
        }
    });

    // Convert the map values back to an array
    const uniqueNewsArray = Array.from(uniqueNewsMap?.values());

    // Organize news by category
    const uniqueNewsByCategory = uniqueNewsArray?.reduce((acc, news) => {
        if (!acc[news.category]) {
            acc[news.category] = [];
        }
        acc[news?.category]?.push({
            title: news.title,
            summary: news.summary,
        });
        return acc;
    }, {});

    // Export the unique news and categorized news
    return { uniqueNews: uniqueNewsArray, uniqueNewsByCategory };
};

export default useUniqueNews;


// import { useContext } from "react";
// import { AdminAuthContext } from "../../Contexts/AdminAuthContext";

// // Function to fetch unique news
// const useUniqueNews = () => {
//     const { allNews } = useContext(AdminAuthContext);

//     const uniqueNewsMap = new Map();

//     allNews?.forEach(news => {
//         if (!uniqueNewsMap.has(news.title)) {
//             uniqueNewsMap.set(news.title, {
//                 category: news.category,
//                 title: news.title,
//                 summary: news.summary,
//             });
//         }
//     });

//     // Convert the map values back to an array
//     const uniqueNewsArray = Array.from(uniqueNewsMap?.values());

//     // Organize news by category
//     const uniqueNewsByCategory = uniqueNewsArray?.reduce((acc, news) => {
//         if (!acc[news.category]) {
//             acc[news.category] = [];
//         }
//         acc[news.category]?.push({
//             title: news.title,
//             summary: news.summary,
//         });
//         return acc;
//     }, {});

//     // Function to randomly select 5 items from each category
//     const getRandomItems = (arr, count) => {
//         const shuffled = arr.sort(() => 0.5 - Math.random());
//         return shuffled.slice(0, count);
//     };

//     // Get random news for each category
//     const randomNewsByCategory = Object.keys(uniqueNewsByCategory)?.reduce((acc, category) => {
//         acc[category] = getRandomItems(uniqueNewsByCategory[category], 5); // Select 5 random news items
//         return acc;
//     }, {});

//     // Export the unique news and categorized news
//     return { uniqueNews: uniqueNewsArray, uniqueNewsByCategory: randomNewsByCategory };
// };

// export default useUniqueNews;
