import React from 'react';
import { FaCheckSquare } from 'react-icons/fa';

const RWsample = () => {
    return (
        <section>
            <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
            
            <div className="text-muted" style={{ fontSize:"14px" }}>
                <p>The case "Public Prosecutor v Wong Chee Kan [2024] SGDC 274" involved Wong Chee Kan, who pleaded guilty to a charge of drink driving under Section 67(1)(b) of the Road Traffic Act 1961. Wong was found having a breath alcohol level of 51 micrograms per 100 milliliters of breath, exceeding the legal limit of 35 micrograms.</p>
                <p>After consuming gin, Wong engaged a valet driver, but a dispute led him to drive himself due to feeling threatened. He was lost, then involved the police following further conflict with the valet driver.</p>
                <p>The court, led by District Judge Shawn Ho, imposed a fine of $2,000 and disqualified Wong from driving for 24 months. The court found no "special reasons" to reduce the penalty, despite the defense arguments citing work-related needs and the circumstances of the incident. The drink driving offense carried predetermined consequences, aligning with ranges based on alcohol levels, highlighting the statutory intent to deter and punish such actions to protect public safety.
                </p>
            </div>

            {/* <h6 style={{color: '#011225'}}>This is what we generate for you.</h6> */}
            <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
            <div style={{ color: "black",fontSize:"14px" }}>
                <p>Drink Driving Case: Public Prosecutor v Wong Chee Kan [2024] SGDC 274</p>
                <p>In Public Prosecutor v Wong Chee Kan [2024] SGDC 274, Wong Chee Kan pleaded guilty to drink driving under Section 67(1)(b) of the Road Traffic Act 1961.</p>
                <p>Wong’s breath alcohol level was 51 micrograms per 100 ml, surpassing the legal limit of 35 micrograms.</p>
                <p>After drinking gin, Wong hired a valet. A dispute arose, and Wong drove himself, feeling threatened by the valet.</p>
                <p>District Judge Shawn Ho imposed a $2,000 fine and a 24-month driving disqualification on Wong.</p>
                <p>The court dismissed arguments for reduced penalties, stressing public safety and strict penalties for drink driving.</p>
            </div>
            
        </section>
    );
};

export default RWsample;