import React, { useEffect } from "react";
import MocUp from "../../Components/PageHeading/MocUp";
import img from './check-mark.png'
import './Thanks.css'
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
// import XIcon from '@mui/icons-material/X';
import PinterestIcon from '@mui/icons-material/Pinterest';
import tiktok from './tik.png'
import xxx from './x.jpg'
// import { FaXTwitter } from "react-icons/fa6";
// import { BsTwitterX } from "react-icons/bs";
import { IoLogoMedium } from "react-icons/io5";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { useNavigate } from "react-router-dom";
import { Point } from "canvg";
import { NavLink } from 'react-router-dom';


const ThanksPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0,0);
  } ,[])
  return (
    <div>
      {/* <MocUp title="" 
      // description="Thanks" 
      /> */}
      <PageHeading 
      title={'Let Us Do It For You'}
      />
      
      <section className="container mx-auto py-5">
        <div className="" style={{color: 'white', textAlign: 'center'}}>
        <p className="px-3">
                Thank you for exploring how our “Let Us Do It For You” service works!
                </p>

                <p className="px-3 ">
                For details on pricing, please visit: <NavLink style={{color: 'blue', textDecoration: 'none'}} to='/pricing'>whalesai.com/pricing</NavLink>
                </p>

                <p className="px-3 ">
                If you’d like to create content on your own, head over to: <NavLink style={{color: 'blue', textDecoration: 'none'}} to='/do-it-yourself'>whalesai.com/do-it-yourself</NavLink> 
                </p>
        </div>
      </section>
      {/* <section className="container mx-auto p-4" style={{color: 'white'}}>
        <div style={{marginTop: '50px', marginBottom: '50px'}}>

            <div className="text-center mx-auto rounded shadow border-yellow" style={{width: '400px', height: '300px', background: 'white', color: 'black'}}>
                <img src={img} style={{width: '80px', height: '80px'}} />
                <h2 style={{fontWeight: 'bold'}}>Thank You</h2>
                <p className="px-3">
                We appreciate your interest in connecting with us. In the meantime, feel free to browse our site for more information about our services, or connect with us directly.
                </p>

                <p className="px-3">
                Thank you for exploring how our “Let Us Do It For You” service works!
                </p>

                <p className="px-3">
                For details on pricing, please visit: whalesai.com/pricing
                </p>

                <p className="px-3">
                If you’d like to create content on your own, head over to: whalesai.com/do-it-yourself
                </p>

                <div className="my-4">
                    <button
                    onClick={() => {
                      navigate('/contact')
                    }}
                    className="btn rounded shadow text-uppercase" style={{background: 'orange', border: 'none', }}>
                        Contact US
                    </button>
                </div>

                <div className="mt-4">
                    <p style={{fontWeight: 'bold'}} className="text-uppercase">Follow US</p>

                    <div className="d-flex justify-content-center gap-2">
                      <LanguageIcon style={{color: 'gray', fontSize: '30px', cursor: 'pointer' }}/>
                      <FacebookIcon style={{color: '#0A66C2', fontSize: '30px', cursor: 'pointer' }}/>
                      <LinkedInIcon style={{color: '#0A66C2', fontSize: '30px', cursor: 'pointer' }}/>
                      <InstagramIcon style={{color: 'orange', fontSize: '30px', cursor: 'pointer' }}/>
                     
                      <IoLogoMedium style={{color: '#000', fontSize: '30px', cursor: 'pointer' }} />
                      <PinterestIcon style={{color: 'black', fontSize: '30px', cursor: 'pointer' }}/>
                      <img style={{height: '30px', width: '30px', cursor: 'pointer'}} src={tiktok} />
                      <img style={{height: '30px', width: '30px', cursor: 'pointer'}} src={xxx} />
                    </div>
                </div>
            </div>

        </div>
      </section> */}
    </div>
  );
};

export default ThanksPage;
