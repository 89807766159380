import React, { useContext, useEffect, useState } from "react";
import Banner from "./Banner/Banner";
import MetaData from "../../Components/MetaData/MetaData";
// import Method1 from "./methods/Method1";
import Method1 from "../Landing page/methods/Method1";
// import Method2 from "./methods/Method2";
import Method2 from "../Landing page/methods/Method2";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import './Home.css'
import TextSlider from "./TextSlider/TextSlider";
import { UserAuthContext } from "../../Contexts/UserAuthContext";

const Home = () => {
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);

  // const payment = searchParams.get("payment");
  // const credits = searchParams.get("credits");

  // useEffect(() => {
  //   if (payment === "success") {
  //     swal({
  //       title: "Success!",
  //       text: `You have purchased ${credits} credits successfully!`,
  //       icon: "success",

  //       // buttons: {
  //       //   cancel: "OK",
  //       //   confirm: "VIEW TRANSACTION",
  //       // },
  //       button: "OK",

  //       className: "modal_class_success",
  //     });
  //     // .then((isConfirmed) => {
  //     //   if (isConfirmed) {
  //     //     navigate("/transaction");
  //     //   }
  //     // });
  //   } else if (payment === "cancelled") {
  //     swal({
  //       title: "Warning!",
  //       text: `You have cancelled the payment!`,
  //       icon: "warning",
  //       button: "Ok",
  //       className: "modal_class_success",
  //     });
  //   }
  // }, [payment, credits]);

  // scroll functionality

  // const [showScrollUp, setShowScrollUp] = useState(false);
  // const [showScrollDown, setShowScrollDown] = useState(true);

  // const checkScrollPosition = () => {
  //   if (!showScrollUp && window.pageYOffset > 400) {
  //     setShowScrollUp(true);
  //   } else if (showScrollUp && window.pageYOffset <= 400) {
  //     setShowScrollUp(false);
  //   }

  //   if (
  //     !showScrollDown &&
  //     window.innerHeight + window.pageYOffset < document.body.offsetHeight - 400
  //   ) {
  //     setShowScrollDown(true);
  //   } else if (
  //     showScrollDown &&
  //     window.innerHeight + window.pageYOffset >=
  //       document.body.offsetHeight - 400
  //   ) {
  //     setShowScrollDown(false);
  //   }
  // };

  // const scrollTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  // const scrollBottom = () => {
  //   window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", checkScrollPosition);
  //   return () => {
  //     window.removeEventListener("scroll", checkScrollPosition);
  //   };
  // }, [showScrollUp, showScrollDown]);

  const navigate = useNavigate();
  const {AIUser} = useContext(UserAuthContext);

  return (
    <div>
      <MetaData
        pageTitle={
          "Smart Social Media Management AI Platform | AI Post Generator"
        }
        pageDescription={
          "Upgrade your social media presence with our top-rated social media management AI platform that delivers the best AI-generated posts. Sign up now!"
        }
        canonical="https://whalesai.com/"
      ></MetaData>
     
      {/* button */}
      <div className="d-flex justify-content-center pt-4 pb-0 mb-0 px-4" style={{ background: "linear-gradient(to right, #174665, #174665)" }}>
        <button
          className="btn gradient-btn1 animated-border1"
          onClick={()=>{
            if(AIUser){
              navigate('/dashboard')
            }
            else navigate('/sign-up')
          }}
        >
          Sign up to win iPhone 16 pro max worth SGD 2,500
        </button>
      </div>

      <Banner />

      
      <div className="d-flex justify-content-center py-2 px-4" style={{ background: "linear-gradient(to right, #096B82, #096A81)" }}>
      {/* <TextSlider/> */}
        {/* <button
          className="btn gradient-btn1 animated-border"
          onClick={() => {
            // navigate("/how-it-works");
          }}
          style={{color: 'white'}}
        >
          SignUp Now and get 2000 FREE CREDITS
        </button> */}
      </div>

      <div className="d-flex justify-content-center py-2 pt-5 px-4" style={{ background: "linear-gradient(to right, #096B82, #096A81)" }}>
        <button
          className="btn gradient-btn animated-border"
          onClick={() => {
            navigate("/how-it-works");
          }}
        >
          Find out how you can create content for your social media channels.
        </button>
      </div>

      <Method1 />
      {/* <AIs /> */}
      {/* <Method1 /> */}
      {/* <Method2 /> */}

      {/* <section style={{ backgroundColor: '#3C2400' }} className="pb-4 pb-md-5">
        <div className="container px-4 mx-auto">
          <Packages />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px 0 0',
            }}
          >
            <NavLink to='/let-us-do-it-for-you'
              className="btn btn-info"
              onClick={() => {
                swal({
                  text: "We’re working on something amazing, and we can't wait to share it with you. Stay tuned for updates as we prepare to launch a brand-new experience that will elevate your expectations and redefine what's possible.", //for mainnet
                  className: 'modal_class_success',
                });
              }}
            >
              HIRE OUR SOCIAL MEDIA MARKETING MANAGER(SMMM) AI FOR JUST USD 30!
            </NavLink>
            {' '}
          </div>
        </div>
      </section> */}

      {/* scroll icon  */}

      {/* {showScrollUp ? (
        <FaArrowAltCircleUp
          onClick={scrollTop}
          className="position-fixed"
          style={{
            bottom: "20px",
            right: "20px",
            padding: "10px",
            backgroundColor: "#fd7e14",
            color: "white",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1000,
            fontSize: "3rem",
          }}
        />
      ) : (
        <FaArrowAltCircleDown
          onClick={scrollBottom}
          className="position-fixed"
          style={{
            bottom: "20px",
            right: "20px",
            padding: "10px",
            backgroundColor: "#6c757d",
            color: "white",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1000,
            fontSize: "3rem",
          }}
        />
      )} */}
      <Method2 />
    </div>
  );
};

export default Home;
