import React, { Fragment, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moneyExchangeIcon from "../../Assets/Images/transaction-data.png";
import Loader from "../../Components/Loader/Loader2";
import { Button } from "@mui/material";
import { DownloadOutlined } from "@mui/icons-material";
import { saveAs } from "file-saver";
import swal from "sweetalert";
import axios from "axios";
import html2canvas from "html2canvas";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import PageHeading from "../../Components/PageHeading/PageHeading";
const VideoAiExport = () => {
  const { AIuser, token, setToken, isBack, setisBack } =
    useContext(UserAuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const creditTolcalString = AIuser?.credits
  ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
  : "0";

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const location = useLocation();
  const videoUrl = location?.state?.videoUrl;
  const pages = location?.state?.pages;

  const downloadVideo = () => {
    const sanitizedText = pages[0].text
      .replace(/[^\w\s]/g, "")
      .split(" ")
      .join("-");

    console.log("sanitizedText", sanitizedText);

    setDownloading(true);
    saveAs(videoUrl, `${sanitizedText}.mp4`);
    setDownloading(false);
  };

  const handleBack = () => {
    const paragraphs = location?.state?.paragraphs;
    const format = location?.state?.format;
    navigate("/generated-video", {
      state: {
        paragraphs: paragraphs,
        format: format,
      },
    });
  };

  const text = "What is AI?!&.";
  const sanitizedText = text
    .replace(/[^\w\s]/g, "")
    .split(" ")
    .join("_");
  console.log(sanitizedText);

  return (
    <Fragment>
      <PageHeading title={"VIDEO AI"} />
      <div className="img-ai-container container pb-5">
        <div className="d-flex justify-content-center">
          <div className="d-flex align-items-start">
            <button className="credits-button">
              CREDITS : {creditTolcalString}
            </button>
            <button
              className="buy-credits-button"
              onClick={() => navigate("/purchase")}
            >
              BUY CREDITS
            </button>
            <button className="money-exchange-button">
              <img src={moneyExchangeIcon} alt="image" />
            </button>
          </div>
        </div>
        <div className="pt-5">
          <div style={{ maxWidth: "850px" }} className="mx-auto">
            <p className="text-warning text-center mb-0">
              Step 5: <span className="text-white">Export Video.</span>{" "}
            </p>

            {videoUrl && (
              <div style={{ width: "400px" }} className="mx-auto mt-2">
                <>
                  <video
                    controls
                    playsInline
                    // muted
                    autoPlay
                    src={videoUrl}
                    className="p-"
                    style={{ width: "100%", height: "100%" }}
                    type="video/mp4"
                  />
                </>

                <div className="d-flex justify-content-center mt-2">
                  <button
                    className="videoai_btnScript me-2 bg-danger"
                    // sx={{
                    //   marginRight: "10px",

                    //   color: "#000",
                    //   background: "#fff",
                    //   "&:hover": { background: "#fff" },
                    // }}
                    // variant="contained"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    className="videoai_btnScript bg-success"
                    onClick={downloadVideo}
                    disabled={downloading}
                  >
                    <DownloadOutlined />
                    {downloading ? "Downloading.." : "Download"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default VideoAiExport;
