import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {

  return (
    <>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white'
          }}
        >
          <Typography variant="caption" component="div" color="text-white">
            {`${props.value}%`}
          </Typography>
        </Box>
      </Box>
      {props.value === 90 && <p className='text-white d-block loading-text'>Standby we are preparing for you!</p>}
    </>
  );
}



export default function CircularStatic(props) {

  const [progress, setProgress] = React.useState(props.value);
  React.useEffect(() => {
    setProgress(props.value)
  }, [props.value])

  return <CircularProgressWithLabel value={progress} />;
}
