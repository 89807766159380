import { useEffect, useState } from "react";
import { Select } from "antd";

const { Option } = Select;

function CompanionVoiceSelect({
  onChangeVoices,
  setDefaultValue,
  defaultValue,
  setVoice,
  voices,
  loaded,
}) {
  // useEffect(() => {
  //   const randomIndex = Math.floor(Math.random() * voices?.length);
  //   setDefaultValue(voices?.[randomIndex]);
  //   setVoice(voices?.[randomIndex]?.value);
  // }, []);

  // console.log(defaultValue);

  return (
    <select
      className="selector-second h-70"
      id="voice"
      name="voice"
      style={{ borderRadius: "4px 0 0 4px" }}
      value={defaultValue?.label}
      onChange={onChangeVoices}>
      {voices?.map((voice) => {
        return (
          <option key={voice.value} value={voice.label}>
            {voice.label}
          </option>
        );
      })}
    </select>
  );
}

export default CompanionVoiceSelect;
