import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuthContext } from "../Contexts/UserAuthContext";
import moneyExchangeIcon from "../Assets/Images/transaction-data.png";

const CreditsButton = () => {
  const { AIuser } = useContext(UserAuthContext);
  const creditTolcalString = AIuser?.credits
  ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
  : "0";
  const navigate = useNavigate();
  return (
    <>
      <button className="credits-button">CREDITS : {creditTolcalString}</button>
      <button
        className="buy-credits-button"
        onClick={() => navigate("/purchase")}
      >
        BUY CREDITS
      </button>
      <button
        onClick={() => navigate("/usage")}
        className="money-exchange-button"
      >
        <img src={moneyExchangeIcon} alt="image" />
      </button>
    </>
  );
};

export default CreditsButton;
