import React from "react";
import { FaCheckSquare } from "react-icons/fa";
import ReactMarkdown from "react-markdown";

const RRC = ({ inputText, isGenerating, text, presetSelected, wordRef }) => {
  return (
    <>
      <p class="preset-title">Restaurant Reviewer</p>
      {inputText ? (
        <>
          <>
          <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
            <p class="text-muted">{inputText}</p>
            {isGenerating == true ? (
              <p
                class="text- mb-0 mt-2"
                style={{
                  fontSize: "14px",
                  color: "#A020F0",
                }}
              >
                Processing...
              </p>
            ) : (
              <>
                {text && (
                  <div>
                    <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
                  <p
                    style={{
                      color: "black",
                    }}
                    class="text- mb-0 text-"
                  >
                    {wordRef.current && (
                      <>
                        <p style={{ color: "black" }}>
                          <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                      </>
                    )}
                  </p>
                  </div>
                )}
              </>
            )}
          </>
        </>
      ) : (
        <>
         <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
          <p className="text-muted" style={{fontSize:"14px" }}>
            Name: The Blue Wharf
            <br />
            Lobster great, noisy, service polite, prices good.
            <br />
          </p>

          <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
          <p style={{ color: "black",fontSize:"14px" }}>
            <ReactMarkdown>{presetSelected.label}</ReactMarkdown>
          </p>
        </>
      )}
    </>
  );
};

export default RRC;
