import React, { useEffect, useState } from "react";
import MocUp from "../../Components/PageHeading/MocUp";
import Calendar from "react-calendar";
import { Col, Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import { MdContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FacebookData } from "./Data";
import StepButton from "../StepButton/StepButton";
import { SpeechBubbleBottomCenter, SpeechBubbleLeft } from "../SpeechBubble/Bubble";

const CalandarsMoc = () => {
  const buttons = [
    "Facebook",
    "Instagram",
    "Threads",
    "Twitter",
    "LinkedIn",
    "TikTok",
    "YouTube",
    "Pinterest",
    "Medium",
    "Telegram",
  ];

  const buttonColors = [
    "#95B9C9",
    "#39ACEF",
    "#0699E8",
    "#488AC7",
    "#367ECA",
    "#6960ED",
    "#1669C7",
    "#0040C4",
    "#0000FE",
    "#151A8F",
  ];

  const navigate = useNavigate();

  const [scheduleData, setScheduleData] = useState(null);
  const [scheduled, setScheduled] = useState(false);
  const [activeButton, setActiveButton] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [socialSchedule, setSocialSchedule] = useState(false);
  const [isChnaged, setIsChnaged] = useState(false);
  const [indexNumber, setIndexNumber] = useState(0);
  const [currentMonth, setCurrentMonth] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [isEnd, setIsEnd] = useState(false);

  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [time, setTime] = useState(() => {
    const foundSchedule = scheduleData?.find(
      (item) => item.platform === buttons[activeButton]
    );
    return foundSchedule
      ? foundSchedule?.contentCategory[indexNumber]?.timeOfPosting
      : "09:00";
  });

  const handleDateChange = (date) => {
    setIsChnaged(false);
    setSelectedDate(date);
    const activePlatform = buttons[activeButton];
    const platformSchedule = scheduleData?.find(
      (item) => item.platform === activePlatform
    );
    console.log("platform Schedule", platformSchedule);
    if (platformSchedule) {
      const startDateParts = platformSchedule?.startingDate.split("-");
      const startDate = new Date(
        startDateParts[2],
        startDateParts[1] - 1,
        startDateParts[0]
      );
      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);

      if (date >= startDate && date <= endDate) {
        const timeDiff = date.getTime() - startDate.getTime();
        const dayNumber = Math.floor(timeDiff / (1000 * 3600 * 24)) + 1;
        // setClickedDay(dayNumber);
        const handleButtonClickSWAL = (value) => {
          swal.close();
        };
        const okButton = document.createElement("button");
        okButton.textContent = "OK";
        okButton.className = "modal-button ok-button";
        okButton.addEventListener("click", () => handleButtonClickSWAL("OK"));
        setTime(
          platformSchedule?.contentCategory[dayNumber - 1]?.timeOfPosting
        );
        // setTodaysContent(platformSchedule?.contentCategory[dayNumber - 1]);
        // setInfo(true);
      } else {
      }
    } else {
    }
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
    const currentMonth = activeStartDate.toLocaleString("default", {
      month: "long",
    });
    const currentYear = activeStartDate.getFullYear();
    setCurrentMonth(currentMonth);
    setCurrentYear(currentYear);
    console.log(`Changed Month: ${currentMonth}, Changed Year: ${currentYear}`);
  };

  const handleButtonClick = (index) => {
    setIsChnaged(false);
    const today = new Date();
    setActiveButton(index);
    setSelectedDate(today);
    setActiveStartDate(today);

    let timeToSet = "09:00";

    if (scheduleData) {
      const platformData = scheduleData;

      if (platformData) {
        setSocialSchedule(true);

        if (indexNumber === -1) {
          timeToSet = "09:00";
        } else {
          timeToSet = platformData.contentCategory[indexNumber]?.timeOfPosting;
        }
      } else {
        setSocialSchedule(false);
      }
    }

    setTime(timeToSet);
  };
  const renderTileContent = ({ date, view }) => {
    if (view === "month" && scheduleData?.length) {
      // Find the schedule for the current active platform
      const activePlatformSchedule = scheduleData?.find(
        (item) => item.platform === buttons[activeButton]
      );

      if (activePlatformSchedule) {
        const parseDate = (dateString) => {
          const [day, month, year] = dateString.split("-").map(Number);
          return new Date(year, month - 1, day);
        };

        const startDate = new Date();
        const endDate = new Date();
        
        endDate.setFullYear(endDate.getFullYear() + 1);

        const formatDate = (date) => {
          const day = String(date.getDate()).padStart(2, '0'); 
          const month = String(date.getMonth() + 1).padStart(2, '0'); 
          const year = date.getFullYear(); 
          return `${day}-${month}-${year}`;
        };

        setDateStart(formatDate(startDate));
        setDateEnd(formatDate(endDate));
        setIsEnd(true);
        
        // Log formatted dates
        console.log(formatDate(startDate), 'tooooooo', formatDate(endDate));

        if (date >= startDate && date <= endDate) {

          return (
            <div>
              <MdContentCopy style={{ color: "black" }} />
            </div>
          );
        }
      }
    }
    return null;
  };

  const handleSchedulerAIClick = async () => {
    const platform = buttons[activeButton];
  
    // Open the initial SweetAlert dialog
    const swalInstance = swal({
      text: `Do you want our Scheduler AI to automatically generate and post daily content to ${
        platform === "Twitter" ? "Twitter/X" : 
        platform === "Facebook" ? "Facebook Page" : 
        platform === "LinkedIn" ? "LinkedIn Page" : platform
      }?`,
      icon: "warning",
      buttons: ["Cancel", "Yes"],
      className: "modal_class_success",
      closeOnClickOutside: false,
      closeOnEsc: false,
    });
  
    // Automatically trigger the "Yes" button click after a delay
    setTimeout(async () => {
      const willPost = true; // Simulating "Yes" button click
      if (willPost) {
        setScheduled(true);
        setScheduleData(FacebookData);
        swal({
          text: `Scheduler AI has planned contents for ${platform}. Please click on individual date to see what type of content is scheduled.`,
          icon: "success",
          className: "modal_class_success",
          timer: 2000,
        });
      }
    }, 3000); // Wait for 3 seconds before auto-confirmation
  };
  
  

  useEffect(() => {
    window.scroll(0,0);
    // Wait for 3 seconds when the page loads
    const timer = setTimeout(() => {
      window.scroll(0, 300);
      handleSchedulerAIClick();
      window.scroll(0, 700);
    }, 3000);

    return () => clearTimeout(timer); 
  }, []);

  console.log(scheduleData);

  return (
    <div>
      <MocUp
        title="CALENDARS"
        description="Schedule Contents with Calendar"
        step="Step 2:"
      />
      <div className="quick-links-container custom-tabs d-flex gap-2">
        {buttons.map((label, index) => (
          <button
            key={index}
            className={`btn custom rounded-lg 
                ${activeButton === index ? "active" : ""}
                `}
            onClick={() => handleButtonClick(index)}
            style={{ backgroundColor: buttonColors[index], height: "50px" }}
          >
            <p className="tabs-item m-0">
              {" "}
              {label === "Twitter" ? (
                "Twitter/X"
              ) : label === "Facebook" ? (
                <>
                  Facebook{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      display: "block",
                      padding: "0px",
                    }}
                  >
                    Page or Group
                  </span>
                </>
              ) : label === "LinkedIn" ? (
                <>
                  LinkedIn{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      display: "block",
                      padding: "0px",
                    }}
                  >
                    Page
                  </span>
                </>
              ) : (
                label
              )}
              {/* {label == "Twitter" ? "Twitter/X" : label} */}
            </p>
          </button>
        ))}
      </div>

      <section>
        <div className="tabs-title">
          {buttons[activeButton] === "Twitter"
            ? "Twitter/X"
            : buttons[activeButton] === "Facebook"
              ? "Facebook Page"
              : buttons[activeButton] === "LinkedIn"
                ? "LinkedIn Page"
                : buttons[activeButton]}
        </div>
        <div
          className="text-center text-white"
          style={{ fontSize: "10px", marginBottom: "20px" }}
        >
          {" "}
          {scheduleData?.length !== 0 &&
          Array.isArray(scheduleData) &&
          scheduleData?.some(
            (item) => item.platform === buttons[activeButton]
          ) ? (
            <div>
              <span>Scheduler AI has scheduled from</span> <br />{" "}
              <spa>{dateStart} to {dateEnd}</spa>
            </div>
          ) : (
            <span> Scheduling Not Done </span>
          )}
        </div>
      </section>

      {/* claendars */}
      <section>
        <div
          className="calendar mb-1 pb-0 mx-auto"
          style={{ position: "relative" }}
        >
          <div>
            <div className="justify-content-center">
              <Col xs="auto" className="calendar-col">
                <div
                  className="calendar-navigation"
                  style={{ position: "relative" }}
                >
                  <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    className="custom-calendar mx-auto"
                    next2Label={null}
                    prev2Label={null}
                    activeStartDate={activeStartDate}
                    onActiveStartDateChange={handleActiveStartDateChange}
                    tileContent={renderTileContent}
                  />
                </div>
              </Col>
            </div>
          </div>
        </div>
      </section>

      {/* below calendars  */}
      <section>
      <div className="mb-3">
          <SpeechBubbleBottomCenter>Discover and create engaging content to share with your audience here!</SpeechBubbleBottomCenter>
        </div>
       <div className="position-relative">
        <div className="d-flex justify-content-center mx-auto">
        {scheduleData?.length !== 0 &&
        Array.isArray(scheduleData) &&
        scheduleData?.some(
          (item) => item.platform === buttons[activeButton]
        ) ? (
          <button
            // onClick={handleReSchedulerClick}
            className=" mb-4 mx-auto d-flex btn btn-secondary"
          >
            Re-Scheduler AI
          </button>
        ) : (
          <button
            onClick={handleSchedulerAIClick}
            className="ai-button rounded mx-auto d-flex"
          >
            Scheduler AI
          </button>
        )}
        </div>

        {/* <div className="position-absolute" style={{left: '55%', top: '0'} }>
          <SpeechBubbleLeft>Schedule your content</SpeechBubbleLeft>
        </div> */}

       </div>

        <div className="text-center text-white pb-0 mb-0">
          <p className="">
            Country: Singapore
            <button
              onClick={() => {
                // setIsEditingCountry(true);
                // setisChanging(true);
              }}
              className="edit-button"
            >
              {" "}
              Edit
            </button>
          </p>
          {/* Time  */}
          <p className="pb-0 mb-0">
            Daily Posting Time: 09:00 (24 hours)
            <button
              onClick={() => {
                if (
                  !(
                    scheduleData?.length !== 0 &&
                    Array.isArray(scheduleData) &&
                    scheduleData?.some(
                      (item) => item.platform === buttons[activeButton]
                    )
                  )
                ) {
                  swal({
                    text: `Please adjust the timing of posting after scheduling the content.`,
                    icon: "warning",
                    // timer: 2000,
                    className: "modal_class_success",
                  });
                } else {
                  // setIsEditingTime(true);
                }
              }}
              style={{
                backgroundColor: !(
                  scheduleData?.length !== 0 &&
                  Array.isArray(scheduleData) &&
                  scheduleData?.some(
                    (item) => item.platform === buttons[activeButton]
                  )
                )
                  ? "#5C636A"
                  : "#39ACEF",
              }}
              className="edit-button mb-0"
            >
              {" "}
              Edit{" "}
            </button>
          </p>
        </div>

        <p className="server-sm">
          There may be slight variations in posting times due to factors such as
          server congestion.
        </p>

        {/* Buttons */}
        <div
          className="text-center container mx-auto mb-2"
          // style={{width: '100%'}}
        >
          <button
            // onClick={handleShowPlatform}
            style={{ width: "100%" }}
            className="btn btn-warning automatically"
          >
            <span>START GENERATING CONTENTS</span>
            <span> AND POSTING AUTOMATICALLY</span>
          </button>
        </div>
        <div className=" container mx-auto d-flex justify-content-center mx-4">
          <button
            onClick={() => {
              navigate("/how-it-works-analysis");
            }}
            className="btn btn-info analysis rounded"
          >
            <p className="pb-0 pt-0 mt-0 mb-0">VIEW YOUR ANALYSIS </p>{" "}
          </button>
        </div>
      </section>

      <section>
        {
          isEnd && <StepButton pre = {`/how-it-works-calendars`} nex = {`/how-it-works-checking-of-contents-by-user`} />
        }
      </section>
    </div>
  );
};

export default CalandarsMoc;
