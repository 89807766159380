import { Box, Button, Card, Typography } from "@mui/material";
import { styled } from "@mui/system";

//! common styles
export const MethodButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
export const MethodButtonText = styled(Typography)({
  color: "white",
  fontWeight: "bold",
});

export const DescriptionText = styled(Typography)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "2rem",
});
export const StartButton = styled(Button)({
  fontSize: "18px",
  fontWeight: 700,
  background: "black",
  padding: "6px 30px",
  border: 0,
  color: "white",
  borderRadius: "4px",
  boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
  marginTop: "3rem",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#000",
  },
});

//! method1 style
export const Method1Container = styled(Box)({
  background: "linear-gradient(#096B82, #072138)",
  padding: "2rem 0",
  color: "white",
});
export const Method1Button = styled(Card)({
  backgroundColor: "#006C62",
  color: "white",
});

//! method2 style
export const Method2Container = styled(Box)({
  background: "linear-gradient(#006B62, #164463)",
  padding: "2rem 0",
  color: "white",
});
export const Method2Button = styled(Card)({
  backgroundColor: "#096C83",
  color: "white",
});
