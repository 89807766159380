// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Dialog from "@mui/material/Dialog";
// import { DialogTitle, Typography, Box, DialogContent } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

// export default function SampleLongArticleImage({ modalLongImage, setModalLongImage }) {
//   const handleClose = () => setModalLongImage(false);

//   return (
//     <BootstrapDialog
//       onClose={handleClose}
//       scroll="body"
//       aria-labelledby="customized-dialog-title"
//       open={modalLongImage}
//     >
//       <Box
//         sx={{
//           backgroundColor: "#3080ab",
//           color: "#fff",
//           width: {
//             lg: "500px",
//           },
//         }}
//       >
//         <DialogTitle
//           sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
//           id="customized-dialog-title"
//         >
//           <div className="d-flex align-items-center justify-content-between">
//             <div className="d-flex align-items-center gap-2 text-white">
//               <Typography variant="h6">Sample</Typography>
//             </div>
//             <IconButton
//               aria-label="close"
//               onClick={handleClose}
//               sx={{
//                 position: "absolute",
//                 right: 8,
//                 color: "white",
//               }}
//             >
//               <CloseIcon />
//             </IconButton>
//           </div>
//         </DialogTitle>

//         <DialogContent>
//           {/* Image Section */}
//           <img
//             src="https://backend.whalesai.com/public/imageai/16082024-dd4c8b.png"
//             alt="Sample"
//             style={{ width: "100%", borderRadius: "8px" }}
//           />

//           {/* Text and Hashtag Section */}
//           <Box
//             sx={{
//               marginTop: 2,
//               padding: 2,
//               backgroundColor: "#fff",
//               color: "#000",
//               borderRadius: "8px",
//               boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
//             }}
//           >
//             <Typography variant="body1">
//             Havana, the capital of Cuba, is a city that effortlessly merges the past with the present, creating a living canvas where every corner tells a story. The streets of Havana are not just thoroughfares; they are vibrant tapestries of life, bursting with color, sound, and energy. From the classic cars that roll down the streets to the lively street musicians who fill the air with infectious rhythms, Havana is a city that lives and breathes its history and culture. One of the first things that captivate visitors to Havana is the city’s striking architecture. 
//             <br/>  <br/>
//             The streets are lined with colonial buildings painted in every hue imaginable, from bright blues and yellows to soft pastels and deep reds. These buildings, many of which date back to the 16th century, stand as a testament to Havana's rich history, having witnessed centuries of change while retaining their old-world charm. As you stroll down these bustling streets, you can't help but admire the intricate details of the colonial facades, with their ornate balconies, wrought-iron gates, and grand archways. Despite the passage of time, these structures remain vibrant, thanks to the efforts of the Cuban people, who lovingly preserve their cultural heritage. The contrast between the well-maintained buildings and those showing signs of wear only adds to the charm, creating a visual narrative of a city that has endured and thrived. 
//             <br/>  <br/>
//             Havana is perhaps most famous for its classic cars, which have become synonymous with the city's identity. These vintage automobiles, relics of the 1950s, continue to cruise the streets, their polished exteriors gleaming in the Caribbean sun. From brightly colored Chevrolets and Fords to Cadillacs and Buicks, these cars are more than just transportation; they are a symbol of Cuba’s resilience and resourcefulness. Kept running through ingenuity and a deep love for the past, these vehicles transport you back in time, adding a layer of nostalgia to the vibrant energy of the city. #HavanaVibes #ColorfulCuba #StreetLife #ClassicCars #ColonialCharm
//             </Typography>
//           </Box>
//         </DialogContent>
//       </Box>
//     </BootstrapDialog>
//   );
// }










import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SampleLongArticleImage({ modalLongImage, setModalLongImage }) {
  const handleClose = () => setModalLongImage(false);

  // Preload the image
  const imageUrl = "https://backend.whalesai.com/public/imageai/16082024-dd4c8b.png";
  const [imageLoaded, setImageLoaded] = React.useState(false);

  React.useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => setImageLoaded(true);
  }, [imageUrl]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalLongImage}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Sample</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          {/* Image Section */}
          {imageLoaded ? (
            <img
              src={imageUrl}
              alt="Sample"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          ) : (
            <Typography variant="body1" sx={{ textAlign: 'center', padding: 2 }}>
              Loading...
            </Typography>
          )}

          {/* Text and Hashtag Section */}
          <Box
            sx={{
              marginTop: 2,
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="body1">
              Havana, the capital of Cuba, is a city that effortlessly merges the past with the present, creating a living canvas where every corner tells a story. The streets of Havana are not just thoroughfares; they are vibrant tapestries of life, bursting with color, sound, and energy. From the classic cars that roll down the streets to the lively street musicians who fill the air with infectious rhythms, Havana is a city that lives and breathes its history and culture. One of the first things that captivate visitors to Havana is the city’s striking architecture. 
              <br/>  <br/>
              The streets are lined with colonial buildings painted in every hue imaginable, from bright blues and yellows to soft pastels and deep reds. These buildings, many of which date back to the 16th century, stand as a testament to Havana's rich history, having witnessed centuries of change while retaining their old-world charm. As you stroll down these bustling streets, you can't help but admire the intricate details of the colonial facades, with their ornate balconies, wrought-iron gates, and grand archways. Despite the passage of time, these structures remain vibrant, thanks to the efforts of the Cuban people, who lovingly preserve their cultural heritage. The contrast between the well-maintained buildings and those showing signs of wear only adds to the charm, creating a visual narrative of a city that has endured and thrived. 
              <br/>  <br/>
              Havana is perhaps most famous for its classic cars, which have become synonymous with the city's identity. These vintage automobiles, relics of the 1950s, continue to cruise the streets, their polished exteriors gleaming in the Caribbean sun. From brightly colored Chevrolets and Fords to Cadillacs and Buicks, these cars are more than just transportation; they are a symbol of Cuba’s resilience and resourcefulness. Kept running through ingenuity and a deep love for the past, these vehicles transport you back in time, adding a layer of nostalgia to the vibrant energy of the city. #HavanaVibes #ColorfulCuba #StreetLife #ClassicCars #ColonialCharm
            </Typography>
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
