import React from "react";
import { FaCheckSquare } from "react-icons/fa";
import ReactMarkdown from "react-markdown";

const ADS = ({ inputText, isGenerating, text, presetSelected, wordRef }) => {
  return (
    <>
      <p class="preset-title">Advertisement</p>
      {inputText ? (
          <>
            <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
            <p class="text-muted">{inputText}</p>

            {isGenerating == true ? (
              <p
                class="text- mb-0 mt-2"
                style={{
                  fontSize: "14px",
                  color: "#A020F0",
                }}
              >
                Processing...
              </p>
            ) : (
              <>
                {text && (
                  <p
                    style={{
                      color: "black",
                    }}
                    class="text- mb-0 text-"
                  >
                    {wordRef.current && (
                      <>
                       <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
                        <p style={{ color: "black" }}>
                          <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                      </>
                    )}
                  </p>
                )}
              </>
            )}
          </>
      ) : (
        <>
        <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
          <p className="text-muted" style={{ fontSize:"14px" }}>
            Learning Room is a virtual environment to help students from
            kindergarten to high school excel in school.
          </p>
           
          <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
          <p style={{ color: "black",fontSize:"14px" }}>
            <ReactMarkdown>{presetSelected.label}</ReactMarkdown>
          </p>

          {/* <button style={{background: '#8B0000', color: 'white'}} className="btn btn-sm">
            Try Now
          </button> */}
        </>
      )}
    </>
  );
};

export default ADS;
