import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Main from "../Components/Main/Main";
import Home from "../Pages/Home/Home";
import CompanionAi from "../Pages/CompanionAi/CompanionAi";
import TextAi from "../Pages/TextAi/TextAi";
import ImageAi from "../Pages/ImageAi/ImageAi";
import BuyCredits from "../Pages/BuyCredits/BuyCredits";
import SingUp from "../Pages/SingUp/SingUp";
import SignIn from "../Pages/SignIn/SignIn";
import ForgotPassword from "../Pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../Pages/ResetPassword/ResetPassword";
import RequireUser from "../Components/RequireUser/RequireUser";
import NotFound from "../Pages/Error/NotFound";
import HowItWorks from "../Pages/HowItWorks/HowItWorks";
import Payment from "../Pages/Payment/Payment";
import FormatScript from "../Pages/VideoAi/FormatScript";
import FormatSelect from "../Pages/VideoAi/FormatSelect";
import MakeVideo from "../Pages/VideoAi/MakeVideo";
import VideoAiExport from "../Pages/VideoAi/VideoAiExport";
import Transaction from "../Pages/Transaction/Transaction";
import GenerateImage from "../Pages/ImageAi/GenerateImage";
import Schedule from "../Pages/Schedule/Schedule";
import Dashboard from "../Pages/Account/Dashboard";
import PurchaseHistory from "../Pages/PurchaseHistory/PurchaseHistory";
import Earnings from "../Pages/Earnings/Earnings";
import WrappedPaymentMethodsPage from "../Pages/PaymentMethods/PaymentMethods";
import CompanionAiApps from "../Pages/CompanionAi/CompanionAiApps";
import VideoAiApp from "../Pages/VideoAi/VideoAiApp";
import MakeVideoApp from "../Pages/VideoAi/MakeVideoApp";
import WrappedPaymentMethodsPageApps from "../Pages/PaymentMethods/PaymentMethodsApp";
import PaymentApp from "../Pages/Payment/PaymetApp";
import TransactionApps from "../Pages/Transaction/TransactionApps";
import BuyCreditsApps from "../Pages/BuyCredits/BuyCreditsApps";
import AboutUs from "../Pages/AboutUs/AboutUs";
import DoItYourself from "../Pages/DoItYourself/DoItYourself";
import VideoAiAuto from "../Pages/VideoAi/VideoAiAuto";
import BuildYourBrand from "../Pages/BuildYourBrand/BuildYourBrand";
import SocialMeadiaMarketing from "../Pages/SocialMediaMarketing/SocialMeadiaMarketing";
import Analysis from "../Pages/Analysis/Analysis";
import Calendars from "../Pages/Calendars/Calendars";
import Savings from "../Pages/Savings/Savings";
import Subscription from "../Pages/Subscription/Subscription";
import TestVideoImage05 from "../Pages/POSTAI/TestVideoImage05";
import Pricing from "../Pages/Pricing/Pricing";
import GenerativeAI from "../Pages/GenerativeAI/GenerativeAI";
import ContactUs from "../Pages/ContactUs/ContactUs";
import NewsVideoAi from "../Pages/NewsVideoAi/NewsVideoAi";
import LandingPage from "../Pages/Landing page/LandingPage";
import API from "../Pages/API/API";
import ArtificialIntelligent from "../Pages/ArtificialIntelligent/ArtificialIntelligent";
import SocialMediaMarketing2 from "../Pages/SocialMediaMarketing2/SocialMediaMarketing2";
import VideoAiNews from "../Pages/VideoAi/VideoAiNews";
import ApiSettings from "../Pages/ApiSettings/ApiSettings";
import ComicVideo from "../Pages/ComicVideo/ComicVideo";
import ContentGenerator from "../Pages/ContentGenerator/ContentGenerator";
import Gallery from "../Pages/Gallery/Gallery";
import ImagePost from "../Pages/ImagePost/ImagePost";
import Subscriptionpayment from "../MocUp/SubsCribePay/SubscriptionpaymentMocUp";
import PrivateRoute from "./PrivateRoutes";
import TestHowItsWorks from "../Pages/TestHowItsWorks/TestHowItsWorks";
import SocialMediaLogins from "../Pages/SocialLogins/SocialMediaLogins";
import Admin from "../Components/Admin/Admin";
import AdminDashboard from "../Pages/Dashboard/AdminDashboard";
import Login from "../Pages/AdminLogin/Login";
import Forgetpassword from "../Pages/AdminLogin/Forgetpassword";
// ResetPassword
import Otp from "../Pages/AdminLogin/Otp";
import ResetPassword2 from "../Pages/AdminLogin/ResetPassword";
import TermsOfUse from "../Pages/TermsOfUse/TermsOfUse";
import DataProtectionNotice from "../Pages/DataProtectionNotice/DataProtectionNotice";
import AdminUser from "../Pages/AdminUser/AdminUser";
import DashboardAdminEditProfile from "../Pages/AdminUser/DashboardAdminEditProfile";
import AdminGeneralUser from "../Pages/AdminGeneralUser/AdminGeneralUser";
import FacebookLoginComponent from "../Components/FacebookLoginComponents/FacebookLoginComponent";
import ScheduledContent from "../Pages/AdminScheduleContent/ScheduledContent";
import DeleteAccount from "../Pages/DeleteUserAccount/DeleteAccount";
import AdminContact from "../Pages/AdminContact/AdminContact";
import AdminNews from "../Pages/AdminNews/AdminNews";
import AdminPayments from "../Pages/AdminPayments/AdminPayments";
import AdminTransaction from "../Pages/AdminTransactions/AdminTransaction";
import Guideline from "../Pages/SocialMediaGuideline/FacebookGuideline";
import AdminTesters from "../Pages/AdminTesters/AdminTesters";
import LetUsDoItForYou from "../MocUp/LetUsDoItForYou/LetUsDoItForYou";
import AnalysisMocUp from "../MocUp/Analysis/AnalysisMocUp";
import CalandarsMoc from "../MocUp/Calandars/CalandarsMoc";
import CheckContent from "../MocUp/CheckContent/CheckContent";
import SubsCribePay from "../MocUp/SubsCribePay/SubsCribePay";
import SubscriptionpaymentMocUp from "../MocUp/SubsCribePay/SubscriptionpaymentMocUp";
import WrappedPaymentMethodsPageMocUp from "../MocUp/SubsCribePay/PaymentMethodsMocUp";
import HistoryMockUp from "../Pages/PurchaseHistory/HistoryMocUp";
import ContentPost from "../MocUp/ContentPost/ContentPost";
import FacebookPage from "../MocUp/FacebookPage/FacebookPage";
import ThanksPage from "../MocUp/ThanksPage/ThanksPage";
import AdminTestPayments from "../Pages/AdminTestPayments/AdminTestPayments";

//Subscriptionpayment

const time = new Date();
time.setSeconds(time.getSeconds() + 180);

const Routes = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/termsofuse",
        element: <TermsOfUse />,
      },
      {
        path: "/dataprotectionnotice",
        element: <DataProtectionNotice />,
      },
      {
        path: "/pricing",
        element: <Pricing />,
      },
      {
        path: "/generative-ai",
        element: <GenerativeAI />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
      // {
      //   path: "/api",
      //   element: <API />,
      // },
      {
        path: "/artificial-intelligence",
        element: <ArtificialIntelligent />,
      },
      {
        path: "/let-us-do-it-for-you",
        element: <SocialMeadiaMarketing />,
      },
      // for mocup 
      {
        path: "/how-it-works-let-us-do-it-for-you",
        element: <LetUsDoItForYou />,
      },
      {
        path: "/how-it-works-analysis",
        element: <AnalysisMocUp />,
      },
      {
        path: "/how-it-works-calendars",
        element: <CalandarsMoc />,
      },
      {
        path: "/how-it-works-checking-of-contents-by-user",
        element: <CheckContent/>
      },
      {
        path: "/how-it-works-subscribe-and-pay",
        element: <SubsCribePay/>
      },
      {
        path: "/how-it-works-social-media-subscription",
        element: <SubscriptionpaymentMocUp/>
      },
      {
        path: '/how-it-works-payment-methods',
        element: <WrappedPaymentMethodsPageMocUp/>
      },
      {
        path: '/how-it-works-purchase-history',
        element: <HistoryMockUp/>
      },
      {
        path: "/how-it-works-social-media-login",
        element: <Guideline />,
      },
      {
        path: "/how-it-works-contents-posted-in-social-media-platforms",
        element: <ContentPost/>,
      },
      {
        path: "/how-it-works-thanks",
        element: <ThanksPage/>,
      },






      {
        path: "/social-media-marketing",
        element: <SocialMediaMarketing2 />,
      },
      {
        path: "/do-it-yourself",
        element: <DoItYourself />,
      },
      {
        path: "/api",
        element: <ContentGenerator />,
      },
      {
        path: "/test-text-article-generator",
        element: <TestHowItsWorks />,
      },
      {
        path: "/savings",
        element: <Savings />,
      },
      {
        path: "/subscription",
        element: <Subscription />,
      },
      {
        path: "/:myReferralCode",
        element: <SingUp />,
      },
      {
        path: "/credits-package/:id",
        element: <Payment />,
      },
      {
        path: "/social-media-subscription",
        element: <Subscriptionpayment />,
      },
      {
        path: "/how-it-works",
        element: <HowItWorks />,
      },
      {
        path: "/contents",
        element: <Gallery />,
      },
      
      {
        path: "/consultant-ai-app/:token",
        element: <CompanionAiApps />,
      },
      {
        path: "/token",
        element: <CompanionAi />,
      },
      // to do ---->> delete
      {
        path: "/fbpost",
        element: <FacebookLoginComponent />,
      },
      {
        path: "/video-ai-app/:token",
        element: <VideoAiApp />,
      },
      {
        path: "/make-video-app/:token",
        element: <MakeVideoApp />,
      },
      {
        path: "/payment-app/:id/:price/:credits/:expire/:discount/:paylimit/:background/:packageNo/:token",
        element: <PaymentApp />,
      },
      {
        path: "/payment-methods-app/:token",
        element: <WrappedPaymentMethodsPageApps />,
      },
      {
        path: "/transaction-app/:token",
        element: <TransactionApps />,
      },
      {
        path: "/purchase-app/:token",
        element: <BuyCreditsApps />,
      },

      {
        element: <RequireUser />,
        children: [
          {
            path: "/analysis",
            element: <Analysis />,
          },
          {
            path: "/calendars",
            element: <Calendars />,
          },
          {
            path: "/delete-user-account",
            element: <DeleteAccount />,
          },
          {
            path: "/consultant-ai",
            element: <CompanionAi />,
          },
          {
            path: "/consultant-ai-app",
            element: <CompanionAi />,
          },
          {
            path: "/text-ai",
            element: <TextAi />,
          },
          {
            path: "/image-ai-demo",
            element: <ImageAi />,
          },
          {
            path: "/post-ai",
            element: <TestVideoImage05 />,
          },
          // {
          //   path: '/do-it-yourself',
          //   element: <DoItYourself />,
          // },
          // {
          //   path: '/let-us-do-it-for-you',
          //   element: <LetUsDoForYou />,
          // },
          {
            path: "/image-ai",
            element: <GenerateImage />,
          },
          {
            path: "/text-video-ai",
            element: <VideoAiAuto />,
          },
          {
            path: "/news-video-ai",
            element: <VideoAiNews />,
          },
          {
            path: "/comic-video-ai",
            element: <ComicVideo />,
          },
          {
            path: "/social-media-post",
            element: <ImagePost />,
          },
          // {
          //   path: '/text-video-ai',
          //   element: <VideoAi />,
          // },
          {
            path: "/build-your-brand",
            element: <BuildYourBrand />,
          },
          {
            path: "/format-script",
            element: <FormatScript />,
          },
          {
            path: "/format-select",
            element: <FormatSelect />,
          },
          {
            path: "/generated-video",
            element: <MakeVideo />,
          },
          {
            path: "/export-video",
            element: <VideoAiExport />,
          },
          {
            path: "/purchase",
            element: <BuyCredits />,
          },
          {
            path: "/dashboard",
            element: <Dashboard />,
          },
          {
            path: "/usage",
            element: <Transaction />,
          },
          {
            path: "/transaction-app",
            element: <Transaction />,
          },
          {
            path: "/purchase-history",
            element: <PurchaseHistory />,
          },

          {
            path: "/schedule",
            element: <Schedule />,
          },
          {
            path: "/payment-methods",
            // element: <PaymentMethods />,
            element: <WrappedPaymentMethodsPage />,
          },
          {
            path: "/earnings",
            element: <Earnings />,
          },
          {
            path: "/api-settings",
            element: <ApiSettings />,
          },
          {
            path: "/social-media-logins",
            element: <SocialMediaLogins />,
          },
          
        ],
      },

      {
        path: "/sign-in",
        element: <SignIn />,
      },
      {
        path: "/sign-up",
        element: <SingUp />,
      },
      {
        path: "/forget-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: '/how-it-works-facebook-page',
    element: <FacebookPage/>
  },
  
  {
    path: "/admin",
    element: (
      <PrivateRoute>
        <Admin />
      </PrivateRoute>
    ),
    // element: <Admin/>,
    children: [
      {
        path: "/admin/dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "/admin/adminUser",
        element: <AdminUser />,
      },
      {
        path: "/admin/adminProfile/:id",
        element: <DashboardAdminEditProfile />,
      },
      {
        path: "/admin/users",
        element: <AdminGeneralUser />,
      },
      {
        path: "/admin/news",
        element: <AdminNews />,
      },
      {
        path: "/admin/outgoing-payments",
        element: <AdminPayments />,
      },
      {
        path: "/admin/scheduled-content",
        element: <ScheduledContent />,
      },
      {
        path: "/admin/enquiries",
        element: <AdminContact />,
      },
      {
        path: "/admin/incoming-payments",
        element: <AdminTransaction />,
      },
      {
        path: "/admin/test-payments",
        element: <AdminTestPayments />,
      },
      {
        path: "/admin/testers",
        element: <AdminTesters />,
      },
      
    ],
  },
  {
    path: "/admin/login",
    element: <Login />,
  },
  {
    path: "/admin/forgetpassword",
    element: <Forgetpassword />,
  },
  {
    path: "/admin/resetpassword/:token",
    element: <ResetPassword2 />,
  },
  // {
  //   path: '/admin/otp',
  //   element: <Otp/>
  // },
  {
    path: "/admin/otp/:token/:email",
    element: <Otp />,
  },
  {
    path: "/landingpage",
    element: <LandingPage />,
  },

  // {
  //   path: '*',
  //   element: <NotFound />,
  // },
]);

export default Routes;
