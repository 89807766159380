import React, { useContext, useEffect } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import moneyExchangeIcon from "../../Assets/Images/transaction-data.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "./ImageAi.css";
import { useNavigate } from "react-router-dom";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import swal from "sweetalert";

const ImageAi = () => {
  const { AIuser, userRefetch, setUserRefetch } = useContext(UserAuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const confirmImg = () =>{
    const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p></p> <p class="text-white">We will generate a sample image for you using artificial intelligence.<br/> It is Free.<br/> <span class="text-warning"> Do you want to proceed?</span></p> `;
      return swal({
        content: wrapper,
        icon: "success",
        buttons: ["No", "Yes"],
        className: "modal_class_success",
      }).then(async function (isConfirm) {
        if (isConfirm) {
          navigate("/image-ai")
        } else {
          console.log("");
        }
      });
    }

  

  const images1 = [
    "https://i.ibb.co/tZs07Gr/imgai2.jpg",
    "https://i.ibb.co/bzkTQbt/imgai.jpg",
    "https://i.ibb.co/3TPcKfV/imgai6.jpg",
  ];
  const images2 = [
    "https://i.ibb.co/7zNwQk4/imgai3.jpg",
    "https://i.ibb.co/vqBnM1m/imgai4.jpg",
    "https://i.ibb.co/hYCstx5/imgai5.jpg",
  ];
  const images = [
    "https://i.ibb.co/tZs07Gr/imgai2.jpg",
    "https://i.ibb.co/bzkTQbt/imgai.jpg",
    "https://i.ibb.co/3TPcKfV/imgai6.jpg",
    "https://i.ibb.co/7zNwQk4/imgai3.jpg",
    "https://i.ibb.co/vqBnM1m/imgai4.jpg",
    "https://i.ibb.co/hYCstx5/imgai5.jpg",
  ];

  const creditTolcalString = AIuser?.credits
  ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
  : "0";

  return (
    <div>
      <PageHeading title={"IMAGE AI"} description={'The Image AI assists in crafting images tailored for social media platforms.'} />
      <div className="img-ai-container container">
        <div className="CreditDiv mt-3">
          <button className="credits-button">
            CREDITS : {creditTolcalString}
          </button>
          <button
            className="buy-credits-button"
            onClick={() => navigate("/purchase")}
          >
            BUY CREDITS
          </button>
          <button
            onClick={() => navigate("/transaction")}
            className="money-exchange-button"
          >
            <img src={moneyExchangeIcon} alt="image" />
          </button>
        </div>

        <p className="text-white mb-0 mt-2 creditText">
            Every request is 50 credits.
          </p>
        <div className="banners">
          {/* banner in small */}
          <div className="banner-in-small">
            <Swiper
              slidesPerView={1}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper mt-3"
            >
              {images.map((img) => (
                <SwiperSlide>
                  <img src={img} alt="image" className="w-100 each-banner-img" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* banner in large */}
          <div className="row g-2 banner-in-large">
            <div className="col-6">
              <Swiper
                slidesPerView={1}
                loop={true}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {images1?.map((res) => (
                  <SwiperSlide>
                    <div className="banner-wrapper">
                      <img
                        className="img-fluid w-100 imgai-img"
                        src={res}
                        alt="dsl  ai"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="col-6">
              <Swiper
                slidesPerView={1}
                loop={true}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {images2?.map((res) => (
                  <SwiperSlide>
                    <div className="banner-wrapper">
                      <img
                        className="img-fluid w-100 imgai-img"
                        src={res}
                        alt="dsl  ai"
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <button
          className="big-button mb-2"
          onClick={confirmImg}
        >
          CLICK HERE TO GENERATE IMAGES
          {/* CLICK HERE TO FIND OUT HOW AI CAN GENERATE IMAGES FOR YOU */}
        </button>
      </div>
      hello
       {/* <div className='social-media-section'>
                <button 
                onClick={handleSocialMedia}
                className='btn btn-primary px-2 py-1'>Post on Social Media Platform</button>
      </div> */}
    </div>
  );
};

export default ImageAi;
