import React, { useEffect, useState } from "react";
import axios from "axios";
// import companionai from "../../../Assets/Images/companion-ai-image.png";
// import textai from "../../../Assets/Images/text-ai-image.png";
// import imageai from "../../../Assets/Images/image-ai-image.png";
// import vidoesai from "../../../Assets/Images/videos-ai-image.png";
import "./AIs.css";
import { Link, useNavigate } from "react-router-dom";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import PageHeading from "../../../Components/PageHeading/PageHeading";

const useLocation = () => {
  const location = window.location.pathname;
  return location;
};

const AIs = () => {
  const [count, setCount] = useState(0);
  const location = useLocation();
  useEffect(() => {
    const countVisitor = async () => {
      axios.get("https://backend.whalesai.com/api/v1/visitor").then((res) => {
        if (res.status === 200) {
          setCount(res.data.count);
        }
      });
    };

    // const location = useLocation();

    const setFp = async () => {
      const fp = await FingerprintJS.load();
      const { visitorId } = await fp.get();
      const res = await axios.post(
        "https://backend.whalesai.com/api/v1/visitor",
        {
          visitorId,
        }
      );
      await countVisitor();
    };

    setFp();
  }, []);
  const navigate = useNavigate();

  const ais = [
    {
      redirect: "/text-ai",
      nth: "one",
      img: "/t3.jpeg",
      header: "TEXT AI",
      credit: '1 Credit per 10 words',
    },
    {
      redirect: "/image-ai",
      nth: "two",
      img: "/i3.jpeg",
      header: "IMAGE AI",
      credit: '20 Credits per image',
    },
    {
      redirect: "/post-ai",
      nth: "three",
      img: "/p3.jpeg",
      header: "POST AI",
      credit: '10 Credits per image',
    },
    {
      redirect: "/text-video-ai",
      nth: "four",
      img: "/v3.jpeg",
      header: "TEXT VIDEO AI",
      credit: '100 Credits per video',
    },
    {
      redirect: "/comic-video-ai",
      nth: "six",
      img: "/comic.jpeg",
      header: "COMIC VIDEO AI",
      credit: '120 Credits per video',
    },
    {
      redirect: "/news-video-ai",
      nth: "five",
      img: "/news-video-ai.jpg",
      header: "NEWS VIDEO AI",
      credit: '100 Credits per video',
    },
    
  ];

  const storedNumber = localStorage.getItem("randomNumber");
  const [randomNumber, setRandomNumber] = useState(storedNumber || "");

  useEffect(() => {
    const generateRandomNumber = () => {
      const otp = Math.floor(Math.random() * 101) + 100;
      setRandomNumber(otp.toString());
      localStorage.setItem("randomNumber", otp.toString());
    };

    if (!storedNumber) {
      generateRandomNumber();
    }

    const intervalId = setInterval(generateRandomNumber, 10 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [storedNumber]);

  return (
    <div>
      <PageHeading title="DO IT YOURSELF" />
      <section className="container mt-4 mb-0 py-4" style={{ backgroundColor: "#2a003c" }}>
        <div className="ais-container container mx-auto pb-4 pt-md-4 py-md-5 px-4 mt-0 mb-0">
          {location !== "/" && (
            <div className="mb-4 d-flex align-items-center justify-content-center">
              <Link
                to="/consultant-ai"
                className="link text-decoration-none consultant-button text-center text-white fs-6 py-2 px-4"
              >
                Your Consultant AI is waiting for you. Click here to discuss.
              </Link>
            </div>
          )}

          <div className="row justify-content-center gy-2 gy-md-4 gx-2 gx-md-4 all-ais-container">
            {ais.map((eachAi) => (
              <div
                onClick={() => navigate(eachAi?.redirect)}
                className="each-ai-card col-6 col-lg-4"
              >
                <h5 className={`ai-card-header ${eachAi?.nth}`}>
                  {eachAi.header}
                </h5>
                <div className="ai-card-img-container">
                  <img className="img-fluid" src={eachAi.img} alt="image" />
                </div>
                <h5 className={`ai-card-credit ${eachAi?.nth} text-uppercase`}>
                  {eachAi.credit} 
                  {/* {eachAi.credit > 1 ? "CREDITS" : "CREDIT"} */}
                </h5>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AIs;
