import React, { useState, useEffect, useContext } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { auth } from "../../Components/Firebase/firebase.config";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import Swal from "sweetalert2";
import "./Guideline.css";
import icon from "./fb.png";
import { NavLink } from "react-router-dom";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import MocUp from "../../Components/PageHeading/MocUp";
import StepButton from "../../MocUp/StepButton/StepButton";
import {
  SpeechBubbleBottomCenter,
  SpeechBubbleBottomLeft,
  SpeechBubbleLeft,
  SpeechBubbleRight,
  SpeechBubbleTopCenter,
} from "../../MocUp/SpeechBubble/Bubble";

const buttons = [
  "Facebook",
  "Instagram",
  "Threads",
  "Twitter",
  "LinkedIn",
  "TikTok",
  "YouTube",
  "Pinterest",
  "Medium",
  "Telegram",
];

const buttonColors = [
  "#95B9C9",
  "#39ACEF",
  "#0699E8",
  "#488AC7",
  "#367ECA",
  "#6960ED",
  "#1669C7",
  "#0040C4",
  "#0000FE",
  "#151A8F",
];

const Guideline = () => {
  const [user, setUser] = useState(null);
  const [showFacebookPopup, setShowFacebookPopup] = useState(false); // State for showing popup
  const { AIuser } = useContext(UserAuthContext);

  const [isIntial, setIsIntial] = useState(true);
  const [isPermission, setIsPermission] = useState(false);
  const [isPage, setIsPage] = useState(false);
  const [select, setSelect] = useState(0);
  const [isGoTo, setIsGoTo] = useState(false);
  const [pageSelect, setPageSelect] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isLoginDone, setIsLoginDone] = useState(false);
  const permission = [
    "Create and manage content on your Page",
    "Show a list of the Pages you manage",
  ];
  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  const handleLogin = (media) => {
    if (media === "Facebook") {
      // Show Facebook login popup at the top
      setShowFacebookPopup(true);
    } else {
      // Show other media popups
      Swal.fire({
        icon: "info",
        text: `${media} functionality coming soon!`,
        confirmButtonColor: "#006666",
        background: "#011225",
        color: "white",
      });
    }
  };
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        Swal.fire({
          text: "You have successfully logged out of Facebook.",
          icon: "success",
          confirmButtonColor: "#006666",
        });
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };
  const closePopup = () => {
    setShowFacebookPopup(false);
    setIsIntial(true);
    setIsPermission(false);
    setIsPage(false);
    setSelect(0);
    setIsGoTo(false);
    setPageSelect(false);
    setIsLastStep(false);
  };
  const intialClick = (e) => {
    e.preventDefault();
    setIsIntial(false);
    setIsPermission(true);
  };

  useEffect(() => {
    window.scroll(0, 0);

    const loginTimer = setTimeout(() => {
      handleLogin("Facebook");
    }, 3000);

    return () => clearTimeout(loginTimer);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      setIsIntial(false);
      setIsPermission(true);
    }, 5000);
    return () => clearTimeout(loginTimer);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      setSelect(2);
    }, 7000);
    return () => clearTimeout(loginTimer);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      setPageSelect(true);
    }, 9000);
    return () => clearTimeout(loginTimer);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      setIsGoTo(true);
      setIsPermission(false);
    }, 11000);
    return () => clearTimeout(loginTimer);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      setIsLastStep(true);
      setIsGoTo(false);
    }, 13000);
    return () => clearTimeout(loginTimer);
  }, []);

  useEffect(() => {
    const loginTimer = setTimeout(() => {
      closePopup();
      setIsLoginDone(true);
    }, 15000);
    return () => clearTimeout(loginTimer);
  }, []);

  return (
    <div>
      {/* <PageHeading title="Social Media Logins" /> */}
      <MocUp
        title="Social Media Logins"
        description="Login to Platform"
        step="Step 5:"
      />
      <section className="container my-4">
        <div className="">
          <div className="buttons-layout mx-auto position-relative">
            {!isLoginDone && (
              <div
                className="position-absolute"
                style={{ top: "-70px", left: "35%" }}
              >
                <SpeechBubbleBottomLeft>
                Unlock seamless Facebook posting for maximum reach and engagement!
                </SpeechBubbleBottomLeft>
              </div>
            )}

            {buttons.map((button, index) => (
              <div key={index} className="every-button">
                <button
                  onClick={() => handleLogin(button)}
                  className="btn btn-block text-white mb-2"
                  style={{
                    backgroundColor: buttonColors[index],
                    width: "200px",
                  }}
                >
                  {button}
                </button>
                <div style={{ width: "130px" }}>
                  {button === "Facebook" && isLoginDone ? (
                    <div className="log-container">
                      <p className="mb-0 mr-2">Logged in</p>
                      <button
                        className="btn btn-sm btn-danger ml-2"
                        onClick={() => {
                          handleLogout();
                          setIsLoginDone(false);
                        }}
                      >
                        Log Out
                      </button>
                    </div>
                  ) : (
                    <p>Not Logged in yet.</p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Facebook Popup Modal */}
      {showFacebookPopup && (
        <div className="facebook-popup">
          <div className="popup-content">
            {/* Step---> 1  */}
            {isIntial && (
              <div>
                <div className="d-flex align-items-center">
                  <img
                    style={{
                      height: "40px",
                      width: "40px",
                      marginTop: "0px",
                      paddingTop: "0px",
                    }}
                    src={icon}
                  />
                  <p style={{ marginTop: "12px" }}>Sign in with facebook</p>
                </div>
                <hr />
                <div>
                  <p>
                    to continue to{" "}
                    <span style={{ color: "blue", fontWeight: "500" }}>
                      whalesai.com
                    </span>{" "}
                  </p>
                </div>
                <NavLink
                  onClick={intialClick}
                  style={{ textDecoration: "none" }}
                  className="d-flex gap-2 align-items-center mt-2 mb-5"
                >
                  <div>
                    <img
                      style={{
                        borderRadius: "50%",
                        height: "40px",
                        width: "40px",
                        marginTop: "0px",
                        paddingTop: "0px",
                      }}
                      className="py-0 my-0"
                      src="https://backend.whalesai.com/public/ffmpeg/whalesailogo.png"
                    />
                  </div>
                  <div>
                    <span style={{ color: "black" }}>Whales AI</span> <br />
                    <span style={{ fontSize: "14px", color: "#616161" }}>
                      developer@whalesai.com
                    </span>
                  </div>

                  {/* <SpeechBubbleLeft>
                    Tap to Select your Facebook Account
                  </SpeechBubbleLeft> */}
                </NavLink>

                <div className="d-flex justify-content-between">
                  <div></div>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-secondary btn-sm d-flex justify-content-end"
                      onClick={closePopup}
                    >
                      Not Now
                    </button>
                    {/* <button className="btn btn-primary btn-sm d-flex justify-content-end">
                      Connect
                    </button> */}
                  </div>
                </div>
              </div>
            )}

            {/* step ---> 2 */}
            {isPermission && (
              <div>
                <dev className="select-page">
                  <h5>Choose the Pages you want Page post to access</h5>
                  <p style={{ fontSize: "12px" }}>
                    Later you'll be able to review what Page post will be able
                    to do with the Pages you select
                  </p>
                  <div>
                    <div className="d-flex gap-2">
                      {select == 1 ? (
                        <MdOutlineCheckBox
                          className="box-size"
                          style={{ color: "blue" }}
                        />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank
                          className="box-size"
                          onClick={() => {
                            setSelect(1);
                          }}
                          style={{ color: "gray" }}
                        />
                      )}

                      <div>
                        <p
                          style={{ marginBottom: "0px", paddingBottom: "0px" }}
                        >
                          Opt in to all current and future Pages
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            marginTop: "0px",
                            paddingTop: "0px",
                            color: "#616161",
                          }}
                        >
                          This will give Page post access to your current Pages,
                          in addition to any Page you create in the future.
                        </p>
                      </div>
                    </div>

                    <div className="d-flex gap-2">
                      {select == 2 ? (
                        <MdOutlineCheckBox style={{ color: "blue" }} />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank
                          onClick={() => {
                            setSelect(2);
                          }}
                          style={{ color: "gray" }}
                        />
                      )}
                      <div>
                        <p
                          style={{ marginBottom: "0px", paddingBottom: "0px" }}
                        >
                          Opt in to current Pages only
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            marginTop: "0px",
                            paddingTop: "0px",
                            color: "#616161",
                          }}
                        >
                          Tin willy goe Page post sonnes to the Pages you select
                        </p>
                      </div>
                      {/* <SpeechBubbleLeft>
                        Select current Page for OPT
                      </SpeechBubbleLeft> */}
                    </div>
                  </div>

                  {/* Step ----------> 2.1 */}
                  <div>
                    {select == 2 && (
                      <div className="mt-5">
                        <div className="d-flex justify-content-between mb-0 pb-0">
                          <p>
                            {!pageSelect ? (
                              <MdOutlineCheckBoxOutlineBlank
                                onClick={() => {
                                  setPageSelect(true);
                                }}
                                style={{ color: "gray" }}
                              />
                            ) : (
                              <MdOutlineCheckBox
                                onClick={() => {
                                  setPageSelect(false);
                                }}
                                style={{ color: "blue" }}
                              />
                            )}
                            Select All
                          </p>

                          <p style={{ fontSize: "14px" }}>
                            {pageSelect && "1 Page Selected"}
                          </p>
                        </div>

                        <hr />
                        <div className="d-flex gap-2 mb-5">
                          <NavLink
                            style={{ textDecoration: "none", color: "black" }}
                            onClick={() => {
                              setPageSelect(true);
                            }}
                            className="d-flex gap-2 mb-5"
                          >
                            <img
                              style={{
                                borderRadius: "50%",
                                height: "40px",
                                width: "40px",
                                marginTop: "0px",
                                paddingTop: "0px",
                              }}
                              className="py-0 my-0"
                              src="https://backend.whalesai.com/public/ffmpeg/whalesailogo.png"
                            />

                            <div>
                              <p
                                style={{
                                  marginBottom: "0px",
                                  paddingBottom: "0px",
                                }}
                              >
                                Whalse AI
                              </p>
                              <p
                                style={{
                                  fontSize: "12px",
                                  marginTop: "0px",
                                  paddingTop: "0px",
                                  color: "#616161",
                                }}
                              >
                                1056347589
                              </p>
                            </div>
                          </NavLink>

                          <div style={{ height: "100px" }}>
                            {/* <SpeechBubbleLeft>
                              Select the page where you want to post
                            </SpeechBubbleLeft> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* step --------------> 2.2  */}
                  <div>
                    {select == 1 && (
                      <div className="mt-5">
                        <hr />
                        <p>No Page Found</p>
                      </div>
                    )}
                  </div>
                </dev>
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-secondary btn-sm d-flex justify-content-end"
                      onClick={() => {
                        setIsPermission(false);
                        setIsIntial(true);
                      }}
                    >
                      Back
                    </button>
                    <button
                      onClick={() => {
                        setIsGoTo(true);
                        setIsPermission(false);
                      }}
                      className={`btn btn-primary btn-sm d-flex justify-content-end`}
                      disabled={!pageSelect}
                    >
                      Connect
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* step------ > 3  */}
            {isGoTo && (
              <div>
                <h5 className="my-5">Review Page post's access request</h5>

                {permission?.map((item, i) => (
                  <div>
                    <div className="d-flex gap-2">
                      <div style={{ width: "40px" }}></div>
                      <div>
                        <p
                          style={{ marginBottom: "0px", paddingBottom: "0px" }}
                        >
                          {item}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            marginTop: "0px",
                            paddingTop: "0px",
                            color: "#616161",
                          }}
                        >
                          1 Page selected
                        </p>
                        <hr style={{ width: "400px" }} />
                      </div>
                    </div>
                  </div>
                ))}

                <div className="d-flex justify-content-between">
                  <div>
                    {/* <SpeechBubbleRight>
                      Tap save to confirm the permission
                    </SpeechBubbleRight> */}
                  </div>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-secondary btn-sm d-flex justify-content-end"
                      onClick={() => {
                        setIsPermission(true);
                        setIsGoTo(false);
                      }}
                    >
                      Back
                    </button>
                    <button
                      onClick={() => {
                        setIsLastStep(true);
                        setIsGoTo(false);
                      }}
                      className="btn btn-primary btn-sm d-flex justify-content-end"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* step -----> 4  */}
            {isLastStep && (
              <div>
                <div style={{ marginBottom: "200px", marginTop: "50px" }}>
                  <hr />
                  <h4 style={{ fontWeight: "bolder" }}>
                    WhalesAI has been connected to Page Post
                  </h4>
                  <p style={{ fontSize: "14px" }}>
                    To manage this connection,{" "}
                    <span style={{ color: "blue" }}>
                      go to Business Integrations
                    </span>
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <div></div>
                  <div className="d-flex gap-2">
                    <button
                      onClick={() => {
                        closePopup();
                        setIsLoginDone(true);
                      }}
                      className="btn btn-primary btn-sm d-flex justify-content-end"
                    >
                      Got It
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {isLoginDone && (
        <div className="mt-5">
          <StepButton
            pre={"/how-it-works-subscribe-and-pay"}
            nex="/how-it-works-contents-posted-in-social-media-platforms"
          />
        </div>
      )}
    </div>
  );
};

export default Guideline;
