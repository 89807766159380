import { RouterProvider } from "react-router-dom";
import Routes from "./Routes/Routes";
import { Toaster } from "react-hot-toast";
import WalletLogin from "./Components/WalletLogin";

function App() {
  
  return (
    <div className="app">
      <WalletLogin />
      <Toaster />
      <RouterProvider router={Routes} />
    </div>
  );
}
export default App;
