import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import { useState, useContext } from "react";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ViewPayment({ setModalOpen, modalOpen, onePayment }) {
  const handleClose = () => setModalOpen(false);
  console.log('specific payment::::', onePayment);
  const { allPayments, getAllPayments } = useContext(AdminAuthContext);

  const handleChangeStatus = async () => {
    handleClose();
    Swal.fire({
      text: "Are you sure you want to change the status of this payment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "modal_class_success",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`https://backend.whalesai.com/api/v1/earnings-payment/${onePayment?._id}`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              status: true, // Toggle the status
            }),
          });
          getAllPayments();
          if (!response.ok) {
            throw new Error('Failed to update status');
           
          }
      
          const data = await response.json();
          getAllPayments();
          
          // Show success alert after status is changed
          Swal.fire({
            text: `Status changed to: ${data.status ? 'PAID' : 'UNPAID'}`,
            icon: 'success',
            customClass: 'modal_class_success',
            allowOutsideClick: false,
          });
          
          // Optionally update the UI state here
          // Example: refreshData() or setPayments(data);
        } catch (error) {
          getAllPayments();
          Swal.fire({
            text: `Error: ${error.message}`,
            icon: "error",
            customClass: "modal_class_success",
            allowOutsideClick: false,
          });
        }
      }
    });
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalOpen}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Payment Details</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            {/* <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1, textAlign: 'center' }}>
             {onePayment?.paymentId}
            </Typography> */}

            <Typography variant="body2" sx={{ color: "#555", mb: 2, textAlign: 'left' }}>
              <strong>Email:</strong> {onePayment?.email}
            </Typography>

            <Typography variant="body2" sx={{ color: "#555", mb: 2, textAlign: 'left' }}>
              <strong>PayPal ID: </strong> 
              {
                onePayment?.paymentId
              }
              {/* {`${onePayment?.paymentId.slice(
                          0,
                          4
                        )}******${onePayment?.paymentId.slice(-4)}`} */}
            </Typography>

            <Typography variant="body2" sx={{ color: "#555", mb: 2, textAlign: 'left' }}>
              <strong>Total Amount:</strong> USD {onePayment?.amount}
            </Typography>

            <Typography variant="body2" sx={{ color: "#555", mb: 2, textAlign: 'left' }}>
              <strong>Date & Time <span style={{fontSize: '10px'}}>(Requested)</span> :</strong> {onePayment?.createdAt}
            </Typography>

            {
                onePayment?.status == true && <Typography variant="body2" sx={{ color: "#555", mb: 2, textAlign: 'left' }}>
                <strong>Date & Time <span style={{fontSize: '10px'}}>(Paid)</span> :</strong> {onePayment?.updatedAt}
              </Typography>
            }

            <Typography variant="body2" sx={{ color: "#555", mb: 2, textAlign: 'left' }}>
              <strong>Status:</strong> {onePayment?.status == false ? "UNPAID" : "PAID"}
            </Typography>

            {
                onePayment?.status == false && <Box display="flex" justifyContent="flex-start">
                <Button variant="contained" color="primary" style={{backgroundColor: '#157347'}} onClick={handleChangeStatus}>
                  Paid
                </Button>
              </Box>
            }

            
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
