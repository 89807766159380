import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { BsCardImage, BsFileImage, BsFillImageFill } from "react-icons/bs";
import { useState } from "react";
import { createTheme } from "@mui/material";
export default function SelectTable({
  selectedCheckboxIndex,
  setSelectedCheckboxIndex,
}) {
  const handleCheckboxChange = (index) => {
    if (selectedCheckboxIndex === index) {
      // uncheck the selected checkbox if it's clicked again
      setSelectedCheckboxIndex(null);
    } else {
      // check the clicked checkbox and uncheck the previously selected one
      setSelectedCheckboxIndex(index);
      localStorage.setItem("format", index);
    }
  };

  const theme = createTheme({
    components: {
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: "white", // Replace with the desired color
          },
          checked: {
            color: "white", // Replace with the desired color
          },
        },
      },
    },
  });

  return (
    <TableContainer>
      <Table
        // sx={{ maxWidth: 350 }}
        aria-label="simple table"
        className="format-table-videoai"
      >
        <TableHead>
          <TableRow className="text-light">
            {/* <TableCell className="text-light" style={{ fontSize: "16px" }}>
              Select
            </TableCell>
            <TableCell className="text-light" style={{ fontSize: "16px" }}>
              Size
            </TableCell>
            <TableCell className="text-light" style={{ fontSize: "16px" }}>
              Sample
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Checkbox
                // chechked
                theme={theme}
                // checked={selectedCheckboxIndex === 0}
                checked={true}
                onChange={() => handleCheckboxChange(0)}
                color="primary"
                // checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": 1,
                }}
              />
            </TableCell>
            <TableCell className="text-light" style={{ fontSize: "15px" }}>
              {" "}
              1:1 (Square)
              <br />
              <span className="text-warning" style={{ fontSize: 11.5 }}>
                Instagram, Twitter,
                <br className="videoai-break-line" />
                Pinterest
              </span>
            </TableCell>
            <TableCell>
              <img
                src="/image/videoImg1/1-1-1.png"
                alt="image"
                style={{ width: 26, height: 26, borderRadius: 2 }}
              />
              {/* <BsFillImageFill
                className="text-end d-flex align-items center"
                size={25}
                style={{ width: 25, height: 25 }}
              /> */}
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Checkbox
                theme={theme}
                // checked={selectedCheckboxIndex === 1}
                checked={true}
                onChange={() => handleCheckboxChange(1)}
                color="primary"
                // checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": 2,
                }}
              />
            </TableCell>
            <TableCell className="text-light" style={{ fontSize: "15px" }}>
              16:9 (Landscape)
              <br />
              <span className="text-warning" style={{ fontSize: 11.5 }}>
                YouTube, Facebook, <br className="videoai-break-line" />{" "}
                LinkedIn
              </span>
            </TableCell>
            <TableCell>
              {" "}
              <img
                src="/image/videoImg16/16-9-1.png"
                alt="image"
                style={{ width: 28, height: 19, borderRadius: 2 }}
              />
              {/* <BsCardImage
                className="text-end"
                size={25}
                height={180}
                // style={{ width: 30, height: 20 }}
              /> */}
            </TableCell>
          </TableRow>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              <Checkbox
                theme={theme}
                // checked={selectedCheckboxIndex === 2}
                checked={true}
                onChange={() => handleCheckboxChange(2)}
                color="primary"
                // checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": 3,
                }}
              />
            </TableCell>
            <TableCell className="text-light" style={{ fontSize: "15px" }}>
              {" "}
              9:16 (Portrait)
              <br />
              <span className="text-warning" style={{ fontSize: 11.5 }}>
                TikTok, Reels,
                <br className="videoai-break-line" />
                 Shorts
              </span>
            </TableCell>
            <TableCell>
              <img
                src="/image/videoImg9/9-16-1.png"
                alt="image"
                style={{ width: 20, height: 30, borderRadius: 2 }}
              />
              {/* <BsFileImage
                className="text-end"
                size={25}
                //   style={{ marginBottom: "-5px" }}
              /> */}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
