import React, { useContext, useImperativeHandle, useRef } from 'react';
import { useState, useEffect } from 'react';
import arrowIcon from './arrow-icon.png';
import './TextAi.css';
import axios from 'axios';
import { notification } from 'antd';
import { FaRegCopy } from 'react-icons/fa';
import wordCount from 'word-count';
import copy from 'copy-to-clipboard';
import { PropagateLoader } from 'react-spinners';
import ADS from './ADS';
import { useLocation } from 'react-router-dom';


import swal from 'sweetalert';

const apiKey = "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";
// const apiKey = "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";
export const handleAiCompletion = async (prompt, preset) => {
  console.log("prompt", prompt, "preset", preset);
  try {
    const response = await axios.post(
      "https://api.openai.com/v1/completions",
      {
        // model: preset === "EC" ? "code-davinci-002" : "gpt-3.5-turbo-instruct",
        model: preset === "EC" ? "gpt-3.5-turbo-instruct" : "gpt-3.5-turbo-instruct",
        prompt: `Write a creative ad for the following product to run on Social Media no hashtags:\n\n${prompt}`,
        temperature: 0,
        max_tokens: 200,
        // 2048
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      }
    );
    console.log(response,"response2");

    let answer;
    if (
      preset === "ASK" ||
      preset === "NLTP" ||
      preset === "MTE" ||
      preset === "JTP" ||
      preset === "MHSC" ||
      preset === "videoai"
    ) {
      answer = response.data.choices[0].text;
    } else if (preset == "CHAT") {
      answer = response.data.choices[0].text.trim();
    } else if (preset == "EC") {
      answer = response.data.choices[0].text
        .split("*/")[0]
        .split("\n")
        .slice(0, 7)
        .join("\n");
      // answer = response.data.choices[0].text.split('\n\n', 2)[1];
    } else {
      console.log(response.data.choices[0],"response.data.choices[0]");
      answer = response.data.choices[0].text.split("\n\n", 2)[1];
    }

    return answer;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const ProductDescription = ({texts, setTexts,inputText, setInputText,isLoading2, setIsLoading2,refs}) => {

  const [preset, setPreset] = useState('ADS');
  const [presetSelected, setPresetSelected] = useState({
    option: 'ADS',
    label: '',
  });
  const [generatingtext, setGeneratingText] = useState('');
  const [text, setText] = useState('');
  const [messages, setMessages] = useState([]);

  const [usingChat, setUsingChat] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const inputRef2 = useRef(null);
  const wordRef = useRef(null);
  const inputRef = useRef(null);
  const divRef = useRef(null);
  const [count, setCount] = useState(0);
  const [words, setWords] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type) => {
    api[type]({
      message: 'Insufficient Credits',
      description:
        'Your remaining credits are getting low. Please buy more credits to avoid interruptions.',
    });
  };

  function countWords(text) {
    // split the text into an array of words
    if (text == '') {
      const wordCount = 0;
      return wordCount;
    }
    const words = text?.split(
      /[\s\u0BB7\u0BCD\u0BB0\u0BBE\u0BA4\u0BCD\u0BA4\u0BC8\u0BB5\u0BBF\u0BB5\u0BC1\u0BAE\u0BCD\u0BAE\u0BA3\u0BCD\u0B9F\u0BCD\u0B9F\u0BA9\u0BCD\u0B9A\u0BCD\u0B9A\u0BB0\u0BCD]/
    );
    // count the length of the words array
    const wordCount = words?.length;
    return wordCount;
  }

  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      // console.log("User is scrolling down");
      setIsScrolling(false);
    } else if (event.deltaY < 0) {
      // console.log("User is scrolling up");
      setIsScrolling(true);
    }
  };

  useEffect(() => {
    if (text == generatingtext) {
      setIsLoading2(false)
  }
  }, [text])
  

  useEffect(() => {
    if (inputRef2.current) {
      inputRef2.current.scrollTo(0,0);
    }

    window.scrollTo(0, 0);
    // if the text is generating then run the below code
    if (isGenerating === true) {
      const handleScroll = () => {
        const scrollTop = inputRef?.current?.scrollTop;
        setIsScrollingUp(scrollTop < inputRef?.current?._prevScrollTop);
        inputRef.current._prevScrollTop = scrollTop;
      };

      inputRef.current._prevScrollTop = 0;
      inputRef.current.addEventListener('scroll', handleScroll);
      const ref = inputRef.current;

      return () => {
        if (ref) {
          ref.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [generatingtext, isGenerating]);

  useEffect(() => {
    if (isScrolling === false && isScrollingUp === false) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
  }, [text, isScrolling, isScrollingUp]);

  useEffect(() => {
    const worker = new Worker('/worker.js');

    worker.addEventListener('message', (event) => {
      const { text, isDone } = event.data;

      // setIsDoneGenerating(isDone);
      if (text !== 'Processing...') {
        setText(text);
      }
      // if () {
      // }
      if (presetSelected.option === 'CHAT') {
        console.log(
          { author: 'ai', body: text },
          'ai generated',
          generatingtext
        );
        setMessages([...messages, { author: 'ai', body: text }]);
        setInputText('');
      }
      inputRef.current.focus();

      setCount(countWords(text));
    });

    worker.postMessage({ generatingtext, words });
    // setShowCursor(prevShowCursor => !prevShowCursor);

    return () => {
      worker.terminate();
    };
  }, [generatingtext, isGenerating]);

  const handleSubmitCredits = async (e) => {
   

    // console.log("question", question, conversation
  };

  useEffect(() => {
    if (inputRef2.current) {
      inputRef2.current.scrollTo(0,0);
    }
    if (inputRef.current) {
      inputRef.current.scrollTop = 0;
    }
    if (wordRef.current) {
      wordRef.current.scrollTo(0,0);
    }
  }, [])
  

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading2(true)
      divRef.current.scrollIntoView({ behavior: 'smooth' });
      localStorage.setItem('inputText', inputText);
      // document.getElementById("inputText").value = "";
    


      if (true) {
        setIsGenerating(true);
        setGeneratingText(() => '');
        setText('');
        setIsScrolling(false);
        setIsScrollingUp(false);
        setShowAlert(false);

        const randomChars =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567891011121314156655';
        let transactionId = '';
        for (let i = 0; i < 8; i++) {
          transactionId += randomChars.charAt(
            Math.floor(Math.random() * randomChars.length)
          );
        }
        // --------------
        let question;

        if (presetSelected.option === 'ADS') {
          question = `Write a creative ad for the following product to run on Facebook:\n\n${inputText}`;
        }

        const response = await handleAiCompletion(
          question,
          presetSelected.option
        );
        // generateImage('all', '4', response,"")
       setTexts(response)
        if (response) {
          console.log(response);
          handleSubmitCredits();
          setGeneratingText(response);
     
          setIsGenerating(false);
        } else {
          setGeneratingText('Invalid prompt!');
          
        }
      } else {
        setShowAlert(true);
      }
    } catch (error) {
      setIsGenerating(false);
      swal({
        text: 'Something went wrong! Please try again.',
        icon: 'error',
        button: 'Ok',
        className: 'modal_class_success',
      });
    }
  };

  useEffect(() => {
    if (location.state !== null) {
      setPresetSelected({
        option: location.state.textAiType,
      });
      setGeneratingText(location.state.response);
      setInputText(location.state.requestQues);
    }
  }, [location.state]);

  const [formattedText, setFormattedText] = useState('');

  useEffect(() => {
    // split the text into lines
    if (text !== 'Processing...') {
      const lines = text?.split('\n');

      // add each line to the formatted text with a newline
      const formattedLines = lines?.map((line, index) => (
        <div key={index}>
          {line}
          <br />
        </div>
      ));

      setFormattedText(formattedLines);
      if (!(presetSelected.option === 'CHAT')) {
        console.log('in to the chat');
        setText(text);
      }
    }
  }, [text]);


    


  const handleClearData = () => {
    setMessages([]);
    setFormattedText([]);
    setInputText('');
    setGeneratingText('');
    setIsGenerating(false);
    setText('');
  };

  useImperativeHandle(refs, () => ({
    handleClearData
}));

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter' &&
      inputText !== '' &&
      !event.shiftKey &&
      window.innerWidth > 1000
    ) {
      divRef.current.scrollIntoView({ behavior: 'smooth' });
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(event);
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      setInputText(inputText + '\n');
    }

    if (window.innerWidth < 1000 && event.key === 'Enter') {
      event.preventDefault();
      setInputText(inputText + '\n');
    }
  };

  useEffect(() => {
    const countWords = () => {
      if (wordRef.current) {
        if (presetSelected.option !== 'ETOL') {
          const numberOfWords = wordCount(wordRef.current.innerText);

          setWords(numberOfWords);
        } else {
          const numberOfWords = wordCount(wordRef.current.innerText);

          const tamilRegex = /[\u0B80-\u0BFF]+/g;

          const tamilwords = wordRef.current.innerText.match(tamilRegex);

          const wordCounts = tamilwords ? tamilwords.length : 0;
          setWords(numberOfWords + wordCounts);
        }
      }
    };
    countWords();
  }, [wordRef, inputText, text, presetSelected.option]);

  const handleCopy = () => {
    const title = document.querySelector('.preset-title').innerText;
    const contents = document
      .querySelector('.copy-contents')
      .innerText.replace(title, '');

    const isCopied = copy(contents);
    if (isCopied) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 2000);
    }
  };

  return (
    <>
      <div className="companion-ai-main">
        <div className="first-field-container" ref={divRef}>
          {/* <div
            style={{
              backgroundColor: '#3080AC',
              paddingInline: '16px',
              paddingBlock: '17px',
              borderRadius: '4px 4px 0px 0px',
            }}
            className="d-flex justify-content-between align-items-center text-white"
          >
            <h5 style={{ fontWeight: '400' }} className="mb-0">
              WORD COUNT : {words}
            </h5> 
            <div className="d-flex gap-3">
              {showTooltip ? (
                <p className="mb-0" style={{ fontSize: '13px' }}>
                  Copied!
                </p>
              ) : (
                <FaRegCopy
                  onClick={handleCopy}
                  className=""
                  // size={20}
                  style={{ cursor: 'pointer' }}
                />
              )}

              <i
                class="fa-regular fa-circle-xmark"
                style={{ cursor: 'pointer' }}
                onClick={handleClearData}
              ></i>
            </div>
          </div> */}

          <div
            id="responseDiv"
            className="fist-text-area"
            onWheel={handleScroll}
            ref={inputRef}
            style={{ overflowY: 'auto', maxHeight: '100vh' }}
          >
            {showAlert == false && (
              <>
                {preset ? (
                  <div class="media-body ml-3">
                    <div
                      className="text-light rounded py-2 mb-2 text-start ask-ai-fonts copy-contents"
                      ref={wordRef}
                    >
                      {
                        <ADS
                          inputText={inputText}
                          isGenerating={isGenerating}
                          text={text}
                          presetSelected={presetSelected}
                          wordRef={wordRef}
                        />
                      }
                    </div>
                  </div>
                ) : (
                  <div className="text-center mx-auto">
                    <PropagateLoader
                      color="white"
                      loading
                      size={window.innerWidth <= 700 ? 10 : 15}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="second-field-container position-relative">
          <form className="text-ai-rep-form flex-row" onSubmit={handleSubmit}>
            <textarea
              style={{
                padding: '10px',
                height:"70px"
              }}
              className="second-text-area text-dark"
              id="inputText"
              name="inputBox"
              
              cols="30"
              rows="3"
              value={inputText}
              onKeyDown={handleKeyDown}
              ref={inputRef2}
              onChange={(e) => {
                setInputText(e.target.value);
                setText('');
                setIsLoading2(true)
              }}
            ></textarea>
            <button
              className="arrow-button"
              onClick={handleSubmit}
              disabled={inputText.length === 0}
            >
              <img src={arrowIcon} alt="image" />
            </button>
          </form>
        </div>
        {/* <button className="credits-button my-4">
            EVERY REQUEST IS 10 CREDITS
          </button> */}
      </div>
      {contextHolder}
    </>
  );
};

export default ProductDescription;
