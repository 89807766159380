import React from 'react';
import PageHeading from '../../Components/PageHeading/PageHeading';
import './Subscription.css';

const Subscription = () => {
    return (
        <div>
            <PageHeading title='SUBSCRIPTION'  />
            <div className='layout'>
                {/* <p className='short-description'>SOCIAL MEDIA MARKETING MANAGER AI(SMMM AI)</p> */}

                <div className='subscription-list'>
                    <p>Pricing -  monthly</p>
                    <ol>
                        <li>Social Media platform - USD 30</li>
                        <li>Social Media platforms - USD 48 (20% discount)</li>
                        <li>Social Media platforms - USD 63 (30% discount)</li>
                        <li>Social Media platforms - USD 72 (40% discount)</li>
                    </ol>
                </div>


            </div>
        </div>
    );
};

export default Subscription;