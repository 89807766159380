import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  Typography,
  Box,
  DialogContent,
  Avatar,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import { useState } from "react";
import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function MessageBox({ setModalOpen, modalOpen, oneMessage }) {
  const handleClose = () => setModalOpen(false);

  // Inside your component
  const [isReplying, setIsReplying] = useState(false);
  const [replyText, setReplyText] = useState("");
  const { allMessage, getAllMessage } = useContext(AdminAuthContext);

  const handleReplyClick = () => {
    setIsReplying(true); // Enable reply mode
  };

  // For handling reply text changes
  const handleReplyTextChange = (event) => {
    setReplyText(event.target.value);
    console.log("Reply ===>>>", event.target.value);
  };

  const handleSendReply = async () => {
    try {
      const response = await axios.post(
        "https://backend.whalesai.com/api/v1/contact-us/send-email-reply",
        {
          reply: replyText,
          email: oneMessage?.email,
          subject: oneMessage?.subject,
          id: oneMessage?._id,
          message: oneMessage?.message,
        }
      );

      console.log("Reply sent successfully:", response.data);

      // Show success message or handle response
      Swal.fire({
        icon: "success",
        text: "Reply sent successfully!",
        className: "modal_class_success",
        background: "#011225",
        color: "white",
      });

      // Optionally reset reply state
      setIsReplying(false);
      setModalOpen(false);
      setReplyText("");
      getAllMessage();
    } catch (error) {
      console.error("Error sending reply:", error);
      setModalOpen(false);
      // Show error message
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to send reply. Please try again.",
        className: "modal_class_success",
        background: "#011225",
        color: "white",
      });
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalOpen}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Enquiry</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              // textAlign: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              {oneMessage?.name}
            </Typography>

            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>User Email:</strong> {oneMessage?.email}
            </Typography>
            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>Country:</strong> {oneMessage?.country}
            </Typography>
            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>Time & Date:</strong> {oneMessage?.createdAt?.slice(0, 10)}
              - {" "}
              {oneMessage?.createdAt?.slice(11, 19)}
              {/* {new Date(oneMessage?.createdAt).toLocaleDateString()} -{" "}
              {new Date(oneMessage?.createdAt).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })} */}
            </Typography>

            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>Subject:</strong> {oneMessage?.subject}
            </Typography>

            <textarea
              value={oneMessage?.message || ""}
              readOnly
              rows={4}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "8px",
                borderColor: "#ccc",
                resize: "none",
                marginTop: "1rem",
                marginBottom: "1rem",
                backgroundColor: "#D9D9D9",
              }}
            />
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleReplyClick}
                disabled={isReplying} // Disable once clicked
              >
                Reply
              </Button>
            </Box>

            {isReplying && (
              <div>
                <textarea
                  value={replyText}
                  onChange={handleReplyTextChange}
                  rows={4}
                  placeholder="Type your reply..."
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "8px",
                    borderColor: "#ccc",
                    resize: "none",
                    marginTop: "1rem",
                    backgroundColor: "#fff",
                  }}
                />
              </div>
            )}
            {isReplying && (
              <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendReply}
                >
                  Send
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setIsReplying(false); // Reset reply mode
                    setReplyText(""); // Clear the reply text
                  }}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
