import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function LongText({ modalLong, setModalLong ,value }) {
  const handleClose = () => setModalLong(false);

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalLong}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">
              {
                  value == 1 ? 'AI Generated Content' : 'Sample'
                }
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          {/* Text and Hashtag Section */}
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="body1">
              Robotics is a fascinating field that fuses engineering, computer
              science, and artificial intelligence to create machines capable of
              performing tasks autonomously or semi-autonomously. Innovations in
              robotics have brought about significant changes in various
              industries such as manufacturing, healthcare, agriculture, and
              even entertainment. Robots are designed to execute tasks with
              precision and consistency, often surpassing human performance in
              activities that require endurance or involve hazardous
              environments. <br /> <br />
              In manufacturing, industrial robots have revolutionized the
              assembly line by enhancing efficiency and reducing the likelihood
              of errors and accidents. These robots can operate 24/7,
              meticulously handling repetitive tasks that would otherwise be
              monotonous or dangerous for human workers. In healthcare, surgical
              robots facilitate minimally invasive procedures, offering greater
              precision and control, which can lead to quicker patient recovery
              times. <br /> <br />
              Agriculture has also benefited from robotic advancements with the
              introduction of drones and automated machinery. These
              technological marvels help in monitoring crop health, optimizing
              irrigation systems, and even harvesting produce. This not only
              increases yield and reduces waste but also allows for more
              sustainable farming practices. <br /> <br />
              In our daily lives, service robots are becoming more prevalent,
              from robotic vacuums that keep our homes clean to social robots
              designed to assist the elderly or provide customer service. Such
              developments are not only convenient but also highlight the
              potential of robotics to improve the quality of life. <br />{" "}
              <br />
              The ongoing evolution of robotics is driven by advancements in
              sensor technology, machine learning, and artificial intelligence,
              making robots smarter and more adaptable. As we continue to push
              the boundaries of what robots can do, the symbiotic relationship
              between humans and machines promises to usher in a new era of
              innovation and productivity. #Robotics #AI #Innovation #Technology
              #FutureTech
            </Typography>
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
