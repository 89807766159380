import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./Analysis.css";
import swal from "sweetalert";
import axios from "axios";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { NavLink, useNavigate } from "react-router-dom";
import Company from "../../Components/Company/Company";
import Swal from "sweetalert2";
import DemoResponse from "../../Components/Company/DemoResponse";
import { FaEdit, FaRegCopy } from "react-icons/fa";
import { MdAutoDelete } from "react-icons/md";

const questions = [
  // {
  //   question:
  //     "What is your primary goal for this month's content marketing campaign?",
  //   options: [
  //     "Increase brand awareness",
  //     " Drive website traffic",
  //     "Generate leads",
  //     "Boost social media engagement",
  //     "Improve customer retention",
  //   ],
  // },
  // {
  //   question: "Who is your target audience for this month's campaign?",
  //   options: [
  //     "Millennials",
  //     "Gen Z",
  //     "Baby Boomers",
  //     "B2B professionals",
  //     "General consumers",
  //   ],
  // },
  // {
  //   question: "What is the tone of voice you want your content to have?",
  //   options: [
  //     "Professional",
  //     "Friendly",
  //     "Humorous",
  //     "Inspirational",
  //     "Informative",
  //   ],
  // },
  // {
  //   question:
  //     "What is the main call to action you want to include in your content?",
  //   options: [
  //     "Visit our website",
  //     "Sign up for our newsletter",
  //     " Follow us on social media",
  //     "Purchase now",
  //     "Contact us for more information",
  //   ],
  // },
  // {
  //   question: "What type of user engagement are you aiming for?",
  //   options: [
  //     "Likes and shares",
  //     "Comments and discussions",
  //     "Click-throughs",
  //     "Sign-ups",
  //     "Direct messages",
  //   ],
  // },
  {
    question:
      "What are your main goals for using social media for your business?",
    options: [
      "Increase brand awareness and visibility.",
      "Generate leads and drive sales.",
      "Engage with current customers and improve relationships.",
      "Provide customer support and answer inquiries.",
      "Establish thought leadership and industry expertise.",
    ],
  },
  {
    question:
      "Which social media platform has been the most beneficial for your business so far?",
    options: ["Facebook", "Instagram", "LinkedIn", "Twitter (X)", "TikTok"],
  },
  {
    question:
      "How often do you post content on your primary social media platform?",
    options: [
      "Several times a day.",
      "Once a day.",
      "A few times a week.",
      "Once a week.",
      "Rarely or when needed.",
    ],
  },
  {
    question: "What type of content tends to perform best for your audience?",
    options: [
      "Product promotions and offers.",
      "Educational content and tips.",
      "Behind-the-scenes and company culture posts.",
      "Customer testimonials and reviews.",
      "Engaging questions and polls.",
    ],
  },
  {
    question:
      "What is your biggest challenge when it comes to social media marketing?",
    options: [
      "Consistent content creation.",
      "Understanding analytics and performance metrics.",
      "Engaging the right audience.",
      "Keeping up with platform trends and algorithm changes.",
      "Converting followers into paying customers.",
    ],
  },
  {
    question: "",
    options: [
      "Likes and shares",
      "Comments and discussions",
      "Click-throughs",
      "Sign-ups",
      "Direct messages",
    ],
  },
];

const Analysis = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [startAnalysis, setStartAnalysis] = useState(false);
  const [isAnalysisComplete, setIsAnalysisComplete] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [result, setResult] = useState([]);
  const [keyWords, setKeyWords] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [infoLength, setInfoLength] = useState(0);
  const [error, setError] = useState("");
  const [info, setInfo] = useState({});
  const [inputResult, setInputResult] = useState(false);
  const [intialResult, setIntialResult] = useState(true);
  const [inputprocessing, setInputProcessing] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  const [newKeyword, setNewKeyword] = useState("");
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  const { AIuser, AiLogOut } = useContext(UserAuthContext);
  console.log("User =>", AIuser?._id);
  const navigate = useNavigate();
  console.log("User Ans => ", userAnswers, typeof userAnswers);

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3000); // 2 seconds delay

    return () => clearTimeout(timer); // Clean up the timer
  }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // 5 seconds delay

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, []);
  // console.log("form value", infoLength);
  useState(() => {
    console.log("inside the function => ", infoLength);
    if (infoLength === 4) setSelectedOption("OK");
    else setSelectedOption(null);
  }, [infoLength]);

  const countNonEmptyFields = () => {
    return Object.values(info).filter((value) => value !== "").length;
  };

  const handleInfoLength = (newInfo) => {
    setInfo(newInfo);
    // setInfoLength(Object.keys(newInfo).length);
  };
  useEffect(() => {
    const data = Object.values(info)?.filter((value) => value !== "").length;
    setInfoLength(data);
  }, [info]);

  // console.log("info => ", info, infoLength);

  const fetchData = async () => {
    setIsFetching(true);
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://backend.whalesai.com/api/v1/social/analyses/${AIuser?._id}`
      );
      const data = await response.json();
      console.log("response => ", data);
      setKeyWords(data?.keywords);
      setResult(data.analysis);
      setIsLoading(false);
      setIsFetching(false);
      // setStartAnalysis(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // console.log("result =>", keyWord);
  useEffect(() => {
    fetchData();
  }, [AIuser?._id]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (result?.length > 0) {
      setIsAnalysisComplete(true);
    }
    // setIsAnalysisComplete(true);
  }, [result]);

  const handleOptionSelect = (option) => {
    if (infoLength === 4) setSelectedOption("ok");
    setSelectedOption(option);
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestion] = option;
    setUserAnswers(newUserAnswers);
  };

  const submitAnswers = async () => {
    {
      // alert(userAnswers);
      const token = localStorage.getItem("aiUser");
      if (!token) {
        alert("User token not found");
        return;
      }

      try {
        const response = await axios.post(
          "https://backend.whalesai.com/api/v1/social/social-analysis",
          {
            userAnswers,
            companyName: info?.companyName,
            companyDescription: info?.shortDescription,
            website: info?.website,
            country: info?.country,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.statusText);
        if (response.statusText == "OK") {
          setIsAnalysisComplete(true);
          setIntialResult(false);
          setIsLoading(false);
          setUserAnswers([]);
          fetchData();
          window.scroll(0, 0);
        }
      } catch (error) {
        console.error("Error submitting answers:", error);
      }
    }
  };

  console.log("option => ", selectedOption, userAnswers?.length);

  const handleNext = () => {
    if (currentQuestion < questions?.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(null);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedOption(null);
      setUserAnswers((prevAnswers) => prevAnswers.slice(0, -1));
    }
  };

  const handleCancel = () => {
    navigate("/let-us-do-it-for-you");
  };

  const handleAnalysis = (e) => {
    e.preventDefault();
    setStartAnalysis(true);
    // setIsLoading(false);
  };
  // const handleAddKeyword = () => {
  //   // Logic to add the keyword can be implemented here
  //   console.log('New Keyword Added:', newKeyword);
  //   Swal.close();
  // };

  // const showPopup = () => {
  //   Swal.fire({
  //     title: 'Add New Keyword',
  //     html: `
  //       <input id="keywordInput" type="text" placeholder="Enter new keyword" class="swal2-input" />
  //     `,
  //     showCancelButton: true,
  //     background: '#011225',
  //     confirmButtonText: 'Add',
  //     cancelButtonText: 'Cancel',
  //     preConfirm: () => {
  //       const keyword = Swal.getPopup().querySelector('#keywordInput').value;
  //       if (!keyword) {
  //         Swal.showValidationMessage(`Please enter a keyword`);
  //       }
  //       return { keyword };
  //     }
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       setNewKeyword(result.value.keyword);
  //       handleAddKeyword();
  //     }
  //   });
  // };

  const showPopup = () => {
    if (keyWords?.length > 29) {
      swal({
        text: "You have already added 10 prompts.",
        className: "modal_class_success",
      });
    } else {
      Swal.fire({
        title: "Add New prompt",
        html: `
        <input id="keywordInput" type="text" placeholder="Enter new prompt" class="swal2-input" style="color: white;" />
       <p style="color: white; font-size: 14px; margin-top: 5px;">
         You can add up to 10 prompts. You have added ${keyWords?.length - 20 <= 1 ? `${keyWords.length - 20} prompt` : `${keyWords.length - 20} prompts`}.
       </p>
        `,
        showCancelButton: true,
        background: "#011225",
        confirmButtonText: "Add",
        cancelButtonText: "Cancel",
        customClass: {
          validationMessage: "custom-validation-message", // Add this line
        },
        preConfirm: () => {
          const keyword = Swal.getPopup().querySelector("#keywordInput").value;
          if (!keyword) {
            Swal.showValidationMessage(
              `<span style="color: #FF0000;">Please enter a prompt</span>`
            );
          }
          return { keyword };
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const newKeyword = result.value.keyword;
          handleAddKeyworddata(newKeyword);
        }
      });
    }
  };

  const editPopUp = (key, index) => {
    const item = key.replace(/^\d+\.\s*/, "");
    Swal.fire({
      title: `Edit Prompt`,
      html: `
        <input id="keywordInput" type="text" placeholder="Enter new prompt" class="swal2-input w-full" value="${item}" style="color: white;" />
      `,
      // <p style="color: white; font-size: 14px; margin-top: 5px;">
      //     You can update the prompt below.
      //   </p>
      showCancelButton: true,
      background: "#011225",
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      customClass: {
        validationMessage: "custom-validation-message", // Optional, if needed
      },
      preConfirm: () => {
        const keyword = Swal.getPopup().querySelector("#keywordInput").value;
        if (!keyword) {
          Swal.showValidationMessage(
            `<span style="color: #FF0000;">Please enter a prompt</span>`
          );
        }
        return { keyword };
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedKeyword = result.value.keyword;
        handleUpdateKeyworddata(updatedKeyword, index); // Replace with your actual update handler
      }
    });
  };

  const deletePopUp = (keyword, index) => {
    Swal.fire({
      title: "Warning!",
      text: "This action is irreversible.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      background: "#011225",
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteKeyword(keyword, index);
      }
    });
  };

  const handleAddKeyworddata = async (newKeyword) => {
    try {
      const userId = "yourUserIdHere"; // Replace with actual user ID
      const response = await axios.patch(
        `https://backend.whalesai.com/api/v1/social/analyses/update/${AIuser?._id}`,
        {
          newKeywords: [newKeyword],
        }
      );

      // Update the keyword list in the state
      setKeyWords(response.data.keywords);

      Swal.fire({
        icon: "success",
        title: "Prompt Added!",
        background: "#011225",
        color: "white",
        text: `New prompt "${newKeyword}" added successfully.`,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        background: "#011225",
        text: "There was an error adding the prompt. Please try again.",
      });
    }
  };

  const handleUpdateKeyworddata = async (updateKeyword, value) => {
    try {
      const response = await axios.patch(
        `https://backend.whalesai.com/api/v1/social/analyses/edit/prompt/${AIuser?._id}`,
        {
          index: value,
          newKeyword: updateKeyword,
        }
      );
      setKeyWords(response.data.keywords);

      Swal.fire({
        icon: "success",
        title: "Prompt Edited!",
        background: "#011225",
        color: "white",
        text: `Prompt "${updateKeyword}" edited successfully.`,
      });
      console.log("Deleted keyword:", updateKeyword);
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        background: "#011225",
        text: "There was an error editing the prompt. Please try again.",
      });
    }
  };

  const handleDeleteKeyword = async (keyword, value) => {
    const item = keyword.replace(/^\d+\.\s*/, "");
    try {
      const response = await axios.patch(
        `https://backend.whalesai.com/api/v1/social/analyses/delete/prompt/${AIuser?._id}`,
        {
          index: value,
        }
      );

      // Update the keyword list in the state
      setKeyWords(response.data.keywords);

      Swal.fire({
        icon: "success",
        title: "Prompt Deleted!",
        background: "#011225",
        color: "white",
        text: `This prompt "${item}" deleted successfully.`,
      });
      console.log("Deleted keyword:", keyword);
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error",
        background: "#011225",
        text: "There was an error deleting the prompt. Please try again.",
      });
    }
  };

  // if (isLoading) {
  //   return <div className="text-center text-white my-5">Loading..........</div>;
  // }
  if (isFetching || isLoading) {
    return (
      <div className="text-center text-white my-5">Processing..........</div>
    );
  }
  // if(error) {

  // }
  console.log("resulttttttttttttttttttttttt =========> :", keyWords);
  console.log("resulttttttttttttttttttttttt =========> :", result);
  return (
    <div className="">
      <PageHeading title="ANALYSIS" />

      <div className="container">
        {/* {isAnalysisComplete && intialResult ? ( */}
        {isAnalysisComplete ? (
          <div className="text-white mt-3">
            {result ? (
              <div>
                <p
                  className="text-justify"
                  // dangerouslySetInnerHTML={{
                  //   __html: result
                  //     .replace(/^(.+?)(\r\n|\r|\n|$)/, "<strong style='color: aquamarine;'><u>$1</u></strong>$2")
                  //     .replace(/^Analysis:/, "<strong>Analysis:</strong>")
                  //     .replace(/###(.*?)(\r\n|\r|\n|$)/g, "<br/><br/><strong style='color: skyblue;'><u>$1</u></strong>$2")
                  //     .replace(/##\s*(.*?)(\r\n|\r|\n|$)/g, "<br/><br/><strong style='color: blueviolet;'>$1</strong>$2")
                  //     .replace(/^### Social Media Analysis/, "<strong>Social Media Analysis</strong>")
                  //     .replace(/\*\*(.*?)\*\*/g, "<strong><br/><br/>$1</strong>")
                  //     .replace(/\b\d+\.\s/g, "")
                  //     .replace(/#/g, "")
                  //     .replace(/-\s+/g, '')
                  //     .replace(/-\s*(\r\n|\r|\n|$)/g, '')
                  //     .replace(/-\s*$/, '')
                  //     .replace(/---/g, '')
                  //     .replace(/--/g, '')
                  //   }}
                  dangerouslySetInnerHTML={{
                    __html: result
                      .replace(/\[(.*?)\]\((.*?)\)/g, "$2")
                      .replace(
                        /#### (.*?)(?=\n|$)/g,
                        "<strong style='color: yellowgreen;'>$1</strong>"
                      ) // Smaller subheadings
                      .replace(
                        /### (.*?)(?=\n|$)/g,
                        "<strong style='color: aquamarine; display: block; margin-bottom: -20px;'>$1</strong>"
                      ) // Small subheadings
                      .replace(
                        /## (.*?)(?=\n|$)/g,
                        "<strong style='color: skyblue; font-size: 20px;'><u>$1</u></strong>"
                      ) // Subheadings
                      .replace(
                        /# (.*?)(?=\n|$)/g,
                        "<strong style='color: blueviolet; font-size: 20px'><u>$1</u></strong>"
                      ) // Headings
                      .replace(
                        /\*\*(.*?)(?=\*\*|\n|$)\*\*/g,
                        "<strong style='color: rgb(242, 230, 215);'>$1</strong>"
                      ) // Bold text without adding <br/> at the end
                      .replace(/\n/g, "<br/>"), // Replace remaining new lines with <br/>
                    // .replace(/^(.+?)(\r\n|\r|\n|$)/, "<strong style='color: orange;'><u>$1</u></strong>$2")
                    // .replace(/^Analysis:/, "<strong>Analysis:</strong>")
                    // .replace(/###(.*?)(\r\n|\r|\n|$)/g, "<br/><br/><strong style='color: skyblue;'><u>$1</u></strong>")
                    // .replace(/##\s*(.*?)(\r\n|\r|\n|$)/g, "<br/><br/><strong style='color: yellow;'>$1</strong>$2")
                    // .replace(/^### Social Media Analysis/, "<strong>Social Media Analysis</strong>")
                    // .replace(/\*\*(.*?)\*\*/g, "<br/><strong style='color: cornflowerblue;'><br/>$1</strong>")
                    // .replace(/\b\d+\.\s/g, "")
                    // .replace(/#/g, "")   // Removes any # characters
                    // .replace(/\*/g, "")  // Removes any * characters
                    // .replace(/"/g, "")
                    // .replace(/-\s*(\r\n|\r|\n|$)/g, '')
                    // .replace(/-\s*$/, '')
                    // .replace(/---/g, '')
                    // .replace(/--/g, '')
                    // .replace(/-\s+/g, '')
                  }}
                ></p>

                <div className="">
                  <p
                    className="fw-bold text-decoration-underline"
                    style={{
                      color: "#87CEEB",
                      fontSize: "20px",
                      textDecoration: "",
                    }}
                  >
                    Suggested Text Contents for 30 days
                  </p>
                  {/* {keyWords &&
                    keyWords.map((keyword, index) => (
                      <div key={index}>
                        {index > -1 ? (
                          <div className="d-flex align-items-center gap-2">
                            <p className="p-0 my-2">{keyword}</p>
                            
                            <FaRegCopy
                              style={{
                                color: "green",
                                cursor: "pointer",
                                fontSize: "20px",
                                width: "24px", 
                                height: "24px", 
                              }}
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(keyword?.slice(4, 10000))
                                  .then(() => {
                                    Swal.fire({
                                      text: "Text Contents copied to your clipboard",
                                      background: "#011225",
                                      icon: "success",
                                      color: "white",
                                    });
                                    
                                  })
                                  .catch((err) => {
                                    Swal.fire({
                                      text: "Could not copy Text Contents to your clipboard! Please try again",
                                      background: "#011225",
                                      icon: "warning",
                                      color: "white",
                                    });
                                    
                                  });
                              }}
                            />
                            
                          </div>
                        ) : (
                          <p>{keyword}</p>
                        )}
                      </div>
                    ))} */}
                  {keyWords &&
  keyWords.map((keyword, index) => (
    <div key={index}>
      {index > -1 ? (
        <div className="d-flex align-items-center gap-2">
          <p className="p-0 my-2">{keyword}</p>
          <div
            style={{
              width: "24px",  // Fixed width for the icon container
              height: "24px", // Fixed height for the icon container
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <FaRegCopy
              style={{
                color: "green",
                cursor: "pointer",
                fontSize: "20px"
              }}
              onClick={() => {
                navigator.clipboard
                  .writeText(keyword?.slice(4, 10000))
                  .then(() => {
                    Swal.fire({
                      text: 'Text Contents copied to your clipboard',
                      background: "#011225",
                      icon: "success",
                      color: 'white'
                    });
                  })
                  .catch((err) => {
                    Swal.fire({
                      text: 'Could not copy Text Contents to your clipboard! Please try again',
                      background: "#011225",
                      icon: "warning",
                      color: 'white'
                    });
                  });
              }}
            />
          </div>
        </div>
      ) : (
        <p>{keyword}</p>
      )}
    </div>
  ))}


                    
                  {/* {keyWords && (
                    <button onClick={showPopup} className="btn mt-3 btn-primary">
                      Add more Prompts
                    </button>
                  )} */}
                </div>
              </div>
            ) : (
              <p className="text-warning">Processing...</p>
            )}
            <div>{/* <h3>Analysis: </h3> */}</div>

            <div className="mt-5">
              <button
                // style={{width: "390px"}}
                type="button"
                className="btn btn-info custom-width"
                onClick={() => {
                  setStartAnalysis(true);
                  setIsAnalysisComplete(false);
                  setCurrentQuestion(0);
                  setUserAnswers([]);
                  setSelectedOption(null);
                  window.scrollTo(0, 0);
                }}
              >
                REDO YOUR ANALYSIS. THERE ARE 6 STEPS
              </button>
            </div>
            <div className="mt-3 mb-5">
              <button
                // style={{width: "390px"}}
                type="button"
                className="btn btn-warning px-4 custom-width "
                onClick={() => {
                  navigate("/calendars");
                  window.scrollTo(0, 0);
                }}
              >
                SCHEDULE YOUR CONTENTS NOW
              </button>
            </div>

            {/* <div className="mt-3 mb-5">
              <button
                // style={{width: "390px"}}
                type="button"
                className="btn btn-success custom-width "
                onClick={() => {
                  navigate("/build-your-brand");
                  window.scrollTo(0, 0);
                }}
              >
                BUILD YOUR BRAND FOR CUSTOMIZED CONTENTS
              </button>
            </div> */}
          </div>
        ) : !startAnalysis ? (
          <div className="text-white text-center analysis">
            <h2 className="mt-5 mb-3 headline-text">
              Are you ready to start ?
            </h2>
            <div className="d-flex justify-content-center gap-3">
              <button onClick={handleAnalysis} className="yes-button">
                Yes
              </button>
              <button
                onClick={() => {
                  swal({
                    text: "We’re working on something amazing, and we can't wait to share it with you. Stay tuned for updates as we prepare to launch a brand-new experience that will elevate your expectations and redefine what's possible.", //for mainnet
                    className: "modal_class_success",
                  });
                }}
                className="no-button"
              >
                No
              </button>
            </div>
          </div>
        ) : (
          <div className="question-card mx-auto ">
            <button
              style={{ borderRadius: "8px" }}
              className="px-4 py-2 mx-auto navigation-buttons step-button"
            >
              Step {currentQuestion + 1}
            </button>
            <h3 className="text-center">
              {questions[currentQuestion].question}
            </h3>
            <div className="flex-container">
              {currentQuestion === 5 ? (
                // Render the form when currentQuestion is 5
                // <form>
                //   {/* Form fields go here */}
                //   <div className="form-group">
                //     <label htmlFor="input1">Input 1</label>
                //     <input type="text" id="input1" className="form-control" />
                //   </div>
                //   <div className="form-group">
                //     <label htmlFor="input2">Input 2</label>
                //     <input type="text" id="input2" className="form-control" />
                //   </div>
                //   {/* <button type="submit" className="btn btn-primary mt-3">
                //     Submit
                //   </button> */}
                // </form>
                <Company
                  onInfoLengthChange={handleInfoLength}
                  className="flex-company"
                />
              ) : (
                // Render the options for other questions
                questions[currentQuestion].options.map((option, index) => (
                  <button
                    key={index}
                    className={`btn option-button text-white py-2 text-break text-wrap ${
                      selectedOption === option ? "selected" : ""
                    }`}
                    onClick={() => handleOptionSelect(option)}
                    style={{
                      backgroundColor:
                        selectedOption === option
                          ? "gray"
                          : [
                              "#007bff",
                              "#28a745",
                              "#ffc107",
                              "#dc3545",
                              "#17a2b8",
                            ][index],
                    }}
                  >
                    {String.fromCharCode(65 + index)} . {option}
                  </button>
                ))
              )}
            </div>

            <div
              className={`navigation-buttons ${currentQuestion === 4 ? "" : ""}`}
            >
              <button
                className={`btn px-4 action-button ${currentQuestion === 0 ? "btn-danger" : "btn-secondary"}`}
                onClick={currentQuestion === 0 ? handleCancel : handleBack}
                disabled={currentQuestion === 0 ? false : currentQuestion === 0}
              >
                {currentQuestion === 0 ? "Cancel" : "Back"}
              </button>

              {currentQuestion === 4 ? (
                <div>
                  <button
                    onClick={() => {
                      if (!userAnswers?.length == 5) {
                        swal({
                          text: "Please fill the form first before proceeding.",
                          className: "modal_class_success",
                        });
                      } else if (selectedOption) {
                        handleNext();
                      } else {
                        swal({
                          text: "Please an option before proceeding.",
                          className: "modal_class_success",
                        });
                      }
                    }}
                    style={{
                      textDecoration: "none",
                      color: "white",
                      // backgroundColor: '#17A2B8'
                      // display: "flex",
                      // alignItems: "center",
                    }}
                    className="btn btn-info px-4"
                  >
                    Next
                  </button>
                </div>
              ) : (
                <button
                  className={`btn px-4 action-button btn-info `}
                  onClick={() => {
                    if (!userAnswers?.length === 5) {
                      swal({
                        text: "Please select an option before proceeding.",
                        className: "modal_class_success",
                      });
                    } else if (
                      currentQuestion === questions?.length - 1 &&
                      userAnswers?.length === 5
                    ) {
                      if (infoLength == 4) {
                        const websitePattern =
                          /^(https?:\/\/)?([a-zA-Z0-9-_]+(\.[a-zA-Z0-9-_]+)+.*)$/;
                        if (
                          info.website &&
                          !websitePattern.test(info.website)
                        ) {
                          swal({
                            text: "Invalid Website URL",
                            className: "modal_class_success",
                          });
                        } else if (
                          info?.shortDescription &&
                          info?.shortDescription?.length > 300
                        ) {
                          swal({
                            text: "Please reduce the text to meet the character limit.",
                            className: "modal_class_success",
                          });
                        } else {
                          setIsProcessing(true);
                          setInputProcessing(true); // Set to processing
                          submitAnswers()
                            .then(() => {
                              // setStartAnalysis(false);
                              setSelectedOption(null);
                              // setCurrentQuestion(0);
                            })
                            .finally(() => {
                              setIsProcessing(false); // Reset to not processing
                              setInputProcessing(false); // testing -> true.
                              setSelectedOption(null);
                              setInputResult(false); // tetsing --> true
                              setUserAnswers([]);
                            });
                        }
                      } else {
                        swal({
                          text: "Please fill the form first before proceeding.",
                          className: "modal_class_success",
                        });
                      }
                    } else if (selectedOption) {
                      handleNext();
                    } else {
                      swal({
                        text: "Please select an option before proceeding.",
                        className: "modal_class_success",
                      });
                    }
                  }}
                >
                  {isProcessing
                    ? "Processing . . . "
                    : currentQuestion == 5
                      ? "Start Analysis"
                      : "Next"}
                </button>
              )}
            </div>
            {/* {inputprocessing && (
              <div>
                <p className="mt-3" style={{ fontWeight: "bold", color: "#FFCA2C" }}>
                  Analysis Input data:
                </p>
                <DemoResponse
                  questions={questions}
                  info={info}
                  userAnswers={userAnswers}
                />
              </div>
            )} */}

            {inputResult ? (
              <div>
                {result && (
                  <div>
                    <p
                      style={{
                        fontWeight: "bold",
                        marginTop: "30px",
                        color: "#FFCA2C",
                      }}
                    >
                      Analysis Output data:
                    </p>
                    <p
                      // dangerouslySetInnerHTML={{
                      //   __html: result
                      //   .replace(/^(.+?)(\r\n|\r|\n|$)/, "<strong style='color: aquamarine;'><u>$1</u></strong>$2")
                      //   .replace(/^Analysis:/, "<strong>Analysis:</strong>")
                      //   .replace(/###(.*?)(\r\n|\r|\n|$)/g, "<br/><br/><strong style='color: skyblue;'><u>$1</u></strong>$2")
                      //   .replace(/##\s*(.*?)(\r\n|\r|\n|$)/g, "<br/><br/><strong style='color: blueviolet;'>$1</strong>$2")
                      //   .replace(/^### Social Media Analysis/, "<strong>Social Media Analysis</strong>")
                      //   .replace(/\*\*(.*?)\*\*/g, "<strong><br/><br/>$1</strong>")
                      //   .replace(/\b\d+\.\s/g, "")
                      //   .replace(/#/g, "")
                      //   .replace(/-\s*(\r\n|\r|\n|$)/g, '')
                      //   .replace(/-\s*$/, '')
                      //   .replace(/---/g, '')
                      //   .replace(/--/g, '')
                      //   .replace(/-\s+/g, '')
                      // }}
                      dangerouslySetInnerHTML={{
                        __html: result
                          .replace(
                            /#### (.*?)(?=\n|$)/g,
                            "<strong style='color: yellowgreen;'>$1</strong>"
                          ) // Smaller subheadings
                          .replace(
                            /### (.*?)(?=\n|$)/g,
                            "<strong style='color: aquamarine; display: block; margin-bottom: -20px;'>$1</strong>"
                          ) // Small subheadings
                          .replace(
                            /## (.*?)(?=\n|$)/g,
                            "<strong style='color: skyblue; font-size: 20px;'><u>$1</u></strong>"
                          ) // Subheadings
                          .replace(
                            /# (.*?)(?=\n|$)/g,
                            "<strong style='color: blueviolet; font-size: 20px'><u>$1</u></strong>"
                          ) // Headings
                          .replace(
                            /\*\*(.*?)(?=\*\*|\n|$)\*\*/g,
                            "<strong style='color: rgb(242, 230, 215);'>$1</strong>"
                          ) // Bold text without adding <br/> at the end
                          .replace(/\n/g, "<br/>"), // Replace remaining new lines with <br/>
                      }}
                    ></p>
                    <div>
                      {loading ? (
                        // <p className="text-warning">Processing...</p>
                        <p></p>
                      ) : (
                        <div>
                          <p
                            style={{
                              fontWeight: "bold",
                              marginTop: "40px",
                              color: "#FFCA2C",
                            }}
                          >
                            Input Data For Content Creation:
                          </p>
                          <p>
                            Based on the following details, generate 20 general
                            terms for automatic content generation without
                            mentioning the company name. The contents must be
                            targeted to end users which are general, useful and
                            attract new followers:
                          </p>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: result
                                .replace(
                                  /#### (.*?)(?=\n|$)/g,
                                  "<strong style='color: yellowgreen;'>$1</strong>"
                                ) // Smaller subheadings
                                .replace(
                                  /### (.*?)(?=\n|$)/g,
                                  "<strong style='color: aquamarine; display: block; margin-bottom: -20px;'>$1</strong>"
                                ) // Small subheadings
                                .replace(
                                  /## (.*?)(?=\n|$)/g,
                                  "<strong style='color: skyblue; font-size: 20px;'><u>$1</u></strong>"
                                ) // Subheadings
                                .replace(
                                  /# (.*?)(?=\n|$)/g,
                                  "<strong style='color: blueviolet; font-size: 20px'><u>$1</u></strong>"
                                ) // Headings
                                .replace(
                                  /\*\*(.*?)(?=\*\*|\n|$)\*\*/g,
                                  "<strong style='color: rgb(242, 230, 215);'>$1</strong>"
                                ) // Bold text without adding <br/> at the end
                                .replace(/\n/g, "<br/>"), // Replace remaining new lines with <br/>
                              // .replace(/^(.+?)(\r\n|\r|\n|$)/, "<strong style='color: aquamarine;'><u>$1</u></strong>$2")
                              // .replace(/^Analysis:/, "<strong>Analysis:</strong>")
                              // .replace(/###(.*?)(\r\n|\r|\n|$)/g, "<br/><br/><strong style='color: skyblue;'><u>$1</u></strong>")
                              // .replace(/##\s*(.*?)(\r\n|\r|\n|$)/g, "<br/><br/><strong style='color: blueviolet;'>$1</strong>$2")
                              // .replace(/^### Social Media Analysis/, "<strong>Social Media Analysis</strong>")
                              // .replace(/\*\*(.*?)\*\*/g, "<strong><br/><br/>$1</strong>")
                              // .replace(/\b\d+\.\s/g, "")
                              // .replace(/#/g, "")   // Removes any # characters
                              // .replace(/\*/g, "")  // Removes any * characters
                              // .replace(/"/g, "")   // Removes any " characters
                              // .replace(/-\s*(\r\n|\r|\n|$)/g, '')
                              // .replace(/-\s*$/, '')
                              // .replace(/---/g, '')
                              // .replace(/--/g, '')
                              // .replace(/-\s+/g, '<br/>')
                            }}
                          ></p>
                          <p
                            style={{
                              fontWeight: "bold",
                              marginTop: "40px",
                              color: "#FFCA2C",
                            }}
                          >
                            Prompts for Content Creation:
                          </p>
                          <div className="mt-3">
                            {keyWords &&
                              keyWords.map((keyword, index) => (
                                <p key={index}>{keyword}</p>
                                // <p key={index}>{keyword.replace(/"/g, '')}</p>
                              ))}
                            {keyWords && (
                              <button
                                onClick={showPopup}
                                className="btn btn-primary"
                              >
                                Add more Prompts
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* <div className="mt-5">
                  {keyWords && 
                    keyWords.map((keyword, index) => (
                      <p key={index}>{keyword}</p>
                    ))
                  }
                  {keyWords && 
                    <button onClick={showPopup} className="btn btn-primary">
                      Add more keywords
                    </button>
                  }
                </div>  */}
              </div>
            ) : (
              // <div>
              //   {
              //     isProcessing && <p className="text-warning">Processing...</p>
              //   }
              // </div>
              <span></span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Analysis;
