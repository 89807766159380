import React from "react";
import "./ApiSettings.css";

const Charge = () => {
  const data = [
    {
      title: "Text AI",
      cost: '1 Credit per 10 words',
      content: "USD 0.002 per word",
      pay: '0.002',
      background: "#05C0A2",
    },
    {
      title: "Image AI",
      cost: '10 Credits',
      content: "USD 0.20",
      pay: '0.20',
      background: "#1D60EF",
    },
    {
      title: "Post AI",
      cost: '10 Credits per image',
      content: "USD 0.20",
      pay: '0.20',
      background: "#FA6918",
    },
    {
      title: "Text Video AI",
      cost: '40 Credits',
      content: "USD 0.40",
      pay: '0.40',
      background: "#d62626",
    },
    {
      title: "News Video AI",
      cost: '40 Credits',
      content: "USD 0.40",
      pay: '0.40',
      background: "#FFCA2C",
    },
    {
      title: "Comic Video AI",
      cost: '60 Credits',
      content: "USD 0.60",
      pay: '0.40',
      background: "#5C81FD",
    },
  ];
  return (
    <div className="grid-content">
      {data.map((item, index) => (
        <div className="">
          <div style={{ borderRadius: "10px" }} className="bg-white">
            <div
              className="package-price text-center"
              style={{ background: `${item.background}` }}
            >
              {/* <p>PACKAGE {index + 1}</p> */}
              <h4>{item.title}</h4>
              <p
                style={{
                  fontSize: "small",
                  // color: "yellow",
                  fontWeight: "500",
                }}
              >
                {item.cost}
              </p>
            </div>
            <div>
              <div className="each-row py-4 border-0">
                <p className="row-value mb-0 text-danger card-content-height">
                  {" "}Low as <br/>
                  {item.content}
                </p>
              </div>
              <div className="pay-button-container">
                {/* <button
                  className="pay-button"
                >
                USD {item.pay}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Charge;
