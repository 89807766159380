// import React from "react";
// import poster1 from './Image/nv1.PNG'
// import poster2 from './Image/nv2.PNG'
// import poster3 from './Image/nv3.PNG'

// const Five = () => {
//   const data = [
//     {
//       title: `NEW LAWSUIT SLAMS TEMU AS "THEFT BUSINESS" STEALING YOUR DATA`,
//       link: [
//         "https://backend.whalesai.com/public/hiwvideos/1080-1080-news-video.mp4",
//         // "https://backend.whalesai.com/public/videos/1920-1080-rT4w6JTG.mp4",
//         // "https://backend.whalesai.com/public/videos/1080-1920-rT4w6JTG.mp4"
//       ],
//       level : '1:1 1080 X 1080 PX',
//       poster: poster1
//     },
//     {
//       title: "ONTARIO COUNTY SHERIFF'S OFFICE USES IPADS TO CONNECT PEOPLE IN MENTAL HEALTH CRISIS WITH CLINICIANS, REDUCING HOSPITAL VISITS",
//       link: [
//         // "https://backend.whalesai.com/public/videos/1080-1080-f7wD6j5F.mp4",
//         "https://backend.whalesai.com/public/hiwvideos/1920-1080-news-video.mp4",
//         // "https://backend.whalesai.com/public/videos/1080-1920-f7wD6j5F.mp4"
//       ],
//       level : '16:9 1920 X 1080 PX',
//       poster: poster2
//     },
//     {
//       title: "AFLW: NEWMAN ON HOW A BUSINESS VENTURE WITH HER TWIN HELPS HER FOOTY",
//       link: [
//         // "https://backend.whalesai.com/public/videos/1080-1080-05aQ51ci.mp4",
//         // "https://backend.whalesai.com/public/videos/1920-1080-05aQ51ci.mp4",
//         "https://backend.whalesai.com/public/hiwvideos/1080-1920-news-video.mp4"
//       ],
//       level : '9:16 1080 X 1920 PX',
//       poster: poster3
//     }
//   ];

  
//   const level = [
//     '1:1 1080 X 1080 PX',
//     '16:9 1920 X 1080 PX',
//     '9:16 1080 X 1920 PX',
//   ]
  
//   return (
//     <div className="text-white">
//       <div>
//       {
//         data.map((video, index) => <div key={index}>
//           <p>{video.title}</p>
//           <p>{video.level}</p>
          
//           {
//             video.link.map((i,j) => <div key={j}
//             style={{ marginBlock: "20px" }}
//             className="d-flex gap-2 flex-column justify-content-center mx-auto"
//           >
            
//             <video
//               controls
//               playsInline
//               poster={video.poster}
//               src={i}
//               className=""
//               style={{ width: "90%", height: '400px' }}
//               type="video/mp4"
//             />
//             {/* {
//               <p>{level[j]}</p>
//             } */}
//           </div>
//           )
//           }
         
          
//         </div>
//       )
//       }
//      </div>

//     </div>
//   );
// };

// export default Five;


import React, { useRef } from "react";
import poster1 from './Image/nv1.PNG';
import poster2 from './Image/nv2.PNG';
import poster3 from './Image/nv3.PNG';

const Five = () => {
  const data = [
    {
      title: `NEW LAWSUIT SLAMS TEMU AS "THEFT BUSINESS" STEALING YOUR DATA`,
      link: ["https://backend.whalesai.com/public/hiwvideos/1080-1080-news-video.mp4"],
      level: '1:1 1080 X 1080 PX',
      poster: poster1,
    },
    {
      title: "ONTARIO COUNTY SHERIFF'S OFFICE USES IPADS TO CONNECT PEOPLE IN MENTAL HEALTH CRISIS WITH CLINICIANS, REDUCING HOSPITAL VISITS",
      link: ["https://backend.whalesai.com/public/hiwvideos/1920-1080-news-video.mp4"],
      level: '16:9 1920 X 1080 PX',
      poster: poster2,
    },
    {
      title: "AFLW: NEWMAN ON HOW A BUSINESS VENTURE WITH HER TWIN HELPS HER FOOTY",
      link: ["https://backend.whalesai.com/public/hiwvideos/1080-1920-news-video.mp4"],
      level: '9:16 1080 X 1920 PX',
      poster: poster3,
    }
  ];

  const videoRefs = useRef([]);

  const handlePlay = (index) => {
    videoRefs.current.forEach((video, i) => {
      if (i !== index && video) {
        video.pause();
      }
    });
  };

  return (
    <div className="text-white">
      <div>
        {data.map((video, index) => (
          <div key={index}>
            <p>{video.title}</p>
            <p>{video.level}</p>
            {video.link.map((i, j) => (
              <div
                key={j}
                style={{ marginBlock: "20px" }}
                className="d-flex gap-2 flex-column justify-content-center mx-auto"
              >
                <video
                  controls
                  playsInline
                  poster={video.poster}
                  src={i}
                  className=""
                  style={{ width: "90%", height: '400px' }}
                  type="video/mp4"
                  ref={(el) => (videoRefs.current[index] = el)}
                  onPlay={() => handlePlay(index)}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Five;
