import React, { useState } from 'react';
import img1 from './Image/image1.png'
import img2 from './Image/image2.png'
import img3 from './Image/image3.png'
import img4 from './Image/image4.png'
import img5 from './Image/image5.png'
import './index.css';
import { Button, Modal } from 'react-bootstrap';

const data = [
    {
        title: 'A grand palace with golden domes and towers.',
        dimension: '1024 X 1024 PX',
        picture: img1,
        hw: 1024
    },
    {
        title: 'A bustling Chinatown street in San Francisco with lanterns hanging overhead, vendors selling dim sum, and the iconic Dragon Gate in the distance.',
        //A bustling Chinatown street in San Francisco with lanterns hanging overhead, vendors selling dim sum, and the iconic Dragon Gate in the distance.
        dimension: '1792 X 1024 PX',
        picture: img4,
        hw: 512
    },
    {
        title: 'A cozy coffee shop interior with comfortable seating, bookshelves, people working on laptops, and a barista preparing drinks.',
        //A cozy coffee shop interior with comfortable seating, bookshelves, people working on laptops, and a barista preparing drinks.
        dimension: '1024 X 1792 PX',
        picture: img5,
        hw: 256
    },
    
]

const buttons = [
    "Facebook",
    "Instagram",
    "Threads",
    "Twitter/X",
    "LinkedIn",
    "TikTok",
    "YouTube",
    "Pinterest",
    "Medium",
    "Telegram",
  ];

  const buttonColors = [
    "#95B9C9",
    "#39ACEF",
    "#0699E8",
    "#488AC7",
    "#367ECA",
    "#6960ED",
    "#1669C7",
    "#0040C4",
    "#0000FE",
    "#151A8F",
  ];

const Two = () => {
    const [showPopUp, setShowPopUp] = useState(false);
    // const [clickedButtonIndex, setClickedButtonIndex] = useState(null);
    const handleSocialMedia = () => {
        setShowPopUp(true);
    }
    return (
        <div className='text-white'>
            {
                data.map((item, index) => <div className='mb-5' key={index}>
                  <p className='fw-bold'>{item.title}</p>
                  <p><span className='fw-bold'>Dimension:</span> {item.dimension}</p>
                  <img className='imgeAI-image' src={item.picture} alt={item.title}  ></img>
                </div>)
            }
            {/* <div className='social-media-section'>
                <button 
                onClick={handleSocialMedia}
                className='btn btn-primary px-2 py-1'>Post on Social Media Platform</button>
            </div> */}

            <Modal show={showPopUp} onHide={() => setShowPopUp(false)}>
                {/* <Modal.Header closeButton>
                </Modal.Header> */}

                <Modal.Body style={{ backgroundColor: '#011225', color: '#fff' }}>
                <div className="text-center">
                    
                    {
                        buttons.map((platform, i) => <button 
                        // onClick={}
                        key={i} 
                        style={{ backgroundColor: buttonColors[i] }}
                        className="btn mx-2 my-2 text-white social-media px-4">{platform}</button>)
                    }
                </div>
                </Modal.Body>


                <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                    setShowPopUp(false)
                    // setSubscribePrice(0)
                    // setToggledPlatforms([])
                    }}
                >
                    OK
                </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Two;