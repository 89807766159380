import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Edit, Delete } from "@mui/icons-material";
import Swal from "sweetalert2";
import "./AdminUser.css";
import DashboardModalNewAdmin from "./DashboardModalNewAdmin";
import SearchBar from "./SearchBar";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import usePagination from "../../hooks/UsePagination";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import Spineer from "../../Components/CustomSpineer/Spineer";
import AdminDataTable from "./AdminDataTable";
import AddNews from "./AddNews";

const AdminUser = () => {
  const [modalShowNewAdmin, setModalShowNewAdmin] = useState(false);
  const [newsModal, setNewsModal] = useState(false);
  const { allAIAdmin, getAllAdmin } = useContext(AdminAuthContext);
  const AdminToken = localStorage.getItem("AiAdmin");
  const [searchQuery, setSearchQuery] = useState("");

  const location = useLocation();
  const { currentAIAdmin } = useContext(AdminAuthContext) || location.state;

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  console.log(
    "admin from Admin user page =====>>>>>",
    allAIAdmin,
    currentAIAdmin
  );

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  // let count = Math.ceil(allAIAdmin?.length / PER_PAGE);
  // let perPageData = usePagination(allAIAdmin, PER_PAGE);
  const filteredAdmins = allAIAdmin?.filter((admin) =>
    admin.username.toLowerCase().includes(searchQuery) || admin.email.toLowerCase().includes(searchQuery)
  );
  let count = Math.ceil(filteredAdmins?.length / PER_PAGE);
  let perPageData = usePagination(filteredAdmins, PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };

  const handleAdminDelete = (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this admin?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://backend.whalesai.com/api/v1/admin/delete/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${AdminToken}`,
          },
        })
          .then((response) => {
            console.log("delete response ====> ", response);
            if (response.ok) {
              getAllAdmin();
              Swal.fire({
                html: `<p class='text-break text-white'>Admin Deleted.</p>`,
                icon: "success",
                customClass: "modal_class_success",
              });
            } else {
              getAllAdmin();
              Swal.fire({
                text: "Failed to delete the admin.",
                icon: "error",
                background: "#011225",
              });
            }
          })
          .catch((error) => {
            console.log("error from catch", error);
            getAllAdmin();
            Swal.fire({
              text: "An error occurred while deleting the admin.",
              icon: "error",
              background: "#011225",
            });
          });
      }
    });
  };

  return (
    <div className="container-fluid  text-white">
      <h5 className="text-start mb-3 font-weight-bold">ADMINS</h5>
      {/* <SearchBar handleSearch={() => {}} /> */}
      <div className="mb-3">
        <SearchBar handleSearch={handleSearch} />
      </div>

      <div
        className="table-responsive pt-3 rounded my-2 mb-5"
        style={{ backgroundColor: "#3080AC", paddingBottom: "10px" }}
      >
        <div className="mx-3">
          <div className="d-flex justify-content-end gap-2 mb-2 mr-3">
            {/* <button
              onClick={() => setNewsModal(true)}
              className="btn btn-primary admin-add-text "
            >
              ADD NEWS
            </button> */}
            <button
              onClick={() => setModalShowNewAdmin(true)}
              className="btn btn-primary mb-1 admin-add-text "
            >
              ADD NEW ADMIN
            </button>
          </div>
        </div>
        {/* <AdminDataTable
          admins={perPageData?.currentData() || []}
          handleAdminDelete={handleAdminDelete}
          currentAIAdmin={currentAIAdmin}
        />
         <CustomPagination
            page={page}
            count={count}
            onChange={handlePageChange}
          /> */}

        {/* <div className="table-responsive">
          <table
            className="table table-hover overflow-auto table-auto w-full"
            style={{ backgroundColor: "#3080AC" }}
          >
            <thead className="bg-[#1a1c339a]">
              <tr>
                <th scope="col">S/no</th>
                <th scope="col" className="">
                  Image
                </th>
                <th scope="col">Username</th>
                <th scope="col" className="">
                  Email
                </th>
                <th scope="col" className="">
                  Mobile
                </th>
                <th scope="col" className="text-center mx-0 px-0">
                  Action
                </th>
              </tr>
            </thead>

            <tbody style={{ backgroundColor: "#3080AC" }}>
              {allAIAdmin && currentAIAdmin && allAIAdmin.length > 0 ? (
                perPageData?.currentData()?.map((admin, index) => (
                  <tr key={admin._id}>
                    <td className="px-4 py-4">{index + 1}</td>
                    <td className="">
                      <img
                        src={admin?.avatar}
                        alt="Admin"
                        className="img-thumbnail my-2"
                        style={{ height: "40px" }}
                      />
                    </td>
                    <td className="py-4" style={{ textTransform: "lowercase" }}>
                      {admin.username}
                    </td>
                    <td className=" py-4">{admin.email}</td>
                    <td className=" py-4">{admin.phone}</td>
                    <td className="text-center">
                      <div className="btn-group ml-0 gap-2" role="group">
                        <Link
                          className="btn rounded-circle btn-sm py-2 btn-success"
                          to={`/admin/adminprofile/${admin._id}`}
                        >
                          <Edit />
                        </Link>
                        {admin?.email === currentAIAdmin?.email ? (
                          ""
                        ) : (
                          <button
                            className="btn rounded-circle btn-sm py-2 btn-danger"
                            onClick={() => handleAdminDelete(admin._id)}
                          >
                            <Delete />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="text-center"
                    colSpan={window.innerWidth <= 768 ? "3" : "6"}
                  >
                    <Spineer />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <CustomPagination
            page={page}
            count={count}
            onChange={handlePageChange}
          />
        </div> */}

        <div className="table-responsive overflow-auto">
          <table
            className="table table-text table-hover"
            style={{ backgroundColor: "#3080AC" }}
          >
            <thead className="bg-[#1a1c339a]">
              <tr>
                <th scope="col">S/no</th>
                <th scope="col" className="">
                  Image
                </th>
                <th scope="col">Username</th>
                <th scope="col" className="d-none d-md-table-cell">
                  Email
                </th>
                <th scope="col" className="d-none d-md-table-cell">
                  Mobile
                </th>
                <th scope="col" className="">
                  Action
                </th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "#3080AC" }}>
              {allAIAdmin && currentAIAdmin && allAIAdmin.length > 0 ? (
                perPageData?.currentData()?.map((admin, index) => (
                  <tr key={admin._id}>
                    <td className="px-4 py-4">{(page - 1) * 10 + index + 1}</td>
                    <td className="">
                      <img
                        src={admin?.avatar}
                        alt="Admin"
                        className="img-thumbnail"
                        style={{ height: "40px" }}
                      />
                    </td>
                    <td className="py-4" style={{ textTransform: "lowercase" }}>
                      {admin.username}
                    </td>
                    <td className="d-none py-4 d-md-table-cell">
                      {admin.email}
                    </td>
                    <td className="d-none py-4 d-md-table-cell">
                      {admin.phone}
                    </td>
                    {/* <td className="text-center pt-3">
                    <div className="btn-group gap-1" role="group">
                    <Link
                          className="btn action-btn rounded-circle btn-sm icon-edit btn-success"
                          to={`/admin/adminprofile/${admin._id}`}
                        >
                          <Edit className="action-button-size"  />
                        </Link>
                      {admin?.email === currentAIAdmin?.email ? (
                          ""
                        ) : (
                          <button
                            className="btn action-btn rounded-circle btn-sm icon-edit btn-danger"
                            onClick={() => handleAdminDelete(admin._id)}
                          >
                            <Delete className="action-button-size" />
                          </button>
                        )}
                    </div>
                  </td> */}

                    <td className="alignment">
                      <div className="btn-group gap-2" role="group">
                        <Link
                          className="btn rounded-circle btn-sm icon-body btn-success"
                          to={`/admin/adminprofile/${admin._id}`}
                        >
                          <Edit className="action-icon" />
                        </Link>
                        {admin?.email === currentAIAdmin?.email ? (
                          ""
                        ) : (
                          <button
                            className="btn rounded-circle btn-sm icon-body btn-danger"
                            onClick={() => handleAdminDelete(admin._id)}
                          >
                            <Delete className="action-icon" />
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="text-center"
                    colSpan={window.innerWidth <= 768 ? "4" : "6"}
                  >
                    <Spineer />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CustomPagination
          page={page}
          count={count}
          onChange={handlePageChange}
        />
      </div>

      <DashboardModalNewAdmin
        show={modalShowNewAdmin}
        refetch={() => {}}
        setRefetch={() => {}}
        setModalShowNewAdmin={setModalShowNewAdmin}
        onHide={() => setModalShowNewAdmin(false)}
      />

      
    </div>
  );
};
export default AdminUser;

// pvs table body data

{
  /* <tbody style={{ backgroundColor: "#3080AC" }}> 
              {perPageData?.currentData()?.map((admin, index) => (
                <tr key={admin._id}>
                  <td className="px-4 py-4">{index + 1}</td>
                  <td className="d-none d-md-table-cell">
                    <img
                      src={admin?.avatar}
                      alt="Admin"
                      className="img-thumbnail my-2"
                      style={{ height: "40px" }}
                    />
                  </td>
                  <td className="py-4" style={{ textTransform: "lowercase" }}>
                    {admin.username}
                  </td>
                  <td className="d-none py-4 d-md-table-cell">{admin.email}</td>
                  <td className="d-none py-4 d-md-table-cell">{admin.phone}</td>
                  <td className="text-center">
                    <div className="btn-group gap-2" role="group">
                      <Link
                        className="btn rounded-circle btn-sm py-2 btn-success"
                        // style={{borderRadius: '30px'}}
                        to={`/admin/adminprofile/${admin._id}`}
                        // to='/admin/adminprofile'
                      >
                        <Edit />
                      </Link>
                      {
                        admin?.email === currentAIAdmin?.email ? '' : <button
                        className="btn rounded-circle btn-sm py-2 btn-danger"
                        onClick={() => handleAdminDelete(admin._id)}
                      >
                        <Delete />
                      </button>
                      }
                      
                    </div>
                  </td>
                </tr>
              ))}
            </tbody> */
}
