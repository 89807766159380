import React, { useContext, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./DeleteAccount.css";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CustomModal from "./CustomModal";

const DeleteAccount = () => {
  const { AIuser, AiLogOut } = useContext(UserAuthContext);
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  // const { AIuser, AiLogOut } = useContext(UserAuthContext);

  const handleInputChange = (e) => {
    setEmail(e.target.value); 
    console.log("Email:", e.target.value); 
  };
  console.log("User", AIuser);

  const handleDelete = (e) => {
    e.preventDefault();
    if(email == '') {
        Swal.fire({
            // title: "Success!",
            text: "Please enter your email first.",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            background: "#011225",
            color: "white",
          });
    }
    else{
        if(email === AIuser?.email) {
            setShowModal(true);
        } 
        else {
            Swal.fire({
                // title: "Success!",
                text: "Email does not match with your account",
                icon: "warning",
                confirmButtonColor: "#3085d6",
                background: "#011225",
                color: "white",
              });
        }
    }
    
  }


  const handleConfirmDelete = () => {
    if (AIuser) {
        fetch(`https://backend.whalesai.com/api/dashboard/user_signup-signin/${AIuser?._id}`, {
          method: "DELETE",
          headers: {
            // Authorization: `Bearer ${AdminToken}`,
          },
        })
          .then((response) => {
            console.log("delete response ====> ", response);
            if (response.ok) {
            //   getAllUser();
              Swal.fire({
                text: `Your account has been deleted`,
                icon: "success",
                // customClass: "modal_class_success",
                background: "#011225",
              });
              AiLogOut();
              setEmail('');
              navigate('/'); 
            } else {
            //   getAllUser();
              Swal.fire({
                text: "Failed to delete the user.",
                icon: "error",
                background: "#011225",
              });
            }
          })
          .catch((error) => {
            console.log("error from catch", error);
            // getAllUser();
            Swal.fire({
              text: "An error occurred while deleting the user.",
              icon: "error",
              background: "#011225",
            });
          });
      }
    // alert("Your account has been deleted."); 
    // Redirect after deletion
};

const handleCancelDelete = () => {
    setShowModal(false); // Hide the modal
    navigate('/');
};

  return (
    <div>
      <PageHeading title="Delete Account" description= 'Once deleted, your account cannot be retrieved.' />
      <section
        className="container account-container pb-5 px-4"
        style={{ color: "white" }}
      >
        <form className="form-controller mx-auto">
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="Email"
            style={{ backgroundColor: "#D9D9D9" }}
            value={email} 
            onChange={handleInputChange}
            required
          />

          <div className="two-button mt-4">
            <button
              style={{ backgroundColor: "#1C5476", color: "white" }}
              className="btn form-controller"
              onClick={handleDelete}
            >
              DELETE ACCOUNT
            </button>
            <button
              style={{ backgroundColor: "#1C5476", color: "white" }}
              className="btn form-controller"
              onClick={ (e) => {
                e.preventDefault();
                navigate('/');
              }}
            >
              CANCEL
            </button>
          </div>
        </form>
        {showModal && (
                    <CustomModal
                        title="Account Deletion Alert"
                        message="Do you want to use WhalesAl again? If Yes, your email will be maintained in our system. If No, your account will be deleted. Your Credits can't be retrieved forever."
                        onConfirm={handleConfirmDelete}
                        onCancel={handleCancelDelete}
                        credit={AIuser?.credits}
                    />
                )}
      </section>
    </div>
  );
};

export default DeleteAccount;