import React, { useContext, useState } from "react";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
// import Spinner from "../../Components/CustomSpinner/Spinner";
import { Delete } from "@mui/icons-material";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import usePagination from "../../hooks/UsePagination";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import Swal from "sweetalert2";
import SearchBar from "../AdminUser/SearchBar";
import moment from "moment";
import ViewTransaction from "../AdminTransactions/ViewTransaction";
// import ViewTransaction from "./ViewTransaction";
// import TransactionDetailsModal from "./TransactionDetailsModal";

const AdminTestPayments = () => {
  const { allTransaction, getallTransaction, allTester } =
    useContext(AdminAuthContext);
  const [page, setPage] = useState(1);
  const [selectedTransaction, setSelectedTransaction] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isTestPayment, setIsTestPayment] = useState(false);

  const PER_PAGE = 10;
  const getMatchedTesterTransactions = (allTester, allTransaction) => {
    if (!allTester || !allTransaction) return [];
    const testerEmails = allTester.map((tester) => tester.email);
    return allTransaction.filter((transaction) =>
      testerEmails.includes(transaction.email)
    );
  };

  const matchedTransactions = getMatchedTesterTransactions(
    allTester,
    allTransaction
  );

  const filteredTransactions = matchedTransactions?.filter(
    (item) =>
      item.name?.toLowerCase().includes(searchQuery) ||
      item.transactionId?.toLowerCase().includes(searchQuery) ||
      item.price?.toString() === searchQuery
  );

  let count = Math.ceil(filteredTransactions?.length / PER_PAGE);
  let perPageData = usePagination(filteredTransactions, PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };

  const handleOpen = (transaction) => {
    setSelectedTransaction(transaction);
    setModalOpen(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this transaction?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://backend.whalesai.com/api/v1/transactions/delete/${id}`, {
          method: "DELETE",

          // https://backend.whalesai.com/api/v1/payment/delete/all
          // delete all API
        })
          .then((response) => {
            if (response.ok) {
              // getallTransaction();
              Swal.fire({
                html: `<p class='text-break text-white'>Transaction Deleted.</p>`,
                icon: "success",
                customClass: "modal_class_success",
                allowOutsideClick: false,
              });
            } else {
              // getallTransaction();
              Swal.fire({
                text: "Failed to delete the transaction.",
                icon: "error",
                background: "#011225",
                allowOutsideClick: false,
              });
            }
          })
          .catch((error) => {
            console.log("Error:", error);
            // getallTransaction();
            Swal.fire({
              text: "An error occurred while deleting the transaction.",
              icon: "error",
              background: "#011225",
              allowOutsideClick: false,
            });
          });
      }
    });
  };

  return (
    <div className="container-fluid text-white">
      <ViewTransaction
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        selectedTransaction={selectedTransaction}
      />
      <h5 className="text-start mb-3 font-weight-bold">TEST PAYMENTS</h5>
      <div className="mb-3 d-flex justify-content-between">
        <SearchBar handleSearch={handleSearch} />
        {/* <button
        className="btn btn-sm py-1 text-uppercase px-3"
        style={{height: '40px', background:'#3080AC', border: 'none', color:'white'}}
        onClick={() => {
          setIsTestPayment(!isTestPayment);
        }}
        >Test Payments</button> */}
      </div>

      <section>
        <div
          className="pt-5 rounded my-2 mb-5 pb-3 table-width-class"
          style={{ backgroundColor: "#3080AC" }}
        >
          <div className="my-3"></div>
          <div className="table-responsive overflow-auto">
            <table
              className="table table-hover"
              style={{ backgroundColor: "#3080AC" }}
            >
              <thead className="bg-[#1a1c339a] table-text-size">
                <tr>
                  <th scope="col">S/no</th>
                  <th scope="col">
                    Date & Time{" "}
                    <span style={{ fontSize: "10px" }}>(SG Time)</span>
                  </th>
                  <th scope="col">Username</th>
                  <th scope="col">Package Type</th>
                  <th scope="col">Package Name</th>
                  <th scope="col">Credits</th>
                  <th scope="col">Expiry Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Status</th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody
                className="table-text"
                style={{ backgroundColor: "#3080AC" }}
              >
                {allTransaction && allTransaction.length > 0 ? (
                  perPageData?.currentData()?.map((transaction, index) => (
                    <tr key={transaction._id}>
                      <td className="py-4 px-4">
                        {(page - 1) * 10 + index + 1}
                      </td>
                      <td className="py-4">
                        {/* {moment(transaction?.createdAt).format(
                          "DD-MM-YYYY HH:MM:SS"
                        )} */}
                        {moment(transaction?.createdAt).format("DD-MM-YYYY")}
                        <br />
                        {moment(transaction?.createdAt).format("HH:mm:ss")}
                      </td>
                      <td className="py-4">{transaction.name}</td>
                      <td className="py-4">Method 1</td>
                      <td className="py-4">
                        Credits Package {transaction?.data?.packageNo}
                      </td>
                      <td className="py-4">{transaction.credits}</td>
                      <td className="py-4">
                        {moment(transaction?.createdAt)
                          .add(parseInt(transaction?.data?.expire), "months")
                          .format("DD-MM-YYYY")}
                      </td>
                      <td className="py-4">USD {transaction.price}</td>
                      <td className="py-4">
                        {transaction.status == "succeeded"
                          ? "Completed"
                          : transaction?.status}
                      </td>

                      <td className="text-center">
                        <div className="btn-group gap-2" role="group">
                          <Link
                            onClick={() => handleOpen(transaction)}
                            className="btn rounded-circle btn-sm icon-body btn-success"
                          >
                            <RemoveRedEyeIcon className="action-icon" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center py-4" colSpan={10}>
                      No incoming payment found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <CustomPagination
            page={page}
            count={count}
            onChange={handlePageChange}
          />
        </div>
      </section>
    </div>
  );
};

export default AdminTestPayments;
