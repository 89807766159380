import React from "react";
import { FaCheckSquare } from "react-icons/fa";

const CLS = ({ inputText, isGenerating, text }) => {
  return (
    <>
      <p class="preset-title">Classification</p>

      {inputText ? (
        <>
         <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
          <p class="text-muted">{inputText}</p>
          {isGenerating == true ? (
            <p
              class="text- mb-0 mt-2"
              style={{
                fontSize: "14px",
                color: "#A020F0",
              }}
            >
              Processing...
            </p>
          ) : (
            <>
              {text && (
                <div>
                  <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
                  <p
                  style={{
                    color: "black",
                  }}
                  class=" mb-0 "
                >
                  {text}
                </p>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
           <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
          <p class="text-muted" style={{fontSize:"14px" }}>Apple</p>
          <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
          <p
         style={{ color: "black",fontSize:"14px" }}
          >
            Category: Technology
          </p>
        </>
      )}
    </>
  );
};

export default CLS;
