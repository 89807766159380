import React from "react";

const GSE = ({ inputText, isGenerating, text }) => {
  return (
    <>
      <p class="preset-title">Grammatical Standard English</p>

      {inputText ? (
        <>
          <p class="text-muted">{inputText}</p>

          {isGenerating == true ? (
            <p
              class="text- mb-0 mt-2"
              style={{
                fontSize: "14px",
                color: "black",
              }}
            >
              Processing...
            </p>
          ) : (
            <>
              {text && (
                <p
                  style={{
                    color: "black",
                  }}
                  class="text- mb-0 text-"
                >
                  {text}
                </p>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <p className="text-muted">She no went to the market.</p>
          <p
            style={{
              color: "black",
            }}
            class="mb-0"
          >
            She did not go to the market.
          </p>
        </>
      )}
    </>
  );
};

export default GSE;
