import { Box, Container, Grid } from "@mui/material";
import { styled } from "@mui/system";
import method1Img from "../../Assets/Images/method01.png";
import method2Img from "../../Assets/Images/method02.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserAuthContext } from "../../Contexts/UserAuthContext";

const BannerContainer = styled(Box)({
  backgroundColor: "#174665",
  padding: "2rem 0",
  color: "white",
});
const BannerImg = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  cursor: "pointer",
});

const Banner = () => {
  const navigate = useNavigate();
  const { AIuser } = useContext(UserAuthContext);

  return (
    <BannerContainer>
      <Container>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12} sx={{ position: "relative" }}>
            <BannerImg
              src={method1Img}
              alt="A complete social media marketing manager"
              onClick={() => navigate("/do-it-yourself")}
            />
            {/* <p className="free-20001">
              {AIuser
                ? `Now you have ${Number(Math.floor(AIuser?.credits)).toLocaleString()} CREDITS`
                : "Sign up now and get 2000 FREE CREDITS"}
            </p> */}
          </Grid>
          <Grid item sm={6} xs={12}>
            <BannerImg
              src={method2Img}
              alt="image"
              onClick={() => navigate("/let-us-do-it-for-you")}
            />
          </Grid>
        </Grid>
      </Container>
    </BannerContainer>
  );
};

export default Banner;
