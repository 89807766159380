import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RiAdminFill } from "react-icons/ri";
import swal from "sweetalert";
import { Button, Container, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import "./DashboardModalNewAdmin.css";
import "./AddNews.css";

const AddNews = (props) => {
  const fileInputRef = useRef(null);

  const { setNewsModal, setIsLoadingAdmin, refetch, userCountry, setRefetch } =
    props;
  const { getAllAdmin, allAIAdmin, getAllNewsData, allNews } =
    useContext(AdminAuthContext);
  const [loading, setLoading] = useState(false);
  const [isloading, setisLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [lastNews, setLastNews] = useState("");
  const [isReady, setIsReady] = useState(false);
  const [newsData, setNewsData] = useState({
    title: "",
    summary: "",
    category: "", // Initially empty; will be updated later
  });

  useEffect(() => {
    const length = allNews?.length - 1;
    if (allNews && length >= 0) {
      const lastCategory = allNews[length].category;
      setLastNews(lastCategory);
    }
  }, [allNews]);

  useEffect(() => {
    // Update newsData with the latest category when lastNews changes
    setNewsData((prevData) => ({
      ...prevData,
      category: lastNews,
    }));
  }, [lastNews]);

  useEffect(() => {

    if(newsData?.title && newsData?.summary) setIsReady(true);
    else setIsReady(false);

  } ,[newsData])

  console.log(lastNews, newsData);

  const handleButtonClick = () => {
    fileInputRef.current.click(); // This opens the file picker
  };

  const handleFileUpload = async (event) => {
    setisLoading(true);
    const file = event.target.files[0];
    // Create form data
    const formData = new FormData();
    formData.append("pdfFile", file);

    try {
      // Call the API to upload the PDF and extract title and summary
      const response = await axios.post(
        "https://backend.whalesai.com/api/v1/admin/news/summary",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Assuming response data has 'title' and 'summary'
      const { title, summary } = response.data;

      console.log( 'here is the response:   ',title, summary, response);
      setisLoading(false);
      setComplete(true);
      // Set the news title and summary based on the response
      setNewsData((prevState) => ({
        ...prevState,
        title,
        summary,
      }));
      setIsReady(true);
    } catch (error) {
      console.error("Error while uploading PDF:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsReady(false);
    setIsUploading(true);
    const newsTitle = newsData.title;
    const newsSummary = newsData.summary;
    const newsCategory = event.target.newsCategory.value;

    const formData = {
      title: newsTitle,
      summary: newsSummary,
      category: newsCategory,
      // country: userCountry,
    };

    console.log("Form Data:", formData);

    try {
      const response = await axios.post(
        "https://backend.whalesai.com/api/v1/admin/news/create",
        formData
      );
      console.log("Response Data:", response);
      const ID = response?.data?._id;
      const newsSummary = response?.data?.summary;
      console.log("ID after uploading;;;;;;;", ID);

      

      if (response.status === 201) {
        try {
          const putResponse = await axios.put(
            `https://backend.whalesai.com/api/v1/admin/news/rewrite/${ID}`,
            {
              summary: newsSummary, // Use the summary from the form data
            }
          );
          const response = await axios.put(
            `https://backend.whalesai.com/api/v1/admin/news/rewrite/comic/${ID}`,
            {
              summary: newsSummary,
            }
          );
          console.log("response===========>>>>>>>", response);
          console.log("PUT response::::::::::::", putResponse);
          setNewsData((prevData) => ({
            ...prevData,
            title: "",
            summary: "",
          }));
          setIsReady(false);
          // setNewSummary(putResponse?.data?.news?.summary);
        } catch (putError) {
          console.error("Error updating the news summary:", putError);
        }
        getAllNewsData();
        setComplete(false);
        setIsUploading(false);
        setNewsModal(false);
        Swal.fire({
          icon: "success",
          text: "News has been added successfully!",
          background: "#011225",
          color: "white",
        });
      } else {
        setNewsModal(false);
        Swal.fire({
          icon: "error",
          text: "Something went wrong. Please try again.",
          background: "#011225",
          color: "white",
        });
      }
    } catch (error) {
      console.error("Error while adding news:", error);
      setNewsModal(false);
      Swal.fire({
        icon: "error",
        text: "Failed to add news. Please try again later.",
        background: "#011225",
        color: "white",
      });
    }
  };

  return (
    <Modal {...props} centered style={{ zIndex: 1000000 }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <RiAdminFill className="fs-4 uppercase" /> ADD NEWS
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#303651", color: "white" }}>
        <Container>
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group controlId="newsTitle" className="mb-3">
              <Form.Label>NEWS TITLE</Form.Label>
              <Form.Control
                placeholder="News Title"
                className="custom-placeholder"
                type="text"
                name="newsTitle"
                value={newsData.title}
                onChange={(e) =>
                  setNewsData({ ...newsData, title: e.target.value })
                }
                required
              />
            </Form.Group>
             */}
            <Form.Group controlId="newsTitle" className="mb-3">
              <Form.Label>NEWS TITLE</Form.Label>
              <div className="input-wrapper">
                <Form.Control
                  placeholder="News Title"
                  className="custom-placeholder text-title"
                  type="text"
                  name="newsTitle"
                  value={newsData.title}
                  onChange={(e) =>
                    setNewsData({ ...newsData, title: e.target.value })
                  }
                  required
                />
                <span
                  className="clear-text"
                  onClick={() => setNewsData({ ...newsData, title: "" })}
                >
                  ×
                </span>
              </div>
            </Form.Group>

            {/* <Form.Group controlId="newsSummary" className="mb-3">
              <Form.Label>NEWS SUMMARY</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="News Summary"
                className="custom-placeholder"
                name="newsSummary"
                value={newsData.summary}
                onChange={(e) =>
                  setNewsData({ ...newsData, summary: e.target.value })
                }
                required
              />
            </Form.Group> */}
            <Form.Group
              controlId="newsSummary"
              className="mb-3 textarea-container"
            >
              <Form.Label>NEWS SUMMARY</Form.Label>
              <div className="textarea-wrapper">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="News Summary"
                  className="custom-placeholder "
                  name="newsSummary"
                  value={newsData.summary}
                  onChange={(e) =>
                    setNewsData({ ...newsData, summary: e.target.value })
                  }
                  required
                />
                <span
                  className="clear-text"
                  onClick={() => setNewsData({ ...newsData, summary: "" })}
                >
                  ×
                </span>
                {/* <span className="clear-text" onClick={() => setNewsData({ ...newsData, summary: "" })}></span> */}
              </div>
            </Form.Group>

            <Form.Group controlId="newsCategory" className="mb-3">
              <Form.Label>NEWS CATEGORY</Form.Label>
              <Form.Select
                name="newsCategory"
                required
                value={newsData.category}
                onChange={(e) =>
                  setNewsData({ ...newsData, category: e.target.value })
                }
              >
                <option value="Business">Business</option>
                <option value="Science">Science</option>
                <option value="Singapore">Singapore</option>
                <option value="Technology">Technology</option>
                <option value="World">World</option>
              </Form.Select>
            </Form.Group>

            <Modal.Footer className="d-flex mx-0 px-0 align-items-center justify-content-between">
              <div className="">
                <button
                  type="button"
                  disabled={isloading || complete}
                  className="btn btn-success px-1"
                  onClick={handleButtonClick}
                  style={{fontSize: '16px'}}
                >
                  {isloading ? "Generating..." : "Generate News Summary and Comic Strip"}
                </button>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept="application/pdf"
                  onChange={handleFileUpload}
                />
              </div>

              <div>
                <Button variant="secondary" onClick={props.onHide} className="px-2">
                  Cancel
                </Button>
                <Button
                  disabled={!isReady || isUploading}
                  //={isloading || complete}
                  type="submit"
                  className={`ms-1 px-2 ${isReady? 'bg-primary' : 'bg-secondary'}`}
                  // variant="primary"
                  style={{border: 'none'}}
                  // disabled={} 

                >
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Form>

          {loading && (
            <div>
              <div className="custom-spinner"></div>
            </div>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AddNews;
