import React from "react";
import Packages from "../../Components/Packages/Packages";
import Packages2 from "../../Components/Packages2/Packages2";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./Pricing.css";
import MetaData from "../../Components/MetaData/MetaData";
import APIPackage from "../../Components/APIPackage/APIPackage";

const Pricing = () => {
  return (
    <div>
         <MetaData
        pageTitle={
          "Best Generative AI Tool for Beginners | Easy Generative AI"
        }
        pageDescription={
          "Find the best generative AI tools for beginners to create text, images, audio, and more. Enjoy user-friendly platforms designed for easy learning and creativity."
        }
        canonical="https://whalesai.com/pricing"
      ></MetaData>
      <PageHeading title="Pricing" />
      <section className="py-5" style={{ background: "#006c62" }}>
        <div className="container px-4">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h2 className="method-heading text-uppercase text-white mb-3">
              Method 1
              <br /> Do it yourself
            </h2>
            {/* <p>
            Create and post various content with our AIs, only paying for what
            you generate.
          </p> */}

            {/* <button
            onClick={() => navigate(`/do-it-yourself`)}
            className="mb-5 mt-4"
          >
            Get Started
          </button> */}
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#0a273f" }} className="pb-4 pb-md-5">
        <div className="container px-4 mx-auto">
          <Packages />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 0 0",
            }}
          >
            {/* <NavLink to='/let-us-do-it-for-you'
              className="btn btn-info"
              onClick={() => {
                swal({
                  text: "We’re working on something amazing, and we can't wait to share it with you. Stay tuned for updates as we prepare to launch a brand-new experience that will elevate your expectations and redefine what's possible.", //for mainnet
                  className: 'modal_class_success',
                });
              }}
            >
              HIRE OUR SOCIAL MEDIA MARKETING MANAGER(SMMM) AI FOR JUST USD 30!
            </NavLink> */}{" "}
          </div>
        </div>
      </section>
     
      <section className="py-5" style={{ background: "#006c62" }}>
        <div className="container px-4">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h2 className="method-heading my-3 text-uppercase text-white">
              Method 2
              <br /> Let us do it for you
            </h2>
          </div>
        </div>
      </section>
      {/*  #0a273f */}
      <section style={{ backgroundColor: "#0a273f" }} className="pb-4 pb-md-5">
        <div className="container px-4 mx-auto">
          <Packages2 />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 0 0",
            }}
          >
            {/* <NavLink to='/let-us-do-it-for-you'
              className="btn btn-info"
              onClick={() => {
                swal({
                  text: "We’re working on something amazing, and we can't wait to share it with you. Stay tuned for updates as we prepare to launch a brand-new experience that will elevate your expectations and redefine what's possible.", //for mainnet
                  className: 'modal_class_success',
                });
              }}
            >
              HIRE OUR SOCIAL MEDIA MARKETING MANAGER(SMMM) AI FOR JUST USD 30!
            </NavLink> */}{" "}
          </div>
        </div>
        {/* <p className="text-center text-uppercase pay-text">
          Pay for 10 months and get 2 months free
        </p> */}
      </section>


      <section className="py-5" style={{ background: "#006c62" }}>
        <div className="container px-4">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h2 className="method-heading my-3 text-uppercase text-white">
            API PACKAGE 
              <br />
              <p >USD 5.00: 250 credits</p>
            </h2>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#0a273f" }} className="pb-4 pb-md-5">
        <div className="container px-4 mx-auto">
          <APIPackage />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 0 0",
            }}
          >
            {/* <NavLink to='/let-us-do-it-for-you'
              className="btn btn-info"
              onClick={() => {
                swal({
                  text: "We’re working on something amazing, and we can't wait to share it with you. Stay tuned for updates as we prepare to launch a brand-new experience that will elevate your expectations and redefine what's possible.", //for mainnet
                  className: 'modal_class_success',
                });
              }}
            >
              HIRE OUR SOCIAL MEDIA MARKETING MANAGER(SMMM) AI FOR JUST USD 30!
            </NavLink> */}{" "}
          </div>
        </div>
        {/* <p className="text-center text-uppercase pay-text">
          Pay for 10 months and get 2 months free
        </p> */}
      </section>
      {/* <div className="d-flex justify-content-center items-center custom ">
        <img src={PricingImg} alt="pricing" />
      </div> */}
      {/* <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-12 col-lg-12">
          <img src={PricingImg} alt="pricing" className="img-fluid" />
        </div>
      </div> */}
    </div>
  );
};

export default Pricing;
