import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  Typography,
  Box,
  DialogContent,
  Avatar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect, useState } from "react";
import { BiWorld } from "react-icons/bi";
import { AiOutlineLike } from "react-icons/ai";
import { FaRegComment } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa6";
import { RiShareForwardLine } from "react-icons/ri";

import { MdOutlineFace6 } from "react-icons/md";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function FacebookPost({ setShowModal, showModal }) {
  const handleClose = () => setShowModal(false);
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${dd}-${mm}-${yyyy}`;

  const [scheduleData, setScheduledata] = useState();
  const [matchedData, setMatchedData] = useState();
  const [generatedContent, setGeneratedContent] = useState();
  const [generatedHashTag, setGeneratedHashTag] = useState();
  const [image, setImage] = useState(false);
  const [video, setVideo] = useState(false);
  const [text, setText] = useState(false);
  const [textVideo, setTextVideo] = useState(false);
  const [newsVideo, setNewsVideo] = useState(false);
  const [comicVideo, setComicVideo] = useState(false);
  const [samleVideoLink, setSampleVideoLink] = useState();
  const [nv, setNv] = useState();
  const [tv, settv] = useState();
  const [cv, setcv] = useState();

  const textWithHastag = `A serene desert oasis with a clear blue pond, date palms, a small tent, and a camel resting nearby. #DesertOasis #NatureBeauty #TravelDreams #PeacefulEscape #Wanderlust`;

  const anotherOne = `Generated at WhalesAI.com #whalesaicom #ai #dsl`;

  const HashtagText = ({ text }) => {
    const words = text.split(" ");

    let firstHashtagFound = false;

    return (
      <Typography variant="body2" sx={{ mb: 1, textAlign: "start" }}>
        {words.map((word, index) => {
          if (word.startsWith("#")) {
            if (!firstHashtagFound) {
              // Add line break after first hashtag and mark it found
              firstHashtagFound = true;
              return (
                <React.Fragment key={index}>
                  <br />
                  <Box component="span" sx={{ color: "blue" }}>
                    {word}{" "}
                  </Box>
                </React.Fragment>
              );
            }
            // For subsequent hashtags, just apply blue color
            return (
              <Box key={index} component="span" sx={{ color: "blue" }}>
                {word}{" "}
              </Box>
            );
          }
          // Regular text
          return (
            <Box key={index} component="span">
              {word}{" "}
            </Box>
          );
        })}
      </Typography>
    );
  };

  //   #FF0033

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={showModal}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab", //colors[platformIndex]
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            {/* <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Post Details</Typography>
            </div> */}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            {/* <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Platform: Facebook Page
            </Typography> */}

            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>Content Type:</strong> 1:1 Image with text and hashtags
            </Typography>

            <div>
              <Box sx={{ border: "1px solid #ccc", padding: 2, mb: 2 }}>
                {/* <div style={{ textAlign: "left", marginBottom: "10px" }}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <img
                        style={{ borderRadius: "50%", height: "40px" }}
                        src="https://whalesai.com/static/media/ds-logo.23cc3eac8e316a53022f.png"
                      />
                      <div>
                        <span>Whalesai</span> <br />
                        <div>
                          <span
                            style={{ fontSize: "12px", marginRight: "8px" }}
                          >
                            Just Now
                          </span>
                          <BiWorld style={{ color: "gray" }} />
                        </div>
                      </div>
                    </div>
                    <p>...</p>
                  </div>
                </div> */}
                {/* <HashtagText text={textWithHastag} /> */}
                <HashtagText text={textWithHastag} />
                <HashtagText text={anotherOne} />
                <Box
                  sx={{
                    width: "100%",
                    // height: "200px",
                    backgroundColor: "#f0f0f0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: "2",
                  }}
                >
                  <img
                    style={{ height: "300px", width: "", marginBottom: "10px" }}
                    src={
                      "https://backend.whalesai.com/public/imageai/22102024-d33e5f.png"
                    }
                  />
                </Box>

                {/* <div
                  style={{ fontSize: "16px" }}
                  className="d-flex justify-content-around mt-3"
                >
                  <p>
                    {" "}
                    <AiOutlineLike
                      style={{ color: "gray", fontSize: "16px" }}
                    />{" "}
                    Like
                  </p>
                  <p>
                    {" "}
                    <FaRegComment
                      style={{ color: "gray", fontSize: "16px" }}
                    />{" "}
                    Comment
                  </p>
                  <p>
                    {" "}
                    <FaWhatsapp
                      style={{ color: "gray", fontSize: "16px" }}
                    />{" "}
                    Send
                  </p>
                  <p>
                    {" "}
                    <RiShareForwardLine
                      style={{ color: "gray", fontSize: "16px" }}
                    />{" "}
                    Share
                  </p>
                </div>

                <div className="d-flex align-items-center gap-2 mb-5">
                  <img
                    style={{ borderRadius: "50%", height: "30px" }}
                    src="https://whalesai.com/static/media/ds-logo.23cc3eac8e316a53022f.png"
                  />

                  <input
                    type="text"
                    className="rounded py-2 px-2"
                    placeholder="Comment as Whalesai"
                    style={{background: '#F1F2F6', width: '100%', border: 'none'}}
                  />

                  <div>

                  </div>
                </div> */}

                <button
                  //   onClick={() => {
                  //     setShowModal(true);
                  //   }}
                  className="rounded py-2"
                  style={{
                    background: "#25678E",
                    width: "100%",
                    color: "white",
                    border: "none",
                    fontWeight: "bold",
                    boxShadow: "revert",
                  }}
                >
                  POST
                </button>
              </Box>
            </div>
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
