export const voices = [
  {
    value: "en-AU-Neural2-A",
    label: "English-Australia-Female-Aurora",
    code: "f1",
  },
  {
    value: "en-AU-Neural2-B",
    label: "English-Australia-Male-Atlas",
    code: "m1",
  },
  {
    value: "en-AU-Neural2-C",
    label: "English-Australia-Female-Robonia",
    code: "f2",
  },
  {
    value: "en-AU-Neural2-D",
    label: "English-Australia-Male-Helios",
    code: "m2",
  },
  {
    value: "en-GB-Neural2-A",
    label: "English-UK-Female-Electra",
    code: "f3",
  },
  {
    value: "en-GB-Neural2-B",
    label: "English-UK-Male-Blaze",
    code: "m3",
  },
  {
    value: "en-GB-Neural2-C",
    label: "English-UK-Female-Xeferia",
    code: "f4",
  },
  {
    value: "en-GB-Neural2-D",
    label: "English-UK-Male-Odin",
    code: "m4",
  },
  {
    value: "en-US-Neural2-C",
    label: "English-US-Female-Asertina",
    code: "f5",
  },
  {
    value: "en-US-Neural2-A",
    label: "English-US-Male-Galen",
    code: "m5",
  },
  {
    value: "en-US-Neural2-E",
    label: "English-US-Female-Xylaloras",
    code: "f6",
  },
  {
    value: "en-US-Neural2-D",
    label: "English-US-Male-Zephyr",
    code: "m6",
  },
];
export const videoBgMusic = [
  {
    value: "Sample 1",
    label: "Sample 1",
    code: "Sample 1",
  },
  {
    value: "Sample 2",
    label: "Sample 2",
    code: "Sample 2",
  },
  {
    value: "Sample 3",
    label: "Sample 3",
    code: "Sample 3",
  },
];

export const myArray = [
  "Image processing in progress.",
  "Analyzing image data.",
  "Enhancing image quality.",
  "Adjusting brightness and contrast.",
  "Cropping image to desired size.",
  "Resizing image to fit desired dimensions.",
  "Adjusting color balance.",
  "Removing unwanted elements from image.",
  "Enhancing sharpness and clarity.",
  "Enhancing details in image.",
  "Adjusting levels and curves.",
  "Applying noise reduction techniques.",
  "Enhancing texture in image.",
  "Applying blur and sharpening techniques.",
  "Adjusting hue and saturation.",
  "Applying color correction techniques.",
  "Enhancing depth of field in image.",
  "Adjusting brightness and contrast.",
  "Enhancing shadows and highlights.",
  "Enhancing color and tone.",
  "Enhancing sharpness and clarity.",
  "Applying texture effects to image.",
  "Enhancing details in image.",
  "Applying depth of field effects to image.",
  "Applying color grading techniques.",
  "Enhancing color and tone.",
];

// export const imageArray=[
//   "A futuristic cityscape at sunset with flying cars and neon lights.",
//   "A dragon made of water emerging from the ocean.",
//   "A steampunk-inspired robot playing a violin.",
//   "A magical forest with glowing mushrooms and fairies.",
//   "A surreal landscape with melting clocks and floating islands.",
//   "A cozy cottage in a snowy winter wonderland.",
//   "A bustling market in an ancient Middle Eastern city.",
//   "A giant octopus battling a pirate ship in a stormy sea.",
//   "A retro 1950s diner with a jukebox and classic cars outside.",
//   "A majestic lion wearing a crown in a royal palace.",
//   "A futuristic spacecraft exploring an alien planet.",
//   "A serene Japanese garden with cherry blossoms in full bloom.",
//   "A fantasy castle perched on a floating island.",
//   "A robot and a human sharing a cup of tea in a cozy living room.",
//   "A whimsical underwater scene with colorful fish and mermaids.",
//   "A bustling cyberpunk city street at night.",
//   "A beautiful peacock spreading its feathers in a garden.",
//   "A time-traveling train passing through different historical eras.",
//   "A group of penguins having a snowball fight in Antarctica.",
//   "A superhero flying over a modern metropolis at dawn.",
//   "A mystical portal opening in the middle of a dense forest.",
//   "A vintage hot air balloon festival in a picturesque countryside.",
//   "A futuristic robot chef cooking in a high-tech kitchen.",
//   "A grand library with towering bookshelves and floating books.",
//   "A tropical island paradise with crystal clear waters and palm trees.",
//   "A medieval knight in shining armor standing before a dragon.",
//   "A bustling Victorian-era street market.",
//   "A colorful carnival with ferris wheels and cotton candy.",
//   "A magical mermaid lagoon at sunset.",
//   "A futuristic city with towering skyscrapers and green spaces.",
//   "A cozy cabin in the woods with a roaring fireplace.",
//   "A fantasy scene with a unicorn grazing in a meadow.",
//   "A space station orbiting a distant planet.",
//   "A modern art gallery with abstract paintings and sculptures.",
//   "A fairytale castle surrounded by enchanted forest.",
//   "A bustling New York City street in the 1920s.",
//   "A whimsical treehouse village in a giant tree.",
//   "A serene beach at sunrise with gentle waves.",
//   "A group of astronauts discovering an alien artifact on Mars.",
//   "A vibrant street festival with dancers and musicians.",
//   "A futuristic sports stadium with holographic displays.",
//   "A picturesque European village with cobblestone streets.",
//   "A dragon soaring over a medieval city.",
//   "A whimsical bakery with giant cakes and pastries.",
//   "A serene mountain landscape with a crystal clear lake.",
//   "A fantasy scene with a wizard casting a spell in a tower.",
//   "A futuristic underwater city with glowing buildings.",
//   "A group of children discovering a hidden garden.",
//   "A majestic eagle flying over a grand canyon.",
//   "A magical library with floating lanterns and ancient books.",
//   "A spaceship landing on a colorful alien planet.",
//   "A Victorian-era woman riding a penny-farthing bicycle.",
//   "A giant robot protecting a small village.",
//   "A surreal dreamscape with floating stairs and doors.",
//   "A medieval blacksmith forging a sword.",
//   "A futuristic city with eco-friendly architecture.",
//   "A cozy reading nook with a window overlooking the sea.",
//   "A time-traveler meeting historical figures in a cafe.",
//   "A fantasy dragon nest with colorful eggs.",
//   "A bustling bazaar in a futuristic space colony.",
//   "A grand ballroom filled with elegantly dressed dancers.",
//   "A mystical phoenix rising from its ashes.",
//   "A retro-futuristic living room with vintage gadgets.",
//   "A magical garden with a giant talking tree.",
//   "A serene river flowing through a dense forest.",
//   "A whimsical scene with anthropomorphic animals having a picnic.",
//   "A majestic castle with a moat and drawbridge.",
//   "A steampunk airship flying over a foggy city.",
//   "A tranquil zen garden with raked sand and stone lanterns.",
//   "A colorful underwater coral reef teeming with life.",
//   "A grand cathedral with intricate stained glass windows.",
//   "A group of adventurers exploring a hidden temple.",
//   "A futuristic train speeding through a neon-lit city.",
//   "A cozy library with floor-to-ceiling bookshelves.",
//   "A magical landscape with floating islands and waterfalls.",
//   "A bustling medieval marketplace with merchants and traders.",
//   "A serene lakeside cabin with mountains in the background.",
//   "A futuristic robot assistant helping in a home.",
//   "A grand palace with golden domes and towers.",
//   "A fantasy forest with giant mushrooms and glowing plants.",
//   "A retro 1980s arcade with colorful neon lights.",
//   "A bustling street market in a futuristic Asian city.",
//   "A magical ice castle in a snowy landscape.",
//   "A group of explorers discovering a lost city.",
//   "A futuristic city with flying trains and green rooftops.",
//   "A cozy cafe with vintage decor and pastries.",
//   "A mystical creature emerging from a magical portal.",
//   "A grand library with an ornate ceiling and chandeliers.",
//   "A serene beach with bioluminescent waves at night.",
//   "A futuristic robot performing surgery in a high-tech hospital.",
//   "A whimsical treehouse with multiple levels and rope bridges.",
//   "A vibrant cityscape with street artists and performers.",
//   "A fantasy landscape with a giant crystal tower.",
//   "A bustling port city with ships and markets.",
//   "A serene garden with koi fish ponds and bridges.",
//   "A futuristic laboratory with advanced technology.",
//   "A magical forest with a hidden fairy village.",
//   "A retro diner with a classic car parked outside.",
//   "A majestic mountain range with snow-capped peaks.",
//   "A whimsical circus with acrobats and clowns.",
//   "A bustling steampunk city with airships and gears.",
//   "A serene meadow with wildflowers and butterflies.",
//   "A futuristic city with hoverboards and drones.",
//   "A cozy cabin by a lake with autumn foliage.",
//   "A fantasy castle with dragons perched on the towers.",
//   "A bustling market in a futuristic floating city.",
//   "A magical library with books that come to life.",
//   "A serene beach with a lighthouse in the distance.",
//   "A futuristic robot playing chess with a human.",
//   "A grand palace with marble floors and golden statues.",
//   "A whimsical garden with oversized flowers and insects.",
//   "A bustling street market in a historic European city.",
//   "A serene forest with a babbling brook and deer.",
//   "A futuristic city with holographic advertisements.",
//   "A cozy bookshop with shelves filled with old books.",
//   "A fantasy landscape with a giant treehouse village.",
//   "A bustling market in an ancient Chinese city.",
//   "A serene garden with a waterfall and koi pond.",
//   "A futuristic spaceport with ships taking off.",
//   "A whimsical bakery with pastries shaped like animals.",
//   "A grand library with a spiral staircase and chandeliers.",
//   "A serene beach with a hammock and palm trees.",
//   "A futuristic robot butler serving drinks.",
//   "A majestic castle on a hill overlooking a village.",
//   "A bustling marketplace in a futuristic desert city.",
//   "A magical forest with glowing flowers and creatures.",
//   "A retro diner with a jukebox and classic car outside.",
//   "A cozy cottage in a snowy landscape with a chimney smoke.",
//   "A fantasy dragon nest with colorful eggs and jewels.",
//   "A bustling bazaar in a futuristic space station.",
//   "A grand ballroom with elegantly dressed guests dancing.",
//   "A mystical phoenix rising from the flames.",
//   "A retro-futuristic living room with vintage technology.",
//   "A magical garden with a giant talking tree.",
//   "A serene river with a wooden bridge and lanterns.",
//   "A whimsical picnic with anthropomorphic animals.",
//   "A majestic castle with a moat and drawbridge.",
//   "A steampunk airship flying through the clouds.",
//   "A tranquil zen garden with stone paths and bamboo.",
//   "A colorful coral reef with tropical fish and sea creatures.",
//   "A grand cathedral with ornate architecture and stained glass.",
//   "A group of adventurers exploring an ancient ruin.",
//   "A futuristic train traveling through a neon cityscape.",
//   "A cozy library with a fireplace and armchairs.",
//   "A magical landscape with floating islands and crystals.",
//   "A bustling medieval marketplace with vendors and shoppers.",
//   "A serene lakeside cabin surrounded by trees.",
//   "A futuristic robot assistant helping in the kitchen.",
//   "A grand palace with intricate carvings and fountains.",
//   "A fantasy forest with oversized mushrooms and fairies.",
//   "A retro 1980s arcade with flashing lights and games.",
//   "A bustling street market in a futuristic Asian metropolis.",
//   "A magical ice palace in a frozen landscape.",
//   "A group of explorers discovering a hidden treasure.",
//   "A futuristic city with flying taxis and green buildings.",
//   "A cozy cafe with vintage decor and warm lighting.",
//   "A mystical creature emerging from a shimmering portal.",
//   "A grand library with vaulted ceilings and ancient tomes.",
//   "A serene beach at night with glowing waves.",
//   "A futuristic robot performing a surgical procedure.",
//   "A whimsical treehouse with ladders and bridges.",
//   "A vibrant city street with artists and musicians.",
//   "A fantasy landscape with a crystal castle.",
//   "A bustling port city with docks and ships.",
//   "A serene garden with ponds and flowers.",
//   "A futuristic laboratory with advanced equipment.",
//   "A magical forest with a fairy village.",
//   "A retro diner with neon signs and classic cars.",
//   "A majestic mountain range with a clear blue sky.",
//   "A whimsical circus with clowns and acrobats.",
//   "A bustling steampunk city with gears and steam.",
//   "A serene meadow with wildflowers and animals.",
//   "A futuristic city with drones and hoverboards.",
//   "A cozy cabin by a lake in autumn.",
//   "A fantasy castle with dragons and knights.",
//   "A bustling market in a floating city.",
//   "A magical library with living books.",
//   "A serene beach with a lighthouse and waves.",
//   "A futuristic robot playing a musical instrument.",
//   "A grand palace with marble halls and gold accents.",
//   "A whimsical garden with large flowers and insects.",
//   "A bustling street market in a historical city.",
//   "A serene forest with a river and wildlife.",
//   "A futuristic city with holographic billboards.",
//   "A cozy bookstore with old and new books.",
//   "A fantasy landscape with a giant treehouse.",
//   "A bustling market in an ancient civilization.",
//   "A serene garden with a koi pond and flowers.",
//   "A futuristic spaceport with spaceships and aliens.",
//   "A whimsical bakery with creative pastries.",
//   "A grand library with spiral staircases and chandeliers.",
//   "A serene beach with palm trees and clear water.",
//   "A futuristic robot helping with daily tasks.",
//   "A majestic castle on a cliff overlooking the sea.",
//   "A bustling market in a futuristic desert oasis.",
//   "A magical forest with glowing plants and animals.",
//   "A retro diner with vintage decor and a jukebox."
// ];



export const imageArray = [
  "A bustling medieval marketplace with vendors selling various goods, including fruits, fabrics, and pottery, under colorful tents, with a stone castle in the background and villagers in period attire.",
  "An underwater scene featuring a vibrant coral reef teeming with diverse marine life such as colorful fish, sea turtles, and an octopus, with rays of sunlight penetrating the water's surface.",
  "A futuristic cityscape at night with towering skyscrapers, flying cars, neon lights, and holographic advertisements, showcasing advanced technology and a lively urban atmosphere.",
  "An enchanted forest with giant, glowing mushrooms, magical creatures like fairies and unicorns, and a sparkling river flowing through, all under a twilight sky with a full moon.",
  "A serene Japanese garden in autumn with red maple leaves, a traditional tea house, a stone lantern, and a koi pond with fish, all surrounded by carefully pruned bonsai trees.",
  "A cozy cabin interior in the mountains, with a roaring fireplace, wooden furniture, a fluffy rug, and large windows showcasing a snowy landscape outside.",
  "A historical scene depicting an ancient Roman forum with marble statues, columns, people in togas, and bustling activity, all under a clear blue sky.",
  "A fantasy dragon soaring over a medieval kingdom, breathing fire, with castles, knights in armor, and lush green landscapes below.",
  "A vibrant street festival in Rio de Janeiro with colorful costumes, samba dancers, large parade floats, confetti, and joyous crowds celebrating.",
  "A peaceful beach at sunset with golden sands, gentle waves, palm trees, a hammock, and a couple walking hand in hand along the shore.",
  "A serene, snow-covered village during Christmas time, with decorated houses, children building a snowman, and a horse-drawn sleigh in the background.",
  "A mystical mountain landscape with jagged peaks, a flowing waterfall, and an ancient temple built into the cliffside, shrouded in mist.",
  "A lively carnival scene with a Ferris wheel, colorful tents, food stalls, and people enjoying various rides and games.",
  "An old-fashioned library with tall bookshelves, ladders, leather-bound books, and a cozy reading nook with a vintage lamp.",
  "A sci-fi laboratory with futuristic equipment, scientists in high-tech suits, and holographic displays showing complex data.",
  "A tranquil lakeside scene at dawn with a wooden pier, a small rowboat, a fisherman casting a line, and mist rising from the water.",
  "A bustling New York City street in the 1920s, with classic cars, men in suits and hats, women in flapper dresses, and Art Deco buildings.",
  "A dramatic pirate ship battle on the high seas, with cannons firing, tattered sails, and pirates swinging between ships with swords drawn.",
  "A beautiful, sprawling vineyard in Tuscany, with rows of grapevines, a rustic farmhouse, and workers harvesting grapes under a clear blue sky.",
  "An enchanting fairy tale castle on a hill, surrounded by lush gardens, a sparkling moat, and a drawbridge leading to the entrance.",
  "A detailed, close-up view of an intricate clockwork mechanism, with gears, springs, and tiny moving parts all meticulously crafted.",
  "An ancient Egyptian tomb interior, with hieroglyphics on the walls, a sarcophagus, and treasures such as gold, jewels, and artifacts scattered around.",
  "A whimsical treehouse village in a dense forest, with wooden bridges connecting the houses, lanterns hanging from branches, and children playing.",
  "A futuristic space station orbiting Earth, with astronauts floating outside, advanced technology, and a stunning view of the planet below.",
  "A vibrant underwater city inhabited by mermaids, with coral buildings, seaweed gardens, and a variety of marine life swimming around.",
  "A cozy, rustic kitchen with a wood-burning stove, hanging herbs, fresh vegetables on the counter, and a window with a view of a garden.",
  "A grand ballroom from the Victorian era, with chandeliers, elegantly dressed couples dancing, and musicians playing classical instruments.",
  "A dramatic scene of a volcano erupting, with flowing lava, ash clouds, and lightning in the sky, while people in the foreground watch from a safe distance.",
  "A serene Zen garden with raked sand patterns, carefully placed rocks, a small pond, and a bonsai tree, all exuding a sense of peace and tranquility.",
  "A bustling farmers' market with stalls selling fresh produce, flowers, baked goods, and handmade crafts, with people browsing and chatting.",
  "A snowy mountain landscape with a group of adventurers climbing, carrying ice picks and ropes, against a backdrop of majestic peaks and a clear blue sky.",
  "A colorful autumn forest with leaves in shades of red, orange, and yellow, a winding path, and a couple walking hand in hand.",
  "An alien planet with strange vegetation, colorful skies, unusual rock formations, and an astronaut exploring the terrain.",
  "A detailed, steampunk-inspired city with Victorian architecture, airships floating in the sky, and people in eclectic, retro-futuristic attire.",
  "A tranquil beach scene with a hammock strung between two palm trees, gentle waves lapping the shore, and a vibrant sunset in the background.",
  "An intricate, ancient Chinese temple with dragon statues, ornate carvings, and monks performing a ritual ceremony.",
  "A lively jazz club from the 1940s with a live band playing on stage, people dancing, and a smoky, intimate atmosphere.",
  "A futuristic underwater research facility with large windows looking out into the ocean, scientists conducting experiments, and advanced technology.",
  "A majestic, medieval cathedral interior with soaring arches, stained glass windows, and a choir singing.",
  "A cozy winter cabin surrounded by snow-covered pine trees, with smoke rising from the chimney and a family inside enjoying a warm fire.",
  "An elaborate masquerade ball with guests wearing ornate masks and costumes, dancing in a grand hall with chandeliers and elegant decorations.",
  "A scenic view of the Grand Canyon at sunset, with deep orange and red hues, towering rock formations, and a winding river below.",
  "A bustling Parisian café on a cobblestone street, with people sitting at outdoor tables, waiters serving coffee, and the Eiffel Tower in the background.",
  "A serene tropical island with white sandy beaches, crystal-clear waters, palm trees, and colorful beach huts.",
  "A detailed botanical illustration of various exotic flowers and plants, with intricate details and vibrant colors.",
  "A peaceful countryside scene with rolling hills, a quaint farmhouse, grazing sheep, and a winding dirt road.",
  "A futuristic metropolis at night with towering skyscrapers, neon lights, flying vehicles, and a bustling crowd of diverse characters.",
  "An ancient Greek amphitheater with actors performing a play, an audience watching attentively, and a backdrop of ruins and olive trees.",
  "A cozy reading nook by a large window, with a comfortable chair, a stack of books, a soft blanket, and a view of a rainy day outside.",
  "A vibrant Moroccan market with stalls selling spices, textiles, lanterns, and ceramics, with people browsing and bartering.",

  "A majestic lion standing on a rock in the African savannah, with a golden sunset, acacia trees, and a herd of wildebeest in the background.",
  "A whimsical fantasy forest with bioluminescent plants, magical creatures like elves and pixies, and a sparkling river running through it.",
  "An old-fashioned blacksmith's workshop with a forge, anvil, tools, and the blacksmith working on a piece of glowing metal.",
  "A serene Scandinavian landscape with fjords, pine forests, a traditional red cabin, and the northern lights in the sky.",
  "A bustling Tokyo street at night with bright neon signs, busy pedestrians, street food vendors, and towering buildings.",
  "An underwater shipwreck with coral growing on the hull, schools of fish swimming around, and divers exploring the scene.",
  "A peaceful sunset over a desert landscape with sand dunes, a camel caravan, and an oasis in the distance.",
  "A vibrant Indian festival with people in colorful attire, dancing, playing instruments, and throwing colored powder in celebration.",
  "A cozy coffee shop interior with comfortable seating, bookshelves, people working on laptops, and a barista preparing drinks.",
  "A dramatic castle on a cliff overlooking a stormy sea, with lightning striking in the background and waves crashing against the rocks.",
  "A detailed anatomical illustration of the human heart, showing veins, arteries, and chambers, with labels and descriptions.",
  "A serene alpine lake with crystal-clear water, surrounded by snow-capped mountains, pine forests, and a wooden cabin by the shore.",
  "A lively street performance in a busy city square with musicians, dancers, and a crowd of onlookers enjoying the show.",
  "A historical Viking village with wooden longhouses, warriors practicing with weapons, and a harbor with longships.",
  "A colorful underwater coral reef teeming with life, including fish, sea turtles, and anemones, with sunlight filtering through the water.",
  "A quaint English countryside village with thatched-roof cottages, a stone church, and a bustling market square.",
  "A majestic dragon perched on a mountain peak, with wings spread wide, scales shimmering, and a panoramic view of the kingdom below.",
  "A futuristic laboratory with scientists conducting experiments, advanced robotic equipment, and holographic data displays.",
  "A serene autumn park with a path covered in fallen leaves, a couple sitting on a bench, and trees in vibrant shades of red, orange, and yellow.",
  "A dramatic stormy sea with towering waves, a lighthouse standing tall on a rocky shore, and a ship struggling against the elements.",
  "An ancient Mayan temple in the jungle, partially overgrown with vines, with a group of explorers discovering its hidden treasures.",
  "A lively Renaissance fair with people in period costumes, jousting tournaments, market stalls, and entertainers performing.",
  "A serene lakeside campground with tents, a campfire, a family roasting marshmallows, and a calm lake reflecting the starry night sky.",
  "A futuristic space colony on Mars, with domed habitats, rovers, and astronauts working on the red, rocky surface.",
  "A detailed map of a fantasy world with continents, oceans, mountain ranges, forests, and cities, all intricately drawn.",
  "A busy New York City street in winter, with people bundled up in coats, snow falling, holiday decorations, and ice skaters in a nearby park.",
  "A whimsical steampunk airship flying through the clouds, with brass gears, large propellers, and passengers looking out over the horizon.",
  "A serene beach scene at sunrise with a lighthouse on a rocky outcrop, waves gently crashing, and seagulls flying overhead.",
  "A detailed cross-section of a medieval castle showing various rooms, including the throne room, dungeon, armory, and banquet hall.",
  "A bustling Italian piazza with people enjoying gelato, children playing, a fountain in the center, and historic buildings surrounding the square.",
  "An eerie haunted house on a hill with overgrown weeds, broken windows, ghostly figures, and a full moon casting an ominous glow.",
  "A cozy attic study with an old wooden desk, stacks of books, a typewriter, and a window offering a view of the city rooftops.",
  "A vibrant Caribbean carnival with people in colorful costumes, steel drum bands, and a parade of floats celebrating in the streets.",
  "A futuristic drone delivery service in a modern city with drones flying between skyscrapers, delivering packages to various locations.",
  "An ancient samurai training ground with warriors practicing sword techniques, a traditional dojo, and cherry blossom trees in bloom.",
  "A serene desert oasis with a clear blue pond, date palms, a small tent, and a camel resting nearby.",
  "A lively medieval fair with jesters, knights in armor, market stalls selling crafts, and villagers enjoying games and performances.",
  "A dramatic iceberg scene in the Arctic with a polar bear and her cubs, seals on the ice, and an aurora borealis lighting up the sky.",
  "A whimsical fairy village with tiny houses built into tree trunks, fairies flying around, and a sparkling stream running through the village.",
  "A futuristic city park with robotic gardeners, children playing with high-tech toys, and people relaxing on futuristic benches.",
  "An intricate stained glass window depicting a religious scene, with vibrant colors and detailed figures illuminated by sunlight.",
  "A bustling Victorian-era street with horse-drawn carriages, people in period attire, street vendors, and gas lamps lighting the way.",
  "A serene winter landscape with a frozen lake, ice skaters gliding across the surface, and snow-covered pine trees in the background.",
  "A dramatic shipwreck scene on a rocky shore, with the remains of an old ship, waves crashing, and seabirds flying overhead.",
  "A cozy bookshop interior with tall shelves filled with books, comfortable reading chairs, and a cat sleeping on the counter.",
  "A futuristic train station with sleek, high-speed trains, passengers boarding, and holographic displays showing schedules and destinations.",
  "A whimsical candy land with gingerbread houses, candy cane trees, a chocolate river, and children exploring the sweet landscape.",
  "A serene sunrise over a rice terrace in Bali, with lush green fields, traditional Balinese houses, and farmers working in the fields.",
  "A vibrant street market in Bangkok with stalls selling exotic fruits, spices, street food, and colorful fabrics, bustling with activity.",
  "A dramatic lightning storm over a vast desert landscape with dark clouds, lightning bolts illuminating the sky, and a lone cactus standing tall.",

  "An ancient Roman bathhouse with elaborate mosaics, columns, a large central pool, and people relaxing in the thermal waters.",
  "A serene mountain lake with a wooden dock, a rowboat tied to it, and a backdrop of snow-capped peaks and dense pine forests.",
  "A bustling fish market in a coastal town with fishermen unloading their catch, stalls selling fresh seafood, and seagulls flying overhead.",
  "A futuristic floating city with interconnected platforms, advanced architecture, flying vehicles, and people moving about their daily lives.",
  "A detailed cross-section of an ancient Egyptian pyramid showing burial chambers, hidden passageways, and archaeologists exploring.",
  "A cozy living room decorated for Christmas with a decorated tree, stockings hung by the fireplace, presents, and a family gathered together.",
  "A vibrant underwater scene with a shipwreck covered in coral, schools of fish swimming around, and a diver exploring the wreckage.",
  "A serene lavender field in Provence, France, with rows of purple flowers, a farmhouse in the distance, and a clear blue sky.",
  "A bustling street in New Orleans during Mardi Gras with people in colorful costumes, jazz bands playing, and festive decorations.",
  "A dramatic mountain cliff with a winding path, climbers ascending, and a breathtaking view of the valley below.",
  "A vibrant rainforest scene with exotic birds, monkeys swinging through the trees, colorful flowers, and a waterfall in the background.",
  "A detailed isometric view of a modern apartment building, showing various rooms and activities inside, with a rooftop garden.",
  "A lively seaside boardwalk with amusement rides, food stalls, people enjoying the beach, and a beautiful sunset over the ocean.",
  "An ancient library with towering bookshelves, ladders, a grand staircase, and scholars studying ancient texts.",
  "A bustling spaceport with various spacecraft, astronauts preparing for missions, and futuristic technology all around.",
  "A serene meadow with wildflowers, a small stream, butterflies fluttering, and a couple having a picnic under a large oak tree.",
  "A dramatic cityscape of Hong Kong at night, with illuminated skyscrapers, boats in the harbor, and a vibrant city life.",
  "A cozy bakery with fresh bread and pastries on display, a baker working behind the counter, and the warm glow of the oven.",
  "A futuristic medical facility with advanced robotic surgery, doctors in high-tech uniforms, and patients being treated with cutting-edge equipment.",
  "A whimsical enchanted garden with oversized flowers, magical creatures, a crystal-clear pond, and a fairy sitting on a mushroom.",
  "A detailed cross-section of a pirate ship showing various decks, crew members at work, and treasure chests in the hold.",
  "A serene mountain valley with a river running through it, wildflowers in bloom, and a small log cabin nestled among the trees.",
  "A bustling farmers' market in a small European village with stalls selling fresh produce, cheeses, baked goods, and handmade crafts.",
  "A dramatic sci-fi battle scene in space with starships, laser beams, explosions, and a distant planet in the background.",
  "A cozy cabin in the woods with smoke rising from the chimney, a stack of firewood, and a snowy landscape all around.",
  "A vibrant Indian wedding ceremony with colorful decorations, traditional attire, dancers, and a joyful atmosphere.",
  "A serene Japanese tea garden with a koi pond, stone lanterns, a tea house, and cherry blossom trees in full bloom.",
  "A futuristic cityscape with vertical gardens, eco-friendly buildings, flying cars, and people walking along elevated walkways.",
  "A whimsical woodland scene with tiny gnome houses, forest animals, mushrooms, and a fairy tale atmosphere.",
  "A bustling fish market in a coastal town with fishermen unloading their catch, stalls selling fresh seafood, and seagulls flying overhead.",
  "A dramatic cliffside lighthouse with waves crashing against the rocks, a beam of light cutting through the fog, and stormy skies above.",
  "A cozy café in Paris with outdoor seating, people enjoying coffee and pastries, and the Eiffel Tower in the distance.",
  "A futuristic city park with robotic gardeners, children playing with high-tech toys, and people relaxing on futuristic benches.",
  "A serene Zen garden with carefully raked sand, strategically placed rocks, a small pond, and a bonsai tree.",
  "A lively medieval fair with jesters, knights in armor, market stalls selling crafts, and villagers enjoying games and performances.",
  "A majestic dragon perched on a mountain peak, with wings spread wide, scales shimmering, and a panoramic view of the kingdom below.",
  "A bustling New York City street in the 1920s, with classic cars, men in suits and hats, women in flapper dresses, and Art Deco buildings.",
  "A whimsical candy land with gingerbread houses, candy cane trees, a chocolate river, and children exploring the sweet landscape.",
  "A serene mountain lake with crystal-clear water, surrounded by snow-capped mountains, pine forests, and a wooden cabin by the shore.",
  "A dramatic desert landscape with towering sand dunes, a caravan of camels, and a vibrant sunset casting long shadows.",
  "A bustling Moroccan market with stalls selling spices, textiles, lanterns, and ceramics, with people browsing and bartering.",
  "A cozy winter cabin surrounded by snow-covered pine trees, with smoke rising from the chimney and a family inside enjoying a warm fire.",
  "A vibrant street festival with colorful decorations, musicians playing, dancers performing, and people celebrating in the streets.",
  "An ancient Egyptian tomb interior, with hieroglyphics on the walls, a sarcophagus, and treasures such as gold, jewels, and artifacts scattered around.",
  "A detailed cutaway view of a submarine showing different compartments, crew members at work, and the underwater environment outside.",
  "A serene vineyard in Napa Valley with rows of grapevines, a rustic farmhouse, and workers harvesting grapes under a sunny sky.",
  "A bustling street scene in Havana, Cuba, with colorful colonial buildings, classic cars, street musicians, and people going about their day.",
  "A futuristic hovercar race through a neon-lit cityscape, with sleek vehicles speeding along elevated tracks and spectators watching from platforms.",
  "A cozy log cabin interior with a roaring fireplace, wooden furniture, a bear rug, and large windows offering a view of a snowy forest.",
  "A dramatic scene of a tornado approaching a small Midwestern town, with dark storm clouds, lightning, and debris being lifted into the air.",

  "A lively Oktoberfest celebration in Germany with people in traditional Bavarian attire, beer tents, pretzels, and folk music.",
  "A whimsical underwater kingdom with mermaids, sea creatures, coral palaces, and treasure chests scattered on the ocean floor.",
  "A bustling Indian street market with stalls selling spices, textiles, street food, and colorful decorations, with people shopping and bartering.",
  "A serene mountain cabin at dawn with a view of a misty valley, pine trees, and a river flowing nearby.",
  "A vibrant Chinese New Year parade with dragon and lion dances, colorful lanterns, firecrackers, and people in traditional attire.",
  "A detailed illustration of a medieval blacksmith's forge with tools, an anvil, a forge with glowing coals, and the blacksmith at work.",
  "A futuristic space elevator extending from a base on Earth into orbit, with spacecraft docking and people traveling up and down.",
  "A serene coastal village in Greece with whitewashed buildings, blue-domed churches, narrow cobblestone streets, and a view of the Aegean Sea.",
  "A dramatic scene of a knight in shining armor battling a fire-breathing dragon in a dark, enchanted forest.",
  "A bustling Christmas market in a European town with wooden stalls selling holiday treats, handmade gifts, twinkling lights, and people enjoying the festive atmosphere.",
  "A serene mountain lake with a wooden pier, a small rowboat, a fisherman casting a line, and mist rising from the water.",
  "A futuristic underwater research facility with large windows looking out into the ocean, scientists conducting experiments, and advanced technology.",
  "A detailed illustration of an ancient Roman gladiatorial arena with spectators, gladiators fighting, and the emperor watching from his throne.",
  "A whimsical fairy tale castle on a hill, surrounded by lush gardens, a sparkling moat, and a drawbridge leading to the entrance.",
  "A bustling market in Marrakech with stalls selling spices, rugs, lanterns, and handmade crafts, with people browsing and bartering.",
  "A serene beach scene at sunset with golden sands, gentle waves, palm trees, a hammock, and a couple walking hand in hand along the shore.",
  "A vibrant street festival in Rio de Janeiro with colorful costumes, samba dancers, large parade floats, confetti, and joyous crowds celebrating.",
  "A peaceful sunrise over a rice terrace in Bali, with lush green fields, traditional Balinese houses, and farmers working in the fields.",
  "A dramatic volcanic eruption with flowing lava, ash clouds, and lightning in the sky, while people in the foreground watch from a safe distance.",
  "A cozy bookshop interior with tall shelves filled with books, comfortable reading chairs, and a cat sleeping on the counter.",
  "A bustling street in New Orleans during Mardi Gras with people in colorful costumes, jazz bands playing, and festive decorations.",
  "A serene lavender field in Provence, France, with rows of purple flowers, a farmhouse in the distance, and a clear blue sky.",
  "A dramatic mountain landscape with jagged peaks, a flowing waterfall, and an ancient temple built into the cliffside, shrouded in mist.",
  "A futuristic metropolis at night with towering skyscrapers, flying cars, neon lights, and holographic advertisements, showcasing advanced technology and a lively urban atmosphere.",
  "A serene alpine village in winter with snow-covered rooftops, a frozen lake, ice skaters, and a backdrop of towering mountains.",
  "A bustling bazaar in Istanbul with stalls selling spices, carpets, ceramics, and lamps, with people shopping and bartering.",
  "A dramatic pirate ship battle on the high seas, with cannons firing, tattered sails, and pirates swinging between ships with swords drawn.",
  "A cozy reading nook in a treehouse with a comfortable chair, a stack of books, a soft blanket, and a view of a forest.",
  "A futuristic city skyline at dawn with sleek skyscrapers, flying vehicles, green spaces, and people going about their daily routines.",
  "A serene Japanese garden with a stone bridge over a koi pond, a traditional tea house, and cherry blossom trees in full bloom.",
  "A lively street performance in a busy city square with musicians, dancers, and a crowd of onlookers enjoying the show.",
  "A whimsical fantasy village with houses made from giant mushrooms, cobblestone streets, and magical creatures like fairies and gnomes.",
  "A bustling market in a coastal town with fishermen unloading their catch, stalls selling fresh seafood, and seagulls flying overhead.",
  "A dramatic cliffside castle overlooking a stormy sea, with waves crashing against the rocks and dark clouds gathering in the sky.",
  "A vibrant street market in Mexico with stalls selling fresh produce, handmade crafts, street food, and colorful decorations.",
  "A serene mountain cabin in autumn with colorful leaves, a small river flowing nearby, and smoke rising from the chimney.",
  "A futuristic research lab with advanced robotic equipment, scientists conducting experiments, and holographic data displays.",
  "A bustling street in Tokyo at night with bright neon signs, busy pedestrians, street food vendors, and towering buildings.",
  "A cozy living room decorated for Halloween with carved pumpkins, spooky decorations, a fireplace, and children in costumes.",
  "A dramatic scene of a spaceship landing on an alien planet, with strange vegetation, alien creatures, and astronauts exploring the terrain.",
  "A vibrant Indian street scene with people in colorful attire, rickshaws, market stalls, and the hustle and bustle of daily life.",
  "A serene lake at sunrise with mist rising from the water, a wooden dock, a small rowboat, and a backdrop of mountains and trees.",
  "A whimsical candy land with gingerbread houses, candy cane trees, a chocolate river, and children exploring the sweet landscape.",
  "A bustling street in New York City in the 1950s, with classic cars, people in period attire, street vendors, and iconic buildings.",
  "A dramatic underwater scene with a sunken ship, colorful coral, schools of fish, and a diver exploring the wreckage.",
  "A serene winter landscape with a frozen lake, snow-covered pine trees, a wooden cabin, and the northern lights in the sky.",
  "A lively Oktoberfest celebration with people in traditional Bavarian attire, beer tents, pretzels, and folk music.",
  "A whimsical treehouse village with wooden bridges connecting the houses, lanterns hanging from branches, and children playing.",
  "A bustling farmers' market with stalls selling fresh produce, flowers, baked goods, and handmade crafts, with people browsing and chatting.",

  "A serene Japanese tea garden with a koi pond, stone lanterns, a tea house, and cherry blossom trees in full bloom.",
  "A bustling street market in Bangkok with stalls selling exotic fruits, spices, street food, and colorful fabrics, bustling with activity.",
  "A cozy cabin in the woods during fall, with golden leaves, a crackling fireplace, and the smell of pine.",
  "A futuristic cityscape at night with glowing neon lights, flying cars, and holographic advertisements.",
  "A serene mountain lake reflecting the snow-capped peaks and a lone canoe drifting peacefully.",
  "A bustling bazaar in Marrakech with vendors selling spices, rugs, and handmade crafts under colorful awnings.",
  "A whimsical underwater kingdom with mermaids, seahorses, and colorful coral reefs.",
  "A dramatic thunderstorm over a vast, open plain, with lightning illuminating the darkened sky.",
  "A serene vineyard in Tuscany with rows of grapevines, a rustic villa, and rolling hills in the background.",
  "A bustling street in Tokyo's Shibuya district with crowds of people, giant screens, and neon lights.",
  "A cozy log cabin covered in snow, smoke rising from the chimney, and a family gathered around a warm fire.",
  "A vibrant Indian wedding with colorful attire, intricate henna designs, and joyful celebrations.",
  "A serene beach at sunset with palm trees, gentle waves, and a couple walking hand in hand along the shore.",
  "A bustling night market in Hong Kong with street food stalls, bright signs, and bustling crowds.",
  "A whimsical fairy tale castle on a hill, surrounded by lush gardens and a moat filled with swans.",
  "A dramatic cliffside castle overlooking a stormy sea, with crashing waves and dark clouds overhead.",
  "A cozy English cottage garden with blooming roses, a stone path, and a wooden bench under a willow tree.",
  "A bustling souk in Dubai with merchants selling gold, spices, and colorful textiles.",
  "A serene mountain peak at sunrise, with mist swirling around jagged peaks and a sense of quiet awe.",
  "A bustling Chinatown street with lanterns, bustling markets, and the aroma of dumplings in the air.",
  "A futuristic laboratory with glowing screens, robotic arms, and scientists conducting experiments.",
  "A serene autumn forest with sunlight filtering through colorful leaves and a carpet of fallen foliage.",
  "A bustling carnival midway with bright lights, spinning rides, and the sound of laughter and excitement.",
  "A cozy countryside inn with a thatched roof, flower-filled gardens, and a welcoming hearth inside.",
  "A bustling market in Istanbul with vendors selling spices, carpets, and handmade ceramics.",
  "A serene mountain stream cascading over rocks, surrounded by towering pine trees and wildflowers.",
  "A vibrant Mexican fiesta with dancers in colorful costumes, mariachi bands, and piñatas.",
  "A dramatic scene of a space battle with starships firing lasers amidst swirling nebulae and distant planets.",
  "A serene bamboo forest in Kyoto, with sunlight filtering through tall stalks and a peaceful atmosphere.",
  "A bustling city square in Rome with historic architecture, outdoor cafes, and bustling tourists.",
  "A whimsical steampunk cityscape with airships, clockwork towers, and steam-powered contraptions.",
  "A serene lake house with a wooden dock, kayaks, and a hammock under tall trees.",
  "A bustling street market in Cairo with vendors selling spices, textiles, and handmade crafts.",
  "A cozy winter cabin surrounded by snowy mountains, with a crackling fire and hot cocoa.",
  "A vibrant Indian street scene with colorful sarees, bustling rickshaws, and street vendors selling samosas.",
  "A serene Japanese onsen with steaming hot springs, wooden bathhouses, and snow falling gently.",
  "A bustling Christmas market in Vienna with wooden stalls, twinkling lights, and the scent of mulled wine.",
  "A whimsical fairy tale village with thatched cottages, winding cobblestone streets, and friendly villagers.",
  "A dramatic scene of a medieval battle with knights in armor, charging horses, and banners fluttering in the wind.",
  "A serene cherry blossom grove in springtime, with pink petals drifting on a gentle breeze.",
  "A bustling harbor in Sydney with sailboats, the Opera House in the background, and seagulls circling overhead.",
  "A cozy cabin in the Canadian Rockies, with a roaring fire, snow falling outside, and hot apple cider.",
  "A vibrant street market in Mexico City with mariachi bands, taco stands, and colorful papel picado banners.",
  "A serene alpine meadow with wildflowers, a crystal-clear stream, and snow-capped peaks in the distance.",
  "A bustling street in Seoul's Gangnam district with skyscrapers, bustling traffic, and trendy boutiques.",
  "A futuristic city on Mars with domed habitats, solar panels, and a red desert landscape beyond.",
  "A serene beach at sunrise, with pastel-colored skies, seashells scattered on the sand, and gentle waves lapping.",
  "A bustling carnival parade with elaborate floats, costumed performers, and confetti filling the air.",
  "A cozy mountain lodge with a roaring fireplace, plaid armchairs, and views of snow-covered peaks.",
  "A vibrant street festival in New Orleans with jazz bands, colorful floats, and people dancing in the streets.",
  "A serene waterfall hidden in a lush tropical jungle, with mist rising and exotic birds singing.",
  "A bustling market in Bangkok with vendors selling spicy street food, colorful silk scarves, and carved wooden elephants.",
  "A cozy English pub with wooden beams, pints of ale, and a lively game of darts in progress.",

  "A vibrant street market in Bangkok with stalls selling exotic fruits, spices, street food, and colorful fabrics, bustling with activity.",
  "A serene mountain cabin in autumn with colorful leaves, a small river flowing nearby, and smoke rising from the chimney.",
  "A futuristic space station orbiting Earth, with astronauts floating outside, advanced technology, and a stunning view of the planet below.",
  "A bustling New York City street in winter, with people bundled up in coats, snow falling, holiday decorations, and ice skaters in a nearby park.",
  "A whimsical candy land with gingerbread houses, candy cane trees, a chocolate river, and children exploring the sweet landscape.",
  "A peaceful sunset over a desert landscape with sand dunes, a camel caravan, and an oasis in the distance.",
  "A cozy café in Paris with outdoor seating, people enjoying coffee and pastries, and the Eiffel Tower in the distance.",
  "A dramatic scene of a tornado approaching a small Midwestern town, with dark storm clouds, lightning, and debris being lifted into the air.",
  "A serene winter landscape with a frozen lake, snow-covered pine trees, a wooden cabin, and the northern lights in the sky.",
  "A bustling street in New Orleans during Mardi Gras with people in colorful costumes, jazz bands playing, and festive decorations.",
  "A whimsical underwater kingdom with mermaids, sea creatures, coral palaces, and treasure chests scattered on the ocean floor.",
  "A vibrant street festival with colorful decorations, musicians playing, dancers performing, and people celebrating in the streets.",
  "A detailed cross-section of an ancient Roman gladiatorial arena with spectators, gladiators fighting, and the emperor watching from his throne.",
  "A serene beach scene at sunset with golden sands, gentle waves, palm trees, a hammock, and a couple walking hand in hand along the shore.",
  "A bustling street in Havana, Cuba, with colorful colonial buildings, classic cars, street musicians, and people going about their day.",
  "A cozy winter cabin surrounded by snow-covered pine trees, with smoke rising from the chimney and a family inside enjoying a warm fire.",
  "A vibrant Indian street scene with people in colorful attire, rickshaws, market stalls, and the hustle and bustle of daily life.",
  "A serene mountain valley with a river running through it, wildflowers in bloom, and a small log cabin nestled among the trees.",
  "A bustling fish market in a coastal town with fishermen unloading their catch, stalls selling fresh seafood, and seagulls flying overhead.",
  "A dramatic volcanic eruption with flowing lava, ash clouds, and lightning in the sky, while people in the foreground watch from a safe distance.",
  "A lively jazz club from the 1940s with a live band playing on stage, people dancing, and a smoky, intimate atmosphere.",
  "A bustling farmers' market with stalls selling fresh produce, flowers, baked goods, and handmade crafts, with people browsing and chatting.",
  "A vibrant Caribbean carnival with people in colorful costumes, steel drum bands, and a parade of floats celebrating in the streets.",
  "A cozy bookshop interior with tall shelves filled with books, comfortable reading chairs, and a cat sleeping on the counter.",
  "A serene lakeside scene at dawn with a wooden pier, a small rowboat, a fisherman casting a line, and mist rising from the water.",
  "A whimsical woodland scene with tiny gnome houses, forest animals, mushrooms, and a fairy tale atmosphere.",
  "A bustling market in Marrakech with stalls selling spices, rugs, lanterns, and handmade crafts, with people browsing and bartering.",
  "A serene alpine village in winter with snow-covered rooftops, a frozen lake, ice skaters, and a backdrop of towering mountains.",
  "A dramatic underwater shipwreck with coral growing on the hull, schools of fish swimming around, and divers exploring the scene.",
  "A lively street performance in a busy city square with musicians, dancers, and a crowd of onlookers enjoying the show.",
  "A futuristic underwater research facility with large windows looking out into the ocean, scientists conducting experiments, and advanced technology.",
  "A serene mountain meadow with wildflowers, a small stream, butterflies fluttering, and a couple having a picnic under a large oak tree.",
  "A dramatic castle on a cliff overlooking a stormy sea, with lightning striking in the background and waves crashing against the rocks.",
  "A bustling street festival in Rio de Janeiro with colorful costumes, samba dancers, large parade floats, confetti, and joyous crowds celebrating.",
  "A cozy living room decorated for Christmas with a decorated tree, stockings hung by the fireplace, presents, and a family gathered together.",
  "A bustling Parisian café on a cobblestone street, with people sitting at outdoor tables, waiters serving coffee, and the Eiffel Tower in the background.",
  "A futuristic cityscape at night with towering skyscrapers, flying cars, neon lights, and holographic advertisements, showcasing advanced technology and a lively urban atmosphere.",
  "A serene Japanese tea garden with a koi pond, stone lanterns, a tea house, and cherry blossom trees in full bloom.",
  "A vibrant Indian wedding ceremony with colorful decorations, traditional attire, dancers, and a joyful atmosphere.",
  "A bustling Mexican street market with stalls selling fresh produce, handmade crafts, street food, and colorful decorations.",
  "A dramatic mountain cliff with a winding path, climbers ascending, and a breathtaking view of the valley below.",
  "A serene winter landscape with a frozen waterfall, snow-covered trees, and deer cautiously moving through the forest.",
  "A bustling Chinatown street in San Francisco with lanterns hanging overhead, vendors selling dim sum, and the iconic Dragon Gate in the distance.",
  "A peaceful countryside scene with rolling hills, grazing sheep, a winding river, and a quaint stone cottage nestled among the trees.",
  "A bustling bazaar in Istanbul with merchants selling carpets, spices, ceramics, and Turkish delights, surrounded by the aromas of coffee and baklava.",
  "A serene mountain lake at sunrise, surrounded by towering peaks, with mist rising from the water and birds gliding overhead.",
  "A lively carnival midway with brightly lit rides, game booths, cotton candy stands, and the sound of laughter and excitement in the air.",
  "A serene autumn forest with leaves of red, orange, and gold carpeting the ground, sunlight filtering through the canopy, and a peaceful stillness.",
  "A bustling market in Delhi with colorful saris, aromatic spices, street vendors selling chai, and the sound of bargaining echoing through the narrow lanes.",
  "A cozy English countryside pub with oak beams, a crackling fire, locals enjoying pints of ale, and the faint sound of a piano playing in the background.",
  "A futuristic lunar colony with domed habitats, solar panels stretching across the lunar surface, and Earth visible in the sky above.",
  "A serene mountain pass with snow-capped peaks, a winding trail, alpine flowers in bloom, and a clear blue sky overhead.",
  "A bustling street in Tokyo's Shibuya district with neon lights, crowds of people, towering skyscrapers, and the iconic Shibuya Crossing.",
  "A tranquil rice terrace in Bali with lush green fields, palm trees swaying in the breeze, and a traditional Balinese hut overlooking the landscape.",
  "A lively Oktoberfest celebration in Munich with beer tents, folk music, people in traditional Bavarian attire, and the aroma of pretzels and bratwurst.",
  "A serene vineyard in Tuscany with rows of grapevines, a rustic stone villa, rolling hills in the background, and the sun setting behind distant mountains.",


]



const scenes = [
  "A bustling medieval marketplace with vendors selling various goods, including fruits, fabrics, and pottery, under colorful tents, with a stone castle in the background and villagers in period attire.",
  "An underwater scene featuring a vibrant coral reef teeming with diverse marine life such as colorful fish, sea turtles, and an octopus, with rays of sunlight penetrating the water's surface.",
  "A futuristic cityscape at night with towering skyscrapers, flying cars, neon lights, and holographic advertisements, showcasing advanced technology and a lively urban atmosphere.",
  "An enchanted forest with giant, glowing mushrooms, magical creatures like fairies and unicorns, and a sparkling river flowing through, all under a twilight sky with a full moon.",
  "A serene Japanese garden in autumn with red maple leaves, a traditional tea house, a stone lantern, and a koi pond with fish, all surrounded by carefully pruned bonsai trees.",
  "A cozy cabin interior in the mountains, with a roaring fireplace, wooden furniture, a fluffy rug, and large windows showcasing a snowy landscape outside.",
  "A historical scene depicting an ancient Roman forum with marble statues, columns, people in togas, and bustling activity, all under a clear blue sky.",
  "A fantasy dragon soaring over a medieval kingdom, breathing fire, with castles, knights in armor, and lush green landscapes below.",
  "A vibrant street festival in Rio de Janeiro with colorful costumes, samba dancers, large parade floats, confetti, and joyous crowds celebrating.",
  "A peaceful beach at sunset with golden sands, gentle waves, palm trees, a hammock, and a couple walking hand in hand along the shore.",
  "A serene, snow-covered village during Christmas time, with decorated houses, children building a snowman, and a horse-drawn sleigh in the background.",
  "A mystical mountain landscape with jagged peaks, a flowing waterfall, and an ancient temple built into the cliffside, shrouded in mist.",
  "A lively carnival scene with a Ferris wheel, colorful tents, food stalls, and people enjoying various rides and games.",
  "An old-fashioned library with tall bookshelves, ladders, leather-bound books, and a cozy reading nook with a vintage lamp.",
  "A sci-fi laboratory with futuristic equipment, scientists in high-tech suits, and holographic displays showing complex data.",
  "A tranquil lakeside scene at dawn with a wooden pier, a small rowboat, a fisherman casting a line, and mist rising from the water.",
  "A bustling New York City street in the 1920s, with classic cars, men in suits and hats, women in flapper dresses, and Art Deco buildings.",
  "A dramatic pirate ship battle on the high seas, with cannons firing, tattered sails, and pirates swinging between ships with swords drawn.",
  "A beautiful, sprawling vineyard in Tuscany, with rows of grapevines, a rustic farmhouse, and workers harvesting grapes under a clear blue sky.",
  "An enchanting fairy tale castle on a hill, surrounded by lush gardens, a sparkling moat, and a drawbridge leading to the entrance.",
  "A detailed, close-up view of an intricate clockwork mechanism, with gears, springs, and tiny moving parts all meticulously crafted.",
  "An ancient Egyptian tomb interior, with hieroglyphics on the walls, a sarcophagus, and treasures such as gold, jewels, and artifacts scattered around.",
  "A whimsical treehouse village in a dense forest, with wooden bridges connecting the houses, lanterns hanging from branches, and children playing.",
  "A futuristic space station orbiting Earth, with astronauts floating outside, advanced technology, and a stunning view of the planet below.",
  "A vibrant underwater city inhabited by mermaids, with coral buildings, seaweed gardens, and a variety of marine life swimming around.",
  "A cozy, rustic kitchen with a wood-burning stove, hanging herbs, fresh vegetables on the counter, and a window with a view of a garden.",
  "A grand ballroom from the Victorian era, with chandeliers, elegantly dressed couples dancing, and musicians playing classical instruments.",
  "A dramatic scene of a volcano erupting, with flowing lava, ash clouds, and lightning in the sky, while people in the foreground watch from a safe distance.",
  "A serene Zen garden with raked sand patterns, carefully placed rocks, a small pond, and a bonsai tree, all exuding a sense of peace and tranquility.",
  "A bustling farmers' market with stalls selling fresh produce, flowers, baked goods, and handmade crafts, with people browsing and chatting.",
  "A snowy mountain landscape with a group of adventurers climbing, carrying ice picks and ropes, against a backdrop of majestic peaks and a clear blue sky.",
  "A colorful autumn forest with leaves in shades of red, orange, and yellow, a winding path, and a couple walking hand in hand.",
  "An alien planet with strange vegetation, colorful skies, unusual rock formations, and an astronaut exploring the terrain.",
  "A detailed, steampunk-inspired city with Victorian architecture, airships floating in the sky, and people in eclectic, retro-futuristic attire.",
  "A tranquil beach scene with a hammock strung between two palm trees, gentle waves lapping the shore, and a vibrant sunset in the background.",
  "An intricate, ancient Chinese temple with dragon statues, ornate carvings, and monks performing a ritual ceremony.",
  "A lively jazz club from the 1940s with a live band playing on stage, people dancing, and a smoky, intimate atmosphere.",
  "A futuristic underwater research facility with large windows looking out into the ocean, scientists conducting experiments, and advanced technology.",
  "A majestic, medieval cathedral interior with soaring arches, stained glass windows, and a choir singing.",
  "A cozy winter cabin surrounded by snow-covered pine trees, with smoke rising from the chimney and a family inside enjoying a warm fire.",
  "An elaborate masquerade ball with guests wearing ornate masks and costumes, dancing in a grand hall with chandeliers and elegant decorations.",
  "A scenic view of the Grand Canyon at sunset, with deep orange and red hues, towering rock formations, and a winding river below.",
  "A bustling Parisian café on a cobblestone street, with people sitting at outdoor tables, waiters serving coffee, and the Eiffel Tower in the background.",
  "A serene tropical island with white sandy beaches, crystal-clear waters, palm trees, and colorful beach huts.",
  "A detailed botanical illustration of various exotic flowers and plants, with intricate details and vibrant colors.",
  "A peaceful countryside scene with rolling hills, a quaint farmhouse, grazing sheep, and a winding dirt road.",
  "A futuristic metropolis at night with towering skyscrapers, neon lights, flying vehicles, and a bustling crowd of diverse characters.",
  "An ancient Greek amphitheater with actors performing a play, an audience watching attentively, and a backdrop of ruins and olive trees.",
  "A cozy reading nook by a large window, with a comfortable chair, a stack of books, a soft blanket, and a view of a rainy day outside.",
  "A vibrant Moroccan market with stalls selling spices, textiles, lanterns, and ceramics, with people browsing and bartering."
];
// 1-50

const moreScenes = [
  "A majestic lion standing on a rock in the African savannah, with a golden sunset, acacia trees, and a herd of wildebeest in the background.",
  "A whimsical fantasy forest with bioluminescent plants, magical creatures like elves and pixies, and a sparkling river running through it.",
  "An old-fashioned blacksmith's workshop with a forge, anvil, tools, and the blacksmith working on a piece of glowing metal.",
  "A serene Scandinavian landscape with fjords, pine forests, a traditional red cabin, and the northern lights in the sky.",
  "A bustling Tokyo street at night with bright neon signs, busy pedestrians, street food vendors, and towering buildings.",
  "An underwater shipwreck with coral growing on the hull, schools of fish swimming around, and divers exploring the scene.",
  "A peaceful sunset over a desert landscape with sand dunes, a camel caravan, and an oasis in the distance.",
  "A vibrant Indian festival with people in colorful attire, dancing, playing instruments, and throwing colored powder in celebration.",
  "A cozy coffee shop interior with comfortable seating, bookshelves, people working on laptops, and a barista preparing drinks.",
  "A dramatic castle on a cliff overlooking a stormy sea, with lightning striking in the background and waves crashing against the rocks.",
  "A detailed anatomical illustration of the human heart, showing veins, arteries, and chambers, with labels and descriptions.",
  "A serene alpine lake with crystal-clear water, surrounded by snow-capped mountains, pine forests, and a wooden cabin by the shore.",
  "A lively street performance in a busy city square with musicians, dancers, and a crowd of onlookers enjoying the show.",
  "A historical Viking village with wooden longhouses, warriors practicing with weapons, and a harbor with longships.",
  "A colorful underwater coral reef teeming with life, including fish, sea turtles, and anemones, with sunlight filtering through the water.",
  "A quaint English countryside village with thatched-roof cottages, a stone church, and a bustling market square.",
  "A majestic dragon perched on a mountain peak, with wings spread wide, scales shimmering, and a panoramic view of the kingdom below.",
  "A futuristic laboratory with scientists conducting experiments, advanced robotic equipment, and holographic data displays.",
  "A serene autumn park with a path covered in fallen leaves, a couple sitting on a bench, and trees in vibrant shades of red, orange, and yellow.",
  "A dramatic stormy sea with towering waves, a lighthouse standing tall on a rocky shore, and a ship struggling against the elements.",
  "An ancient Mayan temple in the jungle, partially overgrown with vines, with a group of explorers discovering its hidden treasures.",
  "A lively Renaissance fair with people in period costumes, jousting tournaments, market stalls, and entertainers performing.",
  "A serene lakeside campground with tents, a campfire, a family roasting marshmallows, and a calm lake reflecting the starry night sky.",
  "A futuristic space colony on Mars, with domed habitats, rovers, and astronauts working on the red, rocky surface.",
  "A detailed map of a fantasy world with continents, oceans, mountain ranges, forests, and cities, all intricately drawn.",
  "A busy New York City street in winter, with people bundled up in coats, snow falling, holiday decorations, and ice skaters in a nearby park.",
  "A whimsical steampunk airship flying through the clouds, with brass gears, large propellers, and passengers looking out over the horizon.",
  "A serene beach scene at sunrise with a lighthouse on a rocky outcrop, waves gently crashing, and seagulls flying overhead.",
  "A detailed cross-section of a medieval castle showing various rooms, including the throne room, dungeon, armory, and banquet hall.",
  "A bustling Italian piazza with people enjoying gelato, children playing, a fountain in the center, and historic buildings surrounding the square.",
  "An eerie haunted house on a hill with overgrown weeds, broken windows, ghostly figures, and a full moon casting an ominous glow.",
  "A cozy attic study with an old wooden desk, stacks of books, a typewriter, and a window offering a view of the city rooftops.",
  "A vibrant Caribbean carnival with people in colorful costumes, steel drum bands, and a parade of floats celebrating in the streets.",
  "A futuristic drone delivery service in a modern city with drones flying between skyscrapers, delivering packages to various locations.",
  "An ancient samurai training ground with warriors practicing sword techniques, a traditional dojo, and cherry blossom trees in bloom.",
  "A serene desert oasis with a clear blue pond, date palms, a small tent, and a camel resting nearby.",
  "A lively medieval fair with jesters, knights in armor, market stalls selling crafts, and villagers enjoying games and performances.",
  "A dramatic iceberg scene in the Arctic with a polar bear and her cubs, seals on the ice, and an aurora borealis lighting up the sky.",
  "A whimsical fairy village with tiny houses built into tree trunks, fairies flying around, and a sparkling stream running through the village.",
  "A futuristic city park with robotic gardeners, children playing with high-tech toys, and people relaxing on futuristic benches.",
  "An intricate stained glass window depicting a religious scene, with vibrant colors and detailed figures illuminated by sunlight.",
  "A bustling Victorian-era street with horse-drawn carriages, people in period attire, street vendors, and gas lamps lighting the way.",
  "A serene winter landscape with a frozen lake, ice skaters gliding across the surface, and snow-covered pine trees in the background.",
  "A dramatic shipwreck scene on a rocky shore, with the remains of an old ship, waves crashing, and seabirds flying overhead.",
  "A cozy bookshop interior with tall shelves filled with books, comfortable reading chairs, and a cat sleeping on the counter.",
  "A futuristic train station with sleek, high-speed trains, passengers boarding, and holographic displays showing schedules and destinations.",
  "A whimsical candy land with gingerbread houses, candy cane trees, a chocolate river, and children exploring the sweet landscape.",
  "A serene sunrise over a rice terrace in Bali, with lush green fields, traditional Balinese houses, and farmers working in the fields.",
  "A vibrant street market in Bangkok with stalls selling exotic fruits, spices, street food, and colorful fabrics, bustling with activity.",
  "A dramatic lightning storm over a vast desert landscape with dark clouds, lightning bolts illuminating the sky, and a lone cactus standing tall."
];

// 51-100 

const anotherArray = [
  "An ancient Roman bathhouse with elaborate mosaics, columns, a large central pool, and people relaxing in the thermal waters.",
  "A serene mountain lake with a wooden dock, a rowboat tied to it, and a backdrop of snow-capped peaks and dense pine forests.",
  "A bustling fish market in a coastal town with fishermen unloading their catch, stalls selling fresh seafood, and seagulls flying overhead.",
  "A futuristic floating city with interconnected platforms, advanced architecture, flying vehicles, and people moving about their daily lives.",
  "A detailed cross-section of an ancient Egyptian pyramid showing burial chambers, hidden passageways, and archaeologists exploring.",
  "A cozy living room decorated for Christmas with a decorated tree, stockings hung by the fireplace, presents, and a family gathered together.",
  "A vibrant underwater scene with a shipwreck covered in coral, schools of fish swimming around, and a diver exploring the wreckage.",
  "A serene lavender field in Provence, France, with rows of purple flowers, a farmhouse in the distance, and a clear blue sky.",
  "A bustling street in New Orleans during Mardi Gras with people in colorful costumes, jazz bands playing, and festive decorations.",
  "A dramatic mountain cliff with a winding path, climbers ascending, and a breathtaking view of the valley below.",
  "A vibrant rainforest scene with exotic birds, monkeys swinging through the trees, colorful flowers, and a waterfall in the background.",
  "A detailed isometric view of a modern apartment building, showing various rooms and activities inside, with a rooftop garden.",
  "A lively seaside boardwalk with amusement rides, food stalls, people enjoying the beach, and a beautiful sunset over the ocean.",
  "An ancient library with towering bookshelves, ladders, a grand staircase, and scholars studying ancient texts.",
  "A bustling spaceport with various spacecraft, astronauts preparing for missions, and futuristic technology all around.",
  "A serene meadow with wildflowers, a small stream, butterflies fluttering, and a couple having a picnic under a large oak tree.",
  "A dramatic cityscape of Hong Kong at night, with illuminated skyscrapers, boats in the harbor, and a vibrant city life.",
  "A cozy bakery with fresh bread and pastries on display, a baker working behind the counter, and the warm glow of the oven.",
  "A futuristic medical facility with advanced robotic surgery, doctors in high-tech uniforms, and patients being treated with cutting-edge equipment.",
  "A whimsical enchanted garden with oversized flowers, magical creatures, a crystal-clear pond, and a fairy sitting on a mushroom.",
  "A detailed cross-section of a pirate ship showing various decks, crew members at work, and treasure chests in the hold.",
  "A serene mountain valley with a river running through it, wildflowers in bloom, and a small log cabin nestled among the trees.",
  "A bustling farmers' market in a small European village with stalls selling fresh produce, cheeses, baked goods, and handmade crafts.",
  "A dramatic sci-fi battle scene in space with starships, laser beams, explosions, and a distant planet in the background.",
  "A cozy cabin in the woods with smoke rising from the chimney, a stack of firewood, and a snowy landscape all around.",
  "A vibrant Indian wedding ceremony with colorful decorations, traditional attire, dancers, and a joyful atmosphere.",
  "A serene Japanese tea garden with a koi pond, stone lanterns, a tea house, and cherry blossom trees in full bloom.",
  "A futuristic cityscape with vertical gardens, eco-friendly buildings, flying cars, and people walking along elevated walkways.",
  "A whimsical woodland scene with tiny gnome houses, forest animals, mushrooms, and a fairy tale atmosphere.",
  "A bustling fish market in a coastal town with fishermen unloading their catch, stalls selling fresh seafood, and seagulls flying overhead.",
  "A dramatic cliffside lighthouse with waves crashing against the rocks, a beam of light cutting through the fog, and stormy skies above.",
  "A cozy café in Paris with outdoor seating, people enjoying coffee and pastries, and the Eiffel Tower in the distance.",
  "A futuristic city park with robotic gardeners, children playing with high-tech toys, and people relaxing on futuristic benches.",
  "A serene Zen garden with carefully raked sand, strategically placed rocks, a small pond, and a bonsai tree.",
  "A lively medieval fair with jesters, knights in armor, market stalls selling crafts, and villagers enjoying games and performances.",
  "A majestic dragon perched on a mountain peak, with wings spread wide, scales shimmering, and a panoramic view of the kingdom below.",
  "A bustling New York City street in the 1920s, with classic cars, men in suits and hats, women in flapper dresses, and Art Deco buildings.",
  "A whimsical candy land with gingerbread houses, candy cane trees, a chocolate river, and children exploring the sweet landscape.",
  "A serene mountain lake with crystal-clear water, surrounded by snow-capped mountains, pine forests, and a wooden cabin by the shore.",
  "A dramatic desert landscape with towering sand dunes, a caravan of camels, and a vibrant sunset casting long shadows.",
  "A bustling Moroccan market with stalls selling spices, textiles, lanterns, and ceramics, with people browsing and bartering.",
  "A cozy winter cabin surrounded by snow-covered pine trees, with smoke rising from the chimney and a family inside enjoying a warm fire.",
  "A vibrant street festival with colorful decorations, musicians playing, dancers performing, and people celebrating in the streets.",
  "An ancient Egyptian tomb interior, with hieroglyphics on the walls, a sarcophagus, and treasures such as gold, jewels, and artifacts scattered around.",
  "A detailed cutaway view of a submarine showing different compartments, crew members at work, and the underwater environment outside.",
  "A serene vineyard in Napa Valley with rows of grapevines, a rustic farmhouse, and workers harvesting grapes under a sunny sky.",
  "A bustling street scene in Havana, Cuba, with colorful colonial buildings, classic cars, street musicians, and people going about their day.",
  "A futuristic hovercar race through a neon-lit cityscape, with sleek vehicles speeding along elevated tracks and spectators watching from platforms.",
  "A cozy log cabin interior with a roaring fireplace, wooden furniture, a bear rug, and large windows offering a view of a snowy forest.",
  "A dramatic scene of a tornado approaching a small Midwestern town, with dark storm clouds, lightning, and debris being lifted into the air."
];

// 101-150

const anotherOne = [
  "A lively Oktoberfest celebration in Germany with people in traditional Bavarian attire, beer tents, pretzels, and folk music.",
  "A whimsical underwater kingdom with mermaids, sea creatures, coral palaces, and treasure chests scattered on the ocean floor.",
  "A bustling Indian street market with stalls selling spices, textiles, street food, and colorful decorations, with people shopping and bartering.",
  "A serene mountain cabin at dawn with a view of a misty valley, pine trees, and a river flowing nearby.",
  "A vibrant Chinese New Year parade with dragon and lion dances, colorful lanterns, firecrackers, and people in traditional attire.",
  "A detailed illustration of a medieval blacksmith's forge with tools, an anvil, a forge with glowing coals, and the blacksmith at work.",
  "A futuristic space elevator extending from a base on Earth into orbit, with spacecraft docking and people traveling up and down.",
  "A serene coastal village in Greece with whitewashed buildings, blue-domed churches, narrow cobblestone streets, and a view of the Aegean Sea.",
  "A dramatic scene of a knight in shining armor battling a fire-breathing dragon in a dark, enchanted forest.",
  "A bustling Christmas market in a European town with wooden stalls selling holiday treats, handmade gifts, twinkling lights, and people enjoying the festive atmosphere.",
  "A serene mountain lake with a wooden pier, a small rowboat, a fisherman casting a line, and mist rising from the water.",
  "A futuristic underwater research facility with large windows looking out into the ocean, scientists conducting experiments, and advanced technology.",
  "A detailed illustration of an ancient Roman gladiatorial arena with spectators, gladiators fighting, and the emperor watching from his throne.",
  "A whimsical fairy tale castle on a hill, surrounded by lush gardens, a sparkling moat, and a drawbridge leading to the entrance.",
  "A bustling market in Marrakech with stalls selling spices, rugs, lanterns, and handmade crafts, with people browsing and bartering.",
  "A serene beach scene at sunset with golden sands, gentle waves, palm trees, a hammock, and a couple walking hand in hand along the shore.",
  "A vibrant street festival in Rio de Janeiro with colorful costumes, samba dancers, large parade floats, confetti, and joyous crowds celebrating.",
  "A peaceful sunrise over a rice terrace in Bali, with lush green fields, traditional Balinese houses, and farmers working in the fields.",
  "A dramatic volcanic eruption with flowing lava, ash clouds, and lightning in the sky, while people in the foreground watch from a safe distance.",
  "A cozy bookshop interior with tall shelves filled with books, comfortable reading chairs, and a cat sleeping on the counter.",
  "A bustling street in New Orleans during Mardi Gras with people in colorful costumes, jazz bands playing, and festive decorations.",
  "A serene lavender field in Provence, France, with rows of purple flowers, a farmhouse in the distance, and a clear blue sky.",
  "A dramatic mountain landscape with jagged peaks, a flowing waterfall, and an ancient temple built into the cliffside, shrouded in mist.",
  "A futuristic metropolis at night with towering skyscrapers, flying cars, neon lights, and holographic advertisements, showcasing advanced technology and a lively urban atmosphere.",
  "A serene alpine village in winter with snow-covered rooftops, a frozen lake, ice skaters, and a backdrop of towering mountains.",
  "A bustling bazaar in Istanbul with stalls selling spices, carpets, ceramics, and lamps, with people shopping and bartering.",
  "A dramatic pirate ship battle on the high seas, with cannons firing, tattered sails, and pirates swinging between ships with swords drawn.",
  "A cozy reading nook in a treehouse with a comfortable chair, a stack of books, a soft blanket, and a view of a forest.",
  "A futuristic city skyline at dawn with sleek skyscrapers, flying vehicles, green spaces, and people going about their daily routines.",
  "A serene Japanese garden with a stone bridge over a koi pond, a traditional tea house, and cherry blossom trees in full bloom.",
  "A lively street performance in a busy city square with musicians, dancers, and a crowd of onlookers enjoying the show.",
  "A whimsical fantasy village with houses made from giant mushrooms, cobblestone streets, and magical creatures like fairies and gnomes.",
  "A bustling market in a coastal town with fishermen unloading their catch, stalls selling fresh seafood, and seagulls flying overhead.",
  "A dramatic cliffside castle overlooking a stormy sea, with waves crashing against the rocks and dark clouds gathering in the sky.",
  "A vibrant street market in Mexico with stalls selling fresh produce, handmade crafts, street food, and colorful decorations.",
  "A serene mountain cabin in autumn with colorful leaves, a small river flowing nearby, and smoke rising from the chimney.",
  "A futuristic research lab with advanced robotic equipment, scientists conducting experiments, and holographic data displays.",
  "A bustling street in Tokyo at night with bright neon signs, busy pedestrians, street food vendors, and towering buildings.",
  "A cozy living room decorated for Halloween with carved pumpkins, spooky decorations, a fireplace, and children in costumes.",
  "A dramatic scene of a spaceship landing on an alien planet, with strange vegetation, alien creatures, and astronauts exploring the terrain.",
  "A vibrant Indian street scene with people in colorful attire, rickshaws, market stalls, and the hustle and bustle of daily life.",
  "A serene lake at sunrise with mist rising from the water, a wooden dock, a small rowboat, and a backdrop of mountains and trees.",
  "A whimsical candy land with gingerbread houses, candy cane trees, a chocolate river, and children exploring the sweet landscape.",
  "A bustling street in New York City in the 1950s, with classic cars, people in period attire, street vendors, and iconic buildings.",
  "A dramatic underwater scene with a sunken ship, colorful coral, schools of fish, and a diver exploring the wreckage.",
  "A serene winter landscape with a frozen lake, snow-covered pine trees, a wooden cabin, and the northern lights in the sky.",
  "A lively Oktoberfest celebration with people in traditional Bavarian attire, beer tents, pretzels, and folk music.",
  "A whimsical treehouse village with wooden bridges connecting the houses, lanterns hanging from branches, and children playing.",
  "A bustling farmers' market with stalls selling fresh produce, flowers, baked goods, and handmade crafts, with people browsing and chatting."
];

// 151-200

const scene_descriptions_2 = [
  "A serene Japanese tea garden with a koi pond, stone lanterns, a tea house, and cherry blossom trees in full bloom.",
  "A bustling street market in Bangkok with stalls selling exotic fruits, spices, street food, and colorful fabrics, bustling with activity.",
  "A cozy cabin in the woods during fall, with golden leaves, a crackling fireplace, and the smell of pine.",
  "A futuristic cityscape at night with glowing neon lights, flying cars, and holographic advertisements.",
  "A serene mountain lake reflecting the snow-capped peaks and a lone canoe drifting peacefully.",
  "A bustling bazaar in Marrakech with vendors selling spices, rugs, and handmade crafts under colorful awnings.",
  "A whimsical underwater kingdom with mermaids, seahorses, and colorful coral reefs.",
  "A dramatic thunderstorm over a vast, open plain, with lightning illuminating the darkened sky.",
  "A serene vineyard in Tuscany with rows of grapevines, a rustic villa, and rolling hills in the background.",
  "A bustling street in Tokyo's Shibuya district with crowds of people, giant screens, and neon lights.",
  "A cozy log cabin covered in snow, smoke rising from the chimney, and a family gathered around a warm fire.",
  "A vibrant Indian wedding with colorful attire, intricate henna designs, and joyful celebrations.",
  "A serene beach at sunset with palm trees, gentle waves, and a couple walking hand in hand along the shore.",
  "A bustling night market in Hong Kong with street food stalls, bright signs, and bustling crowds.",
  "A whimsical fairy tale castle on a hill, surrounded by lush gardens and a moat filled with swans.",
  "A dramatic cliffside castle overlooking a stormy sea, with crashing waves and dark clouds overhead.",
  "A cozy English cottage garden with blooming roses, a stone path, and a wooden bench under a willow tree.",
  "A bustling souk in Dubai with merchants selling gold, spices, and colorful textiles.",
  "A serene mountain peak at sunrise, with mist swirling around jagged peaks and a sense of quiet awe.",
  "A bustling Chinatown street with lanterns, bustling markets, and the aroma of dumplings in the air.",
  "A futuristic laboratory with glowing screens, robotic arms, and scientists conducting experiments.",
  "A serene autumn forest with sunlight filtering through colorful leaves and a carpet of fallen foliage.",
  "A bustling carnival midway with bright lights, spinning rides, and the sound of laughter and excitement.",
  "A cozy countryside inn with a thatched roof, flower-filled gardens, and a welcoming hearth inside.",
  "A bustling market in Istanbul with vendors selling spices, carpets, and handmade ceramics.",
  "A serene mountain stream cascading over rocks, surrounded by towering pine trees and wildflowers.",
  "A vibrant Mexican fiesta with dancers in colorful costumes, mariachi bands, and piñatas.",
  "A dramatic scene of a space battle with starships firing lasers amidst swirling nebulae and distant planets.",
  "A serene bamboo forest in Kyoto, with sunlight filtering through tall stalks and a peaceful atmosphere.",
  "A bustling city square in Rome with historic architecture, outdoor cafes, and bustling tourists.",
  "A whimsical steampunk cityscape with airships, clockwork towers, and steam-powered contraptions.",
  "A serene lake house with a wooden dock, kayaks, and a hammock under tall trees.",
  "A bustling street market in Cairo with vendors selling spices, textiles, and handmade crafts.",
  "A cozy winter cabin surrounded by snowy mountains, with a crackling fire and hot cocoa.",
  "A vibrant Indian street scene with colorful sarees, bustling rickshaws, and street vendors selling samosas.",
  "A serene Japanese onsen with steaming hot springs, wooden bathhouses, and snow falling gently.",
  "A bustling Christmas market in Vienna with wooden stalls, twinkling lights, and the scent of mulled wine.",
  "A whimsical fairy tale village with thatched cottages, winding cobblestone streets, and friendly villagers.",
  "A dramatic scene of a medieval battle with knights in armor, charging horses, and banners fluttering in the wind.",
  "A serene cherry blossom grove in springtime, with pink petals drifting on a gentle breeze.",
  "A bustling harbor in Sydney with sailboats, the Opera House in the background, and seagulls circling overhead.",
  "A cozy cabin in the Canadian Rockies, with a roaring fire, snow falling outside, and hot apple cider.",
  "A vibrant street market in Mexico City with mariachi bands, taco stands, and colorful papel picado banners.",
  "A serene alpine meadow with wildflowers, a crystal-clear stream, and snow-capped peaks in the distance.",
  "A bustling street in Seoul's Gangnam district with skyscrapers, bustling traffic, and trendy boutiques.",
  "A futuristic city on Mars with domed habitats, solar panels, and a red desert landscape beyond.",
  "A serene beach at sunrise, with pastel-colored skies, seashells scattered on the sand, and gentle waves lapping.",
  "A bustling carnival parade with elaborate floats, costumed performers, and confetti filling the air.",
  "A cozy mountain lodge with a roaring fireplace, plaid armchairs, and views of snow-covered peaks.",
  "A vibrant street festival in New Orleans with jazz bands, colorful floats, and people dancing in the streets.",
  "A serene waterfall hidden in a lush tropical jungle, with mist rising and exotic birds singing.",
  "A bustling market in Bangkok with vendors selling spicy street food, colorful silk scarves, and carved wooden elephants.",
  "A cozy English pub with wooden beams, pints of ale, and a lively game of darts in progress."
]
// 201-250

const sceneDescriptions = [
  "A vibrant street market in Bangkok with stalls selling exotic fruits, spices, street food, and colorful fabrics, bustling with activity.",
  "A serene mountain cabin in autumn with colorful leaves, a small river flowing nearby, and smoke rising from the chimney.",
  "A futuristic space station orbiting Earth, with astronauts floating outside, advanced technology, and a stunning view of the planet below.",
  "A bustling New York City street in winter, with people bundled up in coats, snow falling, holiday decorations, and ice skaters in a nearby park.",
  "A whimsical candy land with gingerbread houses, candy cane trees, a chocolate river, and children exploring the sweet landscape.",
  "A peaceful sunset over a desert landscape with sand dunes, a camel caravan, and an oasis in the distance.",
  "A cozy café in Paris with outdoor seating, people enjoying coffee and pastries, and the Eiffel Tower in the distance.",
  "A dramatic scene of a tornado approaching a small Midwestern town, with dark storm clouds, lightning, and debris being lifted into the air.",
  "A serene winter landscape with a frozen lake, snow-covered pine trees, a wooden cabin, and the northern lights in the sky.",
  "A bustling street in New Orleans during Mardi Gras with people in colorful costumes, jazz bands playing, and festive decorations.",
  "A whimsical underwater kingdom with mermaids, sea creatures, coral palaces, and treasure chests scattered on the ocean floor.",
  "A vibrant street festival with colorful decorations, musicians playing, dancers performing, and people celebrating in the streets.",
  "A detailed cross-section of an ancient Roman gladiatorial arena with spectators, gladiators fighting, and the emperor watching from his throne.",
  "A serene beach scene at sunset with golden sands, gentle waves, palm trees, a hammock, and a couple walking hand in hand along the shore.",
  "A bustling street in Havana, Cuba, with colorful colonial buildings, classic cars, street musicians, and people going about their day.",
  "A cozy winter cabin surrounded by snow-covered pine trees, with smoke rising from the chimney and a family inside enjoying a warm fire.",
  "A vibrant Indian street scene with people in colorful attire, rickshaws, market stalls, and the hustle and bustle of daily life.",
  "A serene mountain valley with a river running through it, wildflowers in bloom, and a small log cabin nestled among the trees.",
  "A bustling fish market in a coastal town with fishermen unloading their catch, stalls selling fresh seafood, and seagulls flying overhead.",
  "A dramatic volcanic eruption with flowing lava, ash clouds, and lightning in the sky, while people in the foreground watch from a safe distance.",
  "A lively jazz club from the 1940s with a live band playing on stage, people dancing, and a smoky, intimate atmosphere.",
  "A bustling farmers' market with stalls selling fresh produce, flowers, baked goods, and handmade crafts, with people browsing and chatting.",
  "A vibrant Caribbean carnival with people in colorful costumes, steel drum bands, and a parade of floats celebrating in the streets.",
  "A cozy bookshop interior with tall shelves filled with books, comfortable reading chairs, and a cat sleeping on the counter.",
  "A serene lakeside scene at dawn with a wooden pier, a small rowboat, a fisherman casting a line, and mist rising from the water.",
  "A whimsical woodland scene with tiny gnome houses, forest animals, mushrooms, and a fairy tale atmosphere.",
  "A bustling market in Marrakech with stalls selling spices, rugs, lanterns, and handmade crafts, with people browsing and bartering.",
  "A serene alpine village in winter with snow-covered rooftops, a frozen lake, ice skaters, and a backdrop of towering mountains.",
  "A dramatic underwater shipwreck with coral growing on the hull, schools of fish swimming around, and divers exploring the scene.",
  "A lively street performance in a busy city square with musicians, dancers, and a crowd of onlookers enjoying the show.",
  "A futuristic underwater research facility with large windows looking out into the ocean, scientists conducting experiments, and advanced technology.",
  "A serene mountain meadow with wildflowers, a small stream, butterflies fluttering, and a couple having a picnic under a large oak tree.",
  "A dramatic castle on a cliff overlooking a stormy sea, with lightning striking in the background and waves crashing against the rocks.",
  "A bustling street festival in Rio de Janeiro with colorful costumes, samba dancers, large parade floats, confetti, and joyous crowds celebrating.",
  "A cozy living room decorated for Christmas with a decorated tree, stockings hung by the fireplace, presents, and a family gathered together.",
  "A bustling Parisian café on a cobblestone street, with people sitting at outdoor tables, waiters serving coffee, and the Eiffel Tower in the background.",
  "A futuristic cityscape at night with towering skyscrapers, flying cars, neon lights, and holographic advertisements, showcasing advanced technology and a lively urban atmosphere.",
  "A serene Japanese tea garden with a koi pond, stone lanterns, a tea house, and cherry blossom trees in full bloom.",
  "A vibrant Indian wedding ceremony with colorful decorations, traditional attire, dancers, and a joyful atmosphere.",
  "A bustling Mexican street market with stalls selling fresh produce, handmade crafts, street food, and colorful decorations.",
  "A dramatic mountain cliff with a winding path, climbers ascending, and a breathtaking view of the valley below.",
  "A serene winter landscape with a frozen waterfall, snow-covered trees, and deer cautiously moving through the forest.",
  "A bustling Chinatown street in San Francisco with lanterns hanging overhead, vendors selling dim sum, and the iconic Dragon Gate in the distance.",
  "A peaceful countryside scene with rolling hills, grazing sheep, a winding river, and a quaint stone cottage nestled among the trees.",
  "A bustling bazaar in Istanbul with merchants selling carpets, spices, ceramics, and Turkish delights, surrounded by the aromas of coffee and baklava.",
  "A serene mountain lake at sunrise, surrounded by towering peaks, with mist rising from the water and birds gliding overhead.",
  "A lively carnival midway with brightly lit rides, game booths, cotton candy stands, and the sound of laughter and excitement in the air.",
  "A serene autumn forest with leaves of red, orange, and gold carpeting the ground, sunlight filtering through the canopy, and a peaceful stillness.",
  "A bustling market in Delhi with colorful saris, aromatic spices, street vendors selling chai, and the sound of bargaining echoing through the narrow lanes.",
  "A cozy English countryside pub with oak beams, a crackling fire, locals enjoying pints of ale, and the faint sound of a piano playing in the background.",
  "A futuristic lunar colony with domed habitats, solar panels stretching across the lunar surface, and Earth visible in the sky above.",
  "A serene mountain pass with snow-capped peaks, a winding trail, alpine flowers in bloom, and a clear blue sky overhead.",
  "A bustling street in Tokyo's Shibuya district with neon lights, crowds of people, towering skyscrapers, and the iconic Shibuya Crossing.",
  "A tranquil rice terrace in Bali with lush green fields, palm trees swaying in the breeze, and a traditional Balinese hut overlooking the landscape.",
  "A lively Oktoberfest celebration in Munich with beer tents, folk music, people in traditional Bavarian attire, and the aroma of pretzels and bratwurst.",
  "A serene vineyard in Tuscany with rows of grapevines, a rustic stone villa, rolling hills in the background, and the sun setting behind distant mountains.",
];

// 251-300





export const array = [
  "Renaissance painting of three lovers lamenting a broken glass sphere",
  "CENTERED PORTRAIT OF AN ULTRA DETAILED MECHANICAL CYBERPUNK FEMALE ANDROID, LOOKING INTO THE CAMERA, INTRICATE, ELEGANT, SUPER HIGHLY DETAILED, SMOOTH, SHARP FOCUS, NO BLUR, NO DOF, EXTREME ILLUSTRATION, UNREAL",
  "MECHANICAL DAWN, TECHNOLOGY CITY, A MECHANICAL TRANSFORMER, EPIC FANTASY ART",
  "AN ASTRONAUT RESTING ON MARS IN A BEACH CHAIR, VIBRANT LIGHTING, ELEGANT, HIGHLY DETAILED, SMOOTH, SHARP FOCUS, ILLUSTRATION, BEAUTIFUL, GEOMETRIC",
  "A BEAUTIFUL SUNSET WITH MOUNTAINS IN THE BACKGROUND AND A LAKE WITH GLACIER WATER IN THE FOREGROUND",
  "PHOTO OF CYBERPUNK FACE",
  "A CABIN SURROUNDED BY FOG. RED LIGHTS SHINING OUT OF WINDOWS. CORNFIELD",
  "PHOTO OF POND WITH LOTUS FLOWER",
  "A BABY FENNEC SNEEZING ONTO A STRAWBERRY, DETAILED, MACRO, STUDIO LIGHT, DROPLETS, BACKLIT EARS",
  "A CUP OF COFFEE ON A SAUCER WITH A SPOON, A TILT SHIFT PHOTO, ANAMORPHIC BOKEH, ITALIAN",
  "PHOTO OF SILHOUETTE OF PERSON USING THEIR PHONE OUTSIDE",
];

export const contentTypes = [
  {
    id: "01",
    text: "Quotes",
  },
  {
    id: "02",
    text: "Poems",
  },
  {
    id: "03",
    text: "History",
  },
  {
    id: "04",
    text: "News",
  },
  {
    id: "05",
    text: "Quizzes",
  },
  {
    id: "06",
    text: "Jokes",
  },
  {
    id: "07",
    text: "News",
  },
  {
    id: "08",
    text: "Articles",
  },
];

export const stepsContent = [
  {
    id: "01",
    text: "Select Category of business",
  },
  {
    id: "02",
    text: "Upload Logo",
  },
  {
    id: "03",
    text: "Connect to social media channels needed",
  },
  {
    id: "04",
    text: "Add Hashtags",
  },
  {
    id: "05",
    text: "Determine how many posts per day",
  },
  {
    id: "06",
    text: "Determine what times to post",
  },
  {
    id: "07",
    text: "Determine what type of contents to post",
  },
  {
    id: "08",
    text: "AI WILL DO THE REST",
  },
];

export const channel = [
  {
    id: "01",
    text: "Facebook Personal Profile",
  },
  {
    id: "02",
    text: "Facebook Business Profile",
  },
  {
    id: "03",
    text: "Facebook Group",
  },
  {
    id: "04",
    text: "Google Business Profile",
  },
  {
    id: "05",
    text: "Instagram",
  },
  {
    id: "06",
    text: "LinkedIn",
  },
  {
    id: "07",
    text: "Pinterest",
  },
  {
    id: "08",
    text: "Twitter",
  },
];
