import React from "react";

const ScheduleHead = () => {
  return (
    <div className="container">
      <div className="schedule-head row text-light py-5 ">
        <div className="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center ">
          <p className="text-uppercase schedule-title">
            {" "}
            Let us create the contents for your channels and post the contents
            as per your choice. <br />{" "}
            <span
              style={{
                color: "#e7c648",
              }}
            >
              All done by AI
            </span>
          </p>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 schedule-img-container">
          <img
            src="/schedule_image.png"
            alt="schedule_image"
            className="schedule_image"
          />
        </div>
      </div>
    </div>
  );
};

export default ScheduleHead;
