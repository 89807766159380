import { Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const ButtonContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    padding: "0 10rem",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 2rem",
  },
}));

export const AnalysisButton = styled(Button)({
  width: "100%",
  backgroundColor: "#3080AC",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#3080AA",
  },
});

export const CalenderButton = styled(Button)({
  width: "100%",
  backgroundColor: "#DA0001",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "#DA0001",
  },
});

export const DescriptionText = styled(Typography)(({ theme }) => ({
  marginTop: "1rem",
  color: "white",
  height: "40px",
  [theme.breakpoints.down("md")]: {
    textAlign: "center",
  },
  [theme.breakpoints.down("sm")]: {
    height: "60px",
  },
}));

export const GradientTextButton = styled(Typography)({
  marginTop: "1rem",
  textAlign: "center",
});
