import React from "react";
import "./PageHeading.css";

const MocUp = ({ title, description, step }) => {

  console.log('Page Title',title);
  return (
    <div className="page-heading-container">
      {
        title == 'Text Video AI' || title  == 'Comic Video AI' ? <h1>{title}</h1> : <h1 className="text-uppercase">{title}</h1>
      }
      <p>How it works: Let us do it for you</p>
      <p>{step} <span>{ description}</span></p>
      
    </div>
  );
};

export default MocUp;
