import React, { useEffect } from "react";
import { AiOutlineLike } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import { FaRegComment, FaWhatsapp } from "react-icons/fa";
import { RiShareForwardLine } from "react-icons/ri";
import fb from './fbIcon.png'
import { CiSearch } from "react-icons/ci";
import { FiHome } from "react-icons/fi";
import { CiFlag1 } from "react-icons/ci";
import { MdOutlineAnalytics } from "react-icons/md";
import { AiOutlineNotification } from "react-icons/ai";
import './FacebookPage.css'

import { MdDashboard } from "react-icons/md";
import { FaFacebookMessenger } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const FacebookPage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/how-it-works-thanks'); // Redirect to home page after 5 seconds
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [navigate]);
  return (
    <div className="" style={{ background: "#F1F2F6" }}>
      
      {/* Facebook header */}
      <div
        className="d-flex justify-content-between px-2"
        style={{ height: "50px", background: "#FFFFFF", marginBottom: "10px" }}
      >
        <div style={{ width: "35%" }} className="d-flex gap-2 align-items-center">
          <img
          style={{ height: "40px", width: "40px", }}
          className="rounded"
          src={fb}/>
          <div style={{position: 'relative'}}>
          <CiSearch style={{ color: "gray", fontSize: "16px", position: 'absolute', left: '2', top: '30%' }}/>
          <input
            type="text"
            className=" py-2 px-4"
            placeholder="Search Facebook"
            style={{ background: "#F1F2F6", width: "200px", border: "none", borderRadius: '30px' }}
          />
        </div>
          </div>

        <div style={{width: '30%'}} className="d-sm-flex gap-5 justify-content-between align-items-center d-none">
        <FiHome  style={{ color: "gray", fontSize: "24px" }}/>
        <CiFlag1  style={{ color: "gray", fontSize: "24px" }}/>
        <MdOutlineAnalytics style={{ color: "gray", fontSize: "24px" }}/>
        <AiOutlineNotification style={{ color: "gray", fontSize: "24px" }}/>
        </div>

        <div className="d-flex justify-content-end align-items-center gap-3" style={{ width: "35%" }}>
          <MdDashboard style={{ color: "black", fontSize: "20px", }}/>
          <FaFacebookMessenger style={{ color: "black", fontSize: "20px" }}/>
          <IoMdNotifications style={{ color: "black", fontSize: "20px" }}/>
          <img
                  style={{ borderRadius: "50%", height: "30px" }}
                  src="https://whalesai.com/static/media/ds-logo.23cc3eac8e316a53022f.png"
                />
        </div>
      </div>


      <section className="d-flex mt-2">
        {/* <div style={{ width: "35%" }} className="flex-1 d-none d-sm-block " ></div>  w-100 w-md-30*/}
        <div
          className="flex-1 p-2 rounded mx-auto"
          style={{background: "#FFFFFF", width: "500px" }}
        >
          <div style={{ textAlign: "left", marginBottom: "10px" }}>
            <div className="d-flex justify-content-between">
              <div className="d-flex gap-2">
                <img
                  style={{ borderRadius: "50%", height: "40px" }}
                  src="https://whalesai.com/static/media/ds-logo.23cc3eac8e316a53022f.png"
                />
                <div>
                  <span>Whalesai</span> <br />
                  <div>
                    <span style={{ fontSize: "12px", marginRight: "8px" }}>
                      Just Now
                    </span>
                    <BiWorld style={{ color: "gray" }} />
                  </div>
                </div>
              </div>
              <p>...</p>
            </div>
          </div>

          <div style={{}}>
            <span className="mb-0 pb-0">
              A serene desert oasis with a clear blue pond, date palms, a small
              tent, and a camel resting nearby.
            </span>{" "}
            <br />
            <span className="mt-0 pt-0" style={{ color: "blue" }}>
              #DesertOasis #NatureBeauty #TravelDreams #PeacefulEscape
              #Wanderlust
            </span>
            <br /> <br/>
            <span className="mt-2">Generated at <span style={{color: 'blue'}}>WhalesAI.com</span> </span> <br />
            <span style={{ color: "blue", marginBottom: "8px" }}>
              #whalesaicom #ai #dsl
            </span>
          </div>

          <img
            className="mt-2"
            style={{ height: "400px", width: "100%", marginBottom: "10px" }}
            src={
              "https://backend.whalesai.com/public/imageai/22102024-d33e5f.png"
            }
          />

          <hr/>
          <div
            style={{ fontSize: "16px" }}
            className="d-flex justify-content-around mt-2 mb-0 "
          >
            <p>
              {" "}
              <AiOutlineLike style={{ color: "gray", fontSize: "16px" }} /> Like
            </p>
            <p>
              {" "}
              <FaRegComment style={{ color: "gray", fontSize: "16px" }} />{" "}
              Comment
            </p>
            <p>
              {" "}
              <FaWhatsapp style={{ color: "gray", fontSize: "16px" }} /> Send
            </p>
            <p>
              {" "}
              <RiShareForwardLine
                style={{ color: "gray", fontSize: "16px" }}
              />{" "}
              Share
            </p>
          </div>
          <hr/>

          <div className="d-flex align-items-center gap-2 mb-5">
            <img
              style={{ borderRadius: "50%", height: "30px" }}
              src="https://whalesai.com/static/media/ds-logo.23cc3eac8e316a53022f.png"
            />

            <input
              type="text"
              className=" py-2 px-2"
              placeholder="Comment as Whalesai"
              style={{ background: "#F1F2F6", width: "100%", border: "none", borderRadius: '30px' }}
            />

            <div></div>
          </div>
        </div>

        {/* <div style={{ width: "35%" }} className="flex-1 d-none d-sm-block "></div> */}
      </section>
    </div>
  );
};

export default FacebookPage;
