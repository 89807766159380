export const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  //   return `${minutes + "minutes" < 10 ? "0" : ""}${minutes}:${
  //     seconds + "seconds" < 10 ? "0" : ""
  //   }${seconds}`;

  return {
    hours: `${hours < 10 ? "0" : ""}${hours} hours `,
    minutes: `${minutes < 10 ? "0" : ""}${minutes} minutes `,
    seconds: ` ${seconds < 10 ? "0" : ""}${seconds} seconds`,
  };
};
