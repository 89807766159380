import styled from 'styled-components';

export const SpeechBubbleBottomLeft = styled.div`
  position: relative;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  max-width: 300px;
  text-align: center;
  color: #333;

  /* Tail of the speech bubble */
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 20px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: #f0f0f0 transparent;
    display: block;
    width: 0;
  }

  /* Optional styling to add a comic-like border */
  border: 2px solid black;
  box-shadow: 2px 2px 0px black;
`;

export const SpeechBubbleBottomRight = styled.div`
  position: relative;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  max-width: 320px;
  text-align: center;
  color: #333;
  margin-left: auto;

  /* Tail of the speech bubble on the bottom right */
  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 20px; /* Position the arrow on the right side */
    border-width: 10px 10px 0 10px;
    border-style: solid;
    border-color: #f0f0f0 transparent transparent transparent;
    display: block;
    width: 0;
  }

  /* Optional styling to add a comic-like border */
  border: 2px solid black;
  box-shadow: 2px 2px 0px black;
`;

export const SpeechBubbleBottomCenter = styled.div`
position: relative;
background-color: #f0f0f0;
border-radius: 8px;
padding: 10px;
max-width: 320px;
text-align: center;
color: #333;
margin: 0 auto; /* Centers the bubble horizontally */

/* Tail of the speech bubble on the bottom center */
&:after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%; /* Positions the arrow in the center */
  transform: translateX(-50%); /* Centers the arrow horizontally */
  border-width: 10px 10px 0 10px;
  border-style: solid;
  border-color: #f0f0f0 transparent transparent transparent;
  display: block;
  width: 0;
}

/* Optional styling to add a comic-like border */
border: 2px solid black;
box-shadow: 2px 2px 0px black;
`;


export const SpeechBubbleTopCenter = styled.div`
position: relative;
background-color: #f0f0f0;
border-radius: 8px;
padding: 10px;
max-width: 320px;
text-align: center;
color: #333;
margin: 0 auto; /* Centers the bubble horizontally */

/* Tail of the speech bubble on the top center */
&:before {
  content: "";
  position: absolute;
  top: -10px; /* Positions the arrow at the top */
  left: 50%; /* Positions the arrow in the center */
  transform: translateX(-50%); /* Centers the arrow horizontally */
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #f0f0f0 transparent;
  display: block;
  width: 0;
}

/* Optional styling to add a comic-like border */
border: 2px solid black;
box-shadow: 2px 2px 0px black;
`;

export const SpeechBubbleTopLeft = styled.div`
  position: relative;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  max-width: 320px;
  text-align: center;
  color: #333;

  /* Tail of the speech bubble on the top left */
  &:before {
    content: "";
    position: absolute;
    top: -10px; /* Positions the arrow at the top */
    left: 10px; /* Positions the arrow on the left side */
    border-width: 0 10px 10px 10px;
    border-style: solid;
    border-color: transparent transparent #f0f0f0 transparent;
    display: block;
    width: 0;
  }

  /* Optional styling to add a comic-like border */
  border: 2px solid black;
  box-shadow: 2px 2px 0px black;
`;

// max-width: 200px;

export const SpeechBubbleLeft = styled.div`
position: relative;
background-color: #f0f0f0;
border-radius: 8px;
padding: 10px;

text-align: center;
color: #333;
margin-left: 20px; /* Adds some space from the left edge */

/* Tail of the speech bubble on the left side */
&:before {
  content: "";
  position: absolute;
  top: 50%; /* Centers the arrow vertically */
  left: -10px; /* Positions the arrow on the left */
  transform: translateY(-50%); /* Centers the arrow vertically */
  border-width: 10px 10px 10px 0;
  border-style: solid;
  border-color: transparent #f0f0f0 transparent transparent;
  display: block;
  width: 0;
}

/* Optional styling to add a comic-like border */
border: 2px solid black;
box-shadow: 2px 2px 0px black;
`;


export const SpeechBubbleRight = styled.div`
position: relative;
background-color: #f0f0f0;
border-radius: 8px;
padding: 5px;
max-width: 300px;
text-align: center;
color: #333;
margin-left: 20px; /* Adds some space from the left edge */

/* Tail of the speech bubble on the left side */
&:before {
  content: "";
  position: absolute;
  top: 50%; /* Centers the arrow vertically */
  right: -10px; /* Positions the arrow on the left */
  transform: translateY(-50%); /* Centers the arrow vertically */
  border-width: 10px 10px 10px 0;
  border-style: solid;
  border-color: transparent #f0f0f0 transparent transparent;
  display: block;
  width: 0;
}

/* Optional styling to add a comic-like border */
border: 2px solid black;
box-shadow: 2px 2px 0px black;
`;








export const BubbleContainer = styled.div`
position: relative;
background: #00595c;
color: white;
padding: 10px;
border-radius: 10px;
max-width: 300px;
text-align: center;
margin-top: 20px;

&::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 20px;
  border-width: 10px;
  border-style: solid;
  border-color: #00595c transparent transparent transparent;
}
`;