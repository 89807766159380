import React, { useEffect } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./BuyCredits.css";
import Packages from "../../Components/Packages/Packages";

const BuyCreditsApps = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageHeading title={"PURCHASE"} />
      <div className="buy-credits-container container">
        <Packages />
      </div>
    </div>
  );
};

export default BuyCreditsApps;

const packages = [
  {
    "price": "5",
    "credits": "250",
    "expire": "1",
    "discount": "NO",
    "paylimit": "5",
    "background": "#05C0A2"
  },
  {
    "price": "100",
    "credits": "5000",
    "expire": "3",
    "discount": "20%",
    "paylimit": "80",
    "background": "#1D60EF"
  },
  {
    "price": "1000",
    "credits": "50000",
    "expire": "12",
    "discount": "40%",
    "paylimit": "600",
    "background": "#FA6918"
  },
]
