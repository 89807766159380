import React, { useEffect } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./BuyCredits.css";
import Packages from "../../Components/Packages/Packages";
import Packages2 from "../../Components/Packages2/Packages2";

const BuyCredits = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageHeading title={"PURCHASE"} />
      {/* <div className="buy-credits-container container">
        <Packages />
      </div> */}


      <section className="py-5" style={{ background: "#006c62" }}>
        <div className="container px-4">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h2 className="method-heading text-uppercase text-white mb-3">
              Method 1
              <br /> Do it yourself
            </h2>
          </div>
        </div>
      </section>

      <section style={{ backgroundColor: "#0a273f" }} className="pb-4 pb-md-5">
        <div className="container px-4 mx-auto">
          <Packages />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 0 0",
            }}
          >
           {" "}
          </div>
        </div>
      </section>
      <section className="py-5" style={{ background: "#006c62" }}>
        <div className="container px-4">
          <div className="d-flex flex-column justify-content-center align-items-center text-center">
            <h2 className="method-heading my-3 text-uppercase text-white">
              Method 2
              <br /> Let us do it for you
            </h2>
          </div>
        </div>
      </section>
      <section style={{ backgroundColor: "#0a273f" }} className="pb-4 pb-md-5">
        <div className="container px-4 mx-auto">
          <Packages2 />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "10px 0 0",
            }}
          >
            {" "}
          </div>
        </div>
        {/* <p className="text-center text-uppercase pay-text">
          Pay for 10 months and get 2 months free
        </p> */}
      </section>
    </div>
  );
};

export default BuyCredits;

// const packages = [
//   {
//     "price": "5",
//     "credits": "250",
//     "expire": "1",
//     "discount": "NO",
//     "paylimit": "5",
//     "background": "#05C0A2"
//   },
//   {
//     "price": "100",
//     "credits": "5000",
//     "expire": "3",
//     "discount": "20%",
//     "paylimit": "80",
//     "background": "#1D60EF"
//   },
//   {
//     "price": "1000",
//     "credits": "50000",
//     "expire": "12",
//     "discount": "40%",
//     "paylimit": "600",
//     "background": "#FA6918"
//   },
// ]
