import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import bannner  from './ssm.PNG'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SampleVideoModal({ modalShow, setModalShow, videoPosition }) {
  const handleClose = () => setModalShow(false);

  const videoLinks = [
    'https://backend.whalesai.com/public/hiwvideos/1080-1080-textvideo-normal.mp4',
    'https://backend.whalesai.com/public/hiwvideos/1080-1080-blackwhite.mp4',
    'https://backend.whalesai.com/public/hiwvideos/1080-1080-randomgradient.mp4',
    'https://backend.whalesai.com/public/hiwvideos/1080-1080-singlegradient.mp4',
    'https://backend.whalesai.com/public/hiwvideos/1080-1080-randomonecolor.mp4',
  ]

  const Names = [
    'Single Color',
    'B & W',
    'Color Gradient',
    'Gradient',
    'Colors'
  ]
  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalShow}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }} 
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2 text-white">
            <Typography variant="h6">Sample{videoPosition== 0 ? '':':'} {Names[videoPosition]}</Typography>
          </div>
          <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
        </div>
        </DialogTitle>
        
        <DialogContent>
          <video
            controls
            style={{ width: "100%" }}
            // src="/sample_video.mp4"
            // src='https://backend.whalesai.com/public/videos/1080-1080-MzFgltQkJn9.mp4'
            src={videoLinks[videoPosition]}
            autoPlay
            // "https://i.ibb.co/DYg2YgK/sample.jpg"
            // poster= {bannner}
            className=""
            type="video/mp4"
          />
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
