import React, { useEffect, useState } from "react";
import "../../Pages/PaymentMethods/PaymentMethods.css";
import { loadStripe } from "@stripe/stripe-js"; // Import Stripe
import { Elements } from "@stripe/react-stripe-js"; // Import Elements provider
import { AddCircleOutlined, ArrowDropUpOutlined } from "@mui/icons-material";
import PaymentMethodForm from "../../Pages/PaymentMethods/PaymentMethodForm";
import CardLists from "../../Pages/PaymentMethods/CardLists";
import MocUp from "../../Components/PageHeading/MocUp";
import PaymentMethodFormMocUp from "./PaymentMrthodFormMocUp";
import visaIcon from "./visa.png";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { SpeechBubbleBottomCenter, SpeechBubbleTopCenter } from "../SpeechBubble/Bubble";

// Your Stripe public key
const stripePromise = loadStripe("your-stripe-public-key-here");

const PaymentMethods = () => {
  const [showCardForm, setShowCardForm] = useState(false);
  const [isOK, setIsOK] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleAddPaymentMethod = () => {
    setShowCardForm(!showCardForm);
  };

  // useEffect(() => {
  //   window.scroll(0, 0);

  //   const openFormTimer = setTimeout(() => {
  //     setShowCardForm(true);

  //     const holdFormTimer = setTimeout(() => {
  //       setShowCardForm(false);
  //       setIsOK(true);
  //     }, 3000);

  //     return () => clearTimeout(holdFormTimer);
  //   }, 3000);

  //   return () => clearTimeout(openFormTimer);
  // }, []);

  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  
    const openFormTimer = setTimeout(() => {
      setShowCardForm(true);
  
      const holdFormTimer = setTimeout(() => {
        setShowCardForm(false);
        setIsOK(true);
  
        // Show SweetAlert after setting isOK, with a 2-second delay
        const alertTimer = setTimeout(() => {
          Swal.fire({
            title: 'Successfully Subscribed',
            icon: 'success',
            background: '#011225', // Background color
            color: 'white', // Text color
            confirmButtonColor: '#167649', // Optional: Button color
            timer: 2000,
          });
          navigate('/how-it-works-purchase-history');
        }, 2000); // 2 seconds delay
  
        return () => clearTimeout(alertTimer);
      }, 3000); // Hold the form for 3 seconds
  
      return () => clearTimeout(holdFormTimer);
    }, 3000); // Start after 3 seconds
  
    return () => clearTimeout(openFormTimer);
  }, []);

  return (
    <div>
      <MocUp
        title="PAYMENT METHODS"
        description="Subscribe and Pay"
        step="Step 4:"
      />
      <div
        className="container account-container pb-5"
        style={{ minHeight: "100vh" }}
      >
        <div className="mx-auto" style={{ maxWidth: "400px" }}>
          {/* <SpeechBubbleBottomCenter>Add Card Information</SpeechBubbleBottomCenter> */}
          <button
            onClick={() =>
              showCardForm === false
                ? handleAddPaymentMethod()
                : setShowCardForm(false)
            }
            className="add-payment-btn text-uppercase mt-2"
          >
            {showCardForm ? (
              <ArrowDropUpOutlined className="payment-add-icon me-2 fs-2" />
            ) : (
              <AddCircleOutlined className="payment-add-icon me-2" />
            )}
            Add Payment Method
          </button>

          {/* Conditionally show the Payment Form */}
          {showCardForm && (
            <PaymentMethodFormMocUp isOK={isOK} setIsOK={setIsOK} />
          )}
          {
            isOK && 
            <div>
              <div
            className="px-4 my-2 rounded d-flex align-items-center justify-content-between"
            style={{ background: "white", height: "50px" }}
          >
            <div className="d-flex align-items-center">
            <img src={visaIcon} alt="Visa" style={{ width: "40px", marginRight: "10px" }} />
              <p className="pt-3" style={{fontWeight: 'bold'}} 
              >VISA  4242</p>
            </div>

            <div
            style={{fontSize: '10px'}}
            className="d-flex align-items-center gap-2">
              <button
              style={{background: '#167649', color: 'white', width: '80px'}}
              className="btn btn-sm px-2">
                PAY
              </button>

              <button
              style={{background: '#BB2D3B', color: 'white', width: '80px'}}
              className="btn btn-sm px-2">
                REMOVE
              </button>
            </div>
          </div>
          {/* <SpeechBubbleTopCenter>Make Payment</SpeechBubbleTopCenter> */}
            </div>
          }
        </div>

        
      </div>
    </div>
  );
};

// Wrap your PaymentMethods component in the Elements provider
const WrappedPaymentMethodsPageMocUp = () => (
  <Elements stripe={stripePromise}>
    <PaymentMethods />
  </Elements>
);

export default WrappedPaymentMethodsPageMocUp;