// import React, { useContext, useEffect, useState } from "react";
// import "./SocialMedia.css";
// import PageHeading from "../../Components/PageHeading/PageHeading";
// import { NavLink, useNavigate } from "react-router-dom";
// import { UserAuthContext } from "../../Contexts/UserAuthContext";
// import { Grid } from "@mui/material";
// import {
//   AnalysisButton,
//   ButtonContainer,
//   CalenderButton,
//   DescriptionText,
//   GradientTextButton,
// } from "./style";
// import MocUp from "../../Components/PageHeading/MocUp";
// import StepButton from "../StepButton/StepButton";
// import swal from "sweetalert";
// import Swal from "sweetalert2";

// const LetUsDoItForYou = () => {
//   const [completeAnalysis, setCompleteAnalysis] = useState(false);
//   const [scheduled, setScheduled] = useState(false);
//   const [partialScheduled, setPartialScheduled] = useState(false);
//   const [scheduledData, setScheduledData] = useState([]);
//   const [result, setResult] = useState([]);
//   const [allTime, setAllTime] = useState("");
//   const { AIuser, AiLogOut } = useContext(UserAuthContext);
//   const navigate = useNavigate();
//   const [startAnalysis, setStartAnalysis] = useState(false);

//   useEffect(() => {
//     if (result.length > 0) setCompleteAnalysis(true);
//   }, [result]);

//   useEffect(() => {
//     window.scroll(0, 0);
//   }, []);

//   const handleAnalysis = () => {
//     setStartAnalysis(true);
//   };

//   // useEffect(() => {
//   //   const timer = setTimeout(() => {
//   //     Swal.fire({
//   //       // title: "Automated Guide",
//   //       text: `This is an automated guide that will walk you through all the steps in the "Let Us Do It for You" process. Simply press "Next" or "Previous" to navigate between steps. Each step will run automatically. Thank you!`,
//   //       icon: "info",
//   //       background: '#011225',
//   //       color: 'white'
//   //     });
//   //   }, 1000);

//   //   return () => clearTimeout(timer);
//   // }, []);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       Swal.fire({
//         text: `This is an automated guide that will walk you through all the steps in the "Let Us Do It for You" process. Simply press "Next" or "Previous" to navigate between steps. Each step will run automatically. Thank you!`,
//         icon: "info",
//         background: '#00595C',
//         color: 'white',
//       })
//     //   .then((result) => {
//     //     if (result.isConfirmed) {
//     //       // Speech synthesis
//     //       const msg = new SpeechSynthesisUtterance(
//     //         "Skill Development Opportunities: With AI and machine learning growing rapidly, new tools, techniques, and frameworks are emerging. This helps CS students diversify their skills. For those interested in AI, data science, and machine learning, there’s a huge scope."
//     //       );
//     //       window.speechSynthesis.speak(msg);
//     //     }
//     //   }
//     // );
//     }, 1000);

//     return () => clearTimeout(timer);
//   }, []);

//   return (
//     <div>
//       <MocUp
//         title="LET US DO IT FOR YOU"
//         description="Analysis"
//         step="Step 1:"
//       />
//       <section style={{ backgroundColor: "#172b4d" }}>
//         <div>
//           <p className="text-white w-2-3 text-center mx-auto pt-5">
//             Our SOCIAL MEDIA MARKETING MANAGER AI (SMMM AI) automatically
//             generates and uploads content to social media platforms based on
//             detailed analysis conducted by our AI.
//           </p>
//         </div>

//         <div className=" mt-5 pb-5">
//           <ButtonContainer>
//             <Grid container spacing={6}>
//               <Grid item md={6} sm={12} p={2} width={"100%"}>
//               {/* <p className="scrolling-text">Speech bubbles</p> */}
//                 {completeAnalysis ? (
//                   <AnalysisButton
//                   >
//                     View Analysis
//                   </AnalysisButton>
//                 ) : (
//                   <AnalysisButton
//                   >
//                     Analysis
//                   </AnalysisButton>
//                 )}
//                 {completeAnalysis ? (
//                   <GradientTextButton
//                     className="complete-analysis-animate2 p-visibility"
//                   >
//                     You have done your analysis. <br />
//                     Please click here to View.
//                   </GradientTextButton>
//                 ) : (
//                   <GradientTextButton
//                     className="complete-analysis-animate p-visibility"
//                   >
//                     You have not done your analysis. <br />
//                     Please click here to do.
//                   </GradientTextButton>
//                 )}

//                 {completeAnalysis ? (
//                   <GradientTextButton
//                     className="complete-analysis-animate2 span-visibility"
//                   >
//                     You have done your analysis. Please click here to view.
//                   </GradientTextButton>
//                 ) : (
//                   <GradientTextButton
//                     className="complete-analysis-animate span-visibility"
//                   >
//                     You have not done your analysis. Please click here to do.
//                   </GradientTextButton>
//                 )}
//               </Grid>
//               <Grid item md={6} sm={12} p={2} width={"100%"}>
//                 {/* <p style={{color: 'white'}} className="text-center">Speech bubbles</p> */}
//                 <CalenderButton
//                 >
//                   Calendars
//                 </CalenderButton>
//                 {partialScheduled && (
//                   <GradientTextButton
//                     className="complete-analysis-animate2 p-visibility"
//                   >
//                     You have done your scheduling partially. <br />
//                     Please click here to check.
//                   </GradientTextButton>
//                 )}
//                 {scheduled && (
//                   <GradientTextButton
//                     className="complete-analysis-animate2 p-visibility"
//                   >
//                     You have done your scheduling fully. <br />
//                     Please click here to check.
//                   </GradientTextButton>
//                 )}

//                 {!partialScheduled && !scheduled && (
//                   <GradientTextButton
//                     className="complete-analysis-animate p-visibility"
//                   >
//                     You have not planned your contents. <br /> Please click here
//                     to do.
//                   </GradientTextButton>
//                 )}

//                 {partialScheduled && (
//                   <GradientTextButton
//                     className="complete-analysis-animate2 span-visibility"
//                   >
//                     You have done your scheduling partially. <br />
//                     Please click here to check.
//                   </GradientTextButton>
//                 )}
//                 {scheduled && (
//                   <GradientTextButton
//                     className="complete-analysis-animate2 span-visibility"
//                   >
//                     You have done your scheduling fully. <br />
//                     Please click here to check.
//                   </GradientTextButton>
//                 )}

//                 {!partialScheduled && !scheduled && (
//                   <GradientTextButton
//                     className="complete-analysis-animate span-visibility"
//                   >
//                     You have not planned your contents. <br /> Please click here
//                     to do.
//                   </GradientTextButton>
//                 )}
//               </Grid>
//             </Grid>
//           </ButtonContainer>
//         </div>

//         <StepButton pre='' nex = '/how-it-works-analysis' text2='Click here for: Analysis' btn = '0' />

//       </section>
//     </div>
//   );
// };

// export default LetUsDoItForYou;

import React, { useContext, useEffect, useState } from "react";
import "./SocialMedia.css";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { NavLink, useNavigate } from "react-router-dom";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { Grid } from "@mui/material";
import {
  AnalysisButton,
  ButtonContainer,
  CalenderButton,
  GradientTextButton,
} from "./style";
import MocUp from "../../Components/PageHeading/MocUp";
import StepButton from "../StepButton/StepButton";
import Swal from "sweetalert2";
import styled from "styled-components"; // For styling the bubble
import {
  SpeechBubbleBottomLeft,
  SpeechBubbleBottomRight,
} from "../SpeechBubble/Bubble";

const LetUsDoItForYou = () => {
  const [completeAnalysis, setCompleteAnalysis] = useState(false);
  const { AIuser, AiLogOut } = useContext(UserAuthContext);
  const navigate = useNavigate();
  // const [completeAnalysis, setCompleteAnalysis] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [partialScheduled, setPartialScheduled] = useState(false);
  const [scheduledData, setScheduledData] = useState([]);
  const [result, setResult] = useState([]);
  const [allTime, setAllTime] = useState("");
  // const { AIuser, AiLogOut } = useContext(UserAuthContext);
  // const navigate = useNavigate();
  const [startAnalysis, setStartAnalysis] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [pause, setPause] = useState(false);

  useEffect(() => {
    if (result.length > 0) setCompleteAnalysis(true);
  }, [result]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleAnalysis = () => {
    setStartAnalysis(true);
  };


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     Swal.fire({
  //       text: `This is an automated guide that will walk you through all the steps in the "Let Us Do It for You" process. Simply press "Next" or "Previous" to navigate between steps. Each step will run automatically. Thank you!`,
  //       icon: "info",
  //       background: "#00595C",
  //       color: "white",
  //       timer: 3000,
  //     }).then(() => {
  //       // Set state to true after Swal is closed
  //       // setIsComplete(true);
  //     });
  //   }, 1000);

  //   return () => clearTimeout(timer);
  // }, []);
  useEffect(() => {
    const timer = setTimeout(() => {
      Swal.fire({
        html: `<p>This is an automated guide that will walk you through all the steps in the "Let Us Do It for You" process. Simply press "Next" or "Previous" to navigate between steps. Each step will run automatically. Thank you!</p>
               <p style="margin-bottom: 5px;">Do you want to start?</p>`,
        icon: "info",
        background: "#00595C",
        color: "white",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        buttonsStyling: true,
        customClass: {
          confirmButton: "mx-3 swal-ok-button",
          cancelButton: "mx-3 swal-cancel-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // Action if "Yes" is clicked
          setIsComplete(true);
        } else if (result.isDismissed) {
          navigate("/");
        }
      });
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <MocUp
        title="LET US DO IT FOR YOU"
        description="Analysis"
        step="Step 1:"
      />
      <section style={{ backgroundColor: "#172b4d" }}>
        <div>
          <p className="text-white w-2-3 text-center mx-auto pt-5">
            Our SOCIAL MEDIA MARKETING MANAGER AI (SMMM AI) automatically
            generates and uploads content to social media platforms based on
            detailed analysis conducted by our AI.
          </p>
        </div>

        <div className="mt-5 pb-5">
          <ButtonContainer>
            <Grid container spacing={6}>
              <Grid item md={6} sm={12} p={2} width={"100%"}>
                {/* <p style={{ color: 'white' }} className="text-center">Speech bubbles</p> */}
                <div className="mb-3">
                  {isComplete && (
                    <SpeechBubbleBottomLeft style={{ height: "80px" }}>
                      You can do your analysis of your business here.
                    </SpeechBubbleBottomLeft>
                  )}
                </div>
                {completeAnalysis ? (
                  <AnalysisButton>View Analysis</AnalysisButton>
                ) : (
                  <AnalysisButton>Analysis</AnalysisButton>
                )}
                {completeAnalysis ? (
                  <GradientTextButton className="complete-analysis-animate2 p-visibility">
                    You have done your analysis. <br />
                    Please click here to View.
                  </GradientTextButton>
                ) : (
                  <GradientTextButton className="complete-analysis-animate p-visibility">
                    You have not done your analysis. <br />
                    Please click here to do.
                  </GradientTextButton>
                )}

                {completeAnalysis ? (
                  <GradientTextButton className="complete-analysis-animate2 span-visibility">
                    You have done your analysis. Please click here to view.
                  </GradientTextButton>
                ) : (
                  <GradientTextButton className="complete-analysis-animate span-visibility">
                    You have not done your analysis. Please click here to do.
                  </GradientTextButton>
                )}
              </Grid>

              <Grid item md={6} sm={12} p={2} width={"100%"}>
                <div className="mb-3">
                  {isComplete && (
                    <SpeechBubbleBottomRight style={{ height: "80px" }}>
                      You can access the calendar for scheduling your social
                      media posts here.
                    </SpeechBubbleBottomRight>
                  )}
                </div>
                <CalenderButton>Calendars</CalenderButton>
                {partialScheduled && (
                  <GradientTextButton className="complete-analysis-animate2 p-visibility">
                    You have done your scheduling partially. <br />
                    Please click here to check.
                  </GradientTextButton>
                )}
                {scheduled && (
                  <GradientTextButton className="complete-analysis-animate2 p-visibility">
                    You have done your scheduling fully. <br />
                    Please click here to check.
                  </GradientTextButton>
                )}

                {!partialScheduled && !scheduled && (
                  <GradientTextButton className="complete-analysis-animate p-visibility">
                    You have not planned your contents. <br /> Please click here
                    to do.
                  </GradientTextButton>
                )}

                {partialScheduled && (
                  <GradientTextButton className="complete-analysis-animate2 span-visibility">
                    You have done your scheduling partially. <br />
                    Please click here to check.
                  </GradientTextButton>
                )}
                {scheduled && (
                  <GradientTextButton className="complete-analysis-animate2 span-visibility">
                    You have done your scheduling fully. <br />
                    Please click here to check.
                  </GradientTextButton>
                )}

                {!partialScheduled && !scheduled && (
                  <GradientTextButton className="complete-analysis-animate span-visibility">
                    You have not planned your contents. <br /> Please click here
                    to do.
                  </GradientTextButton>
                )}
              </Grid>
            </Grid>
          </ButtonContainer>
        </div>
        {
          isComplete && <div className="position-relative d-flex justify-content-center align-items-center">
          <button
            onClick={() => {
              setPause(true);
            }}
            className={`btn btn-sm  px-4 py-2 position-absolute ${pause? 'btn-secondary': 'btn-primary'}`}
            style={{ top: "60%", borderRadius: "8px", height: "35px" }}
          >
            Pause
          </button>
        </div>
        }

        {isComplete && (
          <StepButton
            pre=""
            nex="/how-it-works-analysis"
            text2="Click here for: Analysis"
            btn="0"
            pause= {pause}
          />
        )}
      </section>
    </div>
  );
};

export default LetUsDoItForYou;
