import React, { useState, useContext, useEffect } from "react";
import Spineer from "../../Components/CustomSpineer/Spineer";
import { Edit, Delete } from "@mui/icons-material";
import AddReactionIcon from '@mui/icons-material/AddReaction';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import usePagination from "../../hooks/UsePagination";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import EditNewsModal from "./EditNewsModal";
import AddNews from "../AdminUser/AddNews";
import SearchBar from "../AdminUser/SearchBar";
import ViewNews from "./ViewNews";
import axios from "axios";
import "./AdminNews.css";
import ViewComic from "./ViewComic";

const NewsPage = () => {
  const { allNews, setAllNews, getAllNewsData } = useContext(AdminAuthContext); // Fetch all news from context
  const [page, setPage] = useState(1);
  const [oneNews, setOneNews] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [newsModal, setNewsModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [userCountry, setUserCountry] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [sortColumn, setSortColumn] = useState("date"); // Initially sorting by date
  // const [sortOrder, setSortOrder] = useState("asc");
  const [comicModal, setComicModal] = useState(false);

  // console.log("Alll news here===>>>", allNews[2]);

  const PER_PAGE = 10;
  
  const filteredNews = allNews?.filter((news) => {
    const matchesSearchQuery =
      news.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      news.category.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesCategory = selectedCategory
      ? news.category.toLowerCase() === selectedCategory.toLowerCase()
      : true;

    return matchesSearchQuery && matchesCategory;
  });

  let count = Math.ceil(filteredNews?.length / PER_PAGE);
  let perPageData = usePagination(filteredNews, PER_PAGE);
  // Use sorted data

  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };

  // const sortedNews = filteredNews?.sort((a, b) => {
  //   if (sortOrder === "asc") {
  //     return a.category.localeCompare(b.category);
  //   } else {
  //     return b.category.localeCompare(a.category);
  //   }
  // });
  
  // it was working
  // const sortedNews = filteredNews?.sort((a, b) => {
  //   const columnToSortBy = {
  //     // date: a.createdAt.localeCompare(b.createdAt),
  //     date: new Date(a.createdAt.split(' ').reverse().join(' ')).getTime() - new Date(b.createdAt.split(' ').reverse().join(' ')).getTime(),
  //     title: a.title.localeCompare(b.title),
  //     category: a.category.localeCompare(b.category),
  //   };

  //   const order = sortOrder === "asc" ? 1 : -1;

  //   return columnToSortBy[sortColumn] * order;
  // });
  const sortedNews = filteredNews?.sort((a, b) => {
    // Helper function to parse the date string into a valid Date object
    const parseDate = (dateString) => {
      const [date, time] = dateString.split(' ');
      const [day, month, year] = date.split('-');
      const [hours, minutes, seconds] = time.split(':');
      return new Date(year, month - 1, day, hours, minutes, seconds); // Month is 0-based
    };
  
    const columnToSortBy = {
      date: parseDate(a.createdAt) - parseDate(b.createdAt),
      title: a.title.localeCompare(b.title),
      category: a.category.localeCompare(b.category),
    };
  
    const order = sortOrder === "asc" ? 1 : -1;
  
    return columnToSortBy[sortColumn] * order;
  });
  

  // const parseDate = (dateString) => {
  //   const [datePart, timePart] = dateString.split(" ");
  //   const [day, month, year] = datePart.split(":").map(Number);
  //   const [hour, minute, second] = timePart.split(":").map(Number);
  //   return new Date(year, month - 1, day, hour, minute, second);
  // };
  // const sortedNews = filteredNews?.sort((a, b) => {
  //   const order = sortOrder === "asc" ? 1 : -1;
  
  //   const columnToSortBy = {
  //     date: (parseDate(a.createdAt) - parseDate(b.createdAt)) * order,
  //     title: a.title.localeCompare(b.title) * order,
  //     category: a.category.localeCompare(b.category) * order,
  //   };
  
  //   return columnToSortBy[sortColumn];
  // });



  // const sortedNews = filteredNews?.sort((a, b) => {
  //   const order = sortOrder === "asc" ? 1 : -1;
  
  //   // Function to parse the date string in dd:mm:yyyy hr:mm:ss format
  //   const parseDate = (dateString) => {
  //     const [datePart, timePart] = dateString.split(' ');
  //     const [day, month, year] = datePart.split(':').map(Number);
  //     const [hour, minute, second] = timePart.split(':').map(Number);
  
  //     // Create a new Date object (months are 0-indexed in JavaScript)
  //     return new Date(year, month - 1, day, hour, minute, second);
  //   };
  //   const columnToSortBy = {
  //     date: parseDate(a.createdAt) - parseDate(b.createdAt),
  //     title: a.title.localeCompare(b.title),
  //     category: a.category.localeCompare(b.category),
  //   };
  
  //   return columnToSortBy[sortColumn] * order;
  // });
  
  const handleSort = (column) => {
    if (sortColumn === column) {
      // Toggle the order if the same column is clicked again
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      // If a new column is clicked, set it as the new sort column and default to ascending
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  
  // console.log('reverse news::::', allNews, allNews?.reverse())

  perPageData = usePagination(sortedNews, PER_PAGE);

  useEffect(() => {
    window.scrollTo(0, 0);

    axios
      .get("https://api.ipify.org/?format=json")
      .then((response) => {
        const ipAddress = response.data.ip;
        axios
          .get(`https://ipapi.co/${ipAddress}/json/`)
          .then((response) => {
            setUserCountry(response.data.country_name);
            console.log(
              "dataaaaaaaaaaaaaaaaaaaaaaa ========> :::",
              response.data.country_name
            );
          })
          .catch((error) => {
            console.error("Error fetching user country:", error);
            setUserCountry("Unknown");
          });
      })
      .catch((error) => {
        console.error("Error fetching user IP address:", error);
        setUserCountry("Unknown");
      });
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };



  const handleDelete = async (id) => {
    Swal.fire({
      text: "Are you sure you want to delete this news?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://backend.whalesai.com/api/v1/admin/news/delete/${id}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (response.ok) {
              getAllNewsData();
              Swal.fire({
                text: "News Deleted.",
                icon: "success",
                customClass: "modal_class_success",
                allowOutsideClick: false,
              });
            } else {
              getAllNewsData();
              Swal.fire({
                text: "Failed to delete the news.",
                icon: "error",
                background: "#011225",
                allowOutsideClick: false,
              });
            }
          })
          .catch((error) => {
            getAllNewsData();
            Swal.fire({
              text: "An error occurred while deleting the news.",
              icon: "error",
              background: "#011225",
              allowOutsideClick: false,
            });
          });
      }
    });
  };

  const handleEdit = (news) => {
    setOneNews(news);
    setModalOpen(true);
    // alert(news?.title);
  };

  const handleView = (news) => {
    setViewModal(true);
    setOneNews(news);
  };

  const handleComicNews = (news) => {
    setComicModal(true);
    setOneNews(news);
  }

  const breakTextAfterLimit = (text, limit) => {
    let result = "";
    let currentLength = 0;

    text.split(" ").forEach((word) => {
      // Add the word and a space
      if (currentLength + word.length > limit) {
        result += "<br />" + word + " "; // Insert a line break if limit is exceeded
        currentLength = word.length + 1; // Reset length to the new word length + space
      } else {
        result += word + " ";
        currentLength += word.length + 1;
      }
    });

    return result.trim(); // Return the processed string
  };

  return (
    <div className="container-fluid  text-white" style={{ color: "white" }}>
      <EditNewsModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        oneNews={oneNews}
        setRefetch={setRefetch} // Trigger refetch on update
      />

      <ViewNews
        viewModal={viewModal}
        setViewModal={setViewModal}
        oneNews={oneNews}
        setComicModal= {setComicModal}
        comicModal={comicModal}
      />

      <ViewComic
      comicModal={comicModal}
      setComicModal={setComicModal}
      oneNews={oneNews}
      />


      <h5 className="text-start mb-3 font-weight-bold">NEWS</h5>

      <div className="mb-3 d-flex align-items-center gap-2 position-relative">
        <SearchBar className="" handleSearch={handleSearch} />
        <select
          className="py-2 mt-0 rounded position-absolute top-0"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          style={{ left: 260 }}
        >
          <option value="">All Category</option>
          <option value="Business">Business</option>
          <option value="Science">Science</option>
          <option value="Singapore">Singapore</option>
          <option value="Technology">Technology</option>
          <option value="World">World</option>
        </select>
      </div>

      <section>
        <div
          className="pt-3 rounded my-2 mb-5 pb-3 pt-0 table-width-class"
          style={{ backgroundColor: "#3080AC" }}
        >
          <div className="mx-3">
            <div className="d-flex justify-content-end gap-2 mb-2 mr-3">
              <button
                onClick={() => setNewsModal(true)}
                className="btn btn-primary mb-1 admin-add-text "
              >
                ADD NEWS
              </button>
            </div>
          </div>

          <div className="table-responsive overflow-auto">
            <table
              className="table table-hover"
              style={{ backgroundColor: "#3080AC" }}
            >
              <thead className="bg-[#1a1c339a] table-text-size">
                <tr>
                  <th style={{ verticalAlign: "middle" }} scope="col">S/no</th>

                  <th
                    scope="col"
                    onClick={() => handleSort("date")}
                    style={{ cursor: "pointer",  verticalAlign: "middle"}}
                  >
                    Date & Time{" "} 
                    <br/>
                    <span style={{ fontSize: "10px" }}>(SG Time)</span>
                  </th>

                  <th
                    scope="col"
                    onClick={() => handleSort("title")}
                    // style={{ width: "300px" }}
                    style={{ cursor: "pointer",  verticalAlign: "middle"}}
                  >
                    News Title
                  </th>
                  <th
                    scope="col"
                    onClick={() => handleSort("category")}
                    style={{ cursor: "pointer",  verticalAlign: "middle"}}
                  >
                    Category
                  </th>
                  <th
                  style={{ verticalAlign: "middle"}}
                  scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>

              <tbody
                className="table-text"
                style={{ backgroundColor: "#3080AC" }}
              >
                {allNews ? (
                  perPageData?.currentData()?.map((content, index) => (
                    <tr>
                      <td className="py-3">{(page - 1) * 10 + index + 1}</td>
                      <td className="py-3">
                        {content?.createdAt?.slice(0, 10)} <br />
                        {content?.createdAt?.slice(11, 19)}
                      </td>

                      <td
                        className="py-3 "
                        // style={{
                        //   width: "100%",
                        //   maxWidth: "300px",
                        //   whiteSpace: "normal",
                        //   overflowWrap: "break-word",
                        //   wordBreak: "break-word",
                        // }}
                      >
                        {/* {content.title} */}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: breakTextAfterLimit(content?.title, 60),
                          }}
                        />
                      </td>

                      <td className="py-3">{content?.category}</td>
                      <td className="text-center">
                        <div className="btn-group gap-2" role="group">
                          <Link
                            onClick={() => {
                              handleView(content);
                            }}
                            className="btn rounded-circle btn-sm icon-body btn-success"
                          >
                            <RemoveRedEyeIcon className="action-icon" />
                          </Link>
                          <Link
                            onClick={() => {
                              handleComicNews(content);
                            }}
                            className={`btn rounded-circle btn-sm icon-body ${content?.comicSummary? 'btn-info' : 'btn-secondary'}`}
                            style={{
                              color: 'red',
                              pointerEvents: content?.comicSummary ? 'auto' : 'none', // Disable clicks if comicSummary is missing
                              opacity: content?.comicSummary ? 1 : 0.5, // Adjust opacity for a disabled look
                            }}
                          >
                            <AddReactionIcon className="action-icon" />
                          </Link>

                          <Link
                            onClick={() => {
                              handleEdit(content);
                            }}
                            className="btn rounded-circle btn-sm icon-body btn-success"
                          >
                            <Edit className="action-icon" />
                          </Link>
                          <button
                            className="btn rounded-circle btn-sm icon-body btn-danger"
                            onClick={() => handleDelete(content._id)}
                          >
                            <Delete className="action-icon" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="6">
                      <Spineer />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <CustomPagination
            page={page}
            count={count}
            onChange={handlePageChange}
          />
          <AddNews
            show={newsModal}
            refetch={() => {}}
            setRefetch={() => {}}
            setNewsModal={setNewsModal}
            userCountry={userCountry}
            onHide={() => setNewsModal(false)}
          />
        </div>
      </section>
    </div>
  );
};
export default NewsPage;
