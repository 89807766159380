import React from "react";
import ReactMarkdown from "react-markdown";
import RWsample from "./RWsample";
import { FaCheckSquare } from "react-icons/fa";

const SU = ({ inputText, isGenerating, text, presetSelected, wordRef }) => {
  console.log(inputText);
  const formattedText = inputText.replace(/\n\n/g, '<br><br>').replace(/\n/g, '<br>');
  return (
    <>
      <p class="preset-title">Rewrite</p>
      {inputText ? (
          <>
          <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what you enter.</h6>
            {/* <p class="text-muted">{inputText}</p> */}
            <p className="text-muted" dangerouslySetInnerHTML={{ __html: formattedText }}></p>

            {isGenerating == true ? (
              <p
                class="text- mb-0 mt-2"
                style={{
                  fontSize: "14px",
                  color: "#A020F0",
                }}
              >
                Processing...
              </p>
            ) : (
              <>
                {text && (
                  <p
                    style={{
                      color: "black",
                    }}
                    class="text- mb-0 text-"
                  >
                    {wordRef.current && (
                      <>
                      <h6 className='d-flex align-items-center gap-2' style={{color: '#011225'}}> <FaCheckSquare style={{color: '#55C7A8'}} /> This is what we generate for you.</h6>
                        <p style={{ color: "black" }}>
                          <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                      </>
                    )}
                  </p>
                )}
              </>
            )}
          </>
      ) : (
        <>
          {/* <p className="text-muted" style={{ fontSize:"14px" }}>
          A robot is a programmable machine designed to perform tasks autonomously or semi-autonomously, often assisting or replicating human activities. Equipped with sensors and control systems, robots are used across industries, from manufacturing to healthcare, and can operate in environments inaccessible to humans. As robotics and AI advance, robots are becoming essential tools in work, exploration, and everyday life.
          </p>

          <p
     style={{ color: "black",fontSize:"14px" }}
          >
            "Exploring the Role of Robots: From Manufacturing to Healthcare, How These Programmable Machines are Revolutionizing Industries and Enhancing Human Activities"
          </p> */}
          <RWsample/>

          <p style={{ color: "black",fontSize:"14px" }}>
            <ReactMarkdown>{presetSelected.label}</ReactMarkdown>
          </p>
        </>
      )}
    </>
  );
};

export default SU;
