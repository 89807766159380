import React, { useEffect } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./Schedule.css";
import ScheduleHead from "./ScheduleHead";

import ScheduleSteps from "./ScheduleSteps";
import ScheduleContentType from "./ScheduleContentType";
import ScheduleChannel from "./ScheduleChannel";

const Schedule = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PageHeading title={"SCHEDULE FOR SOCIAL MEDIA"} />
      <div className="">
        <ScheduleHead />
        <ScheduleSteps />
        <ScheduleContentType />
        <ScheduleChannel />
      </div>
    </div>
  );
};

export default Schedule;
