import React from "react";
import "./PageHeading.css";

const PageHeading = ({ title, description }) => {

  console.log('Page Title',title);
  return (
    <div className="page-heading-container">
      {
        title == 'Text Video AI' || title  == 'Comic Video AI' ? <h1>{title}</h1> : <h1 className="text-uppercase">{title}</h1>
      }
      
      <p>{description}</p>
    </div>
  );
};

export default PageHeading;
