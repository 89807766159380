import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import moneyExchangeIcon from "../../Assets/Images/transaction-data.png";
import muteIcon from "../../Assets/Images/mute-icon.png";
import "./CompanionAi.css";
import arrowIcon from "../../Assets/Images/arrow-icon.png";
import { Modal, Button, Tooltip, Form, Select, Input, Alert } from "antd";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CompanionVoiceSelect from "./CompanionVoiceSelect";
import { voices } from "../VideoAi/api";
import { Howl } from "howler";
import { useRef } from "react";
import { GoMute, GoUnmute } from "react-icons/go";
import { IoMdSend } from "react-icons/io";
import ReactMarkdown from "react-markdown";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import Avatar from "../Gaming/Avatar";
import { handleAiChatCompletions } from "../../utils/AiApiFunctions";

const CompanionAiApps = () => {
  const { AIuser, userRefetch, setUserRefetch ,setTokenLocal,tokenLocal} = useContext(UserAuthContext);
  const [generatingtext, setGeneratingText] = useState("");
  const [text, setText] = useState("");
  const [questions, setQuestions] = useState("What is your name?");
  const [userName, setUserName] = useState("");
  const [chatbotName, setChatbotName] = useState("");
  const [gender, setGender] = useState("Male");
  const [percent, setPercent] = useState(0);
  const [nameSubmitted, setnameSubmitted] = useState(false);
  const [defaultValue, setDefaultValue] = useState("");
  const [voice, setVoice] = useState(defaultValue);
  const [usingChat, setUsingChat] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isRestarted, setIsRestarted] = useState(false);
  const [audioDuration, setAudioDuration] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isShort, setIsShort] = useState(true);
  const [isHaveViewMessage, setIsHaveViewMessage] = useState(false);

  const [messages, setMessages] = useState([
    { author: chatbotName, body: `Hi ${userName}, How can I help you?` },
  ]);
  const [inputText, setInputText] = useState("");

  const [isGenerating, setIsGenerating] = useState(false);
  const [nextQues, setNextQues] = useState("1st");

  const inputRef = useRef(null);
  const divRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [voiceCount, setVoiceCount] = useState(1);
  const [finalSubmit, setfinalSubmit] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [words, setWords] = useState(0);
  const [audiocontent, setAudioContent] = useState(0);
  const [sound, setSound] = useState(null);
  const [viewMessages, setViewMessages] = useState([]);
  const inputRef2 = useRef(null);
  const wordRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [transactionId, setTransactionId] = useState("");
  const { token } = useParams();
  // const [AIuser, setAIuser] = useState(null);
  // const [userRefetch, setUserRefetch] = useState(false);



  useEffect(() => {
    if (token) {
      
      localStorage.setItem("aiUser",token)
      setTokenLocal(token)
      if(AIuser){
        navigate("/consultant-ai-app")
      }
      // axios
      //   .get(
      //     "https://backend.whalesai.com/api/dashboard/user_signup-signin/current-user",
      //     {
      //       headers: {
      //         authorization: `Bearer ${token}`,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     if (res.status === 200) {
      //       setAIuser(res.data?.result);
      //       console.log("userdata : " + res.data.result)
      //     }
      //   })
      //   .finally(() => { });
      // setUserRefetch(false);
    }
  }, [userRefetch, token,AIuser]);
  


  useEffect(() => {
    // generate a random 8 character alpha numeric string

    const randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz01234567891011121314156655";
    let result = "";
    for (let i = 0; i < 8; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    setTransactionId(result);
  }, []);

  // show the user name in the first question of the chatbot
  const copyMsgs = messages;
  copyMsgs[0].body = `Hi ${userName}, How can I help you?`;

  const handleScroll = (event) => {
    if (event.deltaY > 0) {
      setIsScrolling(false);
    } else if (event.deltaY < 0) {
      setIsScrolling(true);
    }
  };

  useEffect(() => {
    // if the text is generating then run the below code
    if (isGenerating === true) {
      const handleScroll = () => {
        const scrollTop = inputRef?.current?.scrollTop;
        setIsScrollingUp(scrollTop < inputRef?.current?._prevScrollTop);
        inputRef.current._prevScrollTop = scrollTop;
      };

      inputRef.current._prevScrollTop = 0;
      inputRef.current.addEventListener("scroll", handleScroll);
      const ref = inputRef.current;

      return () => {
        if (ref) {
          ref.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [generatingtext, isGenerating]);

  useEffect(() => {
    if (isScrolling === false && isScrollingUp === false) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
  }, [text, isScrolling, isScrollingUp]);

  useEffect(() => {
    const worker = new Worker("/worker.js");

    worker.addEventListener("message", (event) => {
      const { text } = event.data;

      // setIsDoneGenerating(isDone);
      if (text !== "Processing...") {
        setText(text);
      }

      setMessages([...messages, { author: chatbotName, body: text }]);
      setInputText("");

      inputRef.current.focus();
    });

    worker.postMessage({ generatingtext, words });

    return () => {
      worker.terminate();
    };
  }, [generatingtext, isGenerating]);

  const handleSubmitCredits = async (e) => {
    const deductedCredits = AIuser.credits - 1;

    // console.log("question", question, conversation);
    await axios
      .put(
        "https://backend.whalesai.com/api/dashboard/user_signup-signin/update-credits",
        { email: AIuser?.email, credits: deductedCredits },
        {
          headers: {
            authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserRefetch(!userRefetch);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsHaveViewMessage(false);

    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
    localStorage.setItem("inputText", inputText);

    if (AIuser.credits > 0) {
      setIsGenerating(true);
      setGeneratingText(() => "");
      setText("");
      setIsScrolling(false);
      setIsScrollingUp(false);
      setMessages([...messages, { author: userName, body: inputText }]);

      const prompt = messages.map((message) => message.body).join("\n");
      let question = `${prompt}\n${userName}: ${inputText}\n${chatbotName}:`;

      const response = await handleAiChatCompletions(question, "CHAT");

      if (response) {
        handleSubmitCredits();
        const transactionRes = await axios.post(
          "https://backend.whalesai.com/api/v1/credits-transaction/create",
          {
            aiType: "Consultant Ai",
            transactionId: transactionId,
            requestQues: inputText,
            response: response,
            credit: 1,
            userId: AIuser?._id,
            userName: userName,
            companionName: chatbotName,
            companionAiVoice: voice,
            companionAiGender: gender,
            // userId: "6468c8ab7f567716c118ad6c",
          }
        );
        setGeneratingText(response);
        handleSynthesize(response, voice);
        setIsGenerating(false);
      } else {
        setGeneratingText("Invalid prompt!");
      }

      // await axios
      //   .post(
      //     "https://backend.dsl.sg/api/v1/chatgpt/gpt4",
      //     { question: question, preset: "CHAT" },
      //     {
      //       headers: {
      //         authorization: `Bearer dsltoken`,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     if (res.status === 200 && res.data?.outputMessage) {
      //       handleSubmitCredits();
      //       setGeneratingText(res.data?.outputMessage);
      //       handleSynthesize(res.data?.outputMessage, voice);
      //       setIsGenerating(false);
      //     } else {
      //       setGeneratingText("Invalid prompt!");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => { });
    } else {
      setShowAlert(true);
    }
  };

  const handleClearInput = () => {
    document.getElementById("inputText").value = "";
    setInputText("");
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      inputText !== "" &&
      !event.shiftKey &&
      window.innerWidth > 1000
    ) {
      if (divRef.current) {
        divRef.current.scrollIntoView({ behavior: "smooth" });
      }
      event.preventDefault();
      event.stopPropagation();
      handleSubmit(event);
    }

    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setInputText(inputText + "\n");
    }

    if (window.innerWidth < 1000 && event.key === "Enter") {
      event.preventDefault();
      setInputText(inputText + "\n");
    }
  };

  const onFinish = (values) => {
    if (userName && chatbotName && finalSubmit) {
      setnameSubmitted(true);
      setUsingChat(true);

      handleSynthesize("Hi " + userName + ", how can I help you?", voice);

      if (divRef.current) {
        divRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setIsShort(false);
    }
  };

  const handlePercent = (value) => {
    if (value == "1st") {
      setPercent(35);
    } else if (value == "2nd") {
      setPercent(70);
    } else {
      setPercent(100);
      // make showAvatar true after 3seconds
      setTimeout(() => {
        // setShowAvatar(true);
      }, 3000);
    }
  };

  const onChange = (value) => {
    setGender(value);
  };

  const onChangeVoices = (value) => {
    const selectedVoices = voices?.find(
      (voice) => voice.label === value.target.value
    );
    setVoice(selectedVoices.value);
    console.log(selectedVoices.value);

    setDefaultValue(selectedVoices);
    const latestInput = localStorage.getItem("latestInput");
    handleSynthesize(latestInput, selectedVoices.value);
    // setGender(value);
  };

  useEffect(() => {
    return () => {
      if (sound) {
        sound.stop();
      }
    };
  }, [sound]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    if (sound) {
      sound.mute(!isMuted);
    }
  };

  const handleSynthesize = async (input, voiceParam) => {
    try {
      localStorage.setItem("latestInput", input);
      console.log("voiceParam", voiceParam);
      const splittedVoice = voiceParam?.split("-");

      // setVoice(voices?.[randomIndex]?.value);

      const randomVoice = defaultValue?.value;
      const splittedRandomVoice = randomVoice?.split("-");

      const response = await fetch(
        "https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyBnaoRoWAnWm6poRcPrETHcYF0LZryXzw8",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            input: {
              text: input,
            },

            voice: {
              languageCode: voiceParam
                ? `${splittedVoice?.[0]}-${splittedVoice?.[1]}`
                : `${splittedRandomVoice?.[0]}-${splittedRandomVoice?.[1]}`,
              name: voiceParam ? voiceParam : randomVoice,
            },

            audioConfig: {
              audioEncoding: "MP3",
            },
          }),
        }
      );

      const data = await response.json();
      const audioContent = data.audioContent;

      setAudioContent(audioContent);

      // create a new Howler.js sound object
      const sound = new Howl({
        src: [`data:audio/mp3;base64,${audioContent}`],
        mute: isMuted,
        onload: function () {
          const duration = sound.duration();
          setAudioDuration(duration);
        },
        onplay: function () {
          setIsPlaying(true);
        },
        onend: function () {
          setIsPlaying(false);
        },
      });

      // play the sound automatically
      sound.play();
      setSound(sound);
      setIsPlaying(true); // set playing state to true initially
    } catch (err) {
      console.log(err);
    }
  };

  const randomIndex = Math.floor(Math.random() * voices?.length);
  useEffect(() => {
    // scroll to top on initial render
    window.scrollTo(0, 0);
    setDefaultValue(voices?.[randomIndex]);
    setVoice(voices?.[randomIndex]?.value);
  }, []);



  useEffect(() => {
    let i = 0;
    // let textQ = "What is your name?\n\nWhom you want to chat with?";
    let textQ;
    // if (nextQues == "1st") {
    // textQ = "What is your name?";
    // if (!loaded) {
    //   textQ = "Loading....... Please Wait!";
    // } else {
    //   textQ = "What is your name?";
    // }
    // if (isRestarted === false) {
    //   if (loaded) {
    //     handleSynthesize(
    //       "What is your name?",
    //       voiceCount === 1 ? voices?.[randomIndex]?.value : voice
    //     );
    //   }
    // }

    //   textQ = "What is your name?";
    //   if (isRestarted === false) {
    //     handleSynthesize(
    //       "What is your name?",
    //       voice ? voice : voices?.[randomIndex]?.value
    //     );
    //   }
    // }
    if (nextQues == "1st") {
      // textQ = "What is your name?";
      if (!loaded) {
        textQ = "Loading....... Please Wait!";
      } else {
        textQ = "What is your name?";
      }
      if (isRestarted === false) {
        if (loaded) {
          handleSynthesize("What is your name?", voice);
        }
      }
    } else if (nextQues == "2nd") {
      textQ = "Whom you want to chat with?";
      if (isRestarted === false) {
        handleSynthesize(
          "Whom you want to chat with?",
          // voice ? voice : voices?.[randomIndex]?.value
          voice
        );
      }
    } else {
      textQ = "What is your gender?";
      if (isRestarted === false) {
        handleSynthesize("What is your gender?", voice);
      }
    }
    const interval = setInterval(() => {
      setQuestions(textQ.slice(0, i));
      i++;
      if (i > textQ.length) {
        clearInterval(interval);
      }
    }, 80);

    return () => clearInterval(interval);
  }, [nextQues, loaded, voiceCount, voice]);

  const creditTolcalString = AIuser?.credits?parseInt(Math.floor(AIuser?.credits)).toLocaleString():"0";


  useEffect(() => {
    if (location?.state !== null) {
      setIsHaveViewMessage(true);
      setUsingChat(location.state.usingChat);
      setVoice(location.state.companionAiVoice);
      setGender(location.state.companionAiGender);
      setViewMessages(location.state.messages);
      const selectedVoices = voices?.find(
        (voice) => voice.value === location.state.companionAiVoice
      );
      setDefaultValue(selectedVoices);
    }
  }, [location?.state]);



  return (
    <div className="h-100 companion-ai-container">
      <PageHeading title={"CONSULTANT AI"} description={'The Consultant AI is designed to assist with your social media marketing efforts.'} />

      <div className="container companion-ai-main">
        <div className="CreditDiv">
          <button className="credits-button">
            CREDITS : {creditTolcalString}
          </button>
          <button
            className="buy-credits-button"
            onClick={() => navigate("/purchase")}
          >
            BUY CREDITS
          </button>
          <button
            onClick={() => navigate("/transaction")}
            className="money-exchange-button"
          >
            <img src={moneyExchangeIcon} alt="image" />
          </button>
        </div>
        <p className="text-white mb-0 mt-2 creditText">
          Every request is 1 credit.
        </p>
        <div className="selectDiv mt-4">
          <CompanionVoiceSelect
            onChangeVoices={onChangeVoices}
            defaultValue={defaultValue}
            setDefaultValue={setDefaultValue}
            setVoice={setVoice}
            voices={voices}
          />

          {isMuted ? (
            <Tooltip placement="top" title={"Unmute"}>
              <GoUnmute
                className="chatai-icon-styles text-light"
                style={{
                  marginLeft: "5px",

                  fontSize: "28px",
                  cursor: "pointer",
                }}
                onClick={toggleMute}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="top" title={"Mute"}>
              <GoMute
                className="chatai-icon-styles text-light"
                style={{
                  marginLeft: "5px",
                  fontSize: "28px",

                  cursor: "pointer",
                }}
                onClick={toggleMute}
              />
            </Tooltip>
          )}
          {/* <img
            className="mute-icon"
            src={muteIcon}
            alt="image"
            // onClick={handlePlayButton}
          /> */}
        </div>
        <div>
          {
            token && AIuser && <Avatar
            string={gender}
            generatingtext={generatingtext}
            defaultValue={defaultValue}
            voice={voice}
            voices={voices}
            isPlaying={isPlaying}
            loaded={loaded}
            setLoaded={setLoaded}
            isShort={isShort}
          ></Avatar>
          }
        </div>
        <div
          className="first-field-container"
          // ref={inputRef}
          // // onScroll={handleScroll2}
          // onWheel={handleScroll}
        >
          <div className="companion-res-area p-3">
            <div
              className=""
              ref={inputRef}
              // onScroll={handleScroll2}
              onWheel={handleScroll}
            >
              <div class="media mb-3">
                <>
                  <div class="media-body ml-3" ref={divRef}>
                    <div
                      className="text-light rounded  text-start ask-ai-fonts copy-contents"
                      ref={wordRef}
                    >
                      {showAlert == false ? (
                        <>
                          {usingChat ? (
                            <>
                              {isHaveViewMessage ? (
                                <>
                                  {viewMessages?.map((message, i) => {
                                    return (
                                      console.log("viewMessages", message),
                                      (
                                        <div key={i}>
                                          <div
                                            style={{
                                              color: "black",
                                            }}
                                          >
                                            <span
                                              className="chat-author text-uppercase"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                            >
                                              {message?.userName}:
                                            </span>{" "}
                                            {message.userQues}
                                          </div>
                                          <div
                                            style={{
                                              color: "black",
                                            }}
                                          >
                                            <span
                                              className="chat-author text-uppercase"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                            >
                                              {message?.companionName}:
                                            </span>{" "}
                                            {message.companionAns}
                                          </div>
                                        </div>
                                      )
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {messages?.map((message, i) => {
                                    const isHuman = message.author === userName;
                                    return (
                                      <div key={i}>
                                        {isHuman ? (
                                          <div
                                            style={{
                                              color: "black",
                                            }}
                                          >
                                            <span
                                              className="chat-author text-uppercase"
                                              style={{
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                            >
                                              {userName}:
                                            </span>{" "}
                                            {message.body}
                                          </div>
                                        ) : (
                                          <>
                                            {message.body && (
                                              <div
                                                style={{
                                                  color: "black",
                                                }}
                                              >
                                                <span
                                                  className="chat-author text-uppercase"
                                                  style={{
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  {chatbotName}:
                                                </span>{" "}
                                                {message.body}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <p class="mb-0 text-dark">
                                {/* {questions} */}
                                <ReactMarkdown>{questions}</ReactMarkdown>
                                {/* What is your name?
                              <br />
                              Whom you want to chat with? */}
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Alert
                            message="You don't enough credits! Please buy credits to continue."
                            type="error"
                            showIcon
                          />
                        </>
                      )}
                    </div>
                  </div>
                  {/* ) : (
                  <div className="text-center mx-auto">
                    {" "}
                    <PropagateLoader
                      color="white"
                      loading
                      size={window.innerWidth <= 700 ? 10 : 15}
                    />
                  </div>
                )} */}
                </>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
        {nameSubmitted ? (
          <>
            <div className="second-field-container position-relative">
              <form className="text-ai-rep-form " onSubmit={handleSubmit}>
                <textarea
                  className="second-text-area"
                  type="text"
                  lang="en"
                  placeholder=""
                  value={inputText}
                  id="inputText"
                  name="inputBox"
                  onKeyDown={handleKeyDown}
                  ref={inputRef2}
                  onChange={(e) => {
                    setInputText(e.target.value);
                    setUsingChat(true);
                    setText("");
                  }}
                ></textarea>
                <button
                  className="arrow-button arrow"
                  onClick={handleSubmit}
                  disabled={inputText.length === 0}
                >
                  <img src={arrowIcon} alt="image" />
                </button>
              </form>
            </div>

            <div
              style={{ marginBottom: "138px", marginTop: "10px" }}
              className="ai-clearbtn"
            >
              {/* <button className="credits-button mt-2">
                EVERY REQUEST IS 10 CREDITS
              </button> */}
              {inputText.length > 0 && (
                <button
                  className="comp-clear-button "
                  onClick={handleClearInput}
                >
                  CLEAR
                </button>
              )}
            </div>
          </>
        ) : (
          <div>
            <Form
              name="normal_login"
              className="login-form mt-2"
              initialValues={{
                remember: true,
              }}
              layout="vertical"
              onFinish={onFinish}
            >
              {nextQues == "1st" ? (
                <>
                  <Form.Item
                    className="mb-2"
                    name="userName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your Name!",
                      },
                    ]}
                    label="What is your name?"
                    required
                    // tooltip="This is a required field"
                  >
                    <Input
                      disabled={!loaded}
                      className="bg-white text-dark"
                      placeholder=""
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </Form.Item>
                </>
              ) : nextQues == "2nd" ? (
                <Form.Item
                  className="mb-2"
                  name="chatbotName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your chatbot Name!",
                    },
                  ]}
                  label="Whom you want to chat with?"
                  required
                  // tooltip="This is a required field"
                >
                  <Input
                    placeholder=""
                    onChange={(e) => setChatbotName(e.target.value)}
                  />
                </Form.Item>
              ) : nextQues == "3rd" ? (
                <select
                  className="selector-second mb-2"
                  name=""
                  id=""
                  onChange={onChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              ) : null}
              {nextQues == "1st" ? (
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button text-uppercase"
                    variant="contained"
                    onClick={() => {
                      if (userName) {
                        handlePercent("1st");
                        setNextQues("2nd");
                        setVoiceCount(voiceCount + 1);
                      }
                      divRef.current.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    Next
                  </Button>
                </Form.Item>
              ) : nextQues == "2nd" ? (
                <>
                  <div className="d-flex justify-content-start text-uppercase">
                    <Form.Item>
                      <Button
                        type="warning"
                        // htmlType="submit"
                        className="login-form-button me-2 bg-warning text-uppercase"
                        onClick={() => {
                          setNextQues("1st");
                          setVoiceCount(voiceCount + 1);
                          setPercent(0);
                        }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType={"submit"}
                        className="login-form-button text-uppercase"
                        onClick={() => {
                          if (chatbotName) {
                            handlePercent("2nd");
                            setNextQues("3rd");
                            setVoiceCount(voiceCount + 1);
                          }
                          divRef.current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }}
                      >
                        Next
                      </Button>
                    </Form.Item>
                  </div>
                </>
              ) : nextQues == "3rd" ? (
                <>
                  <div className="d-flex justify-content-start text-uppercase">
                    <Form.Item>
                      <Button
                        type="warning"
                        // htmlType="submit"
                        className="login-form-button me-2 bg-warning text-uppercase"
                        onClick={() => {
                          setNextQues("2nd");
                          setVoiceCount(voiceCount + 1);
                          setPercent(35);
                        }}
                      >
                        Back
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        // disabled={gender == ""}
                        htmlType={"submit"}
                        className="login-form-button text-uppercase"
                        onClick={() => {
                          setfinalSubmit(true);
                        }}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </>
              ) : null}
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanionAiApps;
