import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { UserAuthContext } from '../../Contexts/UserAuthContext';
import Loader from '../Loader/Loader';

const RequireUser = () => {
  const location = useLocation();
  const lsToken = localStorage.getItem('aiUser');

  if (!lsToken) {
    // AiLogOut();
    return (
      <Navigate to={'/sign-in'} state={{ from: location.pathname }} replace />
    );
  }

  return <Outlet />;
};

export default RequireUser;
