import React from "react";

const presets = [
  "Ask Anything",
  // "Chat",
  "Classification",
  // "English To Tamil",
  // "Explain Code",
  // "Grammatical Standard English",
  // "Natural Language To Python",
  // "Parse Unstructured Data",
  "Q&A",
  // "Summarize For a 2nd Grader",
  // "Text To Command",
  "Movie To Emoji",
  // "JavaScript To Python",
  "Restaurant Reviewer",
  "Advertisement",
  // "Micro Horror Story Creator",
  "Summarize",
  "Rewrite",
];

const TextAiPresets = ({
  setPreset,
  preset,
  setPresetSelected,
  text,
  inputText,
  setInputText,
  setText,
  setGeneratingText,
  setMessages,

  setUsingChat,
}) => {
  const copyPreset = [...presets];
  const sortedPresets = copyPreset.sort();

  // Choose a random preset when the component mounts
  React.useEffect(() => {
    // const randomIndex = Math.floor(Math.random() * presets.length);
    const randomIndex = 5;
    setPreset(presets[randomIndex]);

    if (randomIndex === 3) {
      setPresetSelected({
        option: "GSE",
        label: `Correct this to standard English:\n\n${
          !inputText ? "She no went to the market." : inputText
        }`,
      });
    } else if (randomIndex === 8) {
      setPresetSelected({
        option: "SFG",
        label:
          "Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is a gas giant with a mass one-thousandth that of the Sun, but two-and-a-half times that of all the other planets in the Solar System combined. Jupiter is one of the brightest objects visible to the naked eye in the night sky, and has been known to ancient civilizations since before recorded history. It is named after the Roman god Jupiter.[19] When viewed from Earth, Jupiter can be bright enough for its reflected light to cast visible shadows,[20] and is on average the third-brightest natural object in the night sky after the Moon and Venus.",
      });
    } else if (randomIndex === 9) {
      setPresetSelected({
        option: "TTC",
        label: `Convert this text to a programmatic command:
  
          Example: Ask Constance if we need some bread
          Output: \`send-msg 'find constance'\` Do we need some bread?
          
          Reach out to the ski store and figure out if I can get my skis fixed before I leave on Thursday`,
      });
    } else if (randomIndex === 13) {
      setPresetSelected({
        option: "QA",
        label:
          "I am a highly intelligent question answering bot. If you ask me a question that is rooted in truth, I will give you the answer. If you ask me a question that is nonsense, trickery, or has no clear answer, I will respond with `Unknown`.\n\nQ: What is human life expectancy in the United States?\nA: \nHuman life expectancy in the United States is 78 years.\n\nQ: Who was president of the United States in 1955?\nA: \nDwight D. Eisenhower was president of the United States in 1955.\n\nQ: Which party did he belong to?\nA: \nHe belonged to the Republican Party.\n\nQ: What is the square root of banana?\nA: \nUnknown\n\nQ: How does a telescope work?\nA: \nTelescopes use lenses or mirrors to focus light and make objects appear closer.\n\nQ: Where were the 1992 Olympics held?\nA: \nThe 1992 Olympics were held in Barcelona, Spain.\n\nQ: How many squigs are in a bonk?\nA: \nUnknown\n\nQ:",
      });
    } else if (randomIndex === 2) {
      setPresetSelected({
        option: "ETOL",
        label:
          "Translate this into 1. French, 2. Spanish and 3. Japanese:\n\nWhat rooms do you have available\n\n1.",
      });
    } else if (randomIndex === 6) {
      setPresetSelected({
        option: "PUD",
        label:
          "A table summarizing the fruits from Goocrux:\n\nThere are many fruits that were found on the recently discovered planet Goocrux. There are neoskizzles that grow there, which are purple and taste like candy. There are also loheckles, which are a grayish blue fruit and are very tart, a little bit like a lemon. Pounits are a bright green color and are more savory than sweet. There are also plenty of loopnovas which are a neon pink flavor and taste like cotton candy. Finally, there are fruits called glowls, which have a very sour and bitter taste which is acidic and caustic, and a pale orange tinge to them.\n\n| Fruit | Color | Flavor |",
      });
    } else if (randomIndex === 1) {
      setPresetSelected({
        option: "CLS",
        label:
          "The following is a list of companies and the categories they fall into:\n\nApple, Facebook, Fedex\n\nApple\nCategory:",
      });
    } else if (randomIndex === 55) {
      setPresetSelected({
        option: "NLTP",
        label:
          "1. Create a list of ten alphabets\n2. Find the vowel from the list\n\n\n# 1. Create a list of ten alphabets\nalphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']\n\n# 2. Find the vowel from the list\nvowels = []\nfor alphabet in alphabets:\n    if alphabet in 'aeiou':\n        vowels.append(alphabet)\n\nprint(vowels)",
      });
    } else if (randomIndex === 2) {
      setPresetSelected({
        option: "EC",
        label:
          "let arr = [1,2,3,4,5,6,7,8,9];\nlet oddNumber = arr.filter(num => num % 2 !== 0);\nconsole.log(oddNumber); // Output: [1, 3, 5, 7, 9]\n\n\nHere's what the above code is doing:\n1. We have an array of numbers.\n2. We use the filter method to filter out the odd numbers.\n3. We use the arrow function to return the numbers that are not divisible by 2.\n4. We store the result in a variable called oddNumber.\n5. We print the result.",
      });
    } 
    // else if (randomIndex === 1) {
    //   setPresetSelected({
    //     option: "CHAT",
    //     label:
    //       "The following is a conversation with an AI assistant. The assistant is helpful, creative, clever, and very friendly.\n\nHuman: Hello, who are you?\nAI: I am an AI created by OpenAI. How can I help you today?\nHuman: ",
    //   });
    // } 
    else if (randomIndex === 0) {
      setPresetSelected({
        option: "ASK",
        label: "Ask anything",
      });
    } 
    else if (randomIndex === 10) {
      setPresetSelected({
        option: "MTE",
        label:
          "Back to the Future: 👨👴🚗🕒 \n\nBatman: 🤵🦇 \n\nTransformers: 🚗🤖 ",
      });
    } else if (randomIndex === 11) {
      setPresetSelected({
        option: "JTP",
        label:
          "JavaScript: \nfunction factorial(num) {\n  if (num < 0) {\n    return -1; // If the number is negative, return -1\n  } else if (num == 0) {\n    return 1; // If the number is 0, the factorial is 1\n  } else {\n    return (num * factorial(num - 1)); // Recursively call the function to calculate the factorial\n  }\n}\nconsole.log(factorial(5)); // Output: 120\n\nPython:\ndef factorial(num):\n    if num < 0:\n        return -1\n    elif num == 0:\n        return 1\n    else:\n        return (num * factorial(num - 1))\nprint(factorial(5))",
      });
    } else if (randomIndex === 12) {
      setPresetSelected({
        option: "RRC",
        label:
          "I had the pleasure of dining at The Blue Wharf recently, and I was really impressed! The lobster was especially delicious, and the prices were very reasonable. The only downside was that it was quite noisy, but the polite service more than made up for it. Highly recommended!",
      });
    } else if (randomIndex === 5) {
      setPresetSelected({
        option: "ADS",
        label:
          "Are you a parent looking for a way to help your child excel in school? Look no further than Learning Room! Our virtual environment provides a safe and engaging way for students from kindergarten to high school to learn and practice the skills they need to succeed. With our comprehensive curriculum, interactive games, and experienced instructors, your child will be well-prepared for their future. Join us today and give your child the tools they need to reach their full potential!",
      });
    } else if (randomIndex === 14) {
      setPresetSelected({
        option: "MHSC",
        label:
          "Topic: Breakfast\nTwo-Sentence Horror Story: He always stops crying when I pour the milk on his cereal. I just have to remember not to let him see his face on the carton.",
      });
    }
  }, [setPreset, setPresetSelected]);

  const handleChange = (event) => {
    console.log(event.target.value,"Ask Anything" == event.target.value);
    setPreset(event.target.value);
    setText("");
    setGeneratingText("");
    setInputText("");
    setMessages([]);
    setUsingChat(false);
    if ("Grammatical Standard English"== event.target.value) {
      setPresetSelected({
        option: "GSE",
        label: `Correct this to standard English:\n\n${
          !inputText ? "She no went to the market." : inputText
        }`,
      });
    } else if ("Summarize For a 2nd Grader" === event.target.value) {
      setPresetSelected({
        option: "SFG",
        label:
          "Jupiter is the fifth planet from the Sun and the largest in the Solar System. It is a gas giant with a mass one-thousandth that of the Sun, but two-and-a-half times that of all the other planets in the Solar System combined. Jupiter is one of the brightest objects visible to the naked eye in the night sky, and has been known to ancient civilizations since before recorded history. It is named after the Roman god Jupiter.[19] When viewed from Earth, Jupiter can be bright enough for its reflected light to cast visible shadows,[20] and is on average the third-brightest natural object in the night sky after the Moon and Venus.",
      });
    } else if ("Text To Command" === event.target.value) {
      setPresetSelected({
        option: "TTC",
        label: `Convert this text to a programmatic command:
  
  Example: Ask Constance if we need some bread
  Output: send-msg \`find constance\` Do we need some bread?
  
  Reach out to the ski store and figure out if I can get my skis fixed before I leave on Thursday`,
      });
    } else if ("Q&A" === event.target.value) {
      setPresetSelected({
        option: "QA",
        label: `I am a highly intelligent question answering bot. If you ask me a question that is rooted in truth, I will give you the answer. If you ask me a question that is nonsense, trickery, or has no clear answer, I will respond with \`Unknown\`.
  
          Q: What is human life expectancy in the United States?
          A: Human life expectancy in the United States is 78 years.
          
          Q: Who was president of the United States in 1955?
          A: Dwight D. Eisenhower was president of the United States in 1955.
          
          Q: Which party did he belong to?
          A: He belonged to the Republican Party.
          
          Q: What is the square root of banana?
          A: Unknown
          
          Q: How does a telescope work?
          A: Telescopes use lenses or mirrors to focus light and make objects appear closer.
          
          Q: Where were the 1992 Olympics held?
          A: The 1992 Olympics were held in Barcelona, Spain.
          
          Q: How many squigs are in a bonk?
          A: Unknown`,
      });
    }
    // else if (presets[2] === event.target.value) {
    //   setPresetSelected({
    //     option: "ETOL",
    //     label:
    //       "Translate this into 1. French, 2. Spanish and 3. Japanese:\n\nWhat rooms do you have available?\n\n1.",
    //   });
  // } 
    else if ("Parse Unstructured Data"=== event.target.value) {
      setPresetSelected({
        option: "PUD",
        label:
          "A table summarizing the fruits from Goocrux:\n\nThere are many fruits that were found on the recently discovered planet Goocrux. There are neoskizzles that grow there, which are purple and taste like candy. There are also loheckles, which are a grayish blue fruit and are very tart, a little bit like a lemon. Pounits are a bright green color and are more savory than sweet. There are also plenty of loopnovas which are a neon pink flavor and taste like cotton candy. Finally, there are fruits called glowls, which have a very sour and bitter taste which is acidic and caustic, and a pale orange tinge to them.",
      });
    } else if (presets[1] === event.target.value) {
      setPresetSelected({
        option: "CLS",
        label:
          "The following is a list of companies and the categories they fall into:\n\nApple, Facebook, Fedex\n\nApple\nCategory:",
      });
    } else if ("Natural Language To Python"=== event.target.value) {
      setPresetSelected({
        option: "NLTP",
        label:
          "1. Create a list of ten alphabets\n2. Find the vowel from the list\n\n\n# 1. Create a list of ten alphabets\nalphabets = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j']\n\n# 2. Find the vowel from the list\nvowels = []\nfor alphabet in alphabets:\n    if alphabet in 'aeiou':\n        vowels.append(alphabet)\n\nprint(vowels)",
      });
    } else if ("Explain Code"==event.target.value) {
      setPresetSelected({
        option: "EC",
        label:
          "let arr = [1,2,3,4,5,6,7,8,9];\nlet oddNumber = arr.filter(num => num % 2 !== 0);\nconsole.log(oddNumber); // Output: [1, 3, 5, 7, 9]\n\n\nHere's what the above code is doing:\n1. We have an array of numbers.\n2. We use the filter method to filter out the odd numbers.\n3. We use the arrow function to return the numbers that are not divisible by 2.\n4. We store the result in a variable called oddNumber.\n5. We print the result.",
      });
    } 
    // else if (presets[1] === event.target.value) {
    //   setPresetSelected({
    //     option: "CHAT",
    //     label:
    //       "The following is a conversation with an AI assistant. The assistant is helpful, creative, clever, and very friendly.\n\nHuman: Hello, who are you?\nAI: I am an AI created by OpenAI. How can I help you today?\nHuman: ",
    //   });
      
    // } 
    else if ("Ask Anything" == event.target.value) {
      setPresetSelected({
        option: "ASK",
        label: "Ask anything",
      });
    } else if ("Movie To Emoji" === event.target.value) {
      setPresetSelected({
        option: "MTE",
        label:
          "Back to the Future: 👨👴🚗🕒 \n\nBatman: 🤵🦇 \n\nTransformers: 🚗🤖 ",
      });
    } else if ("JavaScript To Python" === event.target.value) {
      setPresetSelected({
        option: "JTP",
        label:
          "JavaScript: \nfunction factorial(num) {\n  if (num < 0) {\n    return -1; // If the number is negative, return -1\n  } else if (num == 0) {\n    return 1; // If the number is 0, the factorial is 1\n  } else {\n    return (num * factorial(num - 1)); // Recursively call the function to calculate the factorial\n  }\n}\nconsole.log(factorial(5)); // Output: 120\n\nPython:\ndef factorial(num):\n    if num < 0:\n        return -1\n    elif num == 0:\n        return 1\n    else:\n        return (num * factorial(num - 1))\nprint(factorial(5))",
      });
    } else if ("Restaurant Reviewer" === event.target.value) {
      setPresetSelected({
        option: "RRC",
        label:
          "I had the pleasure of dining at The Blue Wharf recently, and I was really impressed! The lobster was especially delicious, and the prices were very reasonable. The only downside was that it was quite noisy, but the polite service more than made up for it. Highly recommended!",
      });
    } else if ("Advertisement" === event.target.value) {
      setPresetSelected({
        option: "ADS",
        label:
          "Are you a parent looking for a way to help your child excel in school? Look no further than Learning Room! Our virtual environment provides a safe and engaging way for students from kindergarten to high school to learn and practice the skills they need to succeed. With our comprehensive curriculum, interactive games, and experienced instructors, your child will be well-prepared for their future. Join us today and give your child the tools they need to reach their full potential!",
      });
    } else if ("Micro Horror Story Creator" === event.target.value) {
      setPresetSelected({
        option: "MHSC",
        label:
          "Topic: Breakfast\nTwo-Sentence Horror Story: He always stops crying when I pour the milk on his cereal. I just have to remember not to let him see his face on the carton.",
      });
    }
    else if ("Summarize" === event.target.value) {
      setPresetSelected({
        option: "SU",
        label: ''
          // "Topic: Breakfast\nTwo-Sentence Horror Story: He always stops crying when I pour the milk on his cereal. I just have to remember not to let him see his face on the carton.",
      });
    }
    else if ("Rewrite" === event.target.value) {
      setPresetSelected({
        option: "RW",
        label: ''
          // "Topic: Breakfast\nTwo-Sentence Horror Story: He always stops crying when I pour the milk on his cereal. I just have to remember not to let him see his face on the carton.",
      });
    }
  };


  console.log(preset, 'selected option', );

  return (
    <div className="selector-container text-center">
      <select
        className="selector"
        name="preset"
        id=""
        value={preset}
        style={{fontSize:"14px",whiteSpace:"pre-line"}}
        onChange={handleChange}
      >
        {sortedPresets?.map((presets) => {
          return (
            <option key={presets} value={presets} className="textai-option">
              {" "}
              {presets}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default TextAiPresets;
