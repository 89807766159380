import React from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import HowItWorksTab from "../HowItWorks/HowItWorksTab";
import Zero from "./Zero";
import One from "./One";
import Two from "./Two";
import Three from "./Three";
import Four from "./Four";
import Five from "./Five";
import Six from "./Six";
import { useNavigate } from "react-router-dom";

const Gallery = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const data = [
    {
      category: "Consultant AI",
      items: [
        "Plan a social media marketing plan for a month",
        "Plan social media contents for a month using text and images",
        "Generate quotes for a month which can be used for social media",
      ],
    },
    {
      category: "Text AI",
      items: [
        "Ad from product description",
        "Grammatical Standard English",
        "Micro Horror Story Creator",
      ],
    },
    {
      category: "Image AI",
      items: [
        "Illustrate the concept of 'time travel' using everyday objects as your inspiration",
        "Depict a 'mermaid' composed entirely of household items found in a kitchen",
        "Imagine a 'floating city' made up of unconventional materials, like balloons, fruits, and umbrellas",
      ],
    },
    {
      category: "Post AI",
      items: ["Quote", "Mystery short story", "Soup Recipe"],
    },
    {
      category: "Text Video AI",
      items: [
        "The Silent Symphony: Exploring Nature's Harmonies",
        "Whispers of the Past: Uncovering Ancient Mysteries",
        "Journey Through Time: A Visual Odyssey of Human History",
      ],
    },
    {
      category: "News Video AI",
      items: ["Science and Technology", "World Middle East", "Entertainment"],
    },
    {
      category: "Comic Video AI",
      items: [
        "The Science of Sound Waves",
        "The Life Cycle of a Star",
        "The Story of the Wright Brothers",
      ],
    },
  ];

  const handleNavigate = () => {
      // alert(tabValue);
      if(tabValue == 0) navigate('/consultant-ai')
      else if(tabValue == 1) navigate('/text-ai')
      else if(tabValue == 2) navigate('/image-ai')
      else if(tabValue == 3) navigate('/post-ai')
      else if(tabValue == 4) navigate('/text-video-ai')
      else if(tabValue == 6) navigate('/news-video-ai')
      else if(tabValue == 5) navigate('/comic-video-ai')

  }

  return (
    <div>
      <PageHeading title="contents" />
      <div className="container account-container pb-3">
        
        <HowItWorksTab tabValue={tabValue} handleChangeTab={handleChangeTab} />

        <div
          // style={{ marginBlock: "20px", height: "200px", width: "100%" }}
          className="d-flex justify-content-center mt-2"
        >
          <button
          onClick={handleNavigate}
          className="btn btn-warning px-2 py-1">You can create similar contents. Click here.</button>
        </div>

        <div className="container mt-5">
          {tabValue === 0 && <Zero />}

          {tabValue === 1 && <One />}

          {tabValue === 2 && <Two />}

          {tabValue === 3 && <Three />}

          {tabValue === 4 && <Four />}

          {tabValue === 6 && <Five />}

          {tabValue === 5 && <Six />}

        </div>
      </div>
    </div>
  );
};

export default Gallery;
