import React, { useContext, useEffect, useState } from "react";
import "./SocialMedia.css";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { NavLink, useNavigate } from "react-router-dom";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { Grid } from "@mui/material";
import {
  AnalysisButton,
  ButtonContainer,
  CalenderButton,
  DescriptionText,
  GradientTextButton,
} from "./style";

const SocialMeadiaMarketing = () => {
  const [completeAnalysis, setCompleteAnalysis] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [partialScheduled, setPartialScheduled] = useState(false);
  const [scheduledData, setScheduledData] = useState([]);
  const [result, setResult] = useState([]);
  const [allTime, setAllTime] = useState("");
  const { AIuser, AiLogOut } = useContext(UserAuthContext);
  const navigate = useNavigate();
  const [startAnalysis, setStartAnalysis] = useState(false);

  useEffect(() => {
    fetch("https://backend.whalesai.com/api/v1/social/analyses/all")
      .then((res) => res.json())
      .then((data) =>
        setResult(data.filter((item) => item?.userId === AIuser?._id))
      );
  }, []);

  useEffect( () => {
    fetch(`https://backend.whalesai.com/api/v1/social-schedule/schedules/email/${AIuser?.email}`). then(res => res.json()) .then(data => setScheduledData(data))
  }, [AIuser?.email]);

  useEffect(() => {
    if (scheduledData?.length > 0) {
      const times = scheduledData?.map(item => item.timeOfPosting);
      setAllTime(times);
    }
  }, [scheduledData]);

  console.log('amar schedule data',scheduledData, allTime);

  useEffect( () => {
   if(scheduledData?.length !== 0) {
    // setScheduled(true)
    if (scheduledData?.length !== 10) {
      console.log('Yesssssssssssss');
      setPartialScheduled(true);
      setScheduled(false);
    }
    else{
      setScheduled(true);
      setPartialScheduled(false);
    }
   }
   else setScheduled(false);
  } ,[scheduledData, allTime])



  useEffect(() => {
    if (result.length > 0) setCompleteAnalysis(true);
  }, [result]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleAnalysis = () => {
    // Logic to handle analysis start
    setStartAnalysis(true);
  };
  return (
    <div>
      <PageHeading title="LET US DO IT FOR YOU" />
      <div>
        <p className="text-white w-2-3 text-center mx-auto mt-5">
          {/* Our SOCIAL MEDIA MARKETING MANAGER AI (SMMM AI) automatically
          generates and uploads content to social media platforms based on
          detailed social media marketing analysis conducted by our AI. */}
          Our SOCIAL MEDIA MARKETING MANAGER AI (SMMM AI) automatically generates and uploads content to social media platforms based on detailed analysis conducted by our AI.
        </p>
      </div>

      <div className=" mt-5 mb-5">
        <ButtonContainer>
          <Grid container spacing={6}>
            <Grid item md={6} sm={12} p={2} width={"100%"}>
            {completeAnalysis?
              <AnalysisButton
                onClick={() => navigate(AIuser ? "/analysis" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
              >
                View Analysis
              </AnalysisButton>
              :
              <AnalysisButton
                onClick={() => navigate(AIuser ? "/analysis" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
              >
                Analysis
              </AnalysisButton>
             }
              {/* <DescriptionText variant="body1" className="text-center">
                SMMM AI that automates content creation and uploads.
              </DescriptionText> */}

             {completeAnalysis?
             <GradientTextButton
                onClick={() => navigate(AIuser ? "/analysis" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
                className="complete-analysis-animate2 p-visibility"
              >
                You have done your analysis. <br/>Please click here
                to View.
              </GradientTextButton>
              :
              <GradientTextButton
                    onClick={() => 
                      navigate(AIuser ? "/analysis" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })
                    }
                    className="complete-analysis-animate p-visibility"
                  >
                    You have not done your analysis. <br/>Please click here
                    to do.
              </GradientTextButton>
            }
              
              {completeAnalysis?
              <GradientTextButton
                onClick={() => 
                  navigate(AIuser ? "/analysis" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })
                }
                className="complete-analysis-animate2 span-visibility"
              >
                You have done your analysis.
                Please click here to view.
              </GradientTextButton>
              :
              <GradientTextButton
                onClick={() => 
                  navigate(AIuser ? "/analysis" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })
                }
                className="complete-analysis-animate span-visibility"
              >
                You have not done your analysis.
                Please click here to do.
              </GradientTextButton>
             }

            </Grid>
            <Grid item md={6} sm={12} p={2} width={"100%"}>
              <CalenderButton
                onClick={() =>
                  navigate(AIuser ? "/calendars" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })
                }
              >
                Calendars
              </CalenderButton>
              {/* <DescriptionText variant="body1" className="text-center">
                The contents are strategically planned based on thorough
                analysis.
              </DescriptionText> */}
              {/* {
                scheduled ?
                <GradientTextButton
                onClick={() =>
                  navigate(AIuser ? "/calendars " : "/sign-in`)
                }
                className="complete-analysis-animate span-visibility"
              >
                You have done your scheduling. <br/>
                Please click here to check.
              </GradientTextButton> 
              
              : 
              
              <GradientTextButton
                onClick={() =>
                  navigate(AIuser ? "/calendars " : "/sign-in`)
                }
                className="complete-analysis-animate"
              >
                You have not planned your contents. <br/> Please click here to do.
              </GradientTextButton>
              } */}
              {
                partialScheduled && <GradientTextButton
                onClick={() => navigate(AIuser ? "/calendars" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
                className="complete-analysis-animate2 p-visibility"
              >
                You have done your scheduling partially. <br/>
                Please click here to check.
              </GradientTextButton>
              }
              {
                scheduled && <GradientTextButton
                onClick={() => navigate(AIuser ? "/calendars" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
                className="complete-analysis-animate2 p-visibility"
              >
                You have done your scheduling fully. <br/>
                Please click here to check.
              </GradientTextButton>
              }

              {
                !partialScheduled && !scheduled && <GradientTextButton
                onClick={() => navigate(AIuser ? "/calendars" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
                className="complete-analysis-animate p-visibility"
              >
                You have not planned your contents. <br/> Please click here to do.
              </GradientTextButton>
              }


             {
                partialScheduled && <GradientTextButton
                onClick={() => navigate(AIuser ? "/calendars" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
                className="complete-analysis-animate2 span-visibility"
              >
                You have done your scheduling partially. <br/>
                Please click here to check.
              </GradientTextButton>
              }
              {
                scheduled && <GradientTextButton
                onClick={() => navigate(AIuser ? "/calendars" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
                className="complete-analysis-animate2 span-visibility"
              >
                You have done your scheduling fully. <br/>
                Please click here to check.
              </GradientTextButton>
              }

              {
                !partialScheduled && !scheduled && <GradientTextButton
                onClick={() => navigate(AIuser ? "/calendars" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
                className="complete-analysis-animate span-visibility"
              >
                You have not planned your contents. <br/> Please click here to do.
              </GradientTextButton>
              }

              {/* {completeAnalysis?
             <GradientTextButton
                onClick={() => navigate(AIuser ? "/calendars" : "/sign-in", { state: { from: "/let-us-do-it-for-you" } })}
                className="complete-analysis-animate2 p-visibility"
              >
                You have done your scheduling. <br/>
                Please click here to check.
              </GradientTextButton>
              :
              <GradientTextButton
                    onClick={() => navigate(AIuser ? "/calendars" : "/sign-in")}
                    className="complete-analysis-animate p-visibility"
                  >
                    You have not planned your contents. <br/> Please click here to do.
              </GradientTextButton>
            } */}


            {/* {completeAnalysis?
             <GradientTextButton
                onClick={() => navigate(AIuser ? "/calendars" : "/sign-in")}
                className="complete-analysis-animate2 span-visibility"
              >
                You have done your scheduling. <br/>
                Please click here to check.
              </GradientTextButton>
              :
              <GradientTextButton
                    onClick={() => navigate(AIuser ? "/calendars" : "/sign-in")}
                    className="complete-analysis-animate span-visibility"
                  >
                    You have not planned your contents. <br/> Please click here to do.
              </GradientTextButton>
            } */}
              

            </Grid>
          </Grid>
        </ButtonContainer>
      </div>

      <p className="text-to complete-analysis-animate mb-5 text-center ">
        {/* <button
          className=" text-black flex justify-content-center py-2 px-4 mx-auto mb-5"
          style={{
            background: "#FFCA2C",
            // color: "black",
            // padding: '11px 35px',
            border: 0,
            borderRadius: "5px",
            fontWeight: "600",
          }}
        > */}
          {/* Please click the buttons to get started */}
        {/* </button> */}
      </p>
    </div>
  );
};

export default SocialMeadiaMarketing;
