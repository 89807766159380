import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import axios from "axios";
import countryList from "react-select-country-list";
import "./index.css";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#D9D9D9",
    color: "#D9D9D9",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#011225" : "#D9D9D9",
    color: state.isSelected ? "#D9D9D9" : "black",
    "&:hover": {
      backgroundColor: "#011225",
      color: "#D9D9D9",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black",
  }),
};

const MocUpCompany = ({ onInfoLengthChange }) => {
  const [formData, setFormData] = useState({
    companyName: "",
    shortDescription: "",
    website: "",
    country: "",
  });

  console.log("Data => ", formData);

  const [countryOptions, setCountryOptions] = useState([]);
  const countryOption = countryList().getData();

  useEffect(() => {
    // Fetch countries from API
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const countries = response.data.map((country) => ({
          value: country.cca2, // Country code
          label: country.name.common, // Country name
        }));
        setCountryOptions(countries);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  useEffect(() => {
    fetchUserIP();
  }, []);

  const fetchUserIP = async () => {
    try {
      const response = await axios.get("https://api64.ipify.org?format=json");
      const { ip } = response.data;
      fetchCountryData(ip);
    } catch (error) {
      console.error("Error fetching IP address:", error.message);
    }
  };

  const fetchCountryData = async (ip) => {
    const url = `https://ipapi.co/${ip}/json/`;

    try {
      const response = await axios.get(url);
      const { country_name, country_calling_code } = response.data;
      console.log("user => ", country_name);
      setFormData((prevFormData) => ({
        ...prevFormData,
        country: country_name,
      }));
    } catch (error) {
      console.error("Error fetching country data:", error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCountryChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      country: selectedOption?.label || "",
    }));
  };

  const countNonEmptyFields = () => {
    return Object.values(formData).filter((value) => value !== "").length;
  };

  const info = useMemo(() => formData, [formData]);

  // Log the info object whenever any value changes
  useEffect(() => {
    console.log("Form Info:", info);
    if (onInfoLengthChange) {
      onInfoLengthChange(info);
    }
  }, [info, onInfoLengthChange]);

  const handlePaste01 = (e) => {
    e.preventDefault();
    const pasteText = (e.clipboardData || window.clipboardData).getData("text");
    const newText = formData.shortDescription + pasteText;

    if (newText.length > 300) {
      // swal({
      //   text: "Please reduce the text to meet the character limit.",
      //   className: "modal_class_success",
      // });
      setFormData({
        ...formData,
        shortDescription: newText,
      });
    } else {
      setFormData({
        ...formData,
        shortDescription: newText,
      });
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    if (formData?.shortDescription) {
      setFormData({
        ...formData,
        shortDescription: "",
      });
    }
  };

  return (
    <form style={{ width: "100%" }} className="p-4 text-left">
      <div style={{ width: "100%" }} className="form-item mb-4">
        <label
          style={{ width: "100%" }}
          htmlFor="companyName"
          className="mb-1 text-left"
        >
          Company Name/Profile Name
        </label>{" "}
        <br />
        <input
          type="text"
          id="companyName"
          name="companyName"
          //   value={formData.companyName}
          value={"DS Legends Pte. Ltd."}
          onChange={handleInputChange}
          className="form-input p-2 rounded"
          style={{ width: "100%", backgroundColor: "#D9D9D9" }}
        />
      </div>

      <div className="form-item mb-4 text-box">
        <label htmlFor="shortDescription" className="mb-1">
          Short Description
        </label>{" "}
        <br />
        <textarea
          id="shortDescription"
          name="shortDescription"
          //   value={formData.shortDescription}
          value={`We are a leading AI and Web3 development company based in Singapore.`}
          onChange={handleInputChange}
          className="form-textarea pl-3 py-2 rounded"
          maxLength={300}
          style={{ width: "100%", backgroundColor: "#D9D9D9" }}
          onPaste={handlePaste01}
        />
        <div className="action-button">
          <div></div>
          <div className="placement">
            <button
              onClick={handleClear}
              className="btn px-2 py-1 btn-secondary"
            >
              Clear
            </button>
            <button
              className="  btn btn-info px-2 py-1 rounded"
              style={{ color: "black" }}
            >
              <span
                style={{
                  color:
                    formData.shortDescription.length >= 300 ? "red" : "black",
                }}
              >
                {formData.shortDescription.length}
              </span>
              /300
            </button>
          </div>
        </div>
      </div>

      <div className="form-item mb-4">
        <label htmlFor="website" className="mb-1">
          Website
        </label>{" "}
        <br />
        <input
          type="url"
          id="website"
          name="website"
          //   value={formData.website}
          value={"https://dsl.sg"}
          onChange={handleInputChange}
          className="form-input p-2 rounded"
          style={{ width: "100%", backgroundColor: "#D9D9D9" }}
        />
      </div>

      {/* <div className="form-item mb-4">
        <label htmlFor="country" className="mb-1">
          Country
        </label>{" "}
        <br />
        <Select
          options={countryOption}
          value={countryOption.find(
            (option) => option.label === formData.country
          )}
          onChange={handleCountryChange}
          id="country"
          name="country"
          placeholder="Detecting..."
          styles={customStyles}
        />
      </div> */}
      <div className="form-item mb-4">
        <label htmlFor="country" className="mb-1">
          Country
        </label>{" "}
        <br />
        <Select
          options={countryOption}
          value={countryOption.find((option) => option.label === "Singapore")} // Set default to Singapore
          onChange={handleCountryChange}
          id="country"
          name="country"
          placeholder="Detecting..."
          styles={customStyles}
        />
      </div>
    </form>
  );
};

export default MocUpCompany;
