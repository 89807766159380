import React from "react";
import { stepsContent } from "../VideoAi/api";
import swal from "sweetalert";

const ScheduleSteps = () => {

  const handleClick = () =>{
    swal({
      // title: "Are you sure?",
      text: `Coming soon.`,
      icon: "success",
      button: "Ok",
      className: "modal_class_success",
    })
  }
  return (
    <div className="schedule-steps schedule-steps-bg py-5 text-uppercase ">
      <div className="container">
        <h5 className="text-center schedule-title-style  mb-4">Steps</h5>

        <div className="schedule-step-boxes">
          <div className="row">
            {stepsContent.map((step) => (
              <div className="col-lg-3 col-md-4 col-6 mb-4  d-flex justify-content-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <p className="schedule-box-number text-center">{step.id}</p>
                  </div>
                  <div className="schedule-step-box  px-3">
                    <p className="schedule-box-text text-center">{step.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button
            className="buy-credits-button text-uppercase"
            onClick={handleClick}
          >
            Click here to start
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleSteps;
