import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";

import axios from "axios";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import moment from "moment";
import { FaEye } from "react-icons/fa";
import { Pagination } from "@mui/material";
import "./PurchaseHistory.css"
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import { MdAutoDelete } from "react-icons/md";
import Swal from "sweetalert2";
// import swal from "sweetalert";

const PurchaseHistory = () => {
  
  const { AIuser } = useContext(UserAuthContext);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [activeTab, setActiveTab] = useState('credits');

  useEffect(() => {
    const getTransaction = async () => {
      const response = await axios.get(
        `https://backend.whalesai.com/api/v1/payment/${AIuser?._id}`
      );
      setTransactionDetails(response?.data?.result?.reverse());
    };
    getTransaction();
  }, [AIuser?._id]);


  const [showFullId, setShowFullId] = useState(false);

  const location = useLocation();

  const isPaymentSuccess = location?.state?.success;
  const credits = location?.state?.credits;

  useEffect(() => {
    const paymentAlert = async () => {
      try {
        if (isPaymentSuccess == true) {
          swal({
            title: "Success!",
            text: `You have purchased ${credits} credits successfully!`,
            icon: "success",

            button: "OK",

            className: "modal_class_success",
          });
        }
      } catch (err) {
        console.log("Error from dashboard", err);
      }
    };
    paymentAlert();
  }, [isPaymentSuccess, credits]);

  const handleMouseOver = (index) => {
    setShowFullId({
      index: index,
      isTrue: true,
    });
  };

  const handleMouseOut = (index) => {
    setShowFullId({
      index: index,
      isTrue: false,
    });
  };
  const handleTouchStart = (index) => {
    setShowFullId({
      index: index,
      isTrue: true,
    });
  };

  const handleTouchEnd = (index) => {
    setShowFullId({
      index: index,
      isTrue: false,
    });
  };

  const itemsPerPage = 6; // Number of items to display per page

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const paginatedData = transactionDetails?.slice(startIndex, endIndex);

  console.log("data pgination ===> ", paginatedData)

  const handlePageChange = (event, page) => {
    // scroll to half of the page
    window.scrollTo(0, window.innerHeight / 3);
    setCurrentPage(page);
  };

  const handleCreditsTab = () => {
    setActiveTab('credits');
};

// Function to set 'Subscription' tab as active
const handleSubscriptionTab = () => {
    setActiveTab('subscription');
};

const handleCancelSubscription = (details) => {
  Swal.fire({
      // title: 'Confirm Cancelation',
      text: `Your subscription is scheduled to be canceled on 10-08-2024. Are you sure you want to proceed with the cancellation?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      className: "modal_class_success",
      customClass: {
        popup: 'modal_class_success',
        backdrop: 'modal_class_backdrop', 
    }
  }).then((result) => {
      if (result.isConfirmed) {
          swal({
            text: `Your subscription has been cancelled.`,
            icon: 'success',
            confirmButtonColor: '#3085d6',
            className: "modal_class_success",
          //   customClass: {
          //     popup: 'modal_class_success',
          //     backdrop: 'modal_class_backdrop', 
          // }
          }
          ); 
      }
  });
};
 

  return (
    <div>
      <PageHeading title={"PURCHASE HISTORY"} />

      <div className="d-flex justify-content-center align-items-center mt-5 gap-4">
        <button 
        onClick={() => {
          setActiveTab('credits');
        }}
        className={`px-4 py-2 btn ${activeTab === 'credits' ? 'btn-primary' : 'btn-secondary'}`}>Credits</button>
        <button
        onClick={() => {
          setActiveTab('subscription');
        }}
        className={`px-4 py-2 btn ${activeTab === 'subscription' ? 'btn-primary' : 'btn-secondary'}`}>Subscriptions</button>
      </div>
       
       {
        activeTab === 'credits' ? 
        
        <div className="container account-container pb-5">
        <div className="table-container">
          <table style={{minWidth:"600px"}} className="transaction-table">
            <thead>
              <tr>
                <th className="text-start">DATE OF PURCHASE</th>
                <th className="text-start">TRANSACTION ID</th>
                <th className="text-start" style={{minWidth: '100px'}}>PRICE</th>
                <th className="text-start">CREDITS BOUGHT</th>
                <th className="text-start">DATE OF EXPIRY</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData?.length > 0 ?
                paginatedData?.map((details, index) => (
                <tr
                  key={details._id}
                  style={{ borderBottom: "1px solid gray" }}
                >
                  <td className="text-start">
                    {moment(details?.createdAt).format("DD-MM-YYYY")}
                  </td>
                  <td
                    className="text-start maintain-max-width"
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={() => handleMouseOut(index)}
                    onTouchStart={() => handleTouchStart(index)}
                    onTouchEnd={() => handleTouchEnd(index)}
                  >
                    {showFullId.isTrue && showFullId.index === index
                      ? details?.paymentId
                      : `${details?.paymentId.slice(
                          0,
                          4
                        )}******${details?.paymentId.slice(-4)}`}
                  </td>
                  <td className="text-start">USD {details?.price}</td>

                  <td className="text-start">
                    {parseInt(details?.credits).toLocaleString()}
                  </td>
                  <td className="text-start">
                    {moment(details?.createdAt).add(parseInt(details?.data?.expire), 'months').format('DD-MM-YYYY')}
                  </td>
                </tr>
              )):<tr className="text-center"><td colSpan={5} className="text-dark">No Purchase History.</td></tr>}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-center text-light mt-3">
          <Pagination
            shape="rounded"
            count={Math.ceil(transactionDetails?.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </div>

       :

       <div className="container account-container pb-5">
        <div className="table-container">
          <table style={{minWidth:"600px"}} className="transaction-table">
            <thead>
              <tr>
                <th className="text-start">START DATE</th>
                
                <th className="text-start">RENEW DATE</th>
                <th className="text-start">TRANSACTION ID</th>
                <th className="text-start" style={{minWidth: '100px'}}>PRICE</th>
                {/* <th className="text-start">CREDITS BOUGHT</th> */}
                <th className="text-start">ACTION</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData?.length > 0 ?
                paginatedData?.map((details, index) => (
                <tr
                  key={details._id}
                  style={{ borderBottom: "1px solid gray" }}
                >
                  <td className="text-start">
                    {/* {moment(details?.createdAt).format("DD-MM-YYYY")} */}
                    10-07-2024
                  </td>
                  <td className="text-start">
                    {/* {moment(details?.createdAt).format("DD-MM-YYYY")} */}
                    10-08-2024
                  </td>
                  <td
                    className="text-start maintain-max-width"
                    onMouseOver={() => handleMouseOver(index)}
                    onMouseOut={() => handleMouseOut(index)}
                    onTouchStart={() => handleTouchStart(index)}
                    onTouchEnd={() => handleTouchEnd(index)}
                  >
                    {/* {showFullId.isTrue && showFullId.index === index
                      ? details?.paymentId
                      : `${details?.paymentId.slice(
                          0,
                          4
                        )}******${details?.paymentId.slice(-4)}`} */}
                        2za_******aX4y
                  </td>
                  {/* <td className="text-start">USD {details?.price}</td> */}
                  <td className="text-start">USD 48.00</td>

                  {/* <td className="text-start">
                    {parseInt(details?.credits).toLocaleString()}
                  </td> */}
                  <td className="text-start delete-icon">
                    {/* {moment(details?.createdAt).add(parseInt(details?.data?.expire), 'months').format('DD-MM-YYYY')} */}
                    <buton
                    onClick={() => handleCancelSubscription(details)}
                     className="delete-icon btn btn-danger my-0 ">CANCEL SUBSCRIPTION</buton>
                    {/* <MdAutoDelete className="delete-icon" /> */}
                  </td>
                </tr>
              )):<tr className="text-center"><td colSpan={5} className="text-dark">No Purchase History.</td></tr>}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-center text-light mt-3">
          <Pagination
            shape="rounded"
            count={Math.ceil(transactionDetails?.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </div>

       }
      
    </div>
  );
  
  
  
  



};

export default PurchaseHistory;
