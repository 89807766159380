import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PageHeading from "../../Components/PageHeading/PageHeading";
import MocUp from "../../Components/PageHeading/MocUp";
import { SpeechBubbleTopCenter } from "../SpeechBubble/Bubble";

const SubscriptionpaymentMocUp = () => {
  const [platforms, setPlatforms] = useState();
  const [ammount, setAmmount] = useState();
  const [startDate, setStartDate] = useState("");
  const [renewDate, setRenewDate] = useState("");
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    window.scroll(0, 0);
    const data = location?.state?.data;
    setPlatforms(data.platform[0]);
    setAmmount(data.ammount);

    const today = new Date();
    const startDateString = `${String(today.getDate()).padStart(2, "0")}-${String(today.getMonth() + 1).padStart(2, "0")}-${today.getFullYear()}`;
    setStartDate(startDateString);

    const nextMonth = new Date(today);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    const renewDateString = `${String(nextMonth.getDate()).padStart(2, "0")}-${String(nextMonth.getMonth() + 1).padStart(2, "0")}-${nextMonth.getFullYear()}`;
    setRenewDate(renewDateString);
  }, []);
  // console.log('From the location', ammount, platforms);
  const handleNavigateToCheckout = () => {
    navigate("/how-it-works-payment-methods");
  };

  setTimeout(() => {
    handleNavigateToCheckout();
  }, 3000);
  return (
    <div>
      {/* <PageHeading title='checkout'/> */}
      <MocUp title="checkout" description="Subscribe and Pay" step="Step 4:" />

      <div
        className="container account-container d-flex justify-content-center"
        // style={{ minHeight: "100vh" }}
      >
        <div className="col-md-6 col-lg-6 col-12 mb-5">
          <div style={{ borderRadius: "10px" }} className="bg-white">
            <div
              className="package-price text-center"
              style={{ background: `#1D60EF` }}
            >
              <p>SMMM AI SUBSCRIPTION</p>
              <h2>USD {ammount}.00</h2>
            </div>
            <div>
              <div className="each-row py-4">
                <p className="row-header mb-2">Social Media Platform</p>
                <p className="row-value mb-2">{platforms?.join(", ")}</p>
              </div>
              <div className="each-row py-4">
                <p className="row-value mb-0">Starts on: {startDate}</p>
                <p className="row-value mb-0">Auto Renews on: {renewDate}</p>
              </div>
              <div className="pay-button-container">
                <button
                  className="checkout-button"
                  onClick={handleNavigateToCheckout}
                >
                  PROCEED TO PAY WITH CREDIT/DEBIT CARD
                </button>
              </div>
            </div>
          </div>
          {/* <div>
            <SpeechBubbleTopCenter>Subscribe Monthly for USD 30.00 - Join us with a monthly subscription for just USD 30.00 and enhance your social media presence!</SpeechBubbleTopCenter>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionpaymentMocUp;
