import React, { useEffect } from "react";
import './StepButton.css'
import { useNavigate } from "react-router-dom";

const StepButton = ({pre, nex, text2, btn, pause}) => {

  const navigate = useNavigate();
  //   const navigate = useNavigate();

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     navigate(nex);
  //   }, 3000); 

  //   return () => clearTimeout(timer);
  // }, [navigate, nex]);
  

  useEffect(() => {
    if (!pause) {
      const timer = setTimeout(() => {
        navigate(nex);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [navigate, nex, pause]);

  return (
      <div className=" previous-next pb-5">
        {
          btn == 0 ? <span></span> : <button
          onClick={() => {
            navigate(pre);
          }}
          >
            Previous
            </button>
        }
        <div>
        <button
          onClick={() => {
            navigate(nex);
          }}
        >
          Next
        </button>
        {/* <p style={{fontSize: '12px'}} className="mt-3 text-white">{text2}</p> */}
        </div>
      </div>
  );
};

export default StepButton;



// import React, { useEffect } from "react";
// import './StepButton.css';
// import { useNavigate } from "react-router-dom";

// const StepButton = ({ pre, nex, text2, btn }) => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       navigate(nex);
//     }, 3000); // 3 seconds delay

//     return () => clearTimeout(timer); // Clear the timeout if the component unmounts
//   }, [navigate, nex]);

//   return (
//     <div className="previous-next pb-5">
//       {btn === 0 ? (
//         <span></span>
//       ) : (
//         <button
//           onClick={() => {
//             navigate(pre);
//           }}
//         >
//           Previous
//         </button>
//       )}
//       <div>
//         <button
//           onClick={() => {
//             navigate(nex);
//           }}
//         >
//           Next
//         </button>
//         <p style={{ fontSize: '12px' }} className="mt-3 text-white">
//           {text2}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default StepButton;
