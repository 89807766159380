import { Button } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaEnvelope, FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "./SignIn.css";
import { UserAuthContext } from "../../Contexts/UserAuthContext";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [country, setCountry] = useState(""); 
  const { AIuser, token, setToken ,setUserRefetch,userRefetch} = useContext(UserAuthContext);

  const location = useLocation();

  const { state } = location;

  const fromPath = state?.from;

  console.log("location from sign in", state);

  const navigate = useNavigate();


  useEffect(() => {
    window.scrollTo(0, 0);

    axios
    .get("https://api.ipify.org/?format=json")
    .then((response) => {
      const ipAddress = response.data.ip;
      axios
        .get(`https://ipapi.co/${ipAddress}/json/`)
        .then((response) => {
          setCountry(response.data.country_name);
          console.log('dataaaaaaaaaaaaaaaaaaaaaaa ========> :::', response.data.country_name)
        })
        .catch((error) => {
          console.error("Error fetching user country:", error);
          setCountry("Unknown");
        });
    })
    .catch((error) => {
      console.error("Error fetching user IP address:", error);
      setCountry("Unknown");
    });
    
    }, []);

console.log('amar desh..',country);

  // useEffect(() => {
  //   // Fetch the user's country
  //   const fetchCountry = async () => {
  //     try {
  //       const response = await axios.get(`https://ipinfo.io/json?token=685bcf2085b49e`);
  //       setCountry(response.data.country);
  //       console.log('dtaaaaaaaaaaaaaaaaaaaaaaaaaa =================>>>>> :', response.data)
  //     } catch (error) {
  //       console.error("Error fetching country data:", error);
  //     }
  //   };
  //   fetchCountry();
  // }, []);


  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);


  const handleLogin = async (e) => {
    e.preventDefault();

    const password = e.target.password.value;

    await axios
      .post(
        "https://backend.whalesai.com/api/dashboard/user_signup-signin/signin",
        {
          email,
          password
          // country
        }
      )
      .then((res) => {
        if (res.status === 200) {
          setUserRefetch(!userRefetch)
          setToken(res.data.token);
          localStorage.setItem("aiUser", res.data.token);

          if (fromPath !== null && fromPath !== undefined) {
            if (fromPath === "/payment") {
              navigate(
                fromPath,
                {
                  state: {
                    data: state?.data,
                  },
                },
                { replace: true }
              );
            } else {
              navigate(fromPath, { replace: true });
            }
          } else {
            navigate("/dashboard", { replace: true });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        swal({
          title: "Attention",
          text: `${error.response?.data?.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
        // .then((willDelete) => {
        //   if (willDelete) {
        //     // navigate("/clone/sign-up", { replace: true });
        //     navigate("/sign-up", { replace: true });
        //   }
        // });
      });
  };

  return (
    <div className="handleTheLoginBody">
      <div className="container d-flex">
        <div className="pt-5 forCard w-md-50 p-5 rounded mx-auto mt-0">
          <p className="text-center fs-5">Sign In</p>
          <div className="mt-4 pt-2">
            <form onSubmit={handleLogin}>
              <InputGroup>
                <InputGroup.Text
                  id="basic-addon1"
                  className="bg-dark border-0 text-white"
                >
                  <FaEnvelope />
                </InputGroup.Text>
                <Form.Control
                  style={{ textTransform: "lowercase" }}
                  className="inputBackground"
                  placeholder="Email"
                  aria-label="Username"
                  type="email"
                  required
                  aria-describedby="basic-addon1"
                  name="email"
                  onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                />
              </InputGroup>
              <br />
              {/* <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text> */}
              <InputGroup className="mb-3 mt-3">
                <InputGroup.Text className="bg-dark border-0 text-white">
                  <FaLock />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Amount (to the nearest dollar)"
                  className="inputBackground"
                  placeholder="password"
                  type={visiblePassword ? "text" : "password"}
                  required
                  name="password"
                />
                <InputGroup.Text
                  className="bg-dark text-center border-0 cursor-pointer text-white"
                  role="button"
                  type="button"
                  onClick={() => setVisiblePassword(!visiblePassword)}
                >
                  {visiblePassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Link
                  className="text-decoration-none forgetPass"
                  to="/forget-password"
                >
                  <p>Forgot password?</p>
                </Link>
              </Form.Group>
              <div className="mx-auto text-center">
                <Button
                  style={{ backgroundColor: "#f74545" }}
                  className="button-34 ps-5 pe-5 pt-2 pb-2"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
              <p className="forgetPass text-center mt-4">
                New here?{" "}
                <Link to="/sign-up" className="forgetPass">
                  Sign up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
