import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// import { AddCircleOutline } from "@material-ui/icons";

import wordCount from "word-count";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddPageModal({
  handleAddNewPage,
  handleClose,
  open,
  setInput,
  input,
  pages,
  leftPages,
}) {
  // useEffect(() => {
  //   if (input) {
  //     const countWords = () => {
  //       const numberOfWords = wordCount(input);

  //       setWords(numberOfWords);
  //     };
  //     countWords();
  //   }
  // }, [input]);

  const textareaRef = useRef(null);
  const [placeholder, setPlaceHolder] = useState("Please type what you what to appear in this page")

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea.value.length > 160) {
      textarea.value = textarea.value.slice(0, 160);
    }
  };
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text").slice(0, 160);
    event.preventDefault();
    document.execCommand("insertText", false, pastedText);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div
          style={{ backgroundColor: "#1f406b", color: "white", width: "400px" }}
          className="add-page-modal"
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Add Page
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {leftPages == 1 ? (
              <p className="text-warning mb-0" style={{ fontSize: "15px" }}>
                You can add only {leftPages} more page
              </p>
            ) : (
              <p className="text-warning mb-0" style={{ fontSize: "15px" }}>
                You can add up to {leftPages} pages.
              </p>
            )}
            <div
              className="ai-send-text mt-2 position-relative ps-1"
              // style={{ marginBottom: 10 }}
            >
              <div className="">
                <textarea
                  className=" bg-transparent border-0 w-100 text-white   ai-textarea videoai-modal-textarea"
                  type="text"
                  lang="en"
                  placeholder={placeholder}
                  onFocus={()=> setPlaceHolder("")}
                  onBlur={()=> setPlaceHolder("Please type what you what to appear in this page")}
                  value={input}
                  id="inputText"
                  name="inputBox"
                  rows={4}
                  onChange={(e) => {
                    const newInput = e.target.value;
                    const newWordCount = wordCount(newInput);
                    if (newWordCount <= 160) {
                      setInput(newInput);
                    } else {
                      setInput(input);
                    }
                  }}
                  // onPaste={(e) => {
                  //   e.preventDefault();
                  //   const pastedContent = e.clipboardData.getData("text");
                  //   const newInput = input + pastedContent;
                  //   const newWordCount = wordCount(newInput);
                  //   if (newWordCount <= 160) {
                  //     setInput(newInput);
                  //   } else {
                  //     setInput(input);
                  //   }
                  // }}
                  onInput={handleInput}
                  onPaste={handlePaste}
                  ref={textareaRef}
                />
              </div>
            </div>
            {input && (
              <p
                className={`mb-0 ${
                  input?.length == 160 && "text-danger"
                }`}
                style={{
                  fontSize: "13px",
                  fontWeight: "500",
                  letterSpacing: "0.1px",
                }}
              >
                {input?.length}/160 characters
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              disabled={input?.length === 0}
              onClick={handleAddNewPage}
              className="bg-success text-light videoai-btns"
            >
              {/* <AddCircleOutline
                style={{
                  fontSize: "16px",
                  marginRight: "3px",
                  marginTop: "-2px",
                }}
              /> */}
              Add
            </Button>
          </DialogActions>
        </div>
      </BootstrapDialog>
    </div>
  );
}
