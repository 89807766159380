import React, { useEffect } from 'react';
import AIs from '../Home/AIs/AIs';

const DoItYourself = () => {
  useEffect(() => {
    window.scroll(0,0);
  } ,[])
  return (
    <section className="pb-4">
      <div className="">
        <AIs />
        <div className="text-center text-white" style={{ marginTop: '-30px' }}>
          <p>Feel free to create content however you like!</p>
        </div>
      </div>
    </section>
  );
};

export default DoItYourself;
