import React, { useEffect, useState } from "react";
import { FaRegCopy } from "react-icons/fa";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import StepButton from "../StepButton/StepButton";
import { SpeechBubbleLeft } from "../SpeechBubble/Bubble";

const data = `# Analysis for DS Legends Pte. Ltd.

## Introduction
DS Legends Pte. Ltd. is a pioneering Web3 and AI development company based in the vibrant tech hub of Singapore. With a focus on leveraging cutting-edge technology and innovations, the company is keen on using social media strategically to enhance its business objectives, visibility, and customer engagement. This analysis explores their current social media strategies, challenges, and areas of improvement to optimize their digital presence.

### Company Overview
- **Company Name**: DS Legends Pte. Ltd.
- **Industry**: Web3 and AI Development
- **Location**: Singapore
- **Website**: [https://dsl.sg/](https://dsl.sg/)

## Social Media Goals
DS Legends Pte. Ltd. aims to harness the power of social media, primarily to:

- **Increase Brand Awareness and Visibility**: By focusing efforts on social media, the company seeks to broaden its reach and establish a prominent digital footprint within the Web3 and AI sectors. 

## Platform Preference
### Most Beneficial Platform
- **Selected Platform**: LinkedIn
  - **Rationale**: LinkedIn is an ideal platform for B2B companies like DS Legends Pte. Ltd. for professional networking, brand positioning, and sharing industry insights, aligning well with their goal of increasing brand visibility.

## Content Strategy
### Posting Frequency
- **Frequency of Posts**: A few times a week
  - **Implication**: Posting regularly but not overwhelmingly ensures the content is relevant and engaging without overwhelming the audience, maintaining consistent brand interaction.

### Content Performance
- **Top Performing Content Type**: Engaging questions and polls
  - **Insight**: Interactive content such as polls fosters community engagement and interaction, which is crucial for building a rapport with the audience and receiving direct feedback.

## Challenges
The primary challenge faced by DS Legends Pte. Ltd. in their social media endeavors is:

### Keeping up with Platform Trends and Algorithm Changes
- **Challenge Description**: Social media platforms evolve rapidly, with frequent updates to algorithms and content trends that can affect visibility and engagement rates. Staying ahead of these changes is critical for the company to maintain effective social media strategies.

## Conclusion and Recommendations
To optimize their social media strategy, DS Legends Pte. Ltd. should consider the following recommendations:

- **Leverage LinkedIn's full potential** by engaging in industry groups, participating in discussions, and leveraging sponsored content to reach a broader audience.
- **Enhance Content Diversity**: While engaging questions and polls are effective, introducing related educational content could further establish their expertise and thought leadership in the Web3 and AI scenes.
- **Invest in Trend Analysis**: Utilizing social media tools and analytics to monitor trends and algorithm changes will help in staying agile and adapting strategies rapidly.
- **Explore Collaborative Strategies**: Partner with industry influencers or complementary businesses to amplify reach and validate the brand's presence within the industry.

By aligning social media activities with business objectives and continuously adapting to dynamic digital environments, DS Legends Pte. Ltd. can significantly enhance its brand impact and business growth through social media.`;

const keyWords = [
  "1. Day 1: Kick off the month by diving deep into the revolutionary world of Web3 technology with DS Legends Pte. Ltd.! As a leading name in AI and Web3 development, we're committed to unleashing the power of cutting-edge tech to transform industries. Join us on this journey and discover how we're reshaping the digital landscape.",
  "2. Day 2: At DS Legends Pte. Ltd., we're all about harnessing innovation. In the heart of Singapore, we're crafting the future with our Web3 and AI developments. Curious about what these entail? We want to hear your thoughts. What excites you most about Web3 and AI advancements?",
  "3. Day 3: Did you know LinkedIn is our go-to platform for connecting with professionals like you? It's where we share insights and build relationships within the Web3 and AI sectors. Follow us for updates on how we're using LinkedIn to expand our reach and ideas to help optimize your own strategies.",
  "4. Day 4: Engaging with community is key! That's why at DS Legends Pte. Ltd., we love interactive content. Our latest poll dives into the world of AI development—cast your vote and let us know your views on the most promising AI applications.",
  "5. Day 5: In the rapidly evolving tech world, staying ahead of trends is crucial. At DS Legends Pte. Ltd., we continuously analyze social media dynamics to craft effective strategies. What strategies do you use to keep up with tech trends?",
  "6. Day 6: Looking to enrich your feed with tech insights? Follow DS Legends Pte. Ltd. for a fresh take on Web3 advancements. Whether you're a tech enthusiast or a professional, our updates will keep you informed and inspired.",
  "7. Day 7: Industry collaboration can amplify innovation. DS Legends Pte. Ltd. believes in the power of partnerships. Are you interlinking with influencers or businesses for enhanced visibility in your field? Share your experiences below.",
  "8. Day 8: Did you know Singapore is a hub for tech innovation? Based in this vibrant city, DS Legends Pte. Ltd. is capitalizing on its dynamic environment to push the boundaries of Web3 and AI. What makes your city a tech trailblazer?",
  "9. Day 9: Educational content is a powerful tool for establishing thought leadership. DS Legends Pte. Ltd. is here to share knowledge in the Web3 and AI space through engaging posts. Stay tuned for enlightening content that fuels your curiosity!",
  "10. Day 10: Trend analysis is more than just a buzzword—it's how DS Legends Pte. Ltd. stays agile in an ever-changing digital landscape. What tools do you leverage for analyzing trends and enhancing your social media strategy?",
  "11. Day 11: Partnerships propel growth! DS Legends Pte. Ltd. is exploring collaborative strategies to broaden our impact. Have you witnessed significant growth through partnerships in your industry? Tell us your story.",
  "12. Day 12: New to Web3 or AI? DS Legends Pte. Ltd. is your guide. Dive into our content packed with innovative insights and futuristic trends. We'll keep it engaging and educational.",
  "13. Day 13: LinkedIn isn't just for resumes and jobs—it's a community. DS Legends Pte. Ltd. uses LinkedIn to connect with the Web3 and AI sectors, sharing updates and starting conversations. How do you use LinkedIn to grow professionally?",
  "14. Day 14: Challenges make us stronger! Navigating social media algorithm changes is a real hurdle. At DS Legends Pte. Ltd., we adapt to achieve our goals. How do you overcome algorithm updates in your digital strategy?",
  "15. Day 15: The power of polls at DS Legends Pte. Ltd. Interactive content like polls drive insights and engagement. Tell us, what's your favorite way to engage with your audience online?",
  "16. Day 16: Nothing beats engaging discussions on LinkedIn! At DS Legends Pte. Ltd., we're tapping into industry groups to enhance our influence. Join the conversation—what are your favorite LinkedIn groups for professional growth?",
  "17. Day 17: Boost your brand by going beyond borders! DS Legends Pte. Ltd. thrives on expanding digitally. What are your top tips for enhancing your brand's digital footprint?",
  "18. Day 18: Meet DS Legends Pte. Ltd., the Web3 and AI trailblazers using social media to empower business growth. Follow us for updates on our mission to leverage tech innovations for a brighter future.",
  "19. Day 19: How can educational content enrich your digital presence? DS Legends Pte. Ltd. knows the impact it creates. Follow us to gain insights into how we use informative content to position ourselves as industry experts.",
  "20. Day 20: Broaden your horizons with DS Legends Pte. Ltd. We navigate the global tech landscape from our Singapore base, sharing fresh perspectives daily. What's the best tech insight you gained this week?",
  "21. Day 21: Social media is a catalyst for growth. At DS Legends Pte. Ltd., we're enhancing our reach through LinkedIn—what's your go-to platform for advancing your business objectives?",
  "22. Day 22: Staying tech-savvy is key. DS Legends Pte. Ltd. leverages insights from social media tools to adapt strategies. Which tools do you rely on for keeping your digital strategy innovative?",
  "23. Day 23: Innovation drives us! DS Legends Pte. Ltd. is on a mission to explore new horizons in Web3 and AI through powerful collaborations. How do you foster innovation within your team?",
  "24. Day 24: When it comes to social media, diversity counts. DS Legends Pte. Ltd. mixes engaging questions, polls, and insightful content to keep our audience captivated. What's your favorite type of content to create?",
  "25. Day 25: Your success starts with a network. We at DS Legends Pte. Ltd. use LinkedIn to solidify our B2B relationships in the AI and Web3 space. Tell us about your most rewarding professional connection.",
  "26. Day 26: Growth happens when we share knowledge. DS Legends Pte. Ltd. believes educational content solidifies industry impacts. How do you use content to emphasize your brand's expertise?",
  "27. Day 27: Are you riding the wave of the latest innovations? DS Legends Pte. Ltd. is keen on tech trends shaping the future. What trend do you believe will change your industry the most?",
  "28. Day 28: Social engagement translates to success. DS Legends Pte. Ltd. employs interactive content to connect with audiences. How do you ensure your social media remains engaging?",
  "29. Day 29: Insights from industry groups lead to inspired strategies. DS Legends Pte. Ltd. uses these platforms for invaluable professional interactions. How have industry groups influenced your strategies?",
  "30. Day 30: The journey continues! As we wrap up a month of insights from DS Legends Pte. Ltd., remember that innovation isn't a destination—it's a journey. What's the most inspiring tech advancement you've encountered this month?",
];

const Result = () => {

  const [isComplete, setIsComplete] = useState(false);
  // useEffect(() => {
  //   const startScrolling = setTimeout(() => {
  //     const scrollInterval = setInterval(() => {
  //       window.scrollBy({
  //         top: 15, // Increase this value for even faster scrolling
  //         behavior: 'smooth',
  //       });

  //       if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
  //         clearInterval(scrollInterval);
  //       }
  //     }, 1); 
  //   }, 2000); 

  //   return () => {
  //     clearTimeout(startScrolling);
  //   };
  // }, []);
  useEffect(() => {
    const startScrolling = setTimeout(() => {
      const scrollInterval = setInterval(() => {
        window.scrollBy({
          top: 15, // Adjust for faster scrolling
          behavior: 'smooth',
        });

        const reachedBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;

        if (reachedBottom) {
          clearInterval(scrollInterval);
          setTimeout(() => setIsComplete(true), 1000); // Set `isComplete` to true 3 seconds after scrolling completes
        }
      }, 1); // Interval for smooth, fast scroll
    }, 2000); // Start scrolling after 2 seconds

    // Cleanup on unmount
    return () => {
      clearTimeout(startScrolling);
    };
  }, []);
  return (
    <div className="container mt-3 text-white">
      <p
        className="text-justify"
        dangerouslySetInnerHTML={{
          __html: data
            .replace(/\[(.*?)\]\((.*?)\)/g, "$2")
            .replace(
              /#### (.*?)(?=\n|$)/g,
              "<strong style='color: yellowgreen;'>$1</strong>"
            ) // Smaller subheadings
            .replace(
              /### (.*?)(?=\n|$)/g,
              "<strong style='color: aquamarine; display: block; margin-bottom: -20px;'>$1</strong>"
            ) // Small subheadings
            .replace(
              /## (.*?)(?=\n|$)/g,
              "<strong style='color: skyblue; font-size: 20px;'><u>$1</u></strong>"
            ) // Subheadings
            .replace(
              /# (.*?)(?=\n|$)/g,
              "<strong style='color: blueviolet; font-size: 20px'><u>$1</u></strong>"
            ) // Headings
            .replace(
              /\*\*(.*?)(?=\*\*|\n|$)\*\*/g,
              "<strong style='color: rgb(242, 230, 215);'>$1</strong>"
            ) // Bold text without adding <br/> at the end
            .replace(/\n/g, "<br/>"), // Replace remaining new lines with <br/>
        }}
      ></p>

      <div className="">
        <p
          className="fw-bold text-decoration-underline"
          style={{
            color: "#87CEEB",
            fontSize: "20px",
            textDecoration: "",
          }}
        >
          Suggested Text Contents for 30 days
        </p>
        {keyWords &&
          keyWords.map((keyword, index) => (
            <div key={index}>
              {index > -1 ? (
                <div className="d-flex align-items-center gap-2">
                  <p className="p-0 my-2">{keyword}</p>
                  <FaRegCopy
                    style={{
                      color: "green",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(keyword?.slice(4, 10000))
                        .then(() => {
                          Swal.fire({
                            text: "Text Contents copied to your clipboard",
                            background: "#011225",
                            icon: "success",
                            color: "white",
                          });
                        })
                        .catch((err) => {
                          Swal.fire({
                            text: "Could not copy Text Contents to your clipboard! Please try again",
                            background: "#011225",
                            icon: "warning",
                            color: "white",
                          });
                        });
                    }}
                  />
                </div>
              ) : (
                <p>{keyword}</p>
              )}
            </div>
          ))}
      </div>

      <div className="mt-5 d-flex">
        <button
          // style={{width: "390px"}}
          type="button"
          className="btn btn-info custom-width"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          REDO YOUR ANALYSIS. THERE ARE 6 STEPS
        </button>
        {/* <div>
          <SpeechBubbleLeft></SpeechBubbleLeft>
        </div> */}
      </div>
      <div className="mt-3 mb-5 d-flex">
        <button
          // style={{width: "390px"}}
          type="button"
          className="btn btn-warning px-4 custom-width "
          onClick={() => {
            Navigate("/calendars");
            window.scrollTo(0, 0);
          }}
        >
          SCHEDULE YOUR CONTENTS NOW
        </button>
        <div>
          <SpeechBubbleLeft>You can plan and schedule your content here!</SpeechBubbleLeft>
        </div>
      </div>
      {
        isComplete && <StepButton pre = {`/how-it-works-let-us-do-it-for-you`} nex = {`/how-it-works-calendars`} />
      }
    </div>
  );
};

export default Result;
