import React, { useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./index.css";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight, dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Import desired styles
import Swal from "sweetalert2";

const socialMediaPlatforms = [
  "Optional",
  "Facebook",
  "Instagram",
  "Threads",
  "Twitter/X",
  "LinkedIn",
  "Telegram",
  "Medium",
];
const contentTypes = [
  "Optional",
  "Informative post",
  "Quote",
  "Motivation",
  "Captivating story",
  "Poem",
  "Tip and how-to guide",
  "Educational content",
  "Poll and question",
  "Quiz and challenge",
];
const descriptions = [
  "Machine Learning",
  "Deep Learning",
  "Neural Networks",
  "Natural Language Processing",
  "Data Science",
  "Cognitive Computing",
  "Robotics",
  "Automation",
  "Computer Vision",
  "Algorithm Development",
];

const TestHowItsWorks = () => {
  const [selectedPlatform, setSelectedPlatform] = useState(
    socialMediaPlatforms[0]
  );
  const [contentLength, setContentLength] = useState("short");
  const [contentType, setContentType] = useState(contentTypes[0]);
  const [textInput, setTextInput] = useState("");
  const [description, setDescription] = useState(descriptions[0]);
  const [result, setResult] = useState();
  const [selectedTab, setSelectedTab] = useState('JavaScript');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const codeSnippets = {
    JavaScript: `
let myHeaders = new Headers();
const apikey = 'exampleapitokn';
// You can get the apikey from https://whalesai.com/api-settings
myHeaders.append("Authorization", \`Bearer \${apikey}\`);
const raw = {
  description: "${description}",
  lengthtype: "${contentLength}",
  type: "${contentType}",
  socialmedia: "${selectedPlatform}",
};
let requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
};
fetch("https://backend.whalesai.com/api/v1/generate-article",
requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
    `,
    CURL: `
#!/bin/bash

# Define variables for the API request
API_URL="https://backend.whalesai.com/api/v1/generate-article"
API_KEY="your_api_key_here"  # Replace with your actual API key

# Send the request using curl
curl --location "$API_URL" \
--header "Authorization: Bearer $API_KEY" \
--header "Content-Type: application/json" \
--data '{
  description: "${description}",
  lengthtype: "${contentLength}",
  type: "${contentType}",
  socialmedia: "${selectedPlatform}",
}'
    `,
    PHP: `
<?php
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://backend.whalesai.com/api/v1/generate-article',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    description: "${description}",
    lengthtype: "${contentLength}",
    type: "${contentType}",
    socialmedia: "${selectedPlatform}",
  }',
  // You can get the apikey from https://whalesai.com/api-settings
  CURLOPT_HTTPHEADER => array(
    'Authorization: Bearer apikey'
  ),
));
$response = curl_exec($curl);
curl_close($response);
echo $response;
    `,
    Python: `
    import requests

    # Define the API endpoint
    url = "https://backend.whalesai.com/api/v1/generate-article"

    # Define the payload as a dictionary for better readability
    payload = {
       description: "${description}",
       lengthtype: "${contentLength}",
       type: "${contentType}",
       socialmedia: "${selectedPlatform}",
    }

    # Convert the payload to JSON format
    headers = {
        'Authorization': 'Bearer your_api_key_here',  # Replace with your actual API key
        'Content-Type': 'application/json'
    }

    # Send the POST request
    response = requests.post(url, json=payload, headers=headers)

    # Print the response text
    print(response.text)

    `,
    Flutter: `
  import 'dart:convert'; // To handle JSON data
  import 'package:http/http.dart' as http;

  Future<void> generateArticle({
    required String apiKey,
    required String description,
    required String contentLength,
    required String contentType,
    required String selectedPlatform,
  }) async {
    final String url = 'https://backend.whalesai.com/api/v1/generate-article';

    // Create the headers
    final Map<String, String> headers = {
      'Authorization': 'Bearer $apiKey',
      'Content-Type': 'application/json',
    };

    // Create the payload
    final Map<String, String>  payload = {
       "description": "${description}",
       "lengthtype": "${contentLength}",
       "type": "${contentType}",
       "socialmedia": "${selectedPlatform}",
    }

    try {
      // Send the POST request
      final http.Response response = await http.post(
        Uri.parse(url),
        headers: headers,
        body: json.encode(payload),
      );

      // Check if the request was successful
      if (response.statusCode == 200) {
        print('Response data: response.body');
      } else {
        print('Request failed with status: response.statusCode');
      }
    } catch (e) {
      print('Error: $e');
    }
  }
    `,
  };

  //"LinkedIn", "Medium", "Telegram",
  const contentLengthOptions =
    selectedPlatform &&
    ["Facebook", "LinkedIn", "Medium", "Telegram", "Optional"].includes(selectedPlatform)
      ? [
          { label: "short", value: "short" },
          { label: "long", value: "long" },
        ]
      : [{ label: "short", value: "short" }];

  const handlePlatformSelect = (event) => {
    const platform = event.target.value;
    setSelectedPlatform(platform);
    setResult('');
    // setContentLength("short"); // Default value for content length
    // setContentType("Informative post"); // Reset content type
    console.log(`Selected platform: ${platform}`);
  };

  const handleContentLengthChange = (event) => {
    setContentLength(event.target.value);
    setResult('');
  };
  const handleDescriptions = (event) => {
    setDescription(event.target.value);
    setResult('');
  }
  const handleContentTypeChange = (event) => {
    setContentType(event.target.value);
    setResult('');
  };
  const handleTextChange = (event) => {
    setTextInput(event.target.value);
  };

  const handleClearText = () => {
    setTextInput("");
    setDescription(descriptions[0]);
    setSelectedPlatform(socialMediaPlatforms[0]);
    setContentType(contentTypes[0]);
    setContentLength('short');
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const requestData = {
      type: contentType,
      socialmedia: selectedPlatform,
      description: description,
      lengthtype: contentLength,
    };
    console.log("data", requestData);
    try {
      const token =
        "ay54d24654d255ed96e58bdc6d2bc5b6af4f5e63e6013d6c7c7f8191d6573739721f98d8e654";

      // Make the POST request to the API
      const response = await fetch(
        "https://backend.whalesai.com/api/v1/generate-article/search/data",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      // Check if the response is okay (status in the range 200-299)
      if (!response.ok) {
        // Get the error details from the response
        const errorDetails = await response.text(); // Use response.json() if the error is in JSON format
        throw new Error(
          `HTTP error! Status: ${response.status}, Details: ${errorDetails}`
        );
      }

      // Parse and handle the response
      const responseData = await response.json();
      console.log("Response from API:", responseData);
      setResult(responseData?.message);
      setIsLoading(false);
      // setTextInput("");
      Swal.fire({
        title: "Success!",
        text: "Your TEXT ARTICLE has been Generated Successfully.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        background: "#011225",
        color: "white",
      });
      // alert('Submission successful!');
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("There was a problem with the fetch operation:", error);
      // alert(`There was an error with the submission: ${error.message || 'Unknown error occurred'}`);
      Swal.fire({
        title: "Error!",
        text: `There was a problem Generating your TEXT ARTICLE. \n\n Please Try again`,
        icon: "error",
        confirmButtonColor: "#3085d6",
        background: "#011225",
        color: "white",
      }).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <div>
      <PageHeading title="TEXT ARTICLE GENERATOR" />

      <section className="container page-content pt-0 px-4 text-white">
        {/* description */}
        {/* <div className="mt-3">
          <div className="form-group">
            <label htmlFor="textField" className="form-label">
              Description
            </label>
            <textarea
              id="textField"
              className="form-control"
              value={textInput}
              onChange={handleTextChange}
              style={{ height: "100px" }} // Set the height of the text area
            />
          </div>
        </div> */}
        
        {(
          <div className="form-group mt-3">
            <label htmlFor="contentLengthDropdown" className="form-label">
              description
            </label>
            <select
              // style={{ width: "50%" }}
              className="form-select"
              id="contentLengthDropdown"
              value={description}
              onChange={handleDescriptions}
            >
              {descriptions.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        )}

{/* <div className="form-group mt-3">
          <label htmlFor="ContentLengthDropdown" className="form-label">
           socialmedia
          </label>
          <select
            id="socialMediaDropdown"
            className="form-select"
            value={selectedPlatform || ""}
            onChange={handlePlatformSelect}
          >
            {socialMediaPlatforms.map((platform, index) => (
              <option key={index} value={platform}>
                {platform}
              </option>
            ))}
          </select>
        </div> */}

        {selectedPlatform && (
          <div className="form-group mt-3">
            <label htmlFor="ContentLengthDropdown" className="form-label">
             lengthtype
            </label>
            <select
              // style={{ width: "50%" }}
              className="form-select"
              id="ContentLengthDropdown"
              value={contentLength}
              onChange={handleContentLengthChange}
            >
              {contentLengthOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedPlatform && (
          <div className="form-group mt-3">
            <label htmlFor="contentLengthDropdown" className="form-label">
            type (Optional)
            </label>
            <select
              // style={{ width: "50%" }}
              className="form-select"
              id="contentLengthDropdown"
              value={contentType}
              onChange={handleContentTypeChange}
            >
              {contentTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="form-group mt-3">
          <label htmlFor="ContentLengthDropdown" className="form-label">
          socialmedia (Optional)
          </label>
          <select
            id="socialMediaDropdown"
            className="form-select"
            value={selectedPlatform || ""}
            onChange={handlePlatformSelect}
          >
            {socialMediaPlatforms.map((platform, index) => (
              <option key={index} value={platform}>
                {platform}
              </option>
            ))}
          </select>
        </div>
        {/* <div className="mt-3">
          <div className="form-group">
            <label htmlFor="textField" className="form-label">
              Description
            </label>
            <textarea
              id="textField"
              className="form-control"
              value={textInput}
              onChange={handleTextChange}
              style={{ height: "100px" }} // Set the height of the text area
            />
          </div>
        </div> */}

        <div className="mt-3">
            <button
              style={{ width: "110px", marginRight: "8px" }}
              className="btn btn-secondary mr-2"
              onClick={handleClearText}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              style={{ width: "110px" }}
              className="btn btn-primary"
            >
              {
                isLoading? 'Processing...' : 'Generate'
              }
            </button>
          </div>

        {result && (
          <div>
            <textarea
              id="textField"
              className="form-control mt-3"
              value={result}
              // onChange={handleTextChange}
              style={{ height: "100px" }}
            />


      <div>
       <div className=" mt-5">
        {Object.keys(codeSnippets).map((tab) => (
          <button
            key={tab}
            className={`btn ${selectedTab === tab ? 'btn-secondary' : 'btn-info'} mx-2 mt-2`} 
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
      {/* <div>
        <textarea
          id="textField"
          className="form-control mt-3"
          value={codeSnippets[selectedTab]}
          readOnly
          style={{ height: '300px' }}
        />
      </div> */}
      <div>
        <SyntaxHighlighter 
          language={selectedTab.toLowerCase()} 
          style={dracula} // Change style as desired
          showLineNumbers
        >
          {codeSnippets[selectedTab]}
        </SyntaxHighlighter>
      </div>
</div>
          </div>
        )}
      </section>
    </div>
  );
};

export default TestHowItsWorks;
