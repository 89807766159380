import "./BuildYourBrand.css";
import profileUpdateBtn from "../../Assets/Images/profile-photo-update-icon.png";
import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import Swal from "sweetalert2";
import Select from "react-select";
import countryList from "react-select-country-list";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { getCountryCallingCode, getCountries } from 'libphonenumber-js';
import { getCode } from "country-list";
import PhoneInput from "react-phone-number-input";

const BuildYourBrand = () => {
  const { AIuser } = useContext(UserAuthContext);
  const [AI, setAI] = useState();
  const [countryName, setCountryName] = useState();
  const [userCountry, setUserCountry] = useState();
  const [callingCode, setCallingCode] = useState();
  const [specificBrand, setSpecificBrand] = useState();
  const [isBrand, setIsBrand] = useState(false);
  const [phoneCall, setPhoneCall] = useState();
  const [code, setCode] = useState();
  const aiToken = localStorage.getItem("aiUser");
  const countries = getCountries();
  // countries?.forEach(country => {
  //   console.log(`${country}: +${getCountryCallingCode(country)}`);
  // });

  const [formData, setFormData] = useState({
    company_name: "",
    short_description: "",
    long_description: "",
    address: "",
    contact_number: "",
    webUrl: "",
    logo: null,
    country: "",
    country_code: '',
  });

  const fetchBrandData = async () => {
    const response = await fetch(`https://backend.whalesai.com/api/v1/your-brand/get/userid/${AIuser?._id}`);
    const data = await response.json();
    // console.log(data?.message, 'dataaaaaaaaaaaaaaaaa');
    if (data?.message != 'No BuildBrands found for this user') {
      const ans = data?.data[0];
      setSpecificBrand(ans);
      setCountryName(ans?.country);
      if(!ans && userCountry) setCountryName(userCountry);
      if (data?.data?.length !== 0) {
        setIsBrand(true);
      }
      else setIsBrand(false);
    }
    else setIsBrand(false);
  };

  useEffect(() => {
    fetchBrandData();
  }, [AIuser]);

  useEffect(() => {
    if (specificBrand) {
      setFormData({
        company_name: specificBrand?.company_name || "",
        short_description: specificBrand?.short_description || "",
        long_description: specificBrand?.long_description || "",
        address: specificBrand?.address || "",
        contact_number: specificBrand?.contact_number || "",
        webUrl: specificBrand?.webUrl || "",
        logo: specificBrand?.logo || null,
        country: specificBrand?.country || "",
        country_code: specificBrand?.country_code || '',
      });
    }
  }, [specificBrand]);
  // console.log(countryName, callingCode, AIuser, isBrand, specificBrand);

  const countryOptions = countryList().getData();
  countries?.forEach(country => {
    // console.log('desh', country);
    const countryName = countryOptions?.find(option => option.value == country)?.label;
    if (countryName) {
      // console.log(`${countryName}: +${getCountryCallingCode(country)}`, 'desher name');
    }
  });

  console.log('code => ',  `${getCountryCallingCode('BD')}`)
  const country_digit = getCode('Bangladesh');
  console.log('country 2 digit code =>', country_digit);

  useEffect( () => {
    if(specificBrand?.country){
      const data = getCode(specificBrand?.country);
      const call = getCountryCallingCode(data);
      setCode(call);
      // console.log('desire data => ', call);
    }
    else {
      // const data = getCode(formData?.country);
      // const call = getCountryCallingCode(data);
      setCode(callingCode); 
    }
  } ,[specificBrand, countryName])


  useEffect(() => {
    fetchUserIP();
  }, []);


  const fetchUserIP = async () => {
    try {
      const response = await axios.get('https://api64.ipify.org?format=json');
      const { ip } = response.data;
      fetchCountryData(ip);
    } catch (error) {
      console.error('Error fetching IP address:', error.message);
    }
  };

  const fetchCountryData = async (ip) => {
    const url = `https://ipapi.co/${ip}/json/`;

    try {
      const response = await axios.get(url);
      const { country_name, country_calling_code } = response.data;

      // Fetch the country flag image URL
      const flagImageUrl = `https://countryflagsapi.com/png/${country_calling_code.replace('+', '')}`;
      // console.log(flagImageUrl, 'flagggggggggggggggggggggg....');

      if(specificBrand?.country){
        setCountryName(specificBrand?.country);
      }
      // else{
      //   setCountryName(country_name);
      // }
      // setCountryName(country_name);
      setUserCountry(country_name);
      setCallingCode(country_calling_code);

      if(specificBrand?.country){
        setPhoneCall(getCountryCallingCode(getCode(specificBrand.country)))
      } else {
        setPhoneCall(country_calling_code.replace('+', ''))
      }
      console.log('call', phoneCall);
      
      setFormData(prevFormData => ({
        ...prevFormData,
        country: country_name,
        country_code: phoneCall,
        // country_code: country_calling_code.replace('+', ''),
        // country_flag: flagImageUrl // Set the flag image URL in the form data
      }));
    } catch (error) {
      console.error('Error fetching country data:', error.message);
    }
  };


  console.log('from the response', formData);
  console.log('country name', countryName);

  const handleChange = async (e) => {
    const { name, value, files } = e.target;

    if (name === "logo" && files.length > 0) {
      const file = files[0];
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = async () => {
        if (img.width === 1080 && img.height === 1080) {
          setFormData({
            ...formData,
            [name]: file,
          });
          const formDataa = new FormData();
          formDataa.append('logo', file);

          try {
            const url = specificBrand
              ? `https://backend.whalesai.com/api/v1/your-brand/update/${specificBrand?._id}`
              : "https://backend.whalesai.com/api/v1/your-brand/create";
            const method = specificBrand ? 'put' : 'post';
            const response = await axios?.[method](url, formDataa, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${aiToken}`,
              },
            });

            console.log("Image uploaded successfully", response.data);
            fetchBrandData();
          } catch (error) {
            console.error("Error uploading image", error);
            Swal.fire({
              title: "Error",
              text: "There was an error uploading the image.",
              icon: "error",
              background: "#011225",
              confirmButtonColor: "#F44336",
            });
          }
        } else {
          Swal.fire({
            text: "Image must be 1080x1080 pixels.",
            icon: "error",
            background: "#011225",
            confirmButtonColor: "#F44336",
            color: 'white',
          });
        }
      };
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleCountryChange = (selectedOption) => {
    console.log(selectedOption, 'option.........')
    const code = getCountryCallingCode(selectedOption?.value)
    // const code = getCountryCallingCode('Bangladesh');
    console.log(code, selectedOption?.label, 'codeeeeeeeeeeeeeeeeee');
    setFormData({
      ...formData,
      // country: selectedOption.value,
      country: selectedOption?.label,
      country_code: code,
    });
  };

  const handlePaste01 = (e) => {
    e.preventDefault();
    const pasteText = (e.clipboardData || window.clipboardData).getData('text');
    const newText = formData.short_description + pasteText;

    if (newText) {
      setFormData({
        ...formData,
        short_description: newText,
      });
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteText = (e.clipboardData || window.clipboardData).getData('text');
    const newText = formData.long_description + pasteText;

    if (newText) {
      setFormData({
        ...formData,
        long_description: newText,
      });
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const { company_name, short_description, long_description, address, contact_number, webUrl, country } = formData;
    if (!company_name && !short_description && !long_description && !address && !contact_number && !webUrl) {
      Swal.fire({
        title: "Warning",
        text: "Please make sure to fill all required fields.",
        icon: "warning",
        background: "#011225",
        confirmButtonColor: "#FFC107",
        color: "red",
      });
      fetchBrandData();
      return;
    }

    try {
      const url = isBrand
        ? `https://backend.whalesai.com/api/v1/your-brand/update/${specificBrand?._id}`
        : "https://backend.whalesai.com/api/v1/your-brand/create";
      const method = isBrand ? 'put' : 'post';

      const response = await axios?.[method](
        url,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${aiToken}`,
          },
        }
      );
      console.log("Form submitted successfully", response.data);
      Swal.fire({
        title: isBrand ? "Updated" : "Success",
        text: isBrand ? "Your brand has been updated successfully!" : "Your brand has been created successfully!",
        icon: "success",
        background: "#011225",
        confirmButtonColor: "#4CAF50",
        color: "white",
      });
      setFormData({
        company_name: specificBrand?.company_name || "",
        short_description: specificBrand?.short_description || "",
        long_description: specificBrand?.long_description || "",
        address: specificBrand?.address || "",
        contact_number: specificBrand?.contact_number || "",
        webUrl: specificBrand?.webUrl || "",
        logo: specificBrand?.logo || null,
        country: specificBrand?.country || "",
        country_code: specificBrand?.country_code || '',
      });
      fetchBrandData();
    } catch (error) {
      console.error("Error submitting form", error);
      Swal.fire({
        title: "Error",
        text: "There was an error submitting your form.",
        icon: "error",
        background: "#011225",
        confirmButtonColor: "#F44336",
      });
    }
  };


  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#D9D9D9",
      color: "#D9D9D9",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#011225" : "#D9D9D9",
      color: state.isSelected ? "#D9D9D9" : "black",
      "&:hover": {
        backgroundColor: "#011225",
        color: "#D9D9D9",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const [picture, setPicture] = useState();
  useEffect(() => {
    if (formData?.logo) setPicture(formData.logo)
    else setPicture("https://backend.whalesai.com/public/ffmpeg/default.jpg");
  }, [formData]);

  console.log(specificBrand, 'default country')
  // console.log('code', getCountryCallingCode(getCode(specificBrand?.country)).toLowerCase());
  // console.log( 'Code =>' ,getCode('Bangladesh'));
  return (
    <section className="build-your-brand pb-5">
      <PageHeading title='Build your brand' description='Please provide all relevant information. The information provided by
          you will be used for your social media marketing' />
      <div className="container px-4">
        {/* <h2>Build your brand</h2>
        <p className="small">
          Please provide all relevant information. The information provided by
          you will be used for your social media marketing
        </p> */}
        <form onSubmit={handleSubmit}>
          {/*  logo */}
          <div className="d-flex gap-3 justify-content-center w-100 mt-5">
            <div className="avatar-portion d-flex align-items-center gap-2">
              <div className="avatar-container">
                <img
                  style={{ borderRadius: "60px" }}
                  className="avatar"
                  src={picture}
                  alt="image"
                />
                <label className="profile-update-button" htmlFor="profile-img">
                  <img src={profileUpdateBtn} alt="image" />
                </label>
                <input
                  className="w-100 d-none"
                  type="file"
                  accept="image/*"
                  name="logo"
                  id="profile-img"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-100">
              <label htmlFor="profile-img">Company Logo</label>
              <input
                className="w-100"
                type="file"
                accept="image/*"
                name="logo"
                id="profile-img"
                onChange={handleChange}
              />
              <label className="logo-pic">1080 x 1080 px</label>
            </div>
          </div>
          {/*  name */}
          <div className="form-item">
            <label htmlFor="company_name">Company Name</label>
            <input
              type="text"
              id="company_name"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
            />
          </div>
          {/* short description */}
          <div
            style={{ position: 'relative' }}
            className="form-item">
            <label htmlFor="short_description">Company Short Description <span className="">(160 characters)</span> </label>
            <textarea
              style={{ paddingRight: '60px' }}
              rows={1}
              maxLength="160"
              type="text"
              value={formData.short_description}
              id="short_description"
              name="short_description"
              onChange={handleChange}
              onPaste={handlePaste01}
            />
            {/* <span style={{ position: 'absolute', color:'black', right: '10px', top: '65%', transform: 'translateY(-50%)' }}>
                {formData.short_description.length}/160
              </span> */}
            <span
              style={{
                position: 'absolute',
                color: 'black',
                right: '10px',
                top: '65%',
                transform: 'translateY(-50%)'
              }}
            >
              <span
                style={{ color: formData.short_description.length > 160 ? 'red' : 'black', }}
              >{formData.short_description.length}</span>/160
            </span>
          </div>
          {/* long description */}
          <div
            style={{ position: 'relative' }}
            className="form-item">
            <label htmlFor="long_description">Company Long Description <span className="">(1600 characters)</span></label>
            <textarea
              style={{ paddingRight: '60px' }}
              rows={4}
              maxLength="1600"
              type="text"
              value={formData.long_description}
              id="long_description"
              name="long_description"
              onChange={handleChange}
              onPaste={handlePaste}
            />
            <span style={{ position: 'absolute', color: 'black', right: '10px', top: '85%', transform: 'translateY(-50%)' }}>
              <span
                style={{ color: formData.long_description.length > 1600 ? 'red' : 'black', }}
              >{formData.long_description.length}</span>/1600
            </span>
          </div>
          {/* address */}
          <div className="form-item">
            <label htmlFor="address">Company Address</label>
            <input
              type="text"
              value={formData.address}
              id="address"
              name="address"
              onChange={handleChange}
            />
          </div>
          {/* contact number */}
          {/* <div className="form-item">

            <label htmlFor="contact_number">Company Contact Number</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
            <select
              name="country_code"
              value={formData.country_code}
              onChange={handleChange}
              style={{ marginRight: '10px' }}
            >
              <option value="">Select</option>
              {countries.map(country => (
                <option key={country} value={getCountryCallingCode(country)}>
                  {country} (+{getCountryCallingCode(country)})
                </option>
              ))}
            </select>
            <input
              type="number"
              value={formData.contact_number}
              id="contact_number"
              name="contact_number"
              onChange={handleChange}
            />
            </div>
          </div> */}

          <div className="form-item">
            <label htmlFor="contact_number">Company Contact Number</label>
            <div
              className=" country-div"
              style={{ display: 'flex', alignItems: 'center' }}>

              <select
                className="country-code"
                name="country_code"
                value={formData.country_code}
                onChange={handleChange}
                style={{
                  backgroundColor: '#D9D9D9',
                  marginRight: '10px',
                  flexShrink: 0,
                }}
              >
                <option value="">{specificBrand?.country ? specificBrand?.country : 'Detecting...'}</option>
                {countries.map(country => {
                  const countryData = countryOptions?.find(option => option.value == country);
                  if (countryData) {
                    // console.log(countryData, 'from code section...')
                    return (
                      <option key={country} value={getCountryCallingCode(country)}>
                        {countryData.label} 
                        {/* (+{getCountryCallingCode(country)}) */}
                      </option>
                    );
                  } else {
                    return null; 
                  }
                })}
              </select>
              <div className="country-calling-code">
                
                +
                {/* {specificBrand?.country ? getCountryCallingCode(getCode(specificBrand.country)) : 880} */}
                {
                  code
                }
                {/* {
                  specificBrand?.country && getCountryCallingCode(getCode(specificBrand.country))
                } */}
                {/* {
                  !formData?.country_code && !specificBrand?.country && 880
                } */}
              </div>

              <input
                type="number"
                className="country-input"
                value={formData.contact_number}
                id="contact_number"
                name="contact_number"
                onChange={handleChange}
                style={{
                  flexGrow: 1, // Ensures the input takes up the remaining width
                  backgroundColor: '#D9D9D9', // Same background color as the select
                }}
              />
            </div>
          </div>
          {/* website */}
          <div className="form-item">
            <label htmlFor="webUrl">Company Website</label>
            <input
              type="text"
              value={formData.webUrl}
              id="webUrl"
              name="webUrl"
              onChange={handleChange}
            />
          </div>
          {/* country */}
          <div className="form-item">
            <label htmlFor="country" className="mb-1">Country</label>
            <Select
              options={countryOptions}
              value={countryOptions.find(
                (option) => option.value === formData.country
              )}
              // value={formData?.country}
              onChange={handleCountryChange}
              id="country"
              name="country"
              placeholder="Select a country"
              styles={customStyles}
            />
          </div>
          {/* button */}
          <div className="d-flex justify-content-md-end justify-content-center mt-2">
            <button
              type="submit"
              disabled={formData.long_description.length > 1600 || formData.short_description.length > 160}
              // className={formData.long_description.length > 1600 || formData.short_description.length > 160 ? 'btn btn-secondary' : 'btn btn-success'}
              className="btn brand-button"
            >Submit</button>
            {/* <button className= "mt-3 btn btn success">Submit</button> */}
          </div>
        </form>
      </div>
    </section>
  );
};

export default BuildYourBrand;
