import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";

import Loader from "../../Components/Loader/Loader2";
import * as React from "react";

import LinearProgress from "@mui/material/LinearProgress";

// import certificate from "./certificate.jpg";
// const style = ;

export default function PlayVideoModal({
  openPlayModal,
  setOpenPlayModal,
  progress,
  videoUrl,
  makingVideo,
  format,
}) {
  //   const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(true);

  const ShowLoading = () => setLoading(false);
  //   const sourcelink = certificate;

  console.log("videoUrl", videoUrl, format);

  return (
    <div>
      <Modal
        open={openPlayModal}
        onClose={() => setOpenPlayModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          // className={`${format == 0 ? "play-video-modal" : ""}`}
          id=""
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            // height: format == 0 && makingVideo == false && "300px",
            // height: "300px",
            backgroundColor: "#1a416b",
            border: "2px solid white",
            boxShadow: 24,
            color: "white",
            borderRadius: "5px",
          }}
        >
          {makingVideo ? (
            <div className="py-5">
              <p className="text-center mb-0">Generating video.</p>
              <LinearProgress
                color="inherit"
                style={{ height: 10, marginLeft: 15, marginRight: 15 }}
                thickness={4}
              />
              <p className="text-center text-warning mb-0">
                Please don't exit your screen.
              </p>
            </div>
          ) : (
            <>
              {videoUrl ? (
                <>
                  <video
                    controls
                    playsInline
                    // muted
                    autoPlay
                    src={videoUrl}
                    className="p-"
                    style={{ width: "100%", height: "100%" }}
                    type="video/mp4"
                  />
                </>
              ) : (
                <Loader />
              )}
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
