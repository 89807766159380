import React, { useState, useEffect, useContext } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./index.css";
import { auth } from "../../Components/Firebase/firebase.config";
import {
  FacebookAuthProvider,
  linkWithPopup,
  signInWithPopup,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import Swal from "sweetalert2";
import axios from "axios";

const buttons = [
  "Facebook",
  "Instagram",
  "Threads",
  "Twitter",
  "LinkedIn",
  "TikTok",
  "YouTube",
  "Pinterest",
  "Medium",
  "Telegram",
];

const buttonColors = [
  "#95B9C9",
  "#39ACEF",
  "#0699E8",
  "#488AC7",
  "#367ECA",
  "#6960ED",
  "#1669C7",
  "#0040C4",
  "#0000FE",
  "#151A8F",
];

const SocialMediaLogins = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [facebookLogin, setFacebookLogin] = useState(false);
  const [allPageID, setAllPageID] = useState();

  const provider = new FacebookAuthProvider();
  const { AIuser, AiLogOut } = useContext(UserAuthContext);
  // provider.addScope("publish_video");
  // provider.addScope("pages_show_list");
  // provider.addScope("pages_read_engagement");
  // provider.addScope("pages_manage_metadata");
  // provider.addScope("pages_read_user_content");
  // provider.addScope("pages_manage_posts");
  // provider.addScope("pages_manage_engagement");

  useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  // facebook with SDK
  // useEffect(() => {
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: "3757864734451749",
  //       cookie: true,
  //       xfbml: true,
  //       version: "v20.0",
  //     });
  //     window.FB.AppEvents.logPageView();
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "facebook-jssdk");
  // }, []);

  useEffect(() => {
    // Function to initialize the Facebook SDK
    const initializeFB = () => {
      if (window.FB) {
        window.FB.init({
          appId: "1098936551720260", // Replace with your Facebook App ID
          cookie: true,
          xfbml: true,
          version: "v20.0", // Ensure this is a valid version
        });
        window.FB.AppEvents.logPageView();
      }
    };

    // Load the Facebook SDK asynchronously
    (function (d, s, id) {
      if (d.getElementById(id)) {
        initializeFB(); // SDK is already loaded
        return;
      }
      const js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      js.onload = initializeFB; // Initialize SDK after script loads
      const fjs = d.getElementsByTagName(s)[0];
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const handleLogin = (media) => {
    if(media === 'Facebook'){
      if (!window.FB) {
        console.error("Facebook SDK not loaded");
        return;
      }
  
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            console.log("Welcome! Fetching your information...");
            window.FB.api("/me", { fields: "name,email" }, function (userInfo) {
              console.log("Good to see you, " + userInfo.name + ".");
              handleToken(response.authResponse.accessToken); 
              getAllPageIds(response.authResponse.accessToken);
            });
          } else {
            console.log("User cancelled login or did not fully authorize.");
          }
        },
        {
          config_id: "467207639112586",
          response_type: "code",
          override_default_response_type: true,
          scope:
            "public_profile,email,pages_manage_posts,pages_read_engagement,pages_show_list", // Request necessary permissions
        }
      );
    }
    else{
      Swal.fire({
        icon: "warning",
        text: "Coming Soon!",
        allowOutsideClick: false,
        background: "#011225",
        color: "white",
      });
    }
    
  };

  useEffect(() => {
    if (AIuser?.socialTokens[0]?.access_token?.length > 0)
      setFacebookLogin(true);
    else setFacebookLogin(false);
  }, [AIuser]);

  const handleToken = async (token) => {
    try {
      // Retrieve the token from localStorage
      const userToken = localStorage.getItem("aiUser");

      if (userToken) {
        const response = await fetch(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/exchange-token",
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`, // Add the token to the Authorization header
            },
            body: JSON.stringify({ shortLivedToken: token }),
          }
        );

        if (!response.ok) {
          // Handle non-OK responses
          const text = await response.text();
          const headers = JSON.stringify([...response.headers.entries()]); // Capture headers
          throw new Error(
            `HTTP error! Status: ${response.status}, Response: ${text}, Headers: ${headers}`
          );
        }

        // Parse JSON response
        const data = await response.json();
        console.log("Backend response:", data);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  // const handleLogin = (media) => {
  //   if (media == "Facebook") {
  //     // if (user) {
  //     //   // If the user is already logged in, link Facebook to their account
  //     //   linkWithPopup(user, provider)
  //     //     .then((result) => {
  //     //       const credential =
  //     //         FacebookAuthProvider.credentialFromResult(result);
  //     //       console.log(
  //     //         "dataaaaaaaaaaaaaaaaaaaaaaaaaaa==========> :",
  //     //         credential
  //     //       );
  //     //       const accessToken = credential.accessToken;
  //     //       console.log("Facebook access token => ", accessToken);
  //     //       setToken(accessToken);
  //     //       handleToken(accessToken);
  //     //       getAllPageIds(accessToken);
  //     //     })
  //     //     .catch((error) => {
  //     //       console.error(
  //     //         "Error during linking with Facebook: ",
  //     //         error.message
  //     //       );
  //     //     });
  //     // } else {
  //     //   // If no user is logged in, prompt for Facebook login
  //     //   signInWithPopup(auth, provider)
  //     //     .then((result) => {
  //     //       const credential =
  //     //         FacebookAuthProvider.credentialFromResult(result);
  //     //       console.log(
  //     //         "dataaaaaaaaaaaaaaaaaaaaaaaaaaa==========> :",
  //     //         credential
  //     //       );

  //     //       const accessToken = credential.accessToken;
  //     //       console.log("Facebook access token => ", accessToken);
  //     //       setToken(accessToken);
  //     //       handleToken(accessToken);
  //     //       getAllPageIds(accessToken);
  //     //     })
  //     //     .catch((error) => {
  //     //       console.error("Error during Facebook login: ", error.message);
  //     //     });
  //     // }

  //     if (!window.FB) {
  //       console.error("Facebook SDK not loaded");
  //       return;
  //     }
  //     window.FB.login(
  //       function (response) {
  //         if (response.authResponse) {
  //           console.log("Welcome! Fetching your information...");
  //           window.FB.api("/me", { fields: "name,email" }, function (response) {
  //             console.log("Good to see you, " + response.name + ".");
  //             handleToken(response.authResponse.accessToken);
  //           });
  //         } else {
  //           console.log("User cancelled login or did not fully authorize.");
  //         }
  //       },
  //       {
  //         config_id: "8620140518019525",  // Replace with your actual CONFIG_ID
  //         response_type: "code",
  //         override_default_response_type: true,
  //         scope: "public_profile,email,pages_manage_posts,pages_read_engagement,pages_show_list"
  //       }
  //     );
  //   }
  // };

  const getAllPageIds = (accessToken) => {
    fetch(`https://graph.facebook.com/me/accounts?access_token=${accessToken}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data && data.data.length > 0) {
          const pageIds = data.data.map((page) => page.id);
          setAllPageID(pageIds);
          console.log("All Page IDs: ", pageIds);
        } else {
          console.log("No pages found or invalid access token.");
        }
      })
      .catch((error) => {
        console.error("Error fetching Page IDs: ", error.message);
      });
  };

  console.log("AIUSer =>", AIuser?.socialTokens[0]?.access_token);

  const facebookPost = async () => {
    const url = "https://graph.facebook.com/me/feed";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          access_token: "",
          message: "This is a post from my application!",
        }),
      });

      const data = await response.json();
      console.log("Facebook post response:", data);
    } catch (error) {
      console.error("Error posting to Facebook:", error.message);
    }
  };

  const getPageId = async () => {
    fetch(
      "https://graph.facebook.com/v12.0/me/accounts?access_token=your-access-token",
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // You'll get a list of pages with their IDs
        // Example: data.data[0].id
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // const postToFacebook = () => {
  //   const url = "https://graph.facebook.com/me/feed";
  //   const payload = {
  //     message: message,
  //     access_token: accessToken,
  //   };     

  //   axios
  //     .post(url, payload)
  //     .then((res) => {
  //       console.log(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err.response ? err.response.data : "Unknown error");
  //     });
  // };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out successfully");
        setFacebookLogin(false);
        Swal.fire({
          // Use Swal.fire() to display the alert
          text: "You have successfully logged out of Facebook.", //for mainnet
          icon: "success",
          className: "modal_class_success",
        });
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  //   import React, { useEffect } from 'react';

  // const FacebookLogin = () => {
  //   useEffect(() => {
  //     window.fbAsyncInit = function() {
  //       FB.init({
  //         appId: 'YOUR_FACEBOOK_APP_ID',
  //         cookie: true,
  //         xfbml: true,
  //         version: 'v10.0'
  //       });
  //     };

  //     (function(d, s, id){
  //       var js, fjs = d.getElementsByTagName(s)[0];
  //       if (d.getElementById(id)) {return;}
  //       js = d.createElement(s); js.id = id;
  //       js.src = "https://connect.facebook.net/en_US/sdk.js";
  //       fjs.parentNode.insertBefore(js, fjs);
  //     }(document, 'script', 'facebook-jssdk'));
  //   }, []);

  //   const handleLogin = () => {
  //     FB.login(response => {
  //       if (response.authResponse) {
  //         const { accessToken } = response.authResponse;
  //         console.log('Access Token:', accessToken); // You will use this
  //         // Send the access token to your server
  //         fetch('/auth/facebook/token', {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json'
  //           },
  //           body: JSON.stringify({ accessToken })
  //         }).then(res => res.json())
  //           .then(data => console.log('Server Response:', data));
  //       } else {
  //         console.log('User cancelled login or did not fully authorize.');
  //       }
  //     }, { scope: 'email, publish_pages, pages_manage_posts' });
  //   };

  //   return (
  //     <div>
  //       <button onClick={handleLogin}>Login with Facebook</button>
  //     </div>
  //   );
  // };

  // export default FacebookLogin;

  // const { saveUser } = require('../models/userModel');
  // const axios = require('axios');

  // exports.receiveAccessToken = async (req, res) => {
  //   const { accessToken } = req.body;
  //   // Verify the access token with Facebook (optional but recommended)
  //   const userProfile = await verifyAccessToken(accessToken);
  //   if (userProfile) {
  //     const user = {
  //       id: userProfile.id,
  //       displayName: userProfile.name,
  //       token: accessToken
  //     };
  //     saveUser(user);
  //     res.status(200).send('Access token saved successfully');
  //   } else {
  //     res.status(400).send('Invalid access token');
  //   }
  // };

  // const verifyAccessToken = async (token) => {
  //   try {
  //     const response = await axios.get(`https://graph.facebook.com/me?access_token=${token}`);
  //     return response.data;
  //   } catch (error) {
  //     console.error('Error verifying access token: ', error);
  //     return null;
  //   }
  // };
  return (
    <div>
      <PageHeading title="Social Media Logins" />
      <section className="container my-4">
        {/* <button
          onClick={postToFacebook}
          className="text-white fw-bold btn btn-warning"
        >
          Post
        </button> */}
        <div className="">
          <div className="buttons-layout mx-auto">
            {buttons.map((button, index) => (
              <div key={index} className="every-button">
                <button
                  onClick={() => handleLogin(button)}
                  className="btn btn-block text-white mb-2"
                  style={{
                    backgroundColor: buttonColors[index],
                    width: "200px",
                  }}
                >
                  {button}
                </button>
                <div style={{ width: "130px" }}>
                  {button == "Facebook" && user ? (
                    <div className="log-container">
                      <p className="mb-0 mr-2">Logged in</p>
                      <button
                        className="btn btn-sm btn-danger ml-2"
                        onClick={handleLogout}
                      >
                        Log Out
                      </button>
                    </div>
                  ) : (
                    <p>Not Logged in yet.</p>
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* {token && <p style={{width:'500px', color:"white", backgroundColor:"pink"}}>{token}</p>} */}
        </div>
      </section>
    </div>
  );
};

export default SocialMediaLogins;
