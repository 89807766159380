import React from 'react';
import './apiPackage.css'; // Create this CSS file for custom styles

const apiPackages = [
  {
    name: "Text AI",
    credits: "1 Credit per 10 words",
    price: "USD 0.002 per word",
    background: "#05C0A2",
  },
  // {
  //   name: "Image AI",
  //   credits: "10 Credits",
  //   price: "USD 0.20",
  //   background: "#1D60EF",
  // },
  // {
  //   name: "Post AI",
  //   credits: "10 Credits per image",
  //   price: "USD 0.20",
  //   background: "#FA6918",
  // },
  // {
  //   name: "Text Video AI",
  //   credits: "40 Credits",
  //   price: "USD 0.40",
  //   background: "#d62626",
  // },
  // {
  //   name: "News Video AI",
  //   credits: "40 Credits",
  //   price: "USD 0.40",
  //   background: "#3080AC",
  // },
  // {
  //   name: "Comic Video AI",
  //   credits: "60 Credits",
  //   price: "USD 0.60",
  //   background: "#006C62",
  // },
];

const APIPackage = () => {
  const handlePackageClick = (packageName) => {
    alert(`You selected the ${packageName} package.`);
    // You can handle navigation or any other logic here
  };

  return (
    // clas 
    <div className="row g-4 py-md-5 py-3 d-flex justify-content-center">
      {apiPackages.map((item, index) => (
        <div className="col-md-6 col-lg-4 col-12" key={index}>
          <div style={{ borderRadius: "10px" }} className="bg-white">
            <div
              className="package-price text-center"
              style={{ background: `${item.background}` }}
            >
              <h2>{item.name}</h2>
              
            </div>
            <div>
              
              <div className="each-row py-4">
                {/* <p className="row-header mb-2">EXPIRY</p> */}
                <p className="row-value mb-0">
                  {item.credits}
                </p>
              </div>
              <div className="each-row py-4 border-0">
                <p className="row-header mb-2">Low as {item.price}</p>
                
              </div>
              <div className="pay-button-container">
                <button
                  className="pay-button"
                    // onClick={() => handleNavigate(item, index + 1)}
                >
                  {item.price}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default APIPackage;
