import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  Typography,
  Box,
  DialogContent,
  Avatar,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useContext, useEffect, useState } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SpecificSchedule({
  setModalOpen,
  modalOpen,
  oneSchedule,
  name,
}) {
  const handleClose = () => setModalOpen(false);
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${dd}-${mm}-${yyyy}`;

  const [scheduleData, setScheduledata] = useState();
  const [matchedData, setMatchedData] = useState();
  const [generatedContent, setGeneratedContent] = useState();
  const [generatedHashTag, setGeneratedHashTag] = useState();
  const [image, setImage] = useState(false);
  const [video, setVideo] = useState(false);
  const [text, setText] = useState(false);
  const [textVideo, setTextVideo] = useState(false);
  const [newsVideo, setNewsVideo] = useState(false);
  const [comicVideo, setComicVideo] = useState(false);
  const [samleVideoLink, setSampleVideoLink] = useState();
  const [nv, setNv] = useState();
  const [tv, settv] = useState();
  const [cv, setcv] = useState();

  const findData = () => {
    console.log("I am starting ..... ");
    if (oneSchedule && scheduleData?.length > 0) {
      for (let category of scheduleData) {
        if (category?.date === formattedDate) {
          console.log("Matched Data", category);
          setMatchedData(category);
        }
      }
    }
  };

  useEffect(() => {
    if (oneSchedule && oneSchedule.contentCategory?.length > 0) {
      setScheduledata(oneSchedule?.contentCategory);
    }
  }, [oneSchedule]);

  useEffect(() => {
    if (scheduleData?.length > 0) {
      findData();
    }
  }, [scheduleData, formattedDate]);

  useEffect(() => {
    if (matchedData && matchedData.content?.includes("Video" || "video")) {
      setImage(false);
      setText(false);
      setVideo(true);
      if (matchedData && matchedData.content?.includes("Text Video")) {
        setTextVideo(true);
        setNewsVideo(false);
        setComicVideo(false);
        settv(
          "https://backend.whalesai.com/public/hiwvideos/1080-1080-text-video.mp4"
        );
        setNv("");
        setcv("");
      } else if (matchedData && matchedData.content?.includes("News Video")) {
        setNewsVideo(true);
        setTextVideo(false);
        setComicVideo(false);
        settv("");
        setNv(
          "https://backend.whalesai.com/public/hiwvideos/1080-1080-news-video.mp4"
        );
        setcv("");
      } else {
        setComicVideo(true);
        setTextVideo(false);
        setNewsVideo(false);
        settv("");
        setNv("");
        setcv(
          "https://backend.whalesai.com/public/hiwvideos/1080-1080-comic-video.mp4"
        );
      }
    } else if (
      matchedData &&
      matchedData.content?.includes("Image" || "image")
    ) {
      setImage(true);
      setVideo(false);
      setText(false);
    } else {
      setText(true);
      setVideo(false);
      setImage(false);
    }
  }, [matchedData]);

  // useEffect(() => {
  //   if(video){
  //     if(textVideo) setSampleVideoLink('https://backend.whalesai.com/public/hiwvideos/1080-1080-text-video.mp4');
  //     else if(newsVideo) setSampleVideoLink('https://backend.whalesai.com/public/hiwvideos/1080-1080-news-video.mp4');
  //     else setSampleVideoLink('https://backend.whalesai.com/public/hiwvideos/1080-1080-comic-video.mp4');
  //   }
  // } ,[video])

  useEffect(() => {
    if (image || video) {
      setGeneratedContent(matchedData?.generatedContent);
      setGeneratedHashTag(matchedData?.hashtags);
    } else {
      setGeneratedContent(matchedData?.generatedContent);
    }
  }, [matchedData, text, image, video]);

  console.log("Specific user info === >>>>>", oneSchedule);

  const platforms = [
    "Facebook",
    "Instagram",
    "Threads",
    "Twitter",
    "LinkedIn",
    "TikTok",
    "YouTube",
    "Pinterest",
    "Medium",
    "Telegram",
  ];

  const colors = [
    "#075CE5",
    "#FE7813",
    "#000000",
    "#1DA1F2",
    "#0077B5",
    "#F67590",
    "#F60002",
    "#C51F27",
    "#000000",
    "#229AD4",
  ];

  const platformIndex = platforms.indexOf(oneSchedule?.platform);

  console.log("Platform Index number", platformIndex);

  const textWithHastag = `Machine learning isn't just a tech trend; it's the future of how we understand and interact with our world. Harness its power, and unlock endless possibilities. 🌐🤖 #MachineLearning #AI #Innovation #FutureTech #DataScience`;

  const HashtagText = ({ text }) => {
    // Split the text by spaces so each word can be processed individually
    const words = text.split(" ");

    let firstHashtagFound = false;

    return (
      <Typography variant="body2" sx={{ mb: 1, textAlign: "start" }}>
        {words.map((word, index) => {
          if (word.startsWith("#")) {
            if (!firstHashtagFound) {
              // Add line break after first hashtag and mark it found
              firstHashtagFound = true;
              return (
                <React.Fragment key={index}>
                  <br />
                  <Box component="span" sx={{ color: "blue" }}>
                    {word}{" "}
                  </Box>
                </React.Fragment>
              );
            }
            // For subsequent hashtags, just apply blue color
            return (
              <Box key={index} component="span" sx={{ color: "blue" }}>
                {word}{" "}
              </Box>
            );
          }
          // Regular text
          return (
            <Box key={index} component="span">
              {word}{" "}
            </Box>
          );
        })}
      </Typography>
    );
  };

  console.log("video type : Text Video =>", textVideo);
  console.log("video type : News Video =>", newsVideo);
  console.log("video type : Comic Video =>", comicVideo);

  //#FF0033
  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalOpen}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab", //colors[platformIndex]
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Schedule Details</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Platform:{" "}
              {oneSchedule?.platform == "Twitter"
                ? "Twitter/X"
                : oneSchedule?.platform}
            </Typography>

            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>Content Type:</strong> {matchedData?.content}
            </Typography>

            {oneSchedule?.platform == "YouTube" ||
            oneSchedule?.platform == "Instagram" ||
            oneSchedule?.platform == "TikTok" ? (
              <>
                {text && (
                  <div>
                    <Box sx={{ border: "1px solid #ccc", padding: 1, mb: 2 }}>
                      <HashtagText text={textWithHastag} />
                    </Box>
                  </div>
                )}
                {image && (
                  <div>
                    <Box sx={{ border: "1px solid #ccc", padding: 2, mb: 2 }}>
                      {/* <HashtagText text={textWithHastag} /> */}
                      <Box
                        sx={{
                          width: "100%",
                          // height: "200px",
                          backgroundColor: "#f0f0f0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mb: "2",
                        }}
                      >
                        <img
                          style={{ height: "300px", width: "" }}
                          src={
                            matchedData?.generatedContent ||
                            "https://backend.whalesai.com/public/imageai/02102024-1e2858.png"
                          }
                        />
                      </Box>
                      <HashtagText text={textWithHastag} />
                    </Box>
                  </div>
                )}

                {video && (
                  <div>
                    {textVideo && <p>textVideo</p>}
                    {newsVideo && <p>newsVideo</p>}
                    {comicVideo && <p>comicVideo</p>}

                    <Box sx={{ border: "1px solid #ccc", padding: 1, mb: 2 }}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#f0f0f0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {textVideo && (
                          <video width="100%" controls className="mb-2">
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-text-video.mp4"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}

                        {newsVideo && (
                          <video width="100%" controls className="mb-2">
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-news-video.mp4"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        {comicVideo && (
                          <video width="100%" controls className="mb-2">
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-comic-video.mp4"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        {/* <video width="100%" controls className="mb-2">
                          {textVideo && (
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-text-video.mp4"
                              type="video/mp4"
                            />
                          )}
                          {newsVideo && (
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-news-video.mp4"
                              type="video/mp4"
                            />
                          )}
                          {comicVideo && (
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-comic-video.mp4"
                              type="video/mp4"
                            />
                          )}
                          Your browser does not support the video tag.
                        </video> */}
                      </Box>
                      <HashtagText text={textWithHastag} />
                    </Box>
                  </div>
                )}
              </>
            ) : (
              <>
                {text && (
                  <div>
                    <Box sx={{ border: "1px solid #ccc", padding: 1, mb: 2 }}>
                      <HashtagText text={textWithHastag} />
                    </Box>
                  </div>
                )}

                {image && (
                  <div>
                    <Box sx={{ border: "1px solid #ccc", padding: 2, mb: 2 }}>
                      <HashtagText text={textWithHastag} />
                      <Box
                        sx={{
                          width: "100%",
                          // height: "200px",
                          backgroundColor: "#f0f0f0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{ height: "300px", width: "" }}
                          src={
                            matchedData?.generatedContent ||
                            "https://backend.whalesai.com/public/imageai/02102024-1e2858.png"
                          }
                        />
                      </Box>
                    </Box>
                  </div>
                )}

                {video && (
                  <div>
                    {textVideo && <p>textVideo</p>}
                    {newsVideo && <p>newsVideo</p>}
                    {comicVideo && <p>comicVideo</p>}
                    <Box sx={{ border: "1px solid #ccc", padding: 1, mb: 2 }}>
                      <HashtagText text={textWithHastag} />
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#f0f0f0",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {textVideo && (
                          <video width="100%" controls className="mb-2">
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-text-video.mp4"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}

                        {newsVideo && (
                          <video width="100%" controls className="mb-2">
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-news-video.mp4"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                        {comicVideo && (
                          <video width="100%" controls className="mb-2">
                            <source
                              src="https://backend.whalesai.com/public/hiwvideos/1080-1080-comic-video.mp4"
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </Box>
                    </Box>
                  </div>
                )}
              </>
            )}
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
