import React from "react";
import "./Footer.css";
import social1 from "../../../Assets/Images/twitter2.png";
import social2 from "../../../Assets/Images/tiktok-icon.png";
import social3 from "../../../Assets/Images/linked-in-icon.png";
import social4 from "../../../Assets/Images/pinterest-icon.png";
import social5 from "../../../Assets/Images/medium-icon.png";
import social7 from "../../../Assets/Images/insta-icon.png";
import { Link } from "react-router-dom";

const Footer = () => {
  // const { AIuser, AiLogOut } = useContext(UserAuthContext);
  // const [allMedia, setAllMedia] = useState({});
  // useEffect(() => {
  //   axios.get(`https://backend.dsl.sg/api/v1/social-link`).then((res) => {
  //     setAllMedia(res.data);
  //   });
  // }, []);

  return (
    <div className="footer-container">
      <div className="container px-4">
        <div className="row gy-5 g-md-0 g-lg-0 justify-content-between footer-main">
          <div className="col-md-4 col-lg-4 col-12 footer-first-part">
            <div className="d-flex align-items-center">
              {/* <img style={{ width: "45px" }} src={galaraceLogo} alt="image" />{" "} */}
            </div>

            <h3 style={{ marginBottom: "0px" }}>WHALES AI</h3>

            <div
              style={{ maxWidth: "118px" }}
              className="title-bottom-border"
            ></div>
            <p>
              Meet the world's first AI-based social media marketing manager,
              revolutionizing digital outreach.
            </p>

            {/* <p>
              <span className="extra">TEXT AI</span> The Text AI aids in
              generating content for various social media platforms.
            </p>

            <p>
              <span className="extra">IMAGE AI</span> The Image AI assists in
              crafting images tailored for social media platforms.
            </p>

            <p>
              <span className="extra">POST AI</span> The Post AI effortlessly
              engaging social media posts with minimal input, ensuring your
              content always stays fresh and relevant.
            </p>

            <p>
              <span className="extra">TEXT VIDEO AI</span> The Text Video AI can
              swiftly produce text-based videos with voiceovers.
            </p> */}
          </div>

          <div className="col-md-3 col-lg-2 col-12 quick-links-container">
            <h3 style={{ marginBottom: "0px" }}>Quick Links</h3>
            <div
              style={{ maxWidth: "118px" }}
              className="title-bottom-border"
            ></div>

            <Link to={`#`}>
              <p style={{ cursor: "pointer" }}>Text AI</p>
            </Link>
            <Link to={`#`}>
              <p style={{ cursor: "pointer" }}>Image AI</p>
            </Link>
            <Link to={`#`}>
              <p style={{ cursor: "pointer" }}>Post AI</p>
            </Link>
            <Link to={`#`}>
              <p style={{ cursor: "pointer" }}>Text Video AI</p>
            </Link>
            <Link to={`#`}>
              <p style={{ cursor: "pointer" }}>News Video AI</p>
            </Link>
            <Link to={`#`}>
              <p style={{ cursor: "pointer" }}>Comic Video AI</p>
            </Link>
            <Link to={`#`}>
              <p style={{ cursor: "pointer" }}>
                AI Social Media Content Generator
              </p>
            </Link>
          </div>

          <div className="col-md-3 col-lg-2 col-12 footer-third-part">
            <h3
              style={{ marginBottom: "0px", width: "100%" }}
              className="text-nowrap"
            >
              Social Media
            </h3>
            <div
              style={{ maxWidth: "80px" }}
              className="title-bottom-border"
            ></div>

            <div
              style={{ gap: "10px" }}
              className="d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <p>
                {/* <i class="fa-brands fa-twitter"></i> */}{" "}
                <a target="_blank" href={`#`}>
                  <img src={social7} alt="image" />
                </a>
              </p>

              <p>
                {/* <i class="fa-brands fa-twitter"></i> */}{" "}
                <a target="_blank" href={`#`}>
                  <img src={social1} alt="image" />
                </a>
              </p>

              <p>
                {/* <i class="fa-brands fa-tiktok"></i> */}{" "}
                <a target="_blank" href={`#`}>
                  <img src={social2} alt="image" />
                </a>
              </p>

              <p>
                {/* <i class="fa-brands fa-linkedin"></i> */}{" "}
                <a target="_blank" href={`#`}>
                  <img src={social3} alt="image" />
                </a>
              </p>
              <p>
                {/* <i class="fa-brands fa-pinterest"></i> */}{" "}
                <a target="_blank" href={`#`}>
                  <img src={social4} alt="image" />
                </a>
              </p>
              <p>
                {/* <i class="fa-brands fa-medium"></i> */}{" "}
                <a target="_blank" href={`#`}>
                  <img src={social5} alt="image" />
                </a>
              </p>
            </div>

            <div className="quick-links-container mt-3">
              {" "}
              <Link to="#">
                <p style={{ cursor: "pointer" }}>About Us</p>
              </Link>
            </div>
          </div>
        </div>
        <div>
          <p
            className="copy-right-text"
            style={{
              marginBottom: "0px",
              fontSize: "14px",
              marginTop: "10px",
              paddingTop: "18px",
              borderTop: "1px solid white",
            }}
          >
            © Whalesai.com | All rights reserved 2024.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
