import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";

import profileUpdateBtn from "../../Assets/Images/profile-photo-update-icon.png";
import moneyExchangeIcon from "../../Assets/Images/transaction.png";
import "./Account.css";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import axios from "axios";
import { useTimer } from "react-timer-hook";
import EmailVerifyModal from "./EmailVerifyModal";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { useCallback } from "react";
import { AddCircleOutline, CloseRounded } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-hot-toast";
const Dashboard = () => {
  const { AIuser, userRefetch, setUserRefetch } = useContext(UserAuthContext);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [openEmailVerify, setOpenEmailVerify] = useState("");
  const [allUserAffiliateCode, setAllUserAffiliateCode] = useState([]);
  const [introducerInput, setIntroducerInput] = useState("");
  const [password, setPassword] = useState("");

  const [emailOtp, setEmailOtp] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [aiActivition, setAiActivition] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const isPaymentSuccess = location?.state?.success;
  const credits = location?.state?.credits;

  useEffect(() => {
    const paymentAlert = async () => {
      try {
        if (isPaymentSuccess == true) {
          swal({
            title: "Success!",
            text: `You have purchased ${credits} credits successfully!`,
            icon: "success",

            button: "OK",

            className: "modal_class_success",
          });
        }
      } catch (err) {
        console.log("Error from dashboard", err);
      }
    };
    paymentAlert();
  }, [isPaymentSuccess, credits]);

  useEffect(() => {
    setUserName(AIuser?.name);

    setUserEmail(AIuser?.email);
    setProfileImg(AIuser?.image);

    if (AIuser) {
      console.log(AIuser, "AI USER IN DASHBOARD");
    }
  }, [AIuser]);

  // Profile update fn
  const [previewProfile, setPreviewProfile] = useState("");
  const [uploadedImg, setUploadedImg] = useState("");

  const selectProfile = (event) => {
    if (event.target.files[0]) {
      setUploadedImg(event.target.files[0]);
      setPreviewProfile(URL.createObjectURL(event.target.files[0]));
    } else {
      setUploadedImg("");
      setPreviewProfile("");
    }
  };

  useEffect(() => {
    updateProfile();
  }, [uploadedImg]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //image update
  const updateProfile = async () => {
    const ImageFormData = new FormData();
    ImageFormData.append("image", uploadedImg);

    console.log(uploadedImg, "IMG TO UPLOAD");

    if (uploadedImg) {
      await axios
        .put(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/user/update",
          ImageFormData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("aiUser")}`,
            },
          }
        )
        .then((res) => {
          // console.log("update");
          if (res.status === 200) {
            swal({
              title: "Successful",
              text: "Profile updated successfully",
              icon: "success",
              button: "OK",
              className: "modal_class_success",
            });
            setUserRefetch(!userRefetch);
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const updateUserName = async () => {
    const fullNameFormData = new FormData();
    fullNameFormData.append("name", userName);

    if (userName) {
      await axios
        .put(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/user/update",
          fullNameFormData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("aiUser")}`,
            },
          }
        )
        .then((res) => {
          // console.log("update");
          if (res.status === 200) {
            swal({
              title: "Successful",
              text: "Name updated successfully",
              icon: "success",
              button: "OK",
              className: "modal_class_success",
            });
            setUserRefetch(!userRefetch);
          }
        });
    }
  };

  // Timer here
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);

  const { seconds, minutes, resume, restart } = useTimer({
    time,
    onExpire: () => console.warn("onExpire called"),
  });

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };
  // Timer ends

  const verifyEmail = async () => {
    if (userEmail.length > 0 && userEmail.includes("@" && ".")) {
      await axios
        .post(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/email",
          {
            email: userEmail,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            restarting(180);
            swal({
              text: res.data.message,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
            setEmailOtp(res.data.otp);
          }
          setOpenEmailVerify(true);
        })
        .catch((err) => {
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white fs-5'>${err.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Please enter a valid email address</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  const creditTolcalString = AIuser?.credits
    ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
    : "0";

  let content = (
    <>
      <button className="credits-button">CREDITS : {creditTolcalString}</button>
      <button
        className="buy-credits-button"
        onClick={() => navigate("/purchase")}
      >
        BUY CREDITS
      </button>
      <Tooltip placement="top" title="View your purchase history">
        <button
          // onClick={() => navigate("/transaction")}
          className="money-exchange-button"
          onClick={() => navigate("/purchase-history")}
        >
          <img src={moneyExchangeIcon} alt="image" />
        </button>
      </Tooltip>
    </>
  );

  const onCopy = useCallback(() => {
    // setCopied(true);

    // alert("Copied");
    Swal.fire({
      icon: "success",
      // title: 'Copied!',
      text: "Copied to your clipboard.",
      timer: 2000,
      showConfirmButton: false,
      background: "#011225",
      color: "white",
    });
  }, []);
  const [gotRefCode, setGotRefCode] = useState(false);

  useEffect(() => {
    const getAllUsers = async () => {
      const response = await axios.get(
        "https://backend.whalesai.com/api/dashboard/user_signup-signin/"
      );
      setAllUserAffiliateCode(response?.data?.result);
    };
    getAllUsers();
  }, []);

  const ownRefcode = () => {
    return swal({
      title: "Attention",
      text: "You can’t use your own code.",
      icon: "warning",
      button: "OK",
      dangerMode: true,
      className: "modal_class_success",
    });
  };

  const othersRefCodes = allUserAffiliateCode?.filter(
    (i) => i?.myReferralCode !== AIuser?.myReferralCode
  );

  const handleAffiliateCode = (e) => {
    const refCode = othersRefCodes.find(
      (i) => i?.myReferralCode === e.target.value
    );
    if (refCode?.myReferralCode === e.target.value) {
      setGotRefCode(true);
      setIntroducerInput(e.target.value.trim().toUpperCase());
    } else if (e.target.value === AIuser?.myReferralCode) {
      ownRefcode();
    } else {
      setGotRefCode(false);
    }
  };

  const handleAddIntroducerCode = async () => {
    if (introducerInput == "") {
      toast.error("Please enter your introducer code");
    } else {
      const introducerFormData = new FormData();
      introducerFormData.append("introducer", introducerInput.trim());
      await axios
        .put(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/user/update",
          introducerFormData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("aiUser")}`,
            },
          }
        )
        .then((res) => {
          // console.log("update");
          if (res.status === 200) {
            setUserRefetch(!userRefetch);
            toast.success("Your introducer code has been added successfully!");
          }
        });
    }
  };

  const onSubmitPassword = (e) => {
    if (password.trim() === "") {
      toast.error("Please enter password to change!");
    } else {
      e.preventDefault();
      axios
        .post(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/reset-password",
          { newPassword: password },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("aiUser")}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            swal({
              title: "Success",
              text: `${res.data.message}`,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
            setPassword("");
          }
        })
        .catch((err) => {
          swal({
            title: "Attention",
            text: `${err.response.data.message}`,
            icon: "warning",
            button: "OK!",
            className: "modal_class_success",
          });
        });
    }
  };


  const handleShareCopy = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(`Get 5% discount at whalesai.com when you use my code. https://whalesai.com/${AIuser?.myReferralCode}`).then(() => {
      Swal.fire({
        icon: "success",
        // title: 'Copied!',
        text: "Copied to your clipboard.",
        timer: 2000,
        showConfirmButton: false,
        background: "#011225",
        color: "white",
      });
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  }

  return (
    <div>
      <PageHeading title={"DASHBOARD"} />
      <div className="container account-container pb-5">
        <div className="d-flex justify-content-center">
          {/* <button
            className="mb-4"
            style={{
              background: "#FFA500",
              color: "white",
              padding: "11px 35px",
              border: 0,
              borderRadius: "5px",
              fontWeight: "600",
            }}
            onClick={() => navigate("/build-your-brand")}
          >
            BUILD YOUR BRAND
          </button> */}
        </div>

        <div className="d-flex d-md-none align-items-center justify-content-center pb-3">
          {content}
        </div>

        <div className="profile-header d-flex flex-column flex-lg-row align-items-center justify-content-between gap-2">
          <div className="d-flex align-items-center justify-content-center gap-2">
            <div className="avatar-portion d-flex align-items-center gap-2">
              <div className="avatar-container">
                <img
                  style={{ borderRadius: "8px" }} //60px
                  className="avatar"
                  src={
                    previewProfile
                      ? previewProfile
                      : profileImg || "https://backend.whalesai.com/public/ffmpeg/default.jpg"
                  }
                  alt="image"
                />

                <label className="profile-update-button" for="profile-img">
                  <img src={profileUpdateBtn} alt="image" />
                </label>
                <input
                  onChange={(e) => selectProfile(e)}
                  className="w-100 d-none"
                  type="file"
                  accept="image/*"
                  name="image"
                  id="profile-img"
                />
              </div>
            </div>
            <div className="d-none d-md-flex align-items-start">{content}</div>
            <div className="d-flex align-items-center">
              <Tooltip
                placement="top"
                title="Schedule Text, Image or Video for your social media for the next
                12 months."
              />
            </div>
          </div>

          <div className="my-4 d-flex align-items-center justify-content-center">
            <button
              className="consultant-button text-center text-white fs-6 py-2 px-4  rounded"
              style={{ background: "#bb2d3b", border: "0" }}
              onClick={() =>
                aiActivition
                  ? setAiActivition(true)
                  : navigate("/consultant-ai")
              }
            >
              {!aiActivition ? (
                <div
                  className={
                    "d-flex flex-column justify-content-center align-items-center"
                  }
                >
                  <span>Your Consultant AI is waiting for you.</span>
                  <span>Click here to discuss.</span>
                </div>
              ) : (
                `Our Consultant AI is now activated and ready to assist you.`
              )}
            </button>
          </div>

          {/* <div className="mb-4 d-flex align-items-center justify-content-center">
          <a
            href="https://whalesai.com/consultant-ai"
            className="consultant-button text-center text-white fs-6 py-2 px-4"
          >
            {` Your Consultant AI is waiting for you.\n Click here to discuss.`}
          </a>
        </div> */}
        </div>

        {AIuser?.introduce && (
          <div className="mt-3 d-flex align-items-center">
            <p
              className="mb-0  me-2"
              style={{
                fontSize: "18px",
                color: "#bbbaba",
              }}
            >
              Your Introducer Code is
            </p>
            <input
              value={
                AIuser?.introducer === "false"
                  ? AIuser?.introduce
                  : AIuser?.introducer
              }
              onChange={handleAffiliateCode}
              // disabled={AIuser?.introducer !== "false"}
              className="introducer-code-input"
              style={{
                backgroundColor: "#d9d9d9",
                textTransform: "uppercase",
                // borderRadius: "5px",
              }}
            />
            <p
              className="dashboard-add-icon mb-0"
              // disabled={true}
              style={{
                opacity: !gotRefCode || AIuser.introducer !== "false" ? 0.5 : 1,
                display: AIuser.introducer !== "false" ? "none" : "block",
                pointerEvents:
                  !gotRefCode || AIuser.introducer !== "false"
                    ? "none"
                    : "auto",
              }}
              onClick={handleAddIntroducerCode}
            >
              {!gotRefCode ? (
                <CloseRounded className="text-danger" style={{}} />
              ) : (
                <AddCircleOutline className="text-success" style={{}} />
              )}
            </p>
          </div>
        )}

        <div className="fields-container">
          <div className="input-container mb-4">
            <label>Full Name</label>
            <div className="position-relative">
              <input
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Full Name"
                className="w-100"
                type="text"
              />

              <button
                disabled={AIuser?.name === userName}
                onClick={updateUserName}
                className="verified-button width-control"
              >
                {/* {AIuser.name === userName ? "UPDATED" : "UPDATE"} */}
                <EditIcon />
              </button>
            </div>
          </div>
          <div className="input-container mb-4">
            <label>Email</label>
            <div className="position-relative">
              <input
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="abc@gmail.com"
                className="w-100"
                type="text"
              />

              <button
                disabled={AIuser?.email === userEmail}
                onClick={verifyEmail}
                className="verified-button width-control"
              >
                {AIuser?.email === userEmail ? "VERIFIED" : "VERIFY"}
                {/* <EditIcon/> */}
              </button>
              <button
                disabled={AIuser?.email === userEmail}
                onClick={verifyEmail}
                className="verified-button width-control"
              >
                {AIuser?.email === userEmail ? "VERIFIED" : "VERIFY"}
                {/* <EditIcon/> */}
              </button>
              {/* <button className="verified-button">VERIFIED</button> */}
            </div>
          </div>
          <div className="input-container mb-4">
            <label>Password</label>
            <div className="position-relative">
              <input
                required
                placeholder="*********"
                className="w-100"
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="submit"
                disabled={!password}
                className="verified-button"
                onClick={onSubmitPassword}
              >
                CHANGE PASSWORD
                {/* <EditIcon/> */}
              </button>
            </div>
          </div>
          <Link to="/earnings">
            <button className="your-earnings-button bg-success text-light mb-3">
              YOUR EARNINGS
            </button>
          </Link>
          <div className="input-container mb-4">
            <label>Your Affiliate Code / Member ID</label>
            <div className="position-relative">
              <input
                value={AIuser?.myReferralCode}
                disabled
                className="w-100"
                type="text"
              />
              <CopyToClipboard text={AIuser?.myReferralCode} onCopy={onCopy}>
                <button className="verified-button">COPY</button>
              </CopyToClipboard>
            </div>
          </div>
          <div className="input-container mb-4">
            <label>Your Affiliate Link</label>
            <div className="position-relative">
              <input
                value={`${window.origin}/${AIuser?.myReferralCode}`}
                disabled
                className="w-100"
                type="text"
              />
              <CopyToClipboard
                text={`${window.origin}/${AIuser?.myReferralCode}`}
                onCopy={onCopy}
              >
                <button className="verified-button">COPY</button>
              </CopyToClipboard>
            </div>
          </div>

          <div className="mb-2 text-light">
            <div>
              <label className="">Share Affiliate Link</label>
              <div className="d-flex gap-2 mt-1">
                <TwitterShareButton
                  url={window.location.origin + "/" + AIuser?.myReferralCode}
                  title={`Get 5% discount at whalesai.com when you use my code.`}
                >
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={window.location.origin + "/" + AIuser?.myReferralCode}
                  summary={`Get 5% discount at whalesai.com when you use my code.`}
                  title={`Get 5% discount at whalesai.com when you use my code.`}
                >
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
                <WhatsappShareButton
                  url={window.location.origin + "/" + AIuser?.myReferralCode}
                  title={`Get 5% discount at whalesai.com when you use my code.`}
                >
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
                <FacebookShareButton
                  url={window.location.origin + "/" + AIuser?.myReferralCode}
                  // title={`Get 5% discount at whalesai.com when you use my code.`}
                  quote="Get 5% discount at whalesai.com when you use my code."
                >
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
                {/* <PinterestShareButton
                media={window.location.origin + "/" + AIuser?.myReferralCode}
                  url={window.location.origin + "/" + AIuser?.myReferralCode}
                  title={`Get 5% discount at whalesai.com when you use my code.`}
                >
                  <PinterestIcon size={40} round={true} />
                </PinterestShareButton>
                <TelegramShareButton
                  url={window.location.origin + "/" + AIuser?.myReferralCode}
                  title={`Get 5% discount at whalesai.com when you use my code.`}
                >
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton> */}
              </div>
            </div>
          </div>
          <p className="text-start text-light">
            Share your affiliate code to earn 5% of our sales which comes from
            you. Your friend enjoy another 5% too.
          </p>

          <div className="d-flex justify-content-between align-items-center">
            <p className="text-start text-light mt-3">
              Get 5% discount at whalesai.com when you use my code.
              https://whalesai.com/{AIuser?.myReferralCode}
            </p>

            <button className="btn btn-sm py-1"
            style={{backgroundColor: '#3080AC', color: 'white'}}
            onClick={handleShareCopy}
            >COPY</button>
          </div>
        </div>
      </div>

      <EmailVerifyModal
        userEmail={userEmail}
        verifyEmail={verifyEmail}
        minutes={minutes}
        seconds={seconds}
        openEmailVerify={openEmailVerify}
        setOpenEmailVerify={setOpenEmailVerify}
        emailOtp={emailOtp}
      />
    </div>
  );
};

export default Dashboard;
