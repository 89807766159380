// import * as React from "react";
// import { useState } from "react";
// import { styled } from "@mui/material/styles";
// import Dialog from "@mui/material/Dialog";
// import { DialogTitle, Typography, Box, DialogContent, Button, TextField, MenuItem } from "@mui/material";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import axios from "axios";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

// export default function EditNewsModal({ modalOpen, setModalOpen, oneNews, setRefetch }) {
//   const [newsData, setNewsData] = useState({
//     title: oneNews?.title || "",
//     summary: oneNews?.summary || "",
//     category: oneNews?.category || "World",
//   });

//   const handleClose = () => {
//     setModalOpen(false);
//   };

//   const handleInputChange = (e) => {
//     setNewsData({ ...newsData, [e.target.name]: e.target.value });
//   };

//   const handleUpdate = async () => {
//     console.log('Data====>>', newsData);
//     // try {
//     //   const response = await axios.put(`YOUR_PUT_API_ENDPOINT/${oneNews?._id}`, newsData);
//     //   console.log("Update Response:", response.data);
//     //   if (response.status === 200) {
//     //     // Show success message
//     //     alert("News updated successfully!");
//     //     setRefetch((prev) => !prev); // Trigger refetch of the news data
//     //     handleClose(); // Close the modal after update
//     //   }
//     // } catch (error) {
//     //   console.error("Error while updating news:", error);
//     //   alert("Failed to update news. Please try again.");
//     // }
//   };

//   return (
//     <BootstrapDialog
//       onClose={handleClose}
//       scroll="body"
//       aria-labelledby="customized-dialog-title"
//       open={modalOpen}
//     >
//       <Box
//         sx={{
//           backgroundColor: "#011225",
//           color: "white",
//           width: {
//             lg: "500px",
//           },
//         }}
//       >
//         <DialogTitle
//           sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
//           id="customized-dialog-title"
//         >
//           <div className="d-flex align-items-center justify-content-between">
//             <div className="d-flex align-items-center gap-2 text-white">
//               <Typography variant="h6">Edit News</Typography>
//             </div>
//             <IconButton
//               aria-label="close"
//               onClick={handleClose}
//               sx={{
//                 position: "absolute",
//                 right: 8,
//                 color: "white",
//               }}
//             >
//               <CloseIcon />
//             </IconButton>
//           </div>
//         </DialogTitle>

//         <DialogContent>
//           <Box
//             sx={{
//               padding: 2,
//               backgroundColor: "#fff",
//               color: "#000",
//               borderRadius: "8px",
//               boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
//               textAlign: "center",
//             }}
//           >
//             {/* News Title Input */}
//             <TextField
//               label="News Title"
//               variant="outlined"
//               name="title"
//               value={oneNews?.title}
//               onChange={handleInputChange}
//               fullWidth
//               required
//               margin="normal"
//             />

//             {/* News Summary Textarea */}
//             <TextField
//               label="News Summary"
//               variant="outlined"
//               name="summary"
//               value={oneNews?.summary}
//               onChange={handleInputChange}
//               fullWidth
//               multiline
//               rows={6}
//               required
//               margin="normal"
//             />

//             {/* News Category Select */}
//             <TextField
//               label="News Category"
//               variant="outlined"
//               name="category"
//               value={oneNews?.category}
//               onChange={handleInputChange}
//               select
//               fullWidth
//               margin="normal"
//             >
//               <MenuItem value="World">World</MenuItem>
//               <MenuItem value="Business">Business</MenuItem>
//               <MenuItem value="Technology">Technology</MenuItem>
//               <MenuItem value="Sports">Sports</MenuItem>
//               <MenuItem value="Science">Science</MenuItem>
//               <MenuItem value="Lifestyle">Lifestyle</MenuItem>
//             </TextField>

//             {/* Buttons: Cancel & Update */}
//             <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
//               <Button variant="outlined" color="secondary" onClick={handleClose}>
//                 Cancel
//               </Button>
//               <Button variant="contained" color="primary" onClick={handleUpdate}>
//                 Update
//               </Button>
//             </Box>
//           </Box>
//         </DialogContent>
//       </Box>
//     </BootstrapDialog>
//   );
// }










import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent, Button, TextField, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import Swal from "sweetalert2";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function EditNewsModal({ modalOpen, setModalOpen, oneNews, setRefetch }) {
  const [newsData, setNewsData] = useState({
    title: '' ,
    summary: '' ,
    category: '',
  });
  const { allNews, getAllNewsData } = useContext(AdminAuthContext);

  useEffect(() => {
    if (oneNews) {
      setNewsData({
        title: oneNews.title ,
        summary: oneNews.summary,
        category: oneNews.category,
      });
    }
  }, [oneNews]);

  console.log('data before update===>>>',newsData);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleInputChange = (e) => {
    setNewsData({ ...newsData, [e.target.name]: e.target.value });
  };

  const handleUpdate = async () => {
    console.log('Data====>>', newsData);
    try {
      const response = await axios.put(`https://backend.whalesai.com/api/v1/admin/news/update/${oneNews?._id}`, newsData);
      console.log("Update Response:", response);
      if (response.status === 200) {
        // alert("News updated successfully!");
        Swal.fire({
            text: "News Updated.",
            icon: "success",
            customClass: "modal_class_success",
            allowOutsideClick: false,
          });
        getAllNewsData();
        setRefetch((prev) => !prev); // Trigger refetch of the news data
        handleClose(); // Close the modal after update
      }
    } catch (error) {
      console.error("Error while updating news:", error);
      alert("Failed to update news. Please try again.");
    }
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalOpen}
    >
      <Box
        sx={{
          backgroundColor: "#011225",
          color: "white",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Edit News</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            {/* News Title Input */}
            <TextField
              label="News Title"
              variant="outlined"
              name="title"
              defaultValue={oneNews?.title} 
              onChange={handleInputChange}
              fullWidth
              required
              margin="normal"
            />

            {/* News Summary Textarea */}
            <TextField
              label="News Summary"
              variant="outlined"
              name="summary"
            //   value={oneNews?.summary} 
              defaultValue={oneNews?.summary}
              onChange={handleInputChange}
              fullWidth
              multiline
              rows={6}
              required
              margin="normal"
            />

            {/* News Category Select */}
            <TextField
              label="News Category"
              variant="outlined"
              name="category"
              defaultValue={oneNews?.category}
            //   value={oneNews?.category} // Use newsData instead of oneNews
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
            >
              
              <MenuItem value="Business">Business</MenuItem>
              <MenuItem value="Science">Science</MenuItem>
              <MenuItem value="Singapore">Singapore</MenuItem>
              <MenuItem value="Technology">Technology</MenuItem>
              <MenuItem value="World">World</MenuItem>
              {/* <MenuItem value="Lifestyle">Lifestyle</MenuItem> */}
            </TextField>

            {/* Buttons: Cancel & Update */}
            <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
              <Button variant="outlined" color="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleUpdate}>
                Update
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
