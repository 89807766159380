import React from "react";
import ReactMarkdown from "react-markdown";

const ETOL = ({ inputText, isGenerating, text, wordRef }) => {
  return (
    <>
      <p class="preset-title">English To Tamil</p>
      {inputText ? (
        <>
          <>
            <p class="text-muted">{inputText}</p>

            {isGenerating == true ? (
              <p
                class="text- mb-0 mt-2"
                style={{
                  fontSize: "14px",
                  color: "black",
                }}
              >
                Processing...
              </p>
            ) : (
              <>
                {text && (
                  <p
                    style={{
                      color: "black",
                    }}
                    class="text- mb-0 text-"
                  >
                    {wordRef.current && <ReactMarkdown>{text}</ReactMarkdown>}
                  </p>
                )}
              </>
            )}
          </>
        </>
      ) : (
        <>
          <p class="text-muted" style={{fontSize:"14px" }}>What is your name?</p>

          <p
         style={{ color: "black",fontSize:"14px" }}
          >
            உங்கள் பெயர் என்ன?
          </p>
        </>
      )}
    </>
  );
};

export default ETOL;
