import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";

import axios from "axios";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { Pagination } from "@mui/material";
import "./PurchaseHistory.css";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";
import MocUp from "../../Components/PageHeading/MocUp";
import StepButton from "../../MocUp/StepButton/StepButton";
import { SpeechBubbleBottomCenter, SpeechBubbleTopCenter } from "../../MocUp/SpeechBubble/Bubble";

const HistoryMockUp = () => {
  const { AIuser } = useContext(UserAuthContext);
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("subscription"); // Initially set to 'subscription'

  useEffect(() => {
    const getTransaction = async () => {
      const response = await axios.get(
        `https://backend.whalesai.com/api/v1/payment/${AIuser?._id}`
      );
      setTransactionDetails(response?.data?.result?.reverse());
    };
    getTransaction();
  }, [AIuser?._id]);

  const location = useLocation();

  const isPaymentSuccess = location?.state?.success;
  const credits = location?.state?.credits;

  useEffect(() => {
    const paymentAlert = async () => {
      try {
        if (isPaymentSuccess == true) {
          swal({
            title: "Success!",
            text: `You have purchased ${credits} credits successfully!`,
            icon: "success",

            button: "OK",

            className: "modal_class_success",
          });
        }
      } catch (err) {
        console.log("Error from dashboard", err);
      }
    };
    paymentAlert();
  }, [isPaymentSuccess, credits]);

  const itemsPerPage = 6; // Number of items to display per page

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const paginatedData = transactionDetails?.slice(startIndex, endIndex);

  console.log("data pagination ===> ", paginatedData);

  const handlePageChange = (event, page) => {
    // scroll to half of the page
    window.scrollTo(0, window.innerHeight / 3);
    setCurrentPage(page);
  };

  const today = new Date();

  // Format today's date
  const todayFormatted = `${today?.getDate()?.toString()?.padStart(2, "0")}-${(today?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${today?.getFullYear()}`;

  const oneMonthLater = new Date(today);
  oneMonthLater?.setMonth(oneMonthLater?.getMonth() + 1);

  // Format one month later date
  const oneMonthLaterFormatted = `${oneMonthLater?.getDate()?.toString()?.padStart(2, "0")}-${(oneMonthLater?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${oneMonthLater?.getFullYear()}`;

  return (
    <div>
      {/* <PageHeading title={"PURCHASE HISTORY"} /> */}
      <MocUp
        title="PURCHASE HISTORY"
        description="Subscribe and Pay"
        step="Step 4:"
      />

      <div className="d-flex justify-content-center align-items-center mt-5 gap-4">
        <button
          onClick={() => {
            setActiveTab("credits");
          }}
          className={`px-4 py-2 btn ${
            activeTab === "credits" ? "btn-primary" : "btn-secondary"
          }`}
        >
          Credits
        </button>
        <button
          onClick={() => {
            setActiveTab("subscription");
          }}
          className={`px-4 py-2 btn ${
            activeTab === "subscription" ? "btn-primary" : "btn-secondary"
          }`}
        >
          Subscriptions
        </button>
      </div>

      {activeTab === "credits" ? (
        <div className="container account-container pb-5">
          <div className="table-container">
            <table style={{ minWidth: "600px" }} className="transaction-table">
              <thead>
                <tr>
                  <th className="text-start">DATE OF PURCHASE</th>
                  <th className="text-start">TRANSACTION ID</th>
                  <th className="text-start" style={{ minWidth: "100px" }}>
                    PRICE
                  </th>
                  <th className="text-start">CREDITS BOUGHT</th>
                  <th className="text-start">DATE OF EXPIRY</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="d-flex justify-content-center text-light mt-3">
            <Pagination
              shape="rounded"
              count={Math.ceil(transactionDetails?.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
        </div>
      ) : (
        <div className="container account-container pb-5">
          <div className="table-container">
            <table style={{ minWidth: "600px" }} className="transaction-table">
              <thead>
                <tr>
                  <th className="text-start">START DATE</th>
                  <th className="text-start">RENEW DATE</th>
                  <th className="text-start">TRANSACTION ID</th>
                  <th className="text-start" style={{ minWidth: "100px" }}>
                    PRICE
                  </th>
                  <th className="text-start">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ borderBottom: "1px solid gray" }}>
                  <td className="text-start">{todayFormatted}</td>
                  <td className="text-start">{oneMonthLaterFormatted}</td>
                  <td className="text-start maintain-max-width">
                    2za_******aX4y
                  </td>
                  <td className="text-start">USD 30.00</td>
                  <td className="text-start delete-icon position-relative">
                    <button
                      //   onClick={() => handleCancelSubscription(details)}
                      className="delete-icon btn btn-danger my-0 "
                    >
                      CANCEL SUBSCRIPTION
                    </button>
                    {/* <div className="position-absolute" style={{left: '25%', zIndex: '10', top: '-35px'}}>
                      <SpeechBubbleBottomCenter>Cancel Subscription</SpeechBubbleBottomCenter>
                    </div> */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center text-light mt-3">
            <Pagination
              shape="rounded"
              count={Math.ceil(transactionDetails?.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
            />
          </div>
          <div className="mt-5">
          <StepButton pre='/how-it-works-checking-of-contents-by-user' nex='/how-it-works-social-media-login' />
          </div>
        </div>
      )}
    </div>
  );
};

export default HistoryMockUp;
