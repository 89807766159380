// import React, { useContext, useEffect, useState } from "react";
// import PageHeading from "../../Components/PageHeading/PageHeading";
// import "./Analysis.css";
// import swal from "sweetalert";
// import { UserAuthContext } from "../../Contexts/UserAuthContext";
// import { useNavigate } from "react-router-dom";
// import Company from "../../Components/Company/Company";
// import MocUp from "../../Components/PageHeading/MocUp";

// const questions = [
//   {
//     question:
//       "What are your main goals for using social media for your business?",
//     options: [
//       "Increase brand awareness and visibility.",
//       "Generate leads and drive sales.",
//       "Engage with current customers and improve relationships.",
//       "Provide customer support and answer inquiries.",
//       "Establish thought leadership and industry expertise.",
//     ],
//   },
//   {
//     question:
//       "Which social media platform has been the most beneficial for your business so far?",
//     options: ["Facebook", "Instagram", "LinkedIn", "Twitter (X)", "TikTok"],
//   },
//   {
//     question:
//       "How often do you post content on your primary social media platform?",
//     options: [
//       "Several times a day.",
//       "Once a day.",
//       "A few times a week.",
//       "Once a week.",
//       "Rarely or when needed.",
//     ],
//   },
//   {
//     question: "What type of content tends to perform best for your audience?",
//     options: [
//       "Product promotions and offers.",
//       "Educational content and tips.",
//       "Behind-the-scenes and company culture posts.",
//       "Customer testimonials and reviews.",
//       "Engaging questions and polls.",
//     ],
//   },
//   {
//     question:
//       "What is your biggest challenge when it comes to social media marketing?",
//     options: [
//       "Consistent content creation.",
//       "Understanding analytics and performance metrics.",
//       "Engaging the right audience.",
//       "Keeping up with platform trends and algorithm changes.",
//       "Converting followers into paying customers.",
//     ],
//   },
//   {
//     question: "",
//     options: [
//       "Likes and shares",
//       "Comments and discussions",
//       "Click-throughs",
//       "Sign-ups",
//       "Direct messages",
//     ],
//   },
// ];

// const AnalysisMocUp = () => {
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [startAnalysis, setStartAnalysis] = useState(false);
//   const [isAnalysisComplete, setIsAnalysisComplete] = useState(false);
//   const [userAnswers, setUserAnswers] = useState([]);
//   const [result, setResult] = useState([]);
//   const [keyWords, setKeyWords] = useState([]);
//   const [isProcessing, setIsProcessing] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const [infoLength, setInfoLength] = useState(0);
//   const [error, setError] = useState("");
//   const [info, setInfo] = useState({});
//   const [isFetching, setIsFetching] = useState(false);
//   const { AIuser } = useContext(UserAuthContext);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setIsLoading(false);
//     }, 3000);
//     return () => clearTimeout(timer);
//   }, []);

//   useEffect(() => {
//     if (!isLoading && !isFetching && currentQuestion < questions.length) {
//       const autoSelectInterval = setInterval(() => {
//         const randomOptionIndex = Math.floor(
//           Math.random() * questions[currentQuestion].options.length
//         );
//         const randomOption = questions[currentQuestion].options[randomOptionIndex];
//         handleOptionSelect(randomOption);
  
//         setTimeout(() => {
//           if (currentQuestion < questions.length - 1) {
//             handleNext();
//           } else {
//             clearInterval(autoSelectInterval);
//           }
//         }, 1000); 
//       }, 2000); 
  
//       return () => clearInterval(autoSelectInterval);
//     }
//   }, [currentQuestion, isLoading]);
  

//   useEffect(() => {
//     const data = Object.values(info)?.filter((value) => value !== "").length;
//     setInfoLength(data);
//   }, [info]);

//   const handleOptionSelect = (option) => {
//     setSelectedOption(option);
//     const newUserAnswers = [...userAnswers];
//     newUserAnswers[currentQuestion] = option;
//     setUserAnswers(newUserAnswers);
//   };

//   const handleNext = () => {
//     if (currentQuestion < questions.length - 1) {
//       setCurrentQuestion(currentQuestion + 1);
//       setSelectedOption(null);
//     }
//   };

//   const handleBack = () => {
//     if (currentQuestion > 0) {
//       setCurrentQuestion(currentQuestion - 1);
//       setSelectedOption(null);
//       setUserAnswers((prevAnswers) => prevAnswers.slice(0, -1));
//     }
//   };

//   const handleCancel = () => {
//     navigate("/let-us-do-it-for-you");
//   };

//   return (
//     <div className="">
//       <MocUp title="Analysis" description="Analysis" step="Step 1" />
//       <div className="question-card mx-auto container">
//         <button
//           style={{ borderRadius: "8px" }}
//           className="px-4 py-2 mx-auto navigation-buttons step-button"
//         >
//           Step {currentQuestion + 1}
//         </button>
//         <h3 className="text-center">{questions[currentQuestion].question}</h3>
//         <div className="flex-container">
//           {currentQuestion === 5 ? (
//             <Company onInfoLengthChange={setInfoLength} className="flex-company" />
//           ) : (
//             questions[currentQuestion].options.map((option, index) => (
//               <button
//                 key={index}
//                 className={`btn option-button text-white py-2 ${
//                   selectedOption === option ? "selected" : ""
//                 }`}
//                 style={{
//                   backgroundColor:
//                     selectedOption === option
//                       ? "gray"
//                       : ["#007bff", "#28a745", "#ffc107", "#dc3545", "#17a2b8"][index],
//                 }}
//               >
//                 {String.fromCharCode(65 + index)} . {option}
//               </button>
//             ))
//           )}
//         </div>
//         <div className="navigation-buttons">
//           <button
//             className={`btn px-4 action-button ${
//               currentQuestion === 0 ? "btn-danger" : "btn-secondary"
//             }`}
//             onClick={currentQuestion === 0 ? handleCancel : handleBack}
//             disabled={currentQuestion === 0}
//           >
//             {currentQuestion === 0 ? "Cancel" : "Back"}
//           </button>
//           <button
//             className="btn px-4 action-button btn-info"
//           >
//             {isProcessing ? "Processing..." : currentQuestion === 5 ? "Start Analysis" : "Next"}
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AnalysisMocUp;






import React, { useContext, useEffect, useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import "./Analysis.css";
import swal from "sweetalert";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { useNavigate } from "react-router-dom";
import Company from "../../Components/Company/Company";
import MocUp from "../../Components/PageHeading/MocUp";
import MocUpCompany from "../../Components/Company/MocUpCompany";
import Result from "./Result";
import { SpeechBubbleBottomCenter, SpeechBubbleTopCenter, SpeechBubbleTopLeft } from "../SpeechBubble/Bubble";

const questions = [
  {
    question: "What are your main goals for using social media for your business?",
    options: [
      "Increase brand awareness and visibility.",
      "Generate leads and drive sales.",
      "Engage with current customers and improve relationships.",
      "Provide customer support and answer inquiries.",
      "Establish thought leadership and industry expertise.",
    ],
  },
  {
    question: "Which social media platform has been the most beneficial for your business so far?",
    options: ["Facebook", "Instagram", "LinkedIn", "Twitter (X)", "TikTok"],
  },
  {
    question: "How often do you post content on your primary social media platform?",
    options: [
      "Several times a day.",
      "Once a day.",
      "A few times a week.",
      "Once a week.",
      "Rarely or when needed.",
    ],
  },
  {
    question: "What type of content tends to perform best for your audience?",
    options: [
      "Product promotions and offers.",
      "Educational content and tips.",
      "Behind-the-scenes and company culture posts.",
      "Customer testimonials and reviews.",
      "Engaging questions and polls.",
    ],
  },
  {
    question: "What is your biggest challenge when it comes to social media marketing?",
    options: [
      "Consistent content creation.",
      "Understanding analytics and performance metrics.",
      "Engaging the right audience.",
      "Keeping up with platform trends and algorithm changes.",
      "Converting followers into paying customers.",
    ],
  },
  {
    question: "",
    options: [
      "Likes and shares",
      "Comments and discussions",
      "Click-throughs",
      "Sign-ups",
      "Direct messages",
    ],
  },
];

const bubbles = [
  'You can define your primary goals for social media marketing here.',
  'Identify the platform that’s brought the most value to your business so far!',
  'You can indicate how often you post content on your primary social media platform here.',
  'You can determine what type of content performs best for your audience here!',
  'You can share your biggest challenges in social media marketing here!',
  `You can submit all the information you've provided for processing.`
]

const AnalysisMocUp = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [startAnalysis, setStartAnalysis] = useState(false);
  const [isAnalysisComplete, setIsAnalysisComplete] = useState(false);
  const [userAnswers, setUserAnswers] = useState([]);
  const [result, setResult] = useState(false);
  const [keyWords, setKeyWords] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [infoLength, setInfoLength] = useState(0);
  const [error, setError] = useState("");
  const [info, setInfo] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const { AIuser } = useContext(UserAuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000);
    window.scroll(0,0);
    return () => clearTimeout(timer);
  }, []);

  // Auto-fill the form when reaching question 5
  useEffect(() => {
    if (currentQuestion === 5 && !isProcessing) {
      const companyInfo = {
        companyName: "Example Company",
        description: "This is an example company for testing.",
        website: "www.example.com",
        country: "Singapore",
      };
      setInfo(companyInfo);
  
      setTimeout(() => {
        // Trigger the "Start Analysis" button processing
        setIsProcessing(true);
        
        setTimeout(() => {
          
          setIsProcessing(false); 
          setResult(true); 
        }, 5000); 
      }, 2000); 
    }
  }, [currentQuestion, isProcessing]);

  
  // need to uncomment it
  useEffect(() => {
    if (!isLoading && !isFetching && currentQuestion < questions.length) {
      const autoSelectInterval = setInterval(() => {
        const randomOptionIndex = Math.floor(
          Math.random() * questions[currentQuestion].options.length
        );
        const randomOption = questions[currentQuestion].options[randomOptionIndex];
        handleOptionSelect(randomOption);
  
        setTimeout(() => {
          if (currentQuestion < questions.length - 1) {
            handleNext();
          } else {
            clearInterval(autoSelectInterval);
          }
        }, 1000); 
      }, 2000); 
  
      return () => clearInterval(autoSelectInterval);
    }
  }, [currentQuestion, isLoading]);

  useEffect(() => {
    const data = Object.values(info)?.filter((value) => value !== "").length;
    setInfoLength(data);
  }, [info]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestion] = option;
    setUserAnswers(newUserAnswers);
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedOption(null);
    }
  };

  const handleBack = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
      setSelectedOption(null);
      setUserAnswers((prevAnswers) => prevAnswers.slice(0, -1));
    }
  };

  const handleCancel = () => {
    navigate("/let-us-do-it-for-you");
  };

  return (
    <div className="">
      <MocUp title="Analysis" description="Analysis" step="Step 1:" />

      {
        !result ? <div style={{color: 'white'}} className="my-5 mx-auto container">
        <div>
          
          <SpeechBubbleBottomCenter>
            {bubbles[currentQuestion]}
            </SpeechBubbleBottomCenter>

          
        </div>
        <button
          style={{ borderRadius: "8px" }}
          className="px-4 py-2 mx-auto navigation-buttons step-button"
        >
          Step {currentQuestion + 1}
        </button>
        <h3 className="text-center">{questions[currentQuestion].question}</h3>
        <div className="flex-container container">
          {currentQuestion === 5 ? (
            <MocUpCompany info={info} onInfoLengthChange={setInfoLength} className="flex-company" />
          ) : (
            questions[currentQuestion].options.map((option, index) => (
              <button
                key={index}
                className={`btn option-button text-white button-text py-2 ${
                  selectedOption === option ? "selected" : ""
                }`}
                style={{
                  // fontSize: '12px',
                  backgroundColor:
                    selectedOption === option
                      ? "gray"
                      : ["#007bff", "#28a745", "#ffc107", "#dc3545", "#17a2b8"][index],
                }}
              >
                {String.fromCharCode(65 + index)} . {option}
              </button>
            ))
          )}
        </div>
        <div className="navigation-buttons">
          <button
          style={{height: '40px'}}
            className={`btn px-4 action-button ${
              currentQuestion === 0 ? "btn-danger" : "btn-secondary"
            }`}
            onClick={currentQuestion === 0 ? handleCancel : handleBack}
            disabled={currentQuestion === 0}
          >
            {currentQuestion === 0 ? "Cancel" : "Back"}
          </button>
          <div>
          <button
            style={{height: '40px'}}
            className="btn px-4 action-button btn-info"
            disabled={currentQuestion === 5 && isProcessing}
          >
            {isProcessing ? "Processing..." : currentQuestion === 5 ? "Start Analysis" : "Next"}
          </button>
          <div className="mt-3">
          {
            currentQuestion == 5 && <SpeechBubbleTopLeft>You can view your analysis results here!</SpeechBubbleTopLeft>
          }
          </div>
          </div>
        </div>
      </div> : 
      <div>
        
        <Result/>
      </div>
      }
      
    </div>
  );
};

export default AnalysisMocUp;
