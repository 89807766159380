// import React, { useContext } from 'react';
// import './packages.css';
// import { UserAuthContext } from '../../Contexts/UserAuthContext';
// import { useLocation, useNavigate } from 'react-router-dom';

// const Packages = () => {
//   const { AIuser, setCreditsData } = useContext(UserAuthContext);
//   const { pathname } = useLocation();
//   console.log('pathname.....', pathname);

//   const navigate = useNavigate();
//   const location = useLocation();

//   const handleNavigate = (data, packageNo) => {
//     if (!AIuser?.email) {
//       navigate(
//         '/sign-in',
//         {
//           state: { from: '/payment', data: { ...data, packageNo } },
//         },
//         { replace: true }
//       );
//     } else {
//       navigate(`/credits-package/${packageNo}`, {
//         state: { data: { ...data, packageNo } },
//         replace: true,
//       });
//       // set the packageNo in setCreditsData() along with the data
//       setCreditsData({ ...data, packageNo });
//     }
//   };
//   console.log('🚀 ~ file: Packages.js:71 ~ Packages ~ packages:', packages);

//   return (
//     <div className="row g-4 py-md-5 py-3">
//       {packages.map((item, index) => (
//         <div className="col-md-4 col-lg-4 col-12">
//           <div style={{ borderRadius: '10px' }} className="bg-white">
//             <div
//               className="package-price text-center"
//               style={{ background: `${item.background}` }}
//             >
//               <p>CREDITS PACKAGE {index + 1}</p>
//               <h2>USD {item.price}</h2>
//               <p
//                 style={{
//                   fontSize: 'small',
//                   color: 'yellow',
//                   fontWeight: '500',
//                 }}
//               >
//                 Pay USD {item.main}
//               </p>
//             </div>
//             <div>
//               <div className="each-row py-4">
//                 <p className="row-header mb-2">CREDITS</p>
//                 <p className="row-value mb-0">{item.credits} CREDITS</p>
//               </div>
//               <div className="each-row py-4">
//                 <p className="row-header mb-2">EXPIRY</p>
//                 <p className="row-value mb-0">EXPIRY IN {item.expire} MONTH</p>
//               </div>
//               <div className="each-row py-4 border-0">
//                 <p className="row-header mb-2">DISCOUNT</p>
//                 <p className="row-value mb-0 text-danger">
//                   {' '}
//                   {item.discount} DISCOUNT
//                 </p>
//               </div>
//               <div className="pay-button-container">
//                 <button
//                   className="pay-button"
//                   onClick={() => handleNavigate(item, index + 1)}
//                 >
//                   PAY USD {item.paylimit}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Packages;

// const packages = [
//   {
//     price: '5',
//     credits: '250',
//     main: '5',
//     expire: '1',
//     discount: 'NO',
//     paylimit: '5',
//     background: '#05C0A2',
//   },
//   {
//     price: '100',
//     main: '80',
//     credits: '5000',
//     expire: '3',
//     discount: '20%',
//     paylimit: '80',
//     background: '#1D60EF',
//   },
//   {
//     price: '1000',
//     main: '600',
//     credits: '50000',
//     expire: '12',
//     discount: '40%',
//     paylimit: '600',
//     background: '#FA6918',
//   },
// ];




import React, { useContext } from 'react';
import './packages.css';
import { UserAuthContext } from '../../Contexts/UserAuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

const Packages = () => {
  const { AIuser, setCreditsData } = useContext(UserAuthContext);
  const { pathname } = useLocation();
  console.log('pathname.....', pathname);

  const navigate = useNavigate();

  const handleNavigate = (data, packageNo) => {
    let targetPath;
    if (pathname === '/pricing') {
      targetPath = '/purchase';
    } else if (pathname === '/purchase') {
      targetPath = `/credits-package/${packageNo}`;
    } else {
      targetPath = `/credits-package/${packageNo}`;
    }

    if (!AIuser?.email) {
      navigate('/sign-in', {
        state: { from: targetPath, data: { ...data, packageNo } },
        replace: true,
      });
    } else {
      navigate(targetPath, {
        state: { data: { ...data, packageNo } },
        replace: true,
      });
      // set the packageNo in setCreditsData() along with the data
      setCreditsData({ ...data, packageNo });
    }
  };

  

  console.log('🚀 ~ file: Packages.js:71 ~ Packages ~ packages:', packages);

  return (
    <div className="row g-4 py-md-5 py-3">
      {packages.map((item, index) => (
        <div key={index} className="col-md-4 col-lg-4 col-12">
          <div style={{ borderRadius: '10px' }} className="bg-white">
            <div
              className="package-price text-center"
              style={{ background: `${item.background}` }}
            >
              <p>CREDITS PACKAGE {index + 1}</p>
              <h2>USD {item.price}</h2>
              <p
                style={{
                  fontSize: 'small',
                  color: 'yellow',
                  fontWeight: '500',
                }}
              >
                Pay USD {item.main}
              </p>
            </div>
            <div>
              <div className="each-row py-4">
                <p className="row-header mb-2">CREDITS</p>
                <p className="row-value mb-0">{item.credits} CREDITS</p>
              </div>
              <div className="each-row py-4">
                <p className="row-header mb-2">EXPIRY</p>
                <p className="row-value mb-0">EXPIRY IN {item.expire} MONTH</p>
              </div>
              <div className="each-row py-4 border-0">
                <p className="row-header mb-2">DISCOUNT</p>
                <p className="row-value mb-0 text-danger">
                  {item.discount} DISCOUNT
                </p>
              </div>
              <div className="pay-button-container">
                <button
                  className="pay-button"
                  onClick={() => handleNavigate(item, index + 1)}
                >
                  PAY USD {item.paylimit}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Packages;

const packages = [
  {
    price: '5',
    credits: '250',
    main: '5',
    expire: '1',
    discount: 'NO',
    paylimit: '5',
    background: '#05C0A2',
  },
  {
    price: '100',
    main: '80',
    credits: '5000',
    expire: '3',
    discount: '20%',
    paylimit: '80',
    background: '#1D60EF',
  },
  {
    price: '1000',
    main: '600',
    credits: '50000',
    expire: '12',
    discount: '40%',
    paylimit: '600',
    background: '#FA6918',
  },
];
