import React, { useContext, useState } from "react";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import Spineer from "../../Components/CustomSpineer/Spineer";
import { Delete } from "@mui/icons-material";
import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import usePagination from "../../hooks/UsePagination";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import MessageBox from "./MesageBox";
import Swal from "sweetalert2";
import SearchBar from "../AdminUser/SearchBar";

const AdminContact = () => {
  const { allMessage, getAllMessage } = useContext(AdminAuthContext);
  const [page, setPage] = useState(1);
  const [oneMessage, setOneMessage] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const PER_PAGE = 10;
  const filteredMSG = allMessage?.filter(
    (item) =>
      item.message?.toLowerCase().includes(searchQuery) ||
      item.email?.toLowerCase().includes(searchQuery)
  );
  let count = Math.ceil(filteredMSG?.length / PER_PAGE);
  let perPageData = usePagination(filteredMSG, PER_PAGE);
  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };
  const handleOpen = (message) => {
    setOneMessage(message);
    setModalOpen(true);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this message?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://backend.whalesai.com/api/v1/contact-us/delete/${id}`, {
          method: "DELETE",
        })
          .then((response) => {
            console.log("delete response ====> ", response);
            if (response.ok) {
              getAllMessage();
              Swal.fire({
                html: `<p class='text-break text-white'>Message Deleted.</p>`,
                icon: "success",
                customClass: "modal_class_success",
                allowOutsideClick: false,
              });
            } else {
              getAllMessage();
              Swal.fire({
                text: "Failed to delete the message.",
                icon: "error",
                background: "#011225",
                allowOutsideClick: false,
              });
            }
          })
          .catch((error) => {
            console.log("error from catch", error);
            getAllMessage();
            Swal.fire({
              text: "An error occurred while deleting the message.",
              icon: "error",
              background: "#011225",
              allowOutsideClick: false,
            });
          });
      }
    });
  };

  console.log("all message=====>>>>>>", allMessage);
  return (
    <div className="container-fluid text-white" style={{ color: "white" }}>
      <MessageBox
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        oneMessage={oneMessage}
      />
      <h5 className="text-start mb-3 font-weight-bold">ENQUIRIES</h5>
      <div className="mb-3">
        <SearchBar handleSearch={handleSearch} />
      </div>

      <section>
        <div
          className="pt-5 rounded my-2 mb-5 pb-3 table-width-class"
          style={{ backgroundColor: "#3080AC" }}
        >
          <div className="my-3"></div>
          <div className="table-responsive overflow-auto ">
            <table
              className="table table-hover"
              style={{ backgroundColor: "#3080AC" }}
            >
              <thead className="bg-[#1a1c339a] table-text-size">
                <tr>
                  <th scope="col">S/no</th>
                  <th scope="col">Name</th>
                  <th scope="col" className="">
                    Email
                  </th>

                  <th scope="col">
                    Date & Time{" "}
                    <span style={{ fontSize: "10px" }}>(SG Time)</span>
                  </th>
                  <th scope="col">Subject</th>
                  <th scope="col" className="">
                    Message
                  </th>

                  <th scope="col" className="">
                    Status
                  </th>

                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>

              {/* <tbody
                className="table-text"
                style={{ backgroundColor: "#3080AC" }}
              >
                {allMessage ? (
                  perPageData?.currentData()?.map((content, index) => (
                    <tr key={content._id}>
                      <td className="py-4 px-4">
                        {(page - 1) * 10 + index + 1}
                      </td>
                      <td className="py-4">{content?.name}</td>

                      <td className="py-4 ">{content.email}</td>
                      <td className="py-3">
                        {new Date(content.createdAt).toLocaleDateString()}{" "}
                        <br />{" "}
                        {new Date(content.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}
                      </td>
                      <td className="py-4 text-left">
                        {content.subject?.length > 10
                          ? `${content.subject.substring(0, 10)}....`
                          : content.subject}
                      </td>
                      <td className="py-4 text-left">
                        {content.message?.length > 20
                          ? `${content.message.substring(0, 20)}....`
                          : content.message}
                      </td>

                      <td className="py-4 text-left">
                        {content?.reply?.length > 0 ? 'Replied' : 'Not Replied'}
                      </td>

                      <td className="text-center">
                        <div className="btn-group gap-2" role="group">
                          <Link
                            onClick={() => {
                              handleOpen(content);
                            }}
                            className="btn rounded-circle btn-sm icon-body btn-success"
                          >
                            <RemoveRedEyeIcon className="action-icon" />
                          </Link>
                          <button
                            className="btn rounded-circle btn-sm icon-body btn-danger"
                            onClick={() => handleDelete(content?._id)}
                          >
                            <Delete className="action-icon" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      className="text-center"
                      colSpan={window.innerWidth <= 768 ? "6" : "10"}
                    >
                      <Spineer />
                    </td>
                  </tr>
                )}
              </tbody> */}
              <tbody
                className="table-text"
                style={{ backgroundColor: "#3080AC" }}
              >
                {allMessage && allMessage.length > 0 ? (
                  perPageData?.currentData()?.map((content, index) => (
                    <tr key={content._id}>
                      <td className="py-4 px-4">
                        {(page - 1) * 10 + index + 1}
                      </td>
                      <td className="py-4">{content?.name}</td>
                      <td className="py-4 ">{content.email}</td>
                      <td className="py-3">
                        {/* {new Date(content.createdAt).toLocaleDateString()}{" "}
                        <br />
                        {new Date(content.createdAt).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: false,
                        })} */}
                        {
                          content?.createdAt?.slice(0,10)
                        }
                        <br/>
                        {
                          content?.createdAt?.slice(11,19)
                        }
                      </td>
                      <td className="py-4 text-left">
                        {content.subject?.length > 10
                          ? `${content.subject.substring(0, 10)}....`
                          : content.subject}
                      </td>
                      <td className="py-4 text-left">
                        {content.message?.length > 20
                          ? `${content.message.substring(0, 20)}....`
                          : content.message}
                      </td>
                      <td className="py-4 text-left">
                        {content?.reply?.length > 0 ? "Replied" : "Not Replied"}
                      </td>
                      <td className="text-center">
                        <div className="btn-group gap-2" role="group">
                          <Link
                            onClick={() => {
                              handleOpen(content);
                            }}
                            className="btn rounded-circle btn-sm icon-body btn-success"
                          >
                            <RemoveRedEyeIcon className="action-icon" />
                          </Link>
                          <button
                            className="btn rounded-circle btn-sm icon-body btn-danger"
                            onClick={() => handleDelete(content?._id)}
                          >
                            <Delete className="action-icon" />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="8">
                      No enquiry found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <CustomPagination
            page={page}
            count={count}
            onChange={handlePageChange}
          />
        </div>
      </section>
    </div>
  );
};

export default AdminContact;
