import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import { DialogTitle, Typography, Box, DialogContent, Avatar } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function SpecificTester({ setModalOpen, modalOpen, oneUser }) {
  const handleClose = () => setModalOpen(false);

  console.log('Specific tester info === >>>>>', oneUser);

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={modalOpen}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6">Tester Details</Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          {/* Tester Information Section */}
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            {/* Tester Avatar */}
            <Avatar
              alt={oneUser?.name}
              src={oneUser?.image}
              sx={{ width: 120, height: 120, margin: "0 auto 16px" }}
            />

            {/* Tester Details */}
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              {oneUser?.name}
            </Typography>
            
            <Typography variant="body2" sx={{ color: "red", mb: 1 }}>
              <strong>Balance:</strong> {oneUser?.credits} credits
            </Typography>
            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>Email:</strong> {oneUser?.email}
            </Typography>

            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>Member ID:</strong> {oneUser?.memeberId}
            </Typography>

            <Typography variant="body2" sx={{ color: "#555", mb: 1 }}>
              <strong>Referral Code:</strong> {oneUser?.myReferralCode}
            </Typography>
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
