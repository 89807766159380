import { Button } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaEnvelope, FaEye, FaEyeSlash, FaLock } from "react-icons/fa";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { AiOutlineUser } from "react-icons/ai";
import { useTimer } from "react-timer-hook";
import { RiCouponLine } from "react-icons/ri";
import EmailVerificationModal from "./EmailVerificationModal";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import image from './signup.jpg'

const SingUp = () => {
  const { AIuser, token, setToken } = useContext(UserAuthContext);

  const navigate = useNavigate();
  const { myReferralCode } = useParams();

  const [email, setEmail] = useState("");
  const [EmailOtpVerify, setEmailOtpVerify] = useState();
  const [openEmail, setOpenEmail] = useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [country, setCountry] = useState(""); 

  useEffect(() => {
    window.scrollTo(0, 0);

    axios
    .get("https://api.ipify.org/?format=json")
    .then((response) => {
      const ipAddress = response.data.ip;
      axios
        .get(`https://ipapi.co/${ipAddress}/json/`)
        .then((response) => {
          setCountry(response.data.country_name);
          console.log('dataaaaaaaaaaaaaaaaaaaaaaa ========> :::', response.data.country_name)
        })
        .catch((error) => {
          console.error("Error fetching user country:", error);
          setCountry("Unknown");
        });
    })
    .catch((error) => {
      console.error("Error fetching user IP address:", error);
      setCountry("Unknown");
    });
   
  }, []);

  useEffect(() => {
    if (AIuser?._id) {
      Swal.fire({
        text: `Welcome to Whalesai.com—experience the future of social media marketing with the world's first AI-based manager, revolutionizing your digital outreach!`,
        icon: "success",
        customClass: "modal_class_success",
      });
      navigate("/dashboard", { replace: true });
    }
  }, [AIuser, token]);

  // Timer here
  const time = new Date();
  time.setSeconds(time.getSeconds() + 180);

  const { seconds, minutes, resume, restart } = useTimer({
    time,
    onExpire: () => console.warn("onExpire called"),
  });

  const restarting = (sec) => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + sec);
    restart(time);
  };
  // Timer ends

  useEffect(() => {
    (async () => {
      await axios
        .get("https://backend.whalesai.com/api/dashboard/user_signup-signin/")
        .then((res) => setAllUsers(res.data.result));
    })();
  }, []);

  const isExistEmail = allUsers?.find((item) => item.email == email);

  const handleVerifyEmail = async () => {
    setIsSendingEmail(true);
    if (isExistEmail?.email == email) {
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Email is already existing!</p>`;
      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
      // setIsSendingEmail(false);
    } else if (email.length > 0 && email.includes("@" && ".")) {
      await axios
        .post(
          "https://backend.whalesai.com/api/dashboard/user_signup-signin/email",
          {
            email,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            restarting(180);
            swal({
              text: res.data.message,
              icon: "success",
              button: "OK!",
              className: "modal_class_success",
            });
            setEmailOtpVerify(res.data.otp);
          }
          setOpenEmail(true);
          setIsSendingEmail(false);
        })
        .catch((err) => {
          setIsSendingEmail(false);

          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<p class='text-break text-white fs-5'>${err.response.data.message}</p>`;

          Swal.fire({
            html: wrapper,
            icon: "warning",
            customClass: "modal_class_success",
          });
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
      setIsSendingEmail(false);
      const wrapper = document.createElement("div");
      wrapper.innerHTML = `<p class='text-break text-white fs-5'>Please enter a valid email address</p>`;

      Swal.fire({
        html: wrapper,
        icon: "warning",
        customClass: "modal_class_success",
      });
    }
  };

  const addContactToBrevo = async (email, name, country) => {
    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        'api-key': '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',
      },
      body: JSON.stringify({
        email: email,
        // ext_id: extId,
        attributes: {
          Name: name,
          COUNTRIES: country
        },
        emailBlacklisted: false,
        smsBlacklisted: false,
        listIds: [2],
        updateEnabled: false,
        // smtpBlacklistSender: smtpBlacklistSender
      })
    };
  
    try {
      const response = await fetch('https://api.brevo.com/v3/contacts', options);
      const data = await response.json();
      console.log('Contact added successfully:', data);
    } catch (error) {
      console.error('Error adding contact:', error);
    }
  };
  

  const SingUp = async (e) => {
    e.preventDefault();

    if (!emailVerify) {
      swal({
        text: "Please verify your email first.",
        icon: "warning",
        button: "OK!",
        className: "modal_class_success",
      });
      return;
    }

    const name = e.target.name.value;
    const password = e.target.password.value;
      
    const introducer = gotRefCode && e.target.introducer.value;
    

    const data = {
      name: name,
      email: email,
      password: password,
      otp: EmailOtpVerify,
      introducer: introducer,
      country: country
    };

    await axios
      .post(
        "https://backend.whalesai.com/api/dashboard/user_signup-signin/signup",
        data
      )
      .then((res) => {
        
        if (res.status === 200) {
          setToken(res.data.token);
          localStorage.setItem("aiUser", res.data.token);
          addContactToBrevo(email, name, country);
        }
      })
      .catch((error) => {
        console.log(error, "SIGN UP ERROR");
        swal({
          title: "Attention",
          text: `${error.response?.data?.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };

  const [allUser, setAllUser] = useState([]);
  const [allUserWallet, setAffiliateWalletAddress] = useState("");
  const [gotRefCode, setGotRefCode] = useState(false);

  useEffect(() => {
    axios.get('https://backend.whalesai.com/api/dashboard/user_signup-signin/', {
    })
      .then(res => {
        setAllUser(res.data.result);
      })
  }, [])

  const ownRefcode = () => {

    return swal({
      title: "Attention",
      text: "You can’t use your own code.",
      icon: "warning",
      button: "OK",
      dangerMode: true,
      className: "modal_class_success",
    });

  }

  const othersRefCodes = allUser?.filter(i => i?.myReferralCode !== AIuser?.myReferralCode);

  const handleAffiliateCode = (e) => {
    const refCode = othersRefCodes.find(i => i?.myReferralCode === e.target.value);
    if (refCode?.myReferralCode === e.target.value) {
      setGotRefCode(true);
    } else if (e.target.value === AIuser?.myReferralCode) {
      ownRefcode();
    }
    else {
      setGotRefCode(false);
    }
  }

  return (
    <div>
      {/* helooooo...... */}
      <div className="d-flex align-items-center justify-content-center signupDiv">
      <img src={image} alt="signup" className="signupImg"/>

      </div>

      <div className="handleTheLoginBodysignup ">
     <div className="container mx-auto">
       {/* <h2 className="text-center text-warning mb-3">Super Box</h2> */}
       <div className=" forCard w-md-50 p-5 rounded mx-auto ">
         <p className="text-center fs-5">Sign Up</p>
         <div className="mt-4 pt-2">
           <form onSubmit={SingUp}>
             <InputGroup>
               <InputGroup.Text
                 id="basic-addon1"
                 className="border-0 text-white"
                 style={{
                   backgroundColor: (gotRefCode || myReferralCode) ? "black" : "red",
                  
                 }}
               >
                 {(gotRefCode || myReferralCode) ? <RiCouponLine /> : "X" }
               </InputGroup.Text>
               <Form.Control
                 // style={{ textTransform: "none" }}
                 className="inputBackground"
                 placeholder="Affiliate Code"
                 aria-label="Affiliate"
                 type="text"
                 defaultValue={myReferralCode? myReferralCode : ""}
                 onChange={handleAffiliateCode}
                 aria-describedby="basic-addon1"
                 name="introducer"
                 // onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
               />
             </InputGroup>
           
             
             <Form.Label
               className="text-success mb-0 ms-5"
               htmlFor="basic-text"
               style={{ fontSize: "11px" }}
             >
             Please enter the affiliate code if you have one.
             </Form.Label>
             <InputGroup className="mt-2">
               <InputGroup.Text
                 id="basic-addon1"
                 className="bg-dark border-0 text-white"
               >
                 <AiOutlineUser />
               </InputGroup.Text>
               <Form.Control
                 style={{ textTransform: "none" }}
                 className="inputBackground"
                 placeholder="Name"
                 aria-label="Name"
                 type="text"
                 required
                 aria-describedby="basic-addon1"
                 name="name"
                 // onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
               />
             </InputGroup>
             <InputGroup className="mt-4">
               <InputGroup.Text
                 id="basic-addon1"
                 className="bg-dark border-0 text-white"
               >
                 <FaEnvelope />
               </InputGroup.Text>
               <Form.Control
                 style={{ textTransform: "lowercase" }}
                 className="inputBackground"
                 placeholder="Email"
                 aria-label="Username"
                 type="email"
                 required
                 aria-describedby="basic-addon1"
                 name="email"
                 onChange={(e) => {
                   // setEmailVerify(false);
                   setEmail(e.target.value);
                 }}
               />

               {!emailVerify && email.length > 0 && (
                 <button
                   onClick={() => handleVerifyEmail()}
                   className="border-0 p-2 px-3 d-flex justify-content-center bg-danger text-white verify-email-sender-button"
                   type="button"
                   disabled={isSendingEmail && true}
                   style={{
                     borderRadius: "0px 5px 5px 0px",
                   }}
                 >
                   Verify
                 </button>
               )}

               {emailVerify && (
                 <button
                   disabled
                   className="border-0 p-2 px-3 d-flex justify-content-center text-white"
                   style={{
                     backgroundColor: "green",
                     borderRadius: "0px 5px 5px 0px",
                   }}
                 >
                   Verified
                 </button>
               )}
             </InputGroup>
             <br />
             {/* <Form.Text className="text-muted">
                               We'll never share your email with anyone else.
                           </Form.Text> */}
             <InputGroup className="mb-3"
             onClick={() => {
              if (!emailVerify) {
                // alert("Please verify your email before setting a password.");
                swal({
                  text: "Please verify your email before setting a password.", 
                  className: "modal_class_success",
                });
              }
            }}
             >
               <InputGroup.Text className="bg-dark border-0 text-white">
                 <FaLock />
               </InputGroup.Text>
               <Form.Control
                 aria-label="Amount (to the nearest dollar)"
                 className="inputBackground"
                 disabled={emailVerify === false}
                 placeholder="password"
                 type={visiblePassword ? "text" : "password"}
                 required
                 name="password"
               />
               <InputGroup.Text
                 className="bg-dark text-center border-0 cursor-pointer text-white"
                 role="button"
                 type="button"
                 onClick={() => setVisiblePassword(!visiblePassword)}
               >
                 {visiblePassword ? <FaEye /> : <FaEyeSlash />}
               </InputGroup.Text>
             </InputGroup>

             <Form.Group className="mb-3 d-flex" controlId="formBasicCheckbox">
               <p>Already have an account?</p>
               <Link className=" forgetPass ms-1" to="/sign-in">
                 Sign In
               </Link>
               {/* <Link className=" forgetPass ms-1" to="/clone/sign-in">
                 Sign In
               </Link> */}
             </Form.Group>
             <div className="mx-auto text-center">
               <Button
                 style={{ backgroundColor: "#f74545" }}
                 className="button-34 ps-5 pe-5 pt-2 pb-2"
                 type="submit"
               >
                 Submit
               </Button>
             </div>
           </form>
         </div>
       </div>
     </div>

     <EmailVerificationModal
       // handleEmailSubmit={handleEmailSubmit}
       handleVerifyEmail={handleVerifyEmail}
       minutes={minutes}
       seconds={seconds}
       open={openEmail}
       setOpenEmail={setOpenEmail}
       otpVerify={EmailOtpVerify}
       // setForEnable={setEmailForEnable}
       // setError={setEmailError}
       setEmailVerify={setEmailVerify}
     />
   </div>
    </div>
  );
};

export default SingUp;
