import React, { useEffect, useState } from "react";
import MetaData from "../../Components/MetaData/MetaData";
import Method1 from "./methods/Method1";
import Method2 from "./methods/Method2";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import Banner from "./Banner";
import Footer from "./Footer/Footer";

const LandingPage = () => {
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [showScrollDown, setShowScrollDown] = useState(true);

  const checkScrollPosition = () => {
    if (!showScrollUp && window.pageYOffset > 400) {
      setShowScrollUp(true);
    } else if (showScrollUp && window.pageYOffset <= 400) {
      setShowScrollUp(false);
    }

    if (
      !showScrollDown &&
      window.innerHeight + window.pageYOffset < document.body.offsetHeight - 400
    ) {
      setShowScrollDown(true);
    } else if (
      showScrollDown &&
      window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight - 400
    ) {
      setShowScrollDown(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const scrollBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, [showScrollUp, showScrollDown]);

  return (
    <>
      {/* <Navbar /> */}
      <MetaData
        pageTitle={
          "Smart Social Media Management AI Platform | AI Post Generator"
        }
        pageDescription={
          "Upgrade your social media presence with our top-rated social media management AI platform that delivers the best AI-generated posts. Sign up now!"
        }
        canonical="https://whalesai.com/"
      />
      <Banner />
      <Method1 />

      {showScrollUp ? (
        <FaArrowAltCircleUp
          onClick={scrollTop}
          className="position-fixed"
          style={{
            bottom: "20px",
            right: "20px",
            padding: "10px",
            backgroundColor: "#fd7e14",
            color: "white",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1000,
            fontSize: "3rem",
          }}
        />
      ) : (
        <FaArrowAltCircleDown
          onClick={scrollBottom}
          className="position-fixed"
          style={{
            bottom: "20px",
            right: "20px",
            padding: "10px",
            backgroundColor: "#6c757d",
            color: "white",
            borderRadius: "50%",
            cursor: "pointer",
            zIndex: 1000,
            fontSize: "3rem",
          }}
        />
      )}
      <Method2 />
      <Footer />
    </>
  );
};

export default LandingPage;
