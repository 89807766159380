import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { BiRefresh } from "react-icons/bi";
import wordCount from "word-count";
import Box from '@mui/material/Box';
import { createClient } from "pexels";

import { Button as AntButton, Badge } from "antd";

const VideoEditModal = ({
  editVideo,
  setEditVideo,
  setInput,
  input,
  videoFormat,
  pages,
  setPages,
  allImage,
  tabValue,
  setHasEdits,
}) => {
  const [bgImage, setBgImage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [selectImgMdlOpen, setSelectImgMdlOpen] = useState(false)

  const [selectedImg, setSelectedImg] = useState("")
  const [imgsLoading, setImgsLoading] = useState(false);
  const [searchedImgs, setSearchedImgs] = useState([]);

  const client = createClient(
    "tZehacuyqWF2RUtHSnoCI0Q4Z2GE96FvXcAQTjo8s6bEl4OzSSfBpI1g"
  );
  

  function seachImgs(e) {
    e.preventDefault()
    setImgsLoading(true);
    setSelectImgMdlOpen(true)
    const query = e.target["search"].value;
    let ratio;

    if(tabValue == 0){
      ratio = "square"
    }else if (tabValue == 1){
      ratio = "landscape"
    }else {
      ratio = "portrait"
    }
    console.log(ratio, "RATIO HERE")
    client.photos
      .search({ query, per_page: 15, orientation: "portrait" })
      .then((photos) => {
        setSearchedImgs(photos.photos);
        setImgsLoading(false);
      });
  }

  useEffect(() => {
    setInput(editVideo?.text);
  }, [editVideo?.text, setInput]);



  const handleClose = () => {
    setEditVideo(null);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setAlignText(editVideo?.textAlign);
  };

  const [alignText, setAlignText] = useState("center");

  

  useEffect(() => {
    setBgImage(`url(${editVideo?.origin}${editVideo?.image})`);
  }, [editVideo?.image, editVideo?.origin]);

  const handleChangeImage = () => {
    const randomIndex = Math.floor(Math.random() * allImage?.length);
    pages[editVideo?.pageNumber - 1].image = allImage[randomIndex]?.imageName;
    setBgImage(`url(${editVideo?.origin}${allImage[randomIndex]?.imageName}`);
    setHasEdits(true);

  };

  const handleSavePage = () => {
    pages[editVideo?.pageNumber - 1].text = input ? input : editVideo?.text;
    pages[editVideo?.pageNumber - 1].textAlign = alignText;
    setHasEdits(true);

    handleClose();
  };
  const textareaRef = useRef(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea.value.length > 160) {
      textarea.value = textarea.value.slice(0, 160);
    }
  };
  const handlePaste = (event) => {
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text").slice(0, 160);
    event.preventDefault();
    document.execCommand("insertText", false, pastedText);
  };

  return (
    <div>
      <Dialog
        open={editVideo}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          className="px-3 py-4 video-edit-width"
          style={{ minWidth: "400px" }}
        >
          <p style={{marginBottom:"6px", marginTop:"-18px"}} className="text-end video-edit-pageno">
              Page {editVideo?.pageNumber}
          </p>
          <div
            className="p-2 video-edit-textarea-container"
            style={{ background: "#23406C" }}
          >
            <textarea
              className={`text-white ai-textarea videoai-modal-textarea`}
              style={{ border: "1px solid #fff", background: "#011C34", width:"100%" }}
             
              cols="30"
              maxLength={160}
              ref={textareaRef}
              onInput={handleInput}
              rows="4"
              value={
                isTyping
                  ? input
                  : videoFormat.pageCount === 0
                  ? input?.toUpperCase() || editVideo?.text.toUpperCase()
                  : input
                  ? input
                  : editVideo?.text
              }
              // value={input || editVideo?.text}
              id="inputText"
              name="inputBox"
              onChange={(e) => {
                setIsTyping(true);
                const newInput = e.target.value;
                const newWordCount = wordCount(newInput);
                if (newWordCount <= 160) {
                  setInput(newInput);
                  setHasEdits(true);
                } else {
                  setInput(input);
                }
              }}
            
              onPaste={handlePaste}
            />
          </div>
          {(input || editVideo?.text) && (
            <p
              className={`mb-0 text-start ${
                input?.length >= 160 ? "text-danger" : "text-dark"
              }`}
              style={{
                fontSize: "13px",
                fontWeight: "500",
                letterSpacing: "0.1px",
              }}
            >
              {input?.length}/160 characters
            </p>
          )}

          <div className="d-flex justify-content-center pt-2 videoai-edit-container">
            <div className={`d-flex position-relative`}>
             
                <div
                  className={`d-flex justify-content-center align-items-${alignText} p-2`}
                  style={{
                  
                    height: editVideo?.height,
                    width: editVideo?.width,
                    backgroundImage: bgImage,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <div>
                    <div
                      className="inside-box"
                      style={{
                        // backgroundColor: "rgba(255, 255, 255, 0.8)",
                        fontSize: "10px",
                        backgroundColor: "rgba(190, 210, 180, 0.6)",
                        padding: "10px",
                        textAlign: "center",
                        overflowWrap:"anywhere",
                      }}
                    >
                      <p
                        className="text-dark d-flex mb-0 "
                        style={{
                          fontWeight: "500",
                          textTransform:
                            editVideo?.pageNumber == 1 && "uppercase",
                        }}
                      >
                        {/* {editVideo?.text} */}
                        {isTyping ? input : editVideo?.text}
                      </p>
                    </div>
                  </div>
                </div>
              {/* </Badge> */}
            </div>
            {/* <BiRefresh
              title="Change Image"
              onClick={handleChangeImage}
              className="text-end edit-image-change-icon"
              style={{
                cursor: "pointer",
                background: "#21232a",
                marginLeft: "2px",
                // marginTop: "4px",

                borderRadius: "10%",
                color: "lightblue",
                fontSize: "28px",
              }}
            /> */}

            <div className={`flex-column d-flex justify-content-center mb-1`}>
              <div className="position-relative">
                <form onSubmit={(e)=> seachImgs(e)} style={{visibility: "hidden"}}>
                  <input name="search" required={true} placeholder="Search image" style={{width:"89%", display:"block", marginInline:"auto", height:"40px"}}></input>
                  <button type="submit" style={{position:"absolute", top:"8px", right:"10px", border:"none", backgroundColor:"transparent"}}><i class="fa-solid fa-magnifying-glass"></i></button>
                </form>
              </div>
              <div className="d-flex justify-content-center align-items-center py-2">
                <div className=" video-ai-text-align d-flex justify-content-center">
                  <div>
                    <p
                      className="edit-align-style mb-0 text-uppercase"
                      style={{ fontSize: "12px" }}
                    >
                      Text alignment
                    </p>

                    <AntButton
                      size={"small"}
                      className="me-1"
                      onClick={() => setAlignText("start")}
                    >
                      Top
                    </AntButton>
                    <AntButton
                      size={"small"}
                      onClick={() => setAlignText("center")}
                    >
                      Centre
                    </AntButton>
                    {/* <br /> */}
                    <div className="d-flex justify-content-center mt-1 align-bttom-btn">
                      <AntButton
                        size={"small"}
                        className="text-center"
                        onClick={() => setAlignText("end")}
                      >
                        Bottom
                      </AntButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex  justify-content-center mt-2">
            {" "}
            <div>
              <Button
                className="me-1 me-md-2 video-ai-button"
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  handleCloseDialog();
                  handleClose();
                }}
              >
                Cancel
              </Button>
              <Button
                className="video-ai-button"
                variant="contained"
                color="success"
                size="small"
                onClick={handleSavePage}
              >
                Save
              </Button>
            </div>
          </div>
        </div>


        <React.Fragment>
          <Dialog
            className="img-select-modal"
            open={selectImgMdlOpen}
            onClose={()=> setSelectImgMdlOpen(false)}
          >
            <div className="img-select-contianer">
                <div className="all-imgs row g-3">
                  {imgsLoading ? (
                  "Loading"
                  ) : searchedImgs.length > 0 ? (
                    <>
                      {searchedImgs.slice(0, 6).map((photo) => {
                        return <div className="col-6">
                            <div onClick={()=> setSelectedImg(photo)} className={`${selectedImg.id == photo.id ? "selected-img" : ""}`}>
                              <img className="img-fluid" key={photo.id} alt={photo.alt} src={photo.src.tiny} />
                            </div>
                          </div>
                      })}
                    </>
                  ) : undefined}
                </div>
                <div className="d-flex  justify-content-center mt-3">
                <div>
                  <Button
                    className="me-1 me-md-2 video-ai-button"
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={()=> setSelectImgMdlOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="video-ai-button"
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={()=> setSelectImgMdlOpen(false)}
                  >
                    OK
                  </Button>
                </div>
                </div>
            </div>
          </Dialog>
        </React.Fragment>

      </Dialog>

      <div>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure, you want to change for all?"}
          </DialogTitle>
          <DialogActions className="d-flex justify-content-center">
            <div>
              <Button
                className="me-2"
                variant="contained"
                color="success"
                size="small"
                onClick={() => {
                  handleCloseDialog();

                  setAlignText(alignText);

                  handleClose();
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  handleCloseDialog();
                  handleClose();
                }}
              >
                No
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default VideoEditModal;

// className={`${allImage.format == "1" && 'col-6'}`}
