import React from "react";
import ReactMarkdown from "react-markdown";

const ADS = ({ inputText, isGenerating, text, presetSelected, wordRef }) => {
  return (
    <>
      {/* <p class="preset-title text-start">Ad From Product Description</p>  */}
      {inputText ? (
        <>
          <>
            <p class="text-muted text-start">{inputText}</p>

            {isGenerating == true ? (
              <p
                class="text- mb-0 mt-2 text-start"
                style={{
                  fontSize: "14px",
                  color: "black",
                }}
              >
                Processing...
              </p>
            ) : (
              <>
                {text && (
                  <p
                    style={{
                      color: "black",
                      whiteSpace:"pre-line"
                    }}
                    class="text- mb-0 text- text-start"
                  >
                    {wordRef.current && (
                      <>
                        <p style={{ color: "black",whiteSpace:"pre-line" }}>
                          <ReactMarkdown>{text}</ReactMarkdown>
                        </p>
                      </>
                    )}
                  </p>
                )}
              </>
            )}
          </>
        </>
      ) : (
        <>
        <p class="preset-title text-start">How it works?</p>
          {/* <p className="text-start mb-4" style={{textDecoration:'underline',color:"blue"}}>
          How it works?
            </p> */}
            <p className="text-muted text-start mb-2 text-bold" style={{fontSize:"16px",fontWeight:"bold" }}>
          If you enter something like this?
            </p>
            <p className="text-muted text-start mb-4" style={{fontSize:"16px"}}>
Learning Room is a virtual environment to help students from kindergarten to high school excel in school.
            </p>
            <p className="text-dark text-start mb-2 text-bold" style={{fontSize:"16px",fontWeight:"bold"}}>
            Our AI will generate something like this for you.
            </p>
            <p className="text-dark text-start" style={{fontSize:"16px"}}>
             Are you a parent looking for a way to help your child excel in school? Look no further than Learning Room! Our virtual environment provides a safe and engaging way for students from kindergarten to high school to learn and practice the skills they need to succeed. With our comprehensive curriculum, interactive games, and experienced instructors, your child will be well-prepared for their future. Join us today and give your child the tools they need to reach their full potential!
          </p>

          <p style={{ color: "black" }}>
            <ReactMarkdown>{presetSelected.label}</ReactMarkdown>
          </p>
        </>
      )}
    </>
  );
};

export default ADS;
