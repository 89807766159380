import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import {
  DialogTitle,
  Typography,
  Box,
  DialogContent,
  Button,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { useContext, useState } from "react";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import Swal from "sweetalert2";
import './AdminNews.css'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ViewNews({ setViewModal, viewModal, oneNews, setComicModal, comicModal }) {
  const handleClose = () => {
    setViewModal(false);
    setNewSummary("");
    setIsComicSummary(false);
  };
  const { getAllNewsData } = useContext(AdminAuthContext);
  const [isWritting, setIsWritting] = useState(false);
  const [isComic, setIsComic] = useState(false);
  const [newSummary, setNewSummary] = useState("");
  const [isComicSummary, setIsComicSummary] = useState(false);
  const [isNewsSummary, setIsNewsSummary] = useState(false);
  const [isEditing, setIsEditing] = useState(false);


  // const [comicModal, setComicModal] = useState(false);

  const handleReWrite = async () => {
    // setNewSummary('');
    setIsComic(false);
    setIsWritting(true);
    setIsNewsSummary(false);
    setIsComicSummary(false);
    try {
      const response = await axios.put(
        `https://backend.whalesai.com/api/v1/admin/news/rewrite/${oneNews?._id}`,
        {
          summary: oneNews?.summary,
        }
      );
      console.log("response===========>>>>>>>", response);
      setNewSummary(response?.data?.news?.summary);
      setIsNewsSummary(true);
      setIsComicSummary(false);
      getAllNewsData();
      setIsWritting(false);
    } catch (error) {
      console.error("Error updating the news summary:", error);
    }
  };

  const handleComic = async() => {
    setIsWritting(false);
    setIsComic(true);
    setIsNewsSummary(false);
      setIsComicSummary(false);
    try {
      const response = await axios.put(
        `https://backend.whalesai.com/api/v1/admin/news/rewrite/comic/${oneNews?._id}`,
        {
          summary: oneNews?.summary,
        }
      );
      getAllNewsData();
      console.log("response===========>>>>>>>", response);
      setNewSummary(response?.data?.news?.comicSummary);
      setIsNewsSummary(false);
      setIsComicSummary(true);
      setIsComic(false);
      // handleClose();
      // setComicModal(true);

    } catch (error) {
      console.error("Error updating the news summary:", error);
    }
  }

  console.log("One news::::::::::", newSummary);
  console.log(isComic , 'comic', isWritting);

  const handleCopy = () => {
    // Create a string with the title and newSummary
    const textToCopy = `${oneNews?.title || "No Title Available"}\n\n${newSummary || oneNews?.summary || ""}`;
  
    // Copy the text to the clipboard
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        // Show SweetAlert2 after successfully copying
        setViewModal(false);
        Swal.fire({
          // title: 'Copied!',
          text: 'The news details have been copied to your clipboard.',
          background: '#011225',
          color: '#fff',
          icon: 'success',
          confirmButtonText: 'OK',
          confirmButtonColor: '#1c76e0', // Optional: Customize button color
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        Swal.fire({
          title: 'Error!',
          text: 'Failed to copy the text. Please try again.',
          background: '#011225',
          color: '#fff',
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: '#1c76e0', // Optional: Customize button color
        });
      });
  };

  // const formatParagraph = (text) => {
  //   const formattedText = text.replace(/\*\*(.*?)\*\*|##(.*?)##/g, (match, p1, p2) => 
  //     `<strong>${p1 || p2}</strong>`
  //   );
  //   return formattedText;
  // };

  const formatParagraph = (text) => {
    const formattedText = text
      // Remove "Panel [number]:" instances
      .replace(/Panel \d+:/g, "")
      .replace(/Panel \d+/g, "")
      .replace(/---/g, "")
      .replace(/(MALE|FEMALE|Male|Female)([^:]*):(.+)/g, (match, gender, extraText, rest) => `<br/><strong>${gender}${extraText}:</strong> ${rest}`)
      .replace(/(\*\*|##)(.*?)\1/g, (match, marker, content) => `<br/><strong>${content}</strong>`)
    
    return formattedText;
  };

  console.log('smmary', oneNews?.summary);
  

  return (
    <BootstrapDialog
      onClose={handleClose}
      scroll="body"
      aria-labelledby="customized-dialog-title"
      open={viewModal}
    >
      <Box
        sx={{
          backgroundColor: "#3080ab",
          color: "#fff",
          width: {
            lg: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: "2px solid #9a9ead54" }}
          id="customized-dialog-title"
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-2 text-white">
              <Typography variant="h6"> {
                isComicSummary ? 'Comic Strip Details' : 'News Details'
                } </Typography>
            </div>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                color: "white",
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent>
          <div className="d-flex justify-content-end mb-1 mt-0">
             <button onClick={() => {
              handleCopy();
             }} className="btn btn-warning">Copy</button>
          </div>
          <Box
            sx={{
              padding: 2,
              backgroundColor: "#fff",
              color: "#000",
              borderRadius: "8px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", mb: 1, textAlign: "left" }}
            >
              {oneNews?.title || "No Title Available"}
            </Typography>

            {newSummary ? (
              <Typography
                variant="body2"
                sx={{ color: "#555", mb: 2, textAlign: "left" }}
              >
                <strong></strong>
                {newSummary?.split("\n\n").map((paragraph, index) => (
                  // <span key={index}>
                  //   {paragraph}
                  //   <br />
                  //   <br />
                  // </span>
                  
                    isComicSummary ?  <span
                    key={index}
                    // className={index % 2 === 0 ? "align-left" : "align-right"}
                    className={newSummary?.includes("Panel")
                      ? (index % 2 === 0 ? "align-right" : "align-left")
                      : (index % 2 === 0 ? "align-left" : "align-right")}
                    // style={{ textAlign: index % 2 === 0 ? "left" : "right" }}
                      dangerouslySetInnerHTML={{ __html: formatParagraph(paragraph) }}
                    /> :
                   <p
                        key={index}
                          dangerouslySetInnerHTML={{ __html: formatParagraph(paragraph) }}
                        />
                ))}
              </Typography>
            ) : (
              <Typography
                variant="body2"
                sx={{ color: "#555", mb: 2, textAlign: "left" }}
              >
                <strong></strong>
                {isWritting ? (
                  <p className="text-center my-5">
                    <strong>Re-Writing . . .</strong>
                  </p>
                ) : 
                <div>
                  {
                    isComic? <p className="text-center my-5">
                    <strong>Generating Comic Strip . . .</strong>
                  </p> : (
                      oneNews?.summary?.split('\n\n').map((paragraph, index) => (
                        <p
                        key={index}
                        
                          dangerouslySetInnerHTML={{ __html: formatParagraph(paragraph) }}
                        />
                      ))
                    )
                  }
                </div>
                
                }
              </Typography>
            )}

            {/* <Typography
              variant="body2"
              sx={{ color: "#555", mb: 2, textAlign: "left" }}
            >
              <strong></strong>
              {
                isWritting ? <p className="text-center my-5">Re-Writting . . .</p> : oneNews?.summary
              }
               
            </Typography> */}

            <Box display="flex" justifyContent="space-between">
              
              {
                isComicSummary ? <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#157347" }}
                onClick={handleComic}
              >
                RE-WRITE COMIC STRIP
              </Button> : 
              <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#157347" }}
              onClick={handleReWrite}
            >
              Re-write
            </Button>
              }
              
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#BB2D3B" }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>

            <Box display="flex" justifyContent="space-between">
              {
                !isComicSummary ? <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#3080AC", marginTop: '10px' }}
                onClick={handleComic}
              >
              {
                oneNews?.comicSummary? 'Re-Generate Comic Strip' : 'Generate Comic Strip'
              }
              </Button>
              : 
              <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#3080AC", marginTop: '10px' }}
                onClick={() => {
                  // setIsEditing(true);
                  // handleClose();
                  // setComicModal(true);
                }}
                disabled = {isEditing}
              >
                Edit
              </Button>
              }
              {/* <Button
                variant="contained"
                color="primary"
                style={{ backgroundColor: "#BB2D3B" }}
                onClick={handleClose}
              >
                Close
              </Button> */}
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </BootstrapDialog>
  );
}
