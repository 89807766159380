import React, { useEffect } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import CircularProgressWithLabel from '../CircularProgressWithLabel/CircularProgressWithLabel';

const Game3D = ({ unityProvider, isLoaded, loadingProgression, sendMessage, }) => {

    const loadingPercentage = Math.round(loadingProgression * 100);
    return (
        <div style={{ position: 'relative' }}>
            {isLoaded === false && (
                <div className="loading-overlay text-center mx-auto">
                    <CircularProgressWithLabel value={loadingPercentage} />
                </div>
            )}
            <Unity tabIndex="-1" className="unity" style={{ width: '100%' }} unityProvider={unityProvider} />

        </div>
    );
};

export default Game3D;