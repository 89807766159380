import React, { Fragment, useContext, useEffect, useState } from "react";
import "./video-ai.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
// import { AddCircleOutline } from "@material-ui/icons";
import moneyExchangeIcon from "../../Assets/Images/transaction-data.png";
import AddPageModal from "./AddPageModal";
import DeleteIcon from "@mui/icons-material/Delete";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { formatTime } from "../../utils/formatTimer";
import CreditsButton from "../../utils/CreditsButton";
const FormatScript = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { AIuser, setisBack, initialTimer, setInitialTimer } =
    useContext(UserAuthContext);
  const creditTolcalString = AIuser?.credits
  ? parseInt(Math.floor(AIuser?.credits)).toLocaleString()
  : "0";

  const [pages, setPages] = useState([]);

  const [input, setInput] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const text = location?.state?.text;
  // const initialTimer = location?.state?.timer;

  const [timer, setTimer] = useState(initialTimer);

  useEffect(() => {
    let interval;

    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer + 1);
      setInitialTimer((prevTimer) => prevTimer + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [setInitialTimer]);

  const splitParagraphs = (text) => {
    const MIN_SENTENCE_LENGTH = 10;
    const sentences = text?.split(/([.?!]|:)+/);
    let output = [];
    let buffer = "";
    for (let i = 0; i < sentences?.length; i++) {
      const sentence = sentences[i];
      buffer += sentence.trim();
      if (sentence.match(/[.?!:]/)) {
        if (buffer?.length >= MIN_SENTENCE_LENGTH) {
          output.push(buffer);
          buffer = "";
        } else {
          if (i < sentences?.length - 1) {
            buffer += sentences[i + 1];
            i++;
          } else {
            output.push(buffer);
            buffer = "";
          }
        }
      }
    }
    if (buffer?.length > 0) {
      output.push(buffer);
    }

    const objArr = output?.map((text, index) => {
      return { text, id: index + 1, textAlign: "center" };
    });
    return objArr;
  };

  // const output = splitParagraphs(text);

  useEffect(() => {
    const paragraphs = location?.state?.paragraphs;

    const customPage = paragraphs?.find((obj) => obj.customPage);

    if (customPage) {
      setPages(paragraphs);
    } else {
      const output = splitParagraphs(text);
      output?.unshift({
        text: location?.state?.inputText,
        id: 0,
        textAlign: "center",
      });
      setPages(output);
    }
  }, [location?.state?.paragraphs, text, location?.state?.inputText]);

  // const handleNavigate = () => {
  //   navigate("/format-select", {
  //     state: {
  //       paragraphs: pages,

  //       inputText: location?.state?.inputText,
  //       text: text,
  //     },
  //   });
  // };
  // const handleNavigateBack = () => {
  //   // navigate("/videoai")
  //   navigate("/video-ai", {
  //     state: {
  //       text: text,
  //       inputText: location?.state?.inputText,
  //       paragraphs: pages,
  //     },
  //   });
  // };

  const handleAddNewPage = () => {
    setPages([...pages, { text: input, id: pages?.length, customPage: true }]);
    setInput("");
    handleClose();
  };

  const handleRemovepage = (id) => {
    const newPages = pages.filter((page) => page.id !== id);
    setPages(newPages);
  };

  // filter those page which have customPage property
  const newPages = pages?.filter((page) => page.customPage === true);
  const newPagesLength = newPages?.length;
  const leftPages = newPagesLength > 0 ? 10 - newPagesLength : 10;

  // const filteredparagraphs = output?.filter((value) => Boolean(value));

  useEffect(() => {
    // wait 5 seconds and navigate to videoai page
    setTimeout(() => {
      navigate("/format-select", {
        state: {
          paragraphs: pages,

          transactionId: location?.state?.transactionId,
          inputText: location?.state?.inputText,
          text: text,
        },
      });
    }, 5000);
  }, [
    location?.state?.inputText,
    navigate,
    pages,
    text,
    location?.state?.transactionId,
  ]);
  return (
    <Fragment>
      <PageHeading title={"TEXT VIDEO AI"} />
      <div className="img-ai-container container pb-5">
        <div
          style={{
            maxWidth: "872px",
            paddingInline: "12px",
            marginInline: "auto",
          }}
        >
          <div className="d-flex align-items-center justify-content-center centralize-credits-btn-sm">
            <div className="d-flex ">
              <CreditsButton />
            </div>
          </div>
          <p className="text-white text-center mt-2 mb-0 centralize-credits-btn-sm">
            Every request is 500 credits.
          </p>
          <div>
            {timer && (
              <div className="d-flex justify-content-center mt-3">
                <button className="credits-button text-center text-uppercase mt-sm-0 mt-2 me-sm-2 me-0 ">
                  {formatTime(timer).hours}
                  {": "}
                  {formatTime(timer).minutes}
                  {":"}
                  {formatTime(timer).seconds}
                </button>
              </div>
            )}
            <p
              style={{ fontSize: "13px" }}
              className="text-warning text-center pt-2"
            >
              Please do not move away from this browser.<br className="videoai-br-hide" /> Processing will stop.
            </p>
          </div>
        </div>

        <div className="container pt-2">
          <div style={{ maxWidth: "850px" }} className="mx-auto">
            {/* <p
              className="text-warning centralize-credits-btn-sm"
              style={{ marginBottom: "-40px" }}
            >
              Step 2:{" "}
              <span className="text-white ms-1">
                {"  "}
                Here the script will formatted
              </span>{" "}
            </p> */}
            <div className="pragraphMargine">
              {pages?.map((paragraph, index) => (
                <>
                  <div
                  // className="d-flex justify-content-between"
                  >
                    <p className=" text-end text-white mb-2">
                      {paragraph.customPage && (
                        <IconButton
                          aria-label="edit"
                          size="small"
                          sx={{
                            backgroundColor: "rgba(190, 210, 180, 0.6)",
                            marginRight: "4px",
                          }}
                          onClick={() => handleRemovepage(paragraph.id)}
                        >
                          <DeleteIcon
                            style={{ fontSize: "15px" }}
                            className="videoai-delete"
                          />
                        </IconButton>
                      )}
                      Page {index + 1}
                    </p>
                  </div>
                  <p
                    className="w-100 text-white p-3 text-start "
                    style={{
                      background: "#011C34",
                      borderRadius: "10px",
                      border: "1px solid #fff",
                      textTransform: index == 0 && "uppercase",
                    }}
                    key={index}
                  >
                    {paragraph.text}
                  </p>
                </>
              ))}
            </div>

            {/* <div className="firstdivBtn">
              {leftPages > 0 && (
                <>
                  <button onClick={handleClickOpen} className="videoai_btn2">
                   
                    Add Page
                  </button>
                </>
              )}
              <div className=" lastdivBtn">
                
                <button
                  className="videoai_btn1Script"
                 
                  onClick={() => {
                    handleNavigateBack();
                    setisBack(true);
                  }}
                >
                  Back
                </button>
                <button
                  className="videoai_btnScript"
                  // variant="contained"
                  onClick={() => handleNavigate()}
                >
                  STEP 3: SELECT THE FORMAT
                </button>
              </div>
            </div> */}
          </div>
        </div>
        <AddPageModal
          handleAddNewPage={handleAddNewPage}
          input={input}
          setInput={setInput}
          open={open}
          handleClose={handleClose}
          handleClickOpen={handleClickOpen}
          pages={pages}
          leftPages={leftPages}
        />
      </div>
    </Fragment>
  );
};

export default FormatScript;
