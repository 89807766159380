import { Card, CardContent, Typography } from "@mui/material";
import PropTypes from "prop-types";

const extractHeader = (text = "") => text.split(":")[0];

const extractDetails = (text = "") => text.split(":")[1];

const DataCard = ({ title, description, bgColor }) => {
  return (
    <Card
      sx={{
        backgroundColor: `${bgColor}`,
        color: "white",
        height: "100%",
        border: "1px solid white",
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          sx={{ color: "white", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <ul>
          {description.map((data) => (
            <li key={data} style={{ margin: "1rem 0" }}>
              <Typography component={"span"} sx={{ fontWeight: "bold" }}>
                {extractHeader(data)}:
              </Typography>{" "}
              {extractDetails(data)}
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

DataCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.arrayOf(PropTypes.string).isRequired,
  bgColor: PropTypes.string.isRequired,
};

export default DataCard;
