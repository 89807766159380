import React, { useState } from 'react';

const AddYourScript = ({text, setText}) => {


  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteText = (e.clipboardData || window.clipboardData).getData('text');
    const newText = text + pasteText;
    if (newText.length > 1600) {
      setText(newText);
    } else {
      setText(newText);
    }
  };

  const clearText = () => {
    setText('');
  };

  return (
    <div>
      <div className="first-field-container">
        <div
          className="d-flex justify-content-between align-items-center text-white"
          style={{
            backgroundColor: 'rgb(48, 128, 172)',
            paddingInline: '16px',
            paddingBlock: '17px',
          }}
        >
          <h5 className="mb-0 text-start" style={{ fontWeight: 400 }}>
            CHARACTER COUNT : {text.length} <br />
            <span style={{ opacity: 0.4 }}>(Maximum: 1600 characters)</span>
          </h5>
        </div>
        <div id="responseDiv" className="fist-text-area">
          <div className="media-body ml-3 h-100">
            <div className="text-light rounded py-2 mb-2 text-start ask-ai-fonts copy-contents h-100">
              <textarea
                style={{
                  padding: '10px',
                  width: '100%',
                  minHeight: '100%',
                  background:"white",
                  color:"black"
                }}
                className="rounded"
                cols="30"
                rows="3"
                maxLength="1600"
                value={text}
                onChange={handleTextChange}
                onPaste={handlePaste}
              />
            </div>
          </div>
        </div>
      </div>
      {text.length>1600&&<p className='text-center text-warning my-2'>Warning: Your text exceeds 1600 characters. Please edit it to 1600 characters</p>}
      {/* <div className="mt-2 text-center action-button"> */}
        {/* <button className="clear-button rounded btn btn-danger" style={{minWidth:"93px"}} onClick={clearText}>
          Clear
        </button> */}
        {/* <button
          className="generate-button rounded"
          disabled={text.length > 1600}
          style={{
            backgroundColor:  text.length > 1600 ? '#000' : '',
          }}
        >
          Generate
        </button> */}
      {/* </div> */}
    </div>
  );
};

export default AddYourScript;






// import React, { useState } from 'react';

// const AddYourScript = () => {
//   const [text, setText] = useState('');
//   return (
//     <div>
//       <div className="first-field-container">
//         <div
//           className="d-flex justify-content-between align-items-center text-white"
//           style={{
//             backgroundColor: 'rgb(48, 128, 172)',
//             paddingInline: '16px',
//             paddingBlock: '17px',
//           }}
//         >
//           <h5 className="mb-0" style={{ fontWeight: 400 }}>
//             CHARACTER COUNT : {text.length} <br />
//             <span style={{ opacity: 0.4 }}>(Maximum: 1600 characters)</span>
//           </h5>
//         </div>
//         <div id="responseDiv" className="fist-text-area">
//           <div className="media-body ml-3 h-100">
//             <div className="text-light rounded py-2 mb-2 text-start ask-ai-fonts copy-contents h-100">
//               <textarea
//                 style={{
//                   padding: '10px',
//                   width: '100%',
//                   minHeight: '100%',
//                 }}
//                 className="rounded"
//                 cols="30"
//                 rows="3"
//                 maxLength="1600"
//                 value={text}
//                 onChange={(e) => setText(e.target.value)}
//               />

//               {/* <div className="d-flex justify-content-center align-items-center mt-2">
//                 <button className="clear-btn">Clear</button>
//               </div> */}
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* <div className="mt-4 text-center">
//         <button className="generate-button rounded">Generate</button>
//       </div> */}
//       <div className="mt-4 text-center text-md-start action-button">
//         <button className="clear-button rounded">Clear</button>
//         <button className="generate-button rounded">Generate</button>
//       </div>
//     </div>
//   );
// };

// export default AddYourScript;


