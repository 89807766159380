import React, { useContext, useEffect, useState } from "react";
import moneyExchangeIcon from "../../Assets/Images/transaction-data.png";
import PageHeading from "../../Components/PageHeading/PageHeading";

import { loadStripe } from "@stripe/stripe-js";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { Descriptions } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const stripePromise = loadStripe(
  "pk_test_51L0pRfJbegYKTJYPu7wYHHQHTkdeBJeEj0PibmVp39Do9lGIK2Fdq7RC4vZa4yOzZSlHxa7dY6oYPPySQaBWmpPy00BLMhz0Yk"
);
const Payment = () => {

  const {
    AIuser,
    getDssTokenBalance,
    currentAccountDssToken,
    getApprove,
    buyPackageFromContract,
    user,
    openWalletModal,
    currentAccount,
    setRequestLoading,
    setUserRefetch,
    userRefetch
  } = useContext(UserAuthContext);
  console.log("🚀 ~ file: Payment.js:26 ~ Payment ~ currentAccount:", currentAccount)

  const [creditsData, setCreditsData] = useState();
  console.log(creditsData, 'CREDITS DATA')
  const [introducerData, setIntroducerData] = useState({});
  console.log(introducerData, 'INTRODUCER DATA')

  const localtion = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  // console.log("localtion", localtion);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (localtion?.state?.data) {
      console.log("got from location",localtion?.state?.data)
      setCreditsData(localtion?.state?.data);
      return
    } else if(id) {
      console.log("got from id", id)
      const pack = packages.find((pac) => pac.id == id)
      console.log(pack, "pack got from ")
      setCreditsData(pack);
    }
  }, [localtion?.state?.data, id]);
  console.log("dataaa : " + JSON.stringify(localtion?.state?.data))

  const handleChangePackage = (data) => {
    window.scrollTo(0, 0);
    setCreditsData(data);

    navigate("/purchase", {
      state: { data: data },
      replace: true,
    });
  };

  useEffect(() => {
  const getData = async () => {
    if (AIuser?.introducer !== "false" && AIuser?.introducer) {
      const response = await axios.get(
        `https://backend.whalesai.com/api/dashboard/user_signup-signin/get/${AIuser?.introducer}`
      );
      setIntroducerData(response);
    }
  };
      getData();
  });
  const handleNavigateToCheckout = async (data) => {
    window.scrollTo(0, 0);
    setCreditsData(data);

    // total price after giving 5% discount
    let price = creditsData.paylimit;
    if (AIuser?.introducer !== "false") {
      price = creditsData.paylimit - (creditsData.paylimit * 5) / 100;
    }

    if (introducerData) {
      navigate("/payment-methods", {
        state: {
          price: price,
          realPrice: creditsData.paylimit,
          credits: creditsData.credits,
          data:creditsData,
          refEmail: introducerData?.data?.email,
          ownerId: AIuser?.myReferralCode,
          date: new Date(),
        },
        replace: true,
      });
    } else {
      navigate("/payment-methods", {
        state: {
          price: price,
          realPrice: creditsData.paylimit,
          credits: creditsData.credits,
data:creditsData,
          refEmail: "",
          ownerId: AIuser?.myReferralCode,
          date: new Date(),
        },
        replace: true,
      });
    }
  };

  const errorRepresentationFunction = (Status) => {
    return swal({
      title: "Attention",
      text: Status.message,
      icon: Status.status,
      button: "OK",
      dangerMode: true,
      className: "modal_class_success",
    });
  };

  // console.log("creditsData", creditsData);
  // const handleClick = async () => {
  //   let introducerData;
  //    // total price after giving 5% discount
  //    let price = creditsData.paylimit;
  //   if(AIuser?.introducer){
  //     price = creditsData.paylimit - (creditsData.paylimit * 5) / 100;
  //     introducerData = await axios.get(
  //       `https://backend.whalesai.com/api/dashboard/user_signup-signin/get/${AIuser?.introducer}`
  //     );
  //   }

  //   const stripe = await stripePromise;

  //   // Call your server-side endpoint to create a Checkout Session
  //   const response = await fetch(
  //     "https://backend.whalesai.com/api/v1/payment/create-checkout-session",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         price: price,
  //         realPrice: creditsData.paylimit,
  //         credits: creditsData.credits,
  //         email: AIuser.email,
  //         name: AIuser.name,
  //         userId: AIuser._id,
  //         refEmail: introducerData?.data?.email,
  //         ownerId: AIuser?.myReferralCode,
  //         date: new Date(),
  //       }),
  //     }
  //   );

  //   console.log("response", response);

  //   const session = await response.json();

  //   // Redirect the user to the Checkout page
  //   const result = await stripe.redirectToCheckout({
  //     sessionId: session.id,
  //   });

  //   if (result.error) {
  //     console.error(result.error.message);
  //   }
  // };

  console.log(user?.walletAddress);
  console.log(AIuser?._id);

  const buyPackage = async () => {
    console.log("hitted the function")
    setRequestLoading(true);

    getDssTokenBalance();
    let requireBalance;
    if (id == 3) {
      requireBalance = 600000;
      console.log("🚀 yes:", requireBalance);
    } else if (id == 2) {
      requireBalance = 80000;
      console.log("🚀 yes:", requireBalance);
    } else if (id == 1) {
      requireBalance = 5000;
      console.log("🚀 yes:", requireBalance);
    }
    const currentBalance = parseInt(currentAccountDssToken);
    if (currentBalance > requireBalance) {
      let approve = await getApprove(String(requireBalance));

      if (approve.return) {
        let buyPackageFun = await buyPackageFromContract(id);
        if (buyPackageFun.return) {
          console.log("done......")

          const response = await axios.post(
            "https://backend.whalesai.com/api/v1/dss-pay",
            {
              price: creditsData?.price,
              actualPrice: requireBalance,
              credits: creditsData?.credits,
              email: AIuser?.email,
              userId: AIuser?._id,
              date: new Date(),
              walletAddress: user?.walletAddress,
              payUrl: buyPackageFun?.data
            }
          );
          console.log("response", response);

          if (response.data.status === "success") {
            setUserRefetch(!userRefetch);
            console.log(".........successssss")
            // navigate("/dashboard", {
            //   state: { success: true, credits: response.data.credits },
            // });
            navigate("/dashboard");
          } else {
            console.log("error coming")
            setUserRefetch(!userRefetch);
            navigate("/dashboard");

          }
          const wrapper = document.createElement("div");
          wrapper.innerHTML = `<a href=${buyPackageFun?.data} target="_any" className="link_hash">Click here to see transaction</a> <br/> 
          <p>You have successfully brought your Package.</p>`;
          swal({
            title: "Success",
            content: wrapper,
            icon: "success",
            button: "OK",
            className: "modal_class_success",
          });
          setRequestLoading(false);

          getDssTokenBalance()

        } else {
          console.log("approve ==>", buyPackageFun.message);
          errorRepresentationFunction(buyPackageFun);
          setRequestLoading(false);

        }
      } else {
        console.log("approve ==>", approve.message);
        errorRepresentationFunction(approve);
        setRequestLoading(false);

      }
    } else {
      swal({
        title: "Attention",
        text: "You don't have enough Tokens to proceed",
        icon: "warning",
        button: "OK",
        dangerMode: true,
        className: "modal_class_success",
      });
    }
  };

  useEffect(() => {
    if (currentAccount) {
      getDssTokenBalance();
    }
  }, [currentAccount])

  return (
    <div className=" pb-5">
      <PageHeading title={"CHECKOUT"} />
      <div
        className="container account-container d-flex justify-content-center"
      // style={{ minHeight: "100vh" }}
      >
        <div className="col-md-6 col-lg-6 col-12">
          <div style={{ borderRadius: "10px" }} className="bg-white">
            <div
              className="package-price text-center"
              style={{ background: `${creditsData?.background}` }}
            >
              <p>CREDITS PACKAGE PACKAGE {creditsData?.packageNo}</p>
              <h2>${creditsData?.price}</h2>
            </div>
            <div>
              <div className="each-row py-4">
                <p className="row-header mb-2">CREDITS</p>
                <p className="row-value mb-0">{creditsData?.credits} CREDITS</p>
              </div>
              <div className="each-row py-4">
                <p className="row-header mb-2">EXPIRY</p>
                <p className="row-value mb-0">
                  EXPIRY IN {creditsData?.expire} MONTH
                </p>
              </div>
              <div className="each-row py-4 border-0">
                <p className="row-header mb-2">DISCOUNT</p>
                <p className="row-value mb-0 text-danger">
                  {" "}
                  {creditsData?.discount} DISCOUNT
                </p>
              </div>
              <div className="pay-button-container">
                <button
                  className="checkout-button"
                  onClick={handleNavigateToCheckout}
                >
                  PROCEED TO PAY WITH CREDIT/DEBIT CARD
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {creditsData?.packageNo != 3 && (
        <p
          className="text-warning text-center pt-1 cursor-pointer"
          style={{
            cursor: "pointer",
          }}
          onClick={() =>
            handleChangePackage({
              price: "1000",
              packageNo: 3,
              credits: "50000",
              expire: "12",
              discount: "40%",
              paylimit: "600",
              background: "#FA6918",
            })
          }
        >
          Buy Package 3 for 40% discount. Get it now!
        </p>
      )}
      <div className="dssBnt mt-2">
        {/* {user?.walletAddress ? (
          <button className="payDssBtn" onClick={() => buyPackage()}>
            PAY BY DSS TOKENS
          </button>
        ) : (
          <button className="payDssBtn" onClick={() => openWalletModal()}>
            PAY BY DSS TOKENS
          </button>
        )} */}

        {currentAccount ? <button className="payDssBtn" onClick={() => buyPackage()}>
          PAY BY DSS TOKENS
        </button>
          :
          <button className="payDssBtn" onClick={() => openWalletModal()}>
            Connect to wallet
          </button>}
      </div>

      {creditsData?.packageNo == 1 && (
        <p className="text-center text-warning">
          You have to pay 5,000 DSS Tokens
        </p>
      )}
      {creditsData?.packageNo == 2 && (
        <p className="text-center text-warning">
          You have to pay 80,000 DSS Tokens
        </p>
      )}
      {creditsData?.packageNo == 3 && (
        <p className="text-center text-warning">
          You have to pay 600,000 DSS Tokens
        </p>
      )}
      {currentAccount ? <p className="text-center text-warning">
        Current Balance: {Math.floor(currentAccountDssToken)}
      </p> : null}
    </div>
  );
};

export default Payment;


const packages = [
  {
    id: 1,
    price: "5",
    credits: "250",
    main: "5",
    expire: "1",
    discount: "NO",
    paylimit: "5",
    background: "#05C0A2",
  },
  {
    id: 2,
    price: "100",
    main: "80",
    credits: "5000",
    expire: "3",
    discount: "20%",
    paylimit: "80",
    background: "#1D60EF",
  },
  {
    id: 3,
    price: "1000",
    main: "600",
    credits: "50000",
    expire: "12",
    discount: "40%",
    paylimit: "600",
    background: "#FA6918",
  },
];
