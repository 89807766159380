import React, { useEffect, useState } from "react";
import MocUp from "../../Components/PageHeading/MocUp";
import Calendar from "react-calendar";
import { Col, Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import {
  MdContentCopy,
  MdOutlineToggleOff,
  MdOutlineToggleOn,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import StepButton from "../StepButton/StepButton";
import { FacebookData } from "../Calandars/Data";
import {
  SpeechBubbleBottomCenter,
  SpeechBubbleBottomLeft,
  SpeechBubbleLeft,
} from "../SpeechBubble/Bubble";

const SubsCribePay = () => {
  const buttons = [
    "Facebook",
    "Instagram",
    "Threads",
    "Twitter",
    "LinkedIn",
    "TikTok",
    "YouTube",
    "Pinterest",
    "Medium",
    "Telegram",
  ];

  const buttonColors = [
    "#95B9C9",
    "#39ACEF",
    "#0699E8",
    "#488AC7",
    "#367ECA",
    "#6960ED",
    "#1669C7",
    "#0040C4",
    "#0000FE",
    "#151A8F",
  ];

  const navigate = useNavigate();

  const [scheduleData, setScheduleData] = useState(null);
  const [scheduled, setScheduled] = useState(false);
  const [activeButton, setActiveButton] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [socialSchedule, setSocialSchedule] = useState(false);
  const [isChnaged, setIsChnaged] = useState(false);
  const [indexNumber, setIndexNumber] = useState(0);
  const [currentMonth, setCurrentMonth] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [isEnd, setIsEnd] = useState(false);

  const [showPlatforms, setShowPlatforms] = useState(false);
  const [post, setPost] = useState([]);
  const [toggledPlatforms, setToggledPlatforms] = useState([]);

  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [time, setTime] = useState(() => {
    const foundSchedule = scheduleData?.find(
      (item) => item.platform === buttons[activeButton]
    );
    return foundSchedule
      ? foundSchedule?.contentCategory[indexNumber]?.timeOfPosting
      : "09:00";
  });

  const renderTileContent = ({ date, view }) => {
    if (view === "month" && scheduleData?.length) {
      // Find the schedule for the current active platform
      const activePlatformSchedule = scheduleData?.find(
        (item) => item.platform === buttons[activeButton]
      );

      if (activePlatformSchedule) {
        const parseDate = (dateString) => {
          const [day, month, year] = dateString.split("-").map(Number);
          return new Date(year, month - 1, day);
        };

        const startDate = new Date();
        const endDate = new Date();

        endDate.setFullYear(endDate.getFullYear() + 1);

        const formatDate = (date) => {
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        };

        setDateStart(formatDate(startDate));
        setDateEnd(formatDate(endDate));
        // setIsEnd(true);

        // Log formatted dates
        console.log(formatDate(startDate), "tooooooo", formatDate(endDate));

        if (date >= startDate && date <= endDate) {
          return (
            <div>
              <MdContentCopy style={{ color: "black" }} />
            </div>
          );
        }
      }
    }
    return null;
  };

  useEffect(() => {
    setScheduleData(FacebookData);
    window.scroll(0, 0);
  }, []);

  console.log(scheduleData);

  const click = () => {
    setShowPlatforms(true);
  };

  const handleToggle = (platform) => {
    if (post?.includes(platform)) {
      setPost(post?.filter((item) => item !== platform));
    } else {
      if (post.length >= 4) {
        swal({
          text: `You have selected '${post.join(", ")}'.\n You can not select more than 4 platforms.`,
          buttons: false,
          timer: 3000,
          icon: "warning",
          className: "modal_class_success",
          content: {
            element: "span",
            attributes: {
              style: "color: #fff; padding: 20px; border-radius: 5px;",
            },
          },
        });
      } else {
        setPost([...post, platform]);
      }
    }
  };

  const handlePay = (data) => {
    console.log("pay...", data);
    let ammount;
    if (data?.length === 1) ammount = 30.0;
    else if (data?.length === 2) ammount = 48.0;
    else if (data?.length === 3) ammount = 63.0;
    else if (data?.length === 4) ammount = 72.0;
    console.log(ammount);
    if (data?.length !== 0) {
      navigate("/how-it-works-social-media-subscription", {
        state: { data: { ...data, platform: [data], ammount } },
        replace: true,
      });
    }
  };

  setTimeout(() => {
    const platform = 'Facebook';
    setShowPlatforms(true);

    // After 2 seconds of the modal opening, trigger the toggle button
    setTimeout(() => {
      handleToggle(platform);

      // After 2 more seconds, trigger the subscribe button
      setTimeout(() => {
        handlePay(post);
      }, 1000);

    }, 3000);

  }, 3000);

  return (
    <div>
      <MocUp title="CALENDARS" description="Subscribe and Pay" step="Step 4:" />
      <div className="quick-links-container custom-tabs d-flex gap-2">
        {buttons.map((label, index) => (
          <button
            key={index}
            className={`btn custom rounded-lg 
                ${activeButton === index ? "active" : ""}
                `}
            style={{ backgroundColor: buttonColors[index], height: "50px" }}
          >
            <p className="tabs-item m-0">
              {" "}
              {label === "Twitter" ? (
                "Twitter/X"
              ) : label === "Facebook" ? (
                <>
                  Facebook{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      display: "block",
                      padding: "0px",
                    }}
                  >
                    {" "}
                    Page or Group{" "}
                  </span>
                </>
              ) : label === "LinkedIn" ? (
                <>
                  LinkedIn{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      display: "block",
                      padding: "0px",
                    }}
                  >
                    {" "}
                    Page{" "}
                  </span>
                </>
              ) : (
                label
              )}
            </p>
          </button>
        ))}
      </div>

      <section>
        <div className="tabs-title">
          {buttons[activeButton] === "Twitter"
            ? "Twitter/X"
            : buttons[activeButton] === "Facebook"
              ? "Facebook Page"
              : buttons[activeButton] === "LinkedIn"
                ? "LinkedIn Page"
                : buttons[activeButton]}
        </div>
        <div
          className="text-center text-white"
          style={{ fontSize: "10px", marginBottom: "20px" }}
        >
          {" "}
          {scheduleData?.length !== 0 &&
          Array.isArray(scheduleData) &&
          scheduleData?.some(
            (item) => item.platform === buttons[activeButton]
          ) ? (
            <div>
              <span>Scheduler AI has scheduled from</span> <br />{" "}
              <spa>
                {dateStart} to {dateEnd}
              </spa>
            </div>
          ) : (
            <span> Scheduling Not Done </span>
          )}
        </div>
      </section>

      {/* claendars */}
      <section>
        <div
          className="calendar mb-1 pb-0 mx-auto"
          style={{ position: "relative" }}
        >
          <div>
            <div className="justify-content-center">
              <Col xs="auto" className="calendar-col">
                <div
                  className="calendar-navigation"
                  style={{ position: "relative" }}
                >
                  <Calendar
                    // onChange={handleDateChange}
                    value={selectedDate}
                    className="custom-calendar mx-auto"
                    next2Label={null}
                    prev2Label={null}
                    activeStartDate={activeStartDate}
                    // onActiveStartDateChange={handleActiveStartDateChange}
                    tileContent={renderTileContent}
                  />
                </div>
              </Col>
            </div>
          </div>
        </div>
      </section>

      {/* below calendars  */}
      <section>
        {scheduleData?.length !== 0 &&
        Array.isArray(scheduleData) &&
        scheduleData?.some(
          (item) => item.platform === buttons[activeButton]
        ) ? (
          <button className=" mb-4 mx-auto d-flex btn btn-secondary">
            Re-Scheduler AI
          </button>
        ) : (
          <button
            // onClick={handleSchedulerAIClick}
            className="ai-button rounded mx-auto d-flex"
          >
            Scheduler AI
          </button>
        )}

        <div className="text-center text-white pb-0 mb-0">
          <p className="">
            Country: Singapore
            <button
              onClick={() => {
                // setIsEditingCountry(true);
                // setisChanging(true);
              }}
              className="edit-button"
            >
              {" "}
              Edit
            </button>
          </p>
          {/* Time  */}
          <p className="pb-0 mb-0">
            Daily Posting Time: 09:00 (24 hours)
            <button
              onClick={() => {
                if (
                  !(
                    scheduleData?.length !== 0 &&
                    Array.isArray(scheduleData) &&
                    scheduleData?.some(
                      (item) => item.platform === buttons[activeButton]
                    )
                  )
                ) {
                  swal({
                    text: `Please adjust the timing of posting after scheduling the content.`,
                    icon: "warning",
                    // timer: 2000,
                    className: "modal_class_success",
                  });
                } else {
                  // setIsEditingTime(true);
                }
              }}
              style={{
                backgroundColor: !(
                  scheduleData?.length !== 0 &&
                  Array.isArray(scheduleData) &&
                  scheduleData?.some(
                    (item) => item.platform === buttons[activeButton]
                  )
                )
                  ? "#5C636A"
                  : "#39ACEF",
              }}
              className="edit-button mb-0"
            >
              {" "}
              Edit{" "}
            </button>
          </p>
        </div>

        <p className="server-sm">
          There may be slight variations in posting times due to factors such as
          server congestion.
        </p>

        {/* Buttons */}
        <div
          className="container mx-auto mb-3 "
          // style={{top: '-10%'}}
        >
          <SpeechBubbleBottomLeft>You can effortlessly create and schedule content to post automatically!</SpeechBubbleBottomLeft>
        </div>
        <div
          className="text-center container mx-auto mb-2"
          // style={{width: '100%'}}
        >
          <button
            onClick={click}
            style={{ width: "100%" }}
            className="btn btn-warning automatically"
          >
            <span>START GENERATING CONTENTS</span>
            <span> AND POSTING AUTOMATICALLY</span>
          </button>
        </div>
        <div className=" container mx-auto d-flex justify-content-center mx-4">
          <button
            onClick={() => {
              navigate("/how-it-works-analysis");
            }}
            className="btn btn-info analysis rounded"
          >
            <p className="pb-0 pt-0 mt-0 mb-0">VIEW YOUR ANALYSIS </p>{" "}
          </button>
        </div>
      </section>

      <section>
        {isEnd && (
          <StepButton
            pre={`/how-it-works-calendars`}
            nex={`/how-it-works-subscribe-and-pay`}
          />
        )}
      </section>

      {/* modal section  */}
      <Modal show={showPlatforms} onHide={() => setShowPlatforms(false)}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>SMMM AI</Modal.Title>{" "}
        </Modal.Header>

        <Modal.Body style={{ backgroundColor: "#011225", color: "#fff" }}>
        <div className="position-absolute" style={{top: '-24px', left: '105px'}}>
          <SpeechBubbleBottomLeft>Activate your subscription to start posting on Facebook today!</SpeechBubbleBottomLeft>
          </div>
          <div className="mt-3">
            {buttons.map((platform, index) => (
              <p key={index} className="platform-select my-0 py-1">
                <span className="platform-list py-0 px-0 my-0">
                  {platform === "Twitter"
                    ? "Twitter/X"
                    : platform === "Facebook"
                      ? "Facebook"
                      : platform === "LinkedIn"
                        ? "LinkedIn"
                        : platform}
                </span>
                <button
                  className=" btn btn-lg py-0 my-0"
                  onClick={() => {
                    if (
                      !scheduleData?.some((item) => item.platform === platform)
                    ) {
                      swal({
                        text: `Scheduling Not Done. Please do before activating.`,
                        icon: "warning",
                        className: "modal_class_success",
                      });
                    } else {
                      handleToggle(platform);
                    }
                  }}
                >
                  {!scheduleData?.some((item) => item.platform === platform) ? (
                    <>
                      {" "}
                      <MdOutlineToggleOff
                        className="p-0"
                        size={30}
                        style={{ color: "gray" }}
                      />
                    </>
                  ) : (
                    <>
                      {post?.includes(platform) ? (
                        <>
                          <MdOutlineToggleOn
                            className="p-0"
                            size={30}
                            style={{ color: "green" }}
                          />
                        </>
                      ) : (
                        <>
                          <MdOutlineToggleOff
                            className="p-0"
                            size={30}
                            style={{ color: "red" }}
                          />{" "}
                          {""}
                        </>
                      )}
                    </>
                  )}
                </button>
                {platform == "Facebook" ? (
                  <p className="toggle-status py-0 my-0">Scheduling Done. </p>
                ) : (
                  <p className="toggle-status py-0 my-0">
                    Scheduling Not Done.
                    <br /> Click here to do.{" "}
                  </p>
                )}
              </p>
            ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          {
            post?.length === 1 && <SpeechBubbleBottomCenter>Join us with a monthly subscription for just USD 30.00 and enhance your social media presence!</SpeechBubbleBottomCenter>
          }
          
          <Button
            className="btn btn-warning subscribe-button mx-auto"
            onClick={() => handlePay(post)}
          >
            {" "}
            {post?.length === 1 ? (
              <> Subscribe Monthly for USD 30.00</>
            ) : (
              <>
                {" "}
                {post?.length === 2 ? (
                  <> Subscribe Monthly for USD 48.00 </>
                ) : (
                  <>
                    {" "}
                    {post?.length === 3 ? (
                      <> Subscribe Monthly for USD 63.00 </>
                    ) : (
                      <>
                        {" "}
                        {post?.length === 4 ? (
                          <> Subscribe Monthly for USD 72.00 </>
                        ) : (
                          <> Subscribe Monthly for USD 00.00 </>
                        )}
                      </>
                    )}{" "}
                  </>
                )}{" "}
              </>
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setShowPlatforms(false);
              setToggledPlatforms([]);
              setPost([]);
            }}
          >
            {" "}
            Close{" "}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SubsCribePay;
