import { useEffect, useState } from "react";
import { Select } from "antd";

const { Option } = Select;

function VideoAiVoices({
  onChangeVoices,

  defaultValue,

  voices,
}) {
  // useEffect(() => {
  //   const randomIndex = Math.floor(Math.random() * voices?.length);
  //   setDefaultValue(voices?.[randomIndex]);
  //   setVoice(voices?.[randomIndex]?.value);
  // }, []);

  return (
    <Select
      // className="videoai-select me-2"
      className="videoai-voices me-2"
      allowClear
      clearIcon
      showSearch
      //   size="large"
      placeholder="Choose Narration"
      optionFilterProp="children"
      onChange={onChangeVoices}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      value={defaultValue?.label}
      options={voices}
    >
      {voices?.map((voice) => (
        <Option key={voice.value} value={voice.value}>
          {voice.label}
        </Option>
      ))}
    </Select>
  );
}

export default VideoAiVoices;
