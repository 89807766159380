import { CardActionArea, CardContent, Container, Grid } from "@mui/material";
import DataCard from "../DataCard";
import './Method.css';
import {
  DescriptionText,
  Method1Button,
  Method1Container,
  MethodButtonContainer,
  MethodButtonText,
  StartButton,
} from "./style";
import { method1Data } from "../data/method1";
import { useNavigate } from "react-router-dom";

const Method1 = () => {
  const navigate = useNavigate();

  return (
    <Method1Container>
      <Container>
        <MethodButtonContainer>
          <Method1Button onClick={() => navigate("/do-it-yourself")}>
            <CardActionArea>
              <CardContent className="method-heading" sx={{ textAlign: "center" }}>
                <MethodButtonText variant="h6">Method 1</MethodButtonText>
                <MethodButtonText variant="h6">Do it yourself</MethodButtonText>
              </CardContent>
            </CardActionArea>
          </Method1Button>
        </MethodButtonContainer>
        <DescriptionText variant="body1">
        </DescriptionText>
        <p className="text-center sizing">
        <span>Create and post various content with our AIs,</span> <span>only paying for what you generate.</span>
        </p>
        <MethodButtonContainer>
          <StartButton onClick={() => navigate(`/do-it-yourself`)}>
            Get Started
          </StartButton>
        </MethodButtonContainer>

        <Grid container spacing={2} mt={6}>
          {method1Data.map((data) => (
            <Grid key={data.id} item xs={12} sm={6} md={4} lg={3}>
              <DataCard
                title={data.title}
                description={data.description}
                bgColor={"#096C83"}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Method1Container>
  );
};

export default Method1;
