import React, { useState, useContext } from "react";
import Spineer from "../../Components/CustomSpineer/Spineer";
import moment from "moment";
// import { Delete } from "@mui/icons-material";
import { Edit, Delete } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import usePagination from "../../hooks/UsePagination";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import SearchBar from "../AdminUser/SearchBar";
import ViewPayment from "./ViewPayment";
import PaidIcon from "@mui/icons-material/Paid";
// import EditNewsModal from "./EditNewsModal";

const AdminPayments = () => {
  const { allPayments, getAllPayments } = useContext(AdminAuthContext); // Fetch all news from context
  const [page, setPage] = useState(1);
  const [onePayment, setOnePayment] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredPay = allPayments?.filter(
    (item) =>
      item.message?.toLowerCase().includes(searchQuery) ||
      item.email?.toLowerCase().includes(searchQuery)
  );
  const PER_PAGE = 10;
  let count = Math.ceil(filteredPay?.length / PER_PAGE);
  let perPageData = usePagination(filteredPay, PER_PAGE);
  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      text: "Are you sure you want to delete this payment?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`https://backend.whalesai.com/api/v1/earnings-payment/${id}`, {
          method: "DELETE",
        })
          .then((response) => {
            if (response.ok) {
              getAllPayments();
              Swal.fire({
                text: "Payment Deleted.",
                icon: "success",
                customClass: "modal_class_success",
                allowOutsideClick: false,
              });
            } else {
              getAllPayments();
              Swal.fire({
                text: "Failed to delete the payment.",
                icon: "error",
                background: "#011225",
                allowOutsideClick: false,
              });
            }
          })
          .catch((error) => {
            getAllPayments();
            Swal.fire({
              text: "An error occurred while deleting the payment.",
              icon: "error",
              background: "#011225",
              allowOutsideClick: false,
            });
          });
      }
    });
  };

  const handleEdit = (pay) => {
    setOnePayment(pay);
    setModalOpen(true);
  };

  const handleChangeStatus = async (pay) => {
    Swal.fire({
      text: "Are you sure you want to change the status of this payment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: "modal_class_success",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `https://backend.whalesai.com/api/v1/earnings-payment/${pay?._id}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                status: !pay?.status,
              }),
            }
          );
          getAllPayments();
          if (!response.ok) {
            throw new Error("Failed to update status");
          }

          const data = await response.json();
          getAllPayments();

          Swal.fire({
            text: `Status changed to: ${data.status ? "PAID" : "UNPAID"}`,
            icon: "success",
            customClass: "modal_class_success",
            allowOutsideClick: false,
          });
        } catch (error) {
          getAllPayments();
          Swal.fire({
            text: `Error: ${error.message}`,
            icon: "error",
            customClass: "modal_class_success",
            allowOutsideClick: false,
          });
        }
      }
    });
  };

  return (
    <div className="container-fluid text-white" style={{ color: "white" }}>
      <ViewPayment
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onePayment={onePayment}
      />

      <h5 className="text-start mb-3 font-weight-bold">OUTGOING PAYMENTS</h5>
      <div className="mb-3">
        <SearchBar handleSearch={handleSearch} />
      </div>

      <section>
        <div
          className="pt-4 rounded my-2 mb-5 pb-3 pt-0 table-width-class"
          style={{ backgroundColor: "#3080AC" }}
        >
          <div className="" style={{ visibility: "hidden" }}>
            <div className="">
              <button className="btn btn-primary admin-add-text ">
                ADD NEWS
              </button>
            </div>
          </div>

          <div className="table-responsive overflow-auto">
            <table
              className="table table-hover"
              style={{ backgroundColor: "#3080AC" }}
            >
              <thead className="bg-[#1a1c339a] table-text-size">
                <tr>
                  <th scope="col" style={{ verticalAlign: "middle" }}>
                    S/no
                  </th>
                  <th scope="col" style={{ verticalAlign: "middle" }}>
                    PayPal ID
                  </th>
                  <th scope="col" style={{ verticalAlign: "middle" }}>
                    Amount
                  </th>
                  <th scope="col" style={{ verticalAlign: "middle" }}>
                    Date & Time{" "}
                    <span style={{ fontSize: "10px" }}>(SG Time)</span> <br />
                    <span style={{ fontSize: "10px", marginTop: "0px" }}>
                      Requested
                    </span>
                  </th>
                  <th scope="col" style={{ verticalAlign: "middle" }}>
                    Date & Time{" "}
                    <span style={{ fontSize: "10px" }}>(SG Time)</span> <br />
                    <span style={{ fontSize: "10px", marginTop: "0px" }}>
                      Paid
                    </span>
                  </th>
                  <th scope="col" style={{ verticalAlign: "middle" }}>
                    Status
                  </th>
                  <th
                    scope="col"
                    className="text-center"
                    style={{ verticalAlign: "middle" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              {/* <tbody
                className="table-text"
                style={{ backgroundColor: "#3080AC" }}
              >
                {allPayments && allPayments?.length>0 ? (
                  perPageData?.currentData()?.map((content, index) => (
                    <tr>
                      <td className="py-3 px-3">
                        {(page - 1) * 10 + index + 1}
                      </td>
                      

                      <td className="py-3">
                        {`${content?.paymentId.slice(
                          0,
                          4
                        )}******${content?.paymentId.slice(-4)}`}
                      </td>
                      <td className="py-3">USD {content?.amount}</td>

                      <td className="py-3">{content?.createdAt}</td>
                      <td className="py-3">
                        {content?.status == true ? (
                          content?.updatedAt
                        ) : (
                          <span
                            style={{ textAlign: "center", padding: "50px" }}
                          >
                            -
                          </span>
                        )}
                      </td>
                     
                      <td
                        className=""
                        style={{
                          paddingTop: "12px",
                          paddingBottom: "10px",
                          position: "relative",
                        }}
                      >
                        <button
                          className={`btn btn-sm rounded d-flex align-items-center position-relative ${content?.status ? "btn-secondary" : "btn-info"}`}
                         >
                          {content?.status == false ? "UNPAID" : "PAID"}
                        </button>
                      </td>

                      <td className="text-center">
                        <div className="btn-group gap-2" role="group">
                          <Link
                            onClick={() => {
                              handleEdit(content);
                            }}
                            className="btn rounded-circle btn-sm icon-body btn-success"
                          >
                            <PaidIcon className="action-icon" />
                          </Link>
                         
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <tr>
                    <td className="text-center" colSpan="7">
                      No outgoing payments found.
                    </td>
                  </tr>
                  </tr>
                )}
              </tbody> */}
              <tbody
                className="table-text"
                style={{ backgroundColor: "#3080AC" }}
              >
                {allPayments && allPayments.length > 0 ? (
                  perPageData.currentData().map((content, index) => (
                    <tr key={content._id}>
                      <td className="py-3 px-3">
                        {(page - 1) * 10 + index + 1}
                      </td>

                      <td className="py-3">
                        {`${content?.paymentId.slice(0, 4)}******${content?.paymentId.slice(-4)}`}
                      </td>
                      <td className="py-3">USD {content?.amount}</td>

                      <td className="py-3">{content?.createdAt}</td>
                      <td className="py-3">
                        {content?.status === true ? content?.updatedAt : "-"}
                      </td>

                      <td
                        className=""
                        style={{
                          paddingTop: "12px",
                          paddingBottom: "10px",
                          position: "relative",
                        }}
                      >
                        <button
                          className={`btn btn-sm rounded d-flex align-items-center position-relative ${
                            content?.status ? "btn-secondary" : "btn-info"
                          }`}
                        >
                          {content?.status === false ? "UNPAID" : "PAID"}
                        </button>
                      </td>

                      <td className="text-center">
                        <div className="btn-group gap-2" role="group">
                          <Link
                            onClick={() => {
                              handleEdit(content);
                            }}
                            className="btn rounded-circle btn-sm icon-body btn-success"
                          >
                            <PaidIcon className="action-icon" />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan="7">
                      No outgoing payment found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <CustomPagination
            page={page}
            count={count}
            onChange={handlePageChange}
          />
        </div>
      </section>
    </div>
  );
};

export default AdminPayments;
