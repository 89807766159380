import axios from "axios";
import { useContext, useEffect, useState } from "react";
import "./AdminDashboard.css";
import { Box, Card, CardContent, Grid } from "@mui/material";
import DashboardCard from "./DashboardCard";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import { useNavigate } from "react-router-dom";
// import DashboardCard from "../../../components/DashboardCard";

// Helper function to filter matched data
const getMatchedTesterTransactions = (allTester, allTransaction ) => {
  if (!allTester || !allTransaction) return [];

  const testerEmails = allTester.map((tester) => tester.email);
  return allTransaction.filter((transaction) => testerEmails.includes(transaction.email));
};


const AdminDashboard = () => {
  const [students, setStudents] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [affiliate, setAffiliate] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [payments, setPayments] = useState();
  console.log(registrations, "REGISTRATIONS DATA");
  
  const {allAIAdmin, allAIUsers, allScheduledData,allMessage, allNews, allPayments, allTransaction,currentAIAdmin,AdminToken, allTester, allTestPayments} = useContext(AdminAuthContext);
  console.log('from auth provider  ========>  ', allAIAdmin);

  const navigate = useNavigate();

  const matchedTesterTransactions = getMatchedTesterTransactions(allTester, allTransaction);

  // Logging matched data for verification
  console.log("Matched Tester Transactions:", matchedTesterTransactions);


  // Total
  let arr = [];
  paymentData?.forEach((d) => {
    const amount = d?.amount;
    arr.push(Number(amount));
    console.log(amount);
  });

  let result = 0;
  for (let i = 0; i < arr.length; i++) {
    result += arr[i];
  }

  const exchangeRate = 0.7426;

  
  const usdAmount = result * exchangeRate;

  if(!AdminToken && !currentAIAdmin){
    localStorage.removeItem('AiAdmin');
    navigate('/admin/login')
  }

  return (
    <div>
      <div className="container titlesregistered" style={{marginBottom: '100px'}}>
        <h5 className="text-white text-start mb-3 font-bold">DASHBOARD</h5>
        <div className="cards gap-4" >
          <DashboardCard color="#A169E1" path="/admin/adminUser" count={allAIAdmin?.length ?? 0} title="ADMINS" />
          <DashboardCard color="#6C8A8F" path="/admin/users" count={allAIUsers?.length ?? 0} title="USERS" />
          <DashboardCard color="#095C73" path="/admin/testers" count={allTester?.length ?? 0} title="TESTERS" />
          <DashboardCard color="#FFCA2C" path="/admin/news" count={allNews?.length ?? 0} title="NEWS" />


          <DashboardCard color="#FF5B5B" path="/admin/scheduled-content" count={allScheduledData?.length ?? 0} title="SCHEDULED CONTENTS" />

          <DashboardCard color="#46CC6B" path="/admin/outgoing-payments" count= { allPayments?.length ?? 0} title="OUTGOING PAYMENTS" />

          <DashboardCard color="#54C3DD" path="/admin/incoming-payments" count= { allTransaction?.length - matchedTesterTransactions?.length ?? 0} title="INCOMING PAYMENTS" />

          {/* <DashboardCard color="#138496" path="/admin/test-payments" count= { matchedTesterTransactions?.length ?? 0} title="TEST PAYMENTS" /> */}
          
          <DashboardCard color="#3080AC" path="/admin/enquiries" count={allMessage?.length ?? 0} title="ENQUIRIES" />
          {/* <DashboardCard color="#FF5B5B" path="/admin/payments" count={certificate?.length ?? 0} title="PAYMENTS" /> */}
          {/* #3080AC */}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;