import React, { useState } from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import Banner from "../Home/Banner/Banner";
import "./ApiSettings.css";
import { FaEdit } from "react-icons/fa";
import {
  MdAutoDelete,
  MdOutlineToggleOn,
  MdOutlineToggleOff,
} from "react-icons/md";
import Charge from "./Charge";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Token from "./Token";

const extractNumericValue = (amount) => {
  return parseFloat(amount.replace(/[^0-9.]/g, ""));
};

const ApiSettings = () => {
  const [selectedAmount, setSelectedAmount] = useState("USD 5.00");
  const [previousAmount, setPreviousAmount] = useState("USD 5.00");
  const [customAmount, setCustomAmount] = useState("");
  const [customNumber, setCustomNumber] = useState(0);
  const [customNumber2, setCustomNumber2] = useState(0);
  const [toggleAmmount, setToggleAmmount] = useState(0);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const numericValue = extractNumericValue(selectedAmount);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedAmount2, setSelectedAmount2] = useState("USD 5.00");
  const [customAmount2, setCustomAmount2] = useState("");
  const [showCustomInput2, setShowCustomInput2] = useState(false);
  

  const handleSelect = (amount) => {
    if (amount === "Enter own amount") {
      setShowCustomInput(true);
      setPreviousAmount(selectedAmount);
      setSelectedAmount("Enter own amount");
    } else {
      setShowCustomInput(false);
      setSelectedAmount(amount);
    }
  };
  const handleCustomAmountChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, ""); 
    setCustomNumber(parseInt(value));
    setCustomAmount(value ? `USD ${value}` : ""); 
  };
  const formatToFloat = (value) => {
    const floatValue = parseFloat(value).toFixed(2);
    return `USD ${floatValue}`;
  };
  const handleCustomAmountSubmit = () => {
    if (customAmount.trim() === "") {
      setSelectedAmount(previousAmount);
    } else {
      setSelectedAmount(formatToFloat(customNumber));
    }
    setCustomAmount("");
    setShowCustomInput(false);
  };

  const handleCancel = () => {
    setCustomAmount("");
    setShowCustomInput(false);
    setSelectedAmount(previousAmount);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCustomAmountSubmit();
    }
  };

  const handleSelect2 = (amount) => {
    if (amount === "Enter own amount") {
      setShowCustomInput2(true);
    } else {
      setSelectedAmount2(amount);
      setShowCustomInput2(false);
      // setShowPopup(false);
    }
  };

  const handleSubmit = (e) => {
    console.log('acllll.....')
    e.preventDefault();
    setShowPopup(false);
    setShowCustomInput2(false);
  }

  const handleCustomAmountChange2 = (e) => {
    const value = e.target.value.replace(/[^\d]/g, "");
    console.log(value); 
    setCustomNumber2(parseInt(value));
    setCustomAmount2(value); 
    const data = 'USD ' + formatAmount(value);
    setSelectedAmount2( data );
  };

  console.log('tk....222',customAmount2, selectedAmount2);

  const handleCustomAmountSubmit2 = () => {
    console.log('calll.....')
    if (customAmount2.trim() === "") {
      setSelectedAmount2(previousAmount);
    } else {
      setSelectedAmount2(formatToFloat(customNumber));
    }
    setCustomAmount2("");
    setShowCustomInput2(false);
    setShowPopup(false);
  };

  const handleCancel2 = () => {
    setShowCustomInput2(false);
    setCustomAmount2("");
    setShowPopup(false);
  };
  const handleKeyPress2 = (e) => {
    if (e.key === "Enter") {
      handleCustomAmountSubmit2();
      setShowPopup(false);
    }
  };

  const handleToggle = () => {
    if (!isToggled) {
      setShowPopup(true);
      setIsToggled(true)
    } else {
      setIsToggled(false);
    }
  };

  const handleClose = () => {
    setShowPopup(false);
  }

  const handleEdit = () => {
    setShowPopup(true);
  }

  function formatAmount(amount) {
    if (isNaN(amount)) {
      return amount;
    }
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  }
  const num = (parseInt(selectedAmount))
  console.log( 'datttt,......', formatAmount(numericValue) );
  console.log('deteeeee', selectedAmount, numericValue);
  console.log(formatAmount(num));

  return (
    <div className="">
      <PageHeading title={"API Settings"} />
      <div className="page-content container">

        <div className="first-portion container">
          <p className="text-uppercase pb-0 mb-0 fw-bold">Pay as you go</p>{" "}
          <br />
          <div className="dropdown">
            <button
              className="btn btn-info text-uppercase dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              USD {formatAmount(numericValue || 0 )}
            </button>
            <ul
              className="dropdown-menu custm-menu"
              aria-labelledby="dropdownMenuButton"
            >
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSelect("USD 5.00")}
                >
                  USD 5.00
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSelect("USD 10.00")}
                >
                  USD 10.00
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSelect("USD 15.00")}
                >
                  USD 15.00
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSelect("USD 20.00")}
                >
                  USD 20.00
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => handleSelect("Enter own amount")}
                >
                  Enter own amount
                </button>
              </li>
            </ul>
            {showCustomInput && (
              <div className="mt-2 enter-ammount">
                <input
                  type="text"
                  className="enter-ammount-input"
                  placeholder="Enter amount"
                  value={customAmount}
                  onChange={handleCustomAmountChange}
                  onKeyPress={handleKeyPress}
                />
                <button
                  onClick={handleCustomAmountSubmit}
                  className="btn btn-info"
                >
                  OK
                </button>
                <button onClick={handleCancel} className="btn btn-danger">
                  X
                </button>
              </div>
            )}
          </div>
          <p className="text-sm text-uppercase pay-as">
            {showCustomInput
              ? ""
              : `For $${formatAmount(numericValue)}, you get ${formatAmount(numericValue * 50)} credits.`}
          </p>
          <p className="text-uppercase fw-bold">Credit Balance</p>
          <p className="btn btn-warning  custom-button2"> USD 0.00 </p>
        </div>

        {/* 2nd portion  */}

        <div className="second-portion container">
          <p className="mt-2 text-uppercase fw-bold">Enable auto recharge </p>
          <div className="d-flex gap-4 auto-recharge">
              <p className="mb-0 pb-0">{selectedAmount2} </p>
              <button onClick={handleEdit} className="btn btn-info px-4 py-1">Edit</button>
          </div>

          <div>
            <button
              className="toggole-button pt-0 mt-0 py-0 my-0"
              onClick={handleToggle}
            >
              {isToggled ? (
                <MdOutlineToggleOn className="p-0" />
              ) : (
                <MdOutlineToggleOff className="p-0" />
              )}
            </button>


            {/* pop-up  */}
            {showPopup && (
              <div className="popup-container">
                <div className="popup">
                  <button
                  onClick={handleClose}
                  className="btn btn-danger text-white mb-4 cancel-item"> X </button>
                  <p>Please select the minimum amount before auto top-up:</p>

                  <div className="dropdown">
                    <button
                      className="btn btn-warning text-uppercase dropdown-toggle credit-button USD2 pr-5 "
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {selectedAmount2}
                    </button>
                    <ul
                      className="dropdown-menu custm-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSelect2("USD 5.00")}
                        >
                          USD 5.00
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSelect2("USD 10.00")}
                        >
                          USD 10.00
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSelect2("USD 15.00")}
                        >
                          USD 15.00
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSelect2("USD 20.00")}
                        >
                          USD 20.00
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSelect2("Enter own amount")}
                        >
                          Enter own amount
                        </button>
                      </li>
                      {/* <li>
                        <button
                          className="dropdown-item"
                          onClick={() => handleSelect2("USD 20.00")}
                        >
                          SUBMIT
                        </button>
                      </li> */}
                    </ul>
                    {/* <br/> */}
                    

                    {showCustomInput2 && (
                      <div className="mt-2 enter-ammount justify-content-center">
                        <input
                          type="text"
                          className="enter-ammount-input"
                          placeholder="Enter amount"
                          value={customAmount2}
                          onChange={handleCustomAmountChange2}
                          onKeyPress={handleKeyPress2}
                        />
                        {/* <button
                          onClick={handleCustomAmountSubmit2}
                          className="btn btn-info"
                        >
                          OK
                        </button> */}
                        <button
                          onClick={handleCancel2}
                          className="btn btn-danger"
                        >
                          X
                        </button>
                      </div>
                    )}
                    <button
                          className="btn btn-info mx-auto mt-2 pl-4 pr-4 d-block"
                          // onClick={setShowPopup(false)}
                          onClick={handleSubmit || handleCustomAmountSubmit2}
                        >
                          SUBMIT
                    </button>
                  </div>


                </div>
              </div>
            )}
          </div>

          <p className="pb-0 mb-0">Auto recharge is {isToggled ? " on" : " off"}.</p> 
          <span className="part-two-custom-font">
            When your credit balance hits $0, your API requests will cease
            functioning. Activate automatic recharge to ensure your credit
            balance remains topped up
          </span>
        </div>

        <p className="fw-bold mt-4">HOW WE CHARGE IF YOU ACCESS OUR API?</p>
        <div className="grid-content"></div>
        <Charge />
        {/* <div>
          <p className="fw-bold mt-4">API KEYS</p>
          <div className="api-key">
            <input
              type="text"
              placeholder="ENTER NAME OF API"
              className="py-2 px-2"
            />
            <button className="btn btn-info">GENERATE</button>
          </div>
          <div className="table-responsive">
            <table className="table ">
              <thead>
                <tr className="table-headline">
                  <th>Name</th>
                  <th>Secret Key</th>
                  <th>Created Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Test</td>
                  <td>test101test01test2test1081000888avcs</td>
                  <td>24-06-2024</td>
                  <td className="action-option">
                    <FaEdit className="action-edit" />
                    <MdAutoDelete className="action-delete" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
        <Token/>
      </div>
      <Banner />
    </div>
  );
};

export default ApiSettings;
