import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";
import CustomPagination from "../../Components/Pagination/CustomPagination";
import usePagination from "../../hooks/UsePagination";
import "../AdminGeneralUser/index.css";
import SearchBar from "../AdminUser/SearchBar";
import SpecificUser from "../AdminGeneralUser/SpecificUser";
import Swal from "sweetalert2";
import Spineer from "../../Components/CustomSpineer/Spineer";
import SpecificTester from "./SpecificTester";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import axios from "axios";
import { Tooltip } from "bootstrap";

const AdminTesters = () => {
  const { allTester, getAllUser } = useContext(AdminAuthContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [oneUser, setOneUser] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState("asc");

  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  // const filteredAdmins = allTester?.filter((admin) =>
  //   admin?.name?.toLowerCase()?.includes(searchQuery)
  // );
  const filteredAdmins = allTester
  ?.filter((admin) =>
    admin?.name?.toLowerCase()?.includes(searchQuery)
  )
  ?.sort((a, b) => {
    if (sortOrder === "asc") {
      return a.name.localeCompare(b.name); // Ascending order
    } else {
      return b.name.localeCompare(a.name); // Descending order
    }
  });

  let count = Math.ceil(filteredAdmins?.length / PER_PAGE);
  let perPageData = usePagination(filteredAdmins, PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    perPageData.jump(p);
  };

  // sort username
  // useEffect(() => {
  //   if (sortOrder === "asc") {
  //     filteredAdmins.sort((a, b) => a.name.localeCompare(b.name));
  //   } else {
  //     filteredAdmins.sort((a, b) => b.name.localeCompare(a.name));
  //   }
  // }, [sortOrder, filteredAdmins]);

  // tool tip
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new Tooltip(tooltipTriggerEl)
    );
  }, []);
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchQuery(value);
  };

  const handleOpen = (user) => {
    // alert(user?._id);
    setOneUser(user);
    setModalOpen(true);
  };

  const handleuserDelete = (id) => {
    Swal.fire({
      text: "Are you sure, you want to delete this user?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(
          `https://backend.whalesai.com/api/dashboard/user_signup-signin/${id}`,
          {
            method: "DELETE",
            headers: {
              // Authorization: `Bearer ${AdminToken}`,
            },
          }
        )
          .then((response) => {
            console.log("delete response ====> ", response);
            if (response.ok) {
              getAllUser();
              Swal.fire({
                html: `<p class='text-break text-white'>User Deleted.</p>`,
                icon: "success",
                customClass: "modal_class_success",
              });
            } else {
              getAllUser();
              Swal.fire({
                text: "Failed to delete the user.",
                icon: "error",
                background: "#011225",
              });
            }
          })
          .catch((error) => {
            console.log("error from catch", error);
            getAllUser();
            Swal.fire({
              text: "An error occurred while deleting the user.",
              icon: "error",
              background: "#011225",
            });
          });
      }
    });
  };

  const handleMakeTester = async (id) => {
    Swal.fire({
      text: "Are you sure you want to make this Tester an User?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes",
      customClass: "modal_class_success",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `https://backend.whalesai.com/api/dashboard/user_signup-signin/role/${id}`,
            {
              role: "user",
            }
          );

          if (response.status === 200) {
            Swal.fire({
              html: `<p class='text-break text-white'>Tester role updated to User.</p>`,
              icon: "success",
              customClass: "modal_class_success",
            });

            getAllUser();
          } else {
            Swal.fire({
              text: "Failed to update user role.",
              icon: "error",
              background: "#011225",
            });
          }
        } catch (error) {
          Swal.fire({
            text: "An error occurred while updating the user role.",
            icon: "error",
            background: "#011225",
          });
          console.error("Error while updating user role:", error);
        }
      }
    });
  };

  return (
    <div className="container-fluid text-white">
      <SpecificTester
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        oneUser={oneUser}
      />
      <h5 className="text-start mb-3 font-weight-bold">TESTERS </h5>

      <div className="d-flex justify-content-between align-items-center">
        <SearchBar handleSearch={handleSearch} />
      </div>

      <div
        className="table-responsive pt-3 rounded my-2 mb-5"
        style={{ backgroundColor: "#3080AC", paddingBottom: "10px" }}
      >
        <div className="mx-3">
          <div className="d-flex justify-content-start gap-2 mb-2 ml-3">
            <p
              style={{ marginTop: "10px", marginBottom: "10px" }}
              className="pb-0 total-user"
            >
              TOTAL TESTERS: {allTester?.length}
            </p>
          </div>
        </div>
        <div className="table-responsive overflow-auto">
          <table
            className="table table-hover"
            style={{ backgroundColor: "#3080AC" }}
          >
            <thead className="bg-[#1a1c339a] table-text-size">
              <tr>
                <th scope="col">S/no</th>
                <th
                  scope="col"
                  onClick={() =>
                    setSortOrder(sortOrder === "asc" ? "desc" : "asc")
                  }
                  style={{ cursor: "pointer" }}
                >
                  Username 
                </th>

                <th scope="col" className="d-none d-md-table-cell">
                  Email
                </th>
                <th scope="col" className="d-none d-md-table-cell">
                Date & Time (SG Time)
                </th>
                <th scope="col" className="text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody
              className="table-text-size"
              style={{ backgroundColor: "#3080AC" }}
            >
              {allTester ? (
                perPageData?.currentData()?.map((admin, index) => (
                  <tr key={admin._id}>
                    <td className="py-4 px-4">{(page - 1) * 10 + index + 1}</td>

                    <td className="py-4" style={{ textTransform: "lowercase" }}>
                      {admin.name}
                    </td>
                    <td className="py-4 d-none d-md-table-cell">
                      {admin.email}
                    </td>
                    <td className="py-4 d-none d-md-table-cell">
                    {admin.createdAt?.slice(0,9).replace(/\//g, '-')} <br/>
                    {admin.createdAt?.slice(9, 24).replace(/\//g, '-')}
                    </td>
                    <td className="text-center alignment">
                      <div className="btn-group gap-2" role="group">
                        <button
                          className="btn rounded-circle btn-sm icon-body btn-warning"
                          onClick={() => handleMakeTester(admin._id)}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top" // Specifies that the tooltip should appear above the button
                          title="Make User Account"
                        >
                          <TaskAltIcon className="action-icon" />
                        </button>
                        <Link
                          onClick={() => {
                            handleOpen(admin);
                          }}
                          className="btn rounded-circle btn-sm icon-body btn-success"
                        >
                          <RemoveRedEyeIcon className="action-icon" />
                        </Link>
                        <button
                          className="btn rounded-circle btn-sm icon-body btn-danger"
                          onClick={() => handleuserDelete(admin._id)}
                        >
                          <Delete className="action-icon" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="text-center"
                    colSpan={window.innerWidth <= 768 ? "4" : "6"}
                  >
                    <Spineer />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CustomPagination
          page={page}
          count={count}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default AdminTesters;
