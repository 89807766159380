import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { useContext } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function AddPaypalModal({ handleClose, open }) {
  const { userRefetch, setUserRefetch } = useContext(UserAuthContext);
  const [paypalDetails, setPaypalDetails] = useState({
    paypalId: "",
    confirmPaypalId: "",
  });
  const [error, setError] = useState("");

  const handleSave = async (e) => {
    e.preventDefault();
    console.log(paypalDetails);
    if (
      paypalDetails.paypalId === paypalDetails.confirmPaypalId &&
      paypalDetails.paypalId !== "" &&
      paypalDetails.confirmPaypalId !== ""
    ) {
      const fullNameFormData = new FormData();
      fullNameFormData.append("payId", paypalDetails.paypalId);

      if (paypalDetails.paypalId) {
        await axios
          .put(
            "https://backend.whalesai.com/api/dashboard/user_signup-signin/user/update",
            fullNameFormData,
            {
              headers: {
                authorization: `Bearer ${localStorage.getItem("aiUser")}`,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              toast.success("Paypal Id added successfully");
              setUserRefetch(!userRefetch);
              handleClose();
              setError("");
            }
          });
      }
    } else {
      setError("Paypal Id does not match!");
    }
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="mt-4"
        >
          Please enter your PayPal ID to receive payments.
        </BootstrapDialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent dividers>
            <div className="col-12">
              <label htmlFor="inputAddress" className="form-label">
                Paypal Id
              </label>
              <input
                required
                onChange={(e) =>
                  setPaypalDetails({
                    ...paypalDetails,
                    paypalId: e.target.value,
                  })
                }
                type="text"
                className="form-control add-paypal-input"
                id="inputAddress"
                placeholder=""
              />
            </div>

            <div className="col-12">
              <label htmlFor="inputAddress" className="form-label">
                Confirm Paypal Id
              </label>
              <input
                required
                onChange={(e) =>
                  setPaypalDetails({
                    ...paypalDetails,
                    confirmPaypalId: e.target.value,
                  })
                }
                type="text"
                className="form-control add-paypal-input"
                id="inputAddress"
                placeholder=""
              />
            </div>

            {error && (
              <p
                style={{
                  color: "rgb(231, 31, 31)",
                  fontSize: "13px",
                  fontWeight: "bold",
                  // margin top 4px and bottom 0
                  margin: "4px 0 0 0",
                }}
              >
                {error}
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <button className="btn btn-sm btn-danger" onClick={handleClose}>
              CANCEL
            </button>
            <button
              type="submit"
              className="btn btn-sm btn-success"
              //   onClick={handleSave}
            >
              SAVE
            </button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
}
