// import axios from "axios";
// import { useContext, useEffect, useState } from "react";
// import { styled } from "@mui/material/styles";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
// import swal from "sweetalert";
// import { useTimer } from "react-timer-hook";
// import Button from "@mui/material/Button";
// import CssBaseline from "@mui/material/CssBaseline";
// import TextField from "@mui/material/TextField";
// import Box from "@mui/material/Box";
// import Container from "@mui/material/Container";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { useNavigate, useParams } from "react-router-dom";
// // import { AdminContext } from "../../../context/AdminContext";
// import logo from "../../Assets/Images/ds-logo.png";
// import { AdminAuthContext } from "../../Contexts/AdminAuthContext";

// const defaultTheme = createTheme();

// const CustomTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: theme.palette.common.black,
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.black,
//   },
// }));

// const Otp = () => {
//   const [forEnable, setForEnable] = useState(false);
//   const navigate = useNavigate();
//   const time = new Date();
//   time.setSeconds(time.getSeconds() + 180); 
//   const adminToken = localStorage.getItem("AiAdmin");
//   const { currentAIAdmin, setCurrentAIAdmin, AdminToken } = useContext(AdminAuthContext);
//   const { seconds, minutes, isRunning } = useTimer({
//     expiryTimestamp: time,
//     onExpire: () => console.warn("OTP expired"),
//   });


//   useEffect(() => {
//     const timer = setTimeout(() => setForEnable(true), 180000); 
//     return () => clearTimeout(timer); 
//   }, []);

//   const resendOTP = () => {
//     axios
//       .get(
//         `https://backend.whalesai.com/api/v1/admin/resend-otp`,
//         {
//           headers: {
//             Authorization: `Bearer ${adminToken}`,
//           },
//         }
//       )
//       .then((res) => {
//         if (res.status === 200) {
//           swal({
//             title: "Success",
//             text: "OTP resent",
//             icon: "success",
//             button: "OK!",
//             className: "modal_class_success",
//           });
//           setForEnable(false);
//         }
//       })
//       .catch((err) => {
//         swal({
//           title: "Attention",
//           text: `${err.response.data.message}`,
//           icon: "warning",
//           button: "OK!",
//           className: "modal_class_success",
//         });
//       });
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     axios
//       .post(
//         `https://backend.whalesai.com/api/v1/admin/verify-otp`,
//         {
//           otp: data.get("otp"),
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${adminToken}`,
//           },
//         }
//       )
//       .then((res) => {
//         if (res.status === 200) {
//           localStorage.setItem("AiAdmin", res.data.token);
//           console.log("After successfully login ===> ", res);
//           setCurrentAIAdmin(res?.data?.admin);
//           console.log('After verifying OTP ====>>>>' , res?.data?.admin)
//           const response = res?.data?.message;

//           navigate("/admin", {
//             state: {
//               currentAIAdmin: res?.data?.admin,
//             },
//           });
//         }
//       })
//       .catch((err) => {
//         swal({
//           title: "Attention",
//           text: `${err.response.data.message}`,
//           icon: "warning",
//           button: "OK!",
//           className: "modal_class_success",
//         });
//       });
//   };

//   return (
//     <div className="handleTheLoginBody">
//       <ThemeProvider theme={defaultTheme}>
//         <Container
//           component="main"
//           maxWidth="xs"
//           className="pt-5 forCard p-5 rounded mx-auto"
//         >
//           <CssBaseline />
//           <Box
//             sx={{
//               marginTop: 8,
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <img src={logo} className="handleLogoLogin mx-auto" alt="logo" />
//             <h3 className="text-center text-2xl mt-2">
//               Please check your email for OTP
//             </h3>
//             <Box
//               component="form"
//               onSubmit={handleSubmit}
//               noValidate
//               sx={{ mt: 1, width: "100%" }}
//             >
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="otp"
//                 label="Enter OTP"
//                 name="otp"
//                 autoComplete="text"
//                 autoFocus
//               />
//               <div
//                 className="mx-auto text-center"
//                 style={{
//                   display: "flex",
//                   flexWrap: "wrap",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   gap: "1rem",
//                 }}
//               >
//                 <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
//                   Submit
//                 </Button>
//                 <Button
//                   onClick={resendOTP}
//                   disabled={seconds > 0}
//                   variant="contained"
//                   sx={{ mt: 3, mb: 2 }}
//                 >
//                   Re-Send OTP
//                 </Button>
//               </div>
//             </Box>
//             <div className="text-center text-dark fw-bolder mt-3">
//               <span>{minutes}</span>:
//               <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
//             </div>
//           </Box>
//         </Container>
//       </ThemeProvider>
//     </div>
//   );
// };

// export default Otp;






import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import swal from "sweetalert";
import { useTimer } from "react-timer-hook";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../../Assets/Images/ds-logo.png";
import { AdminAuthContext } from "../../Contexts/AdminAuthContext";

const defaultTheme = createTheme();

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Otp = () => {
  const [forEnable, setForEnable] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180); // Countdown timer in seconds
  const navigate = useNavigate();
  const adminToken = localStorage.getItem("AiAdmin");
  const adminEmail = localStorage.getItem("AdminEmail");
  const { currentAIAdmin, setCurrentAIAdmin, AdminToken } = useContext(AdminAuthContext);

  console.log('email ===>>>', adminEmail);

  const { seconds, minutes, restart } = useTimer({
    expiryTimestamp: new Date(new Date().getTime() + 180 * 1000), // Initial timer setup for 3 minutes
    onExpire: () => {
      setForEnable(true); // Enable the resend button when time expires
    },
  });

  useEffect(() => {
    const timer = setTimeout(() => setForEnable(true), 180000); // Enable the button after 180 seconds
    return () => clearTimeout(timer); // Clear timer on unmount
  }, []);

  const resendOTP = () => {
    const email = adminEmail;
    axios
      .post(`https://backend.whalesai.com/api/v1/admin/resend-otp`,  { email },  {
        
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
        
        
      })
      .then((res) => {
        if (res.status === 200) {
          swal({
            title: "Success",
            text: "OTP resent",
            icon: "success",
            button: "OK!",
            className: "modal_class_success",
          });
          setForEnable(false); // Disable the button
          
          // Restart the countdown timer
          const newExpiryTime = new Date(new Date().getTime() + 180 * 1000);
          restart(newExpiryTime); // Restart the timer for 3 minutes
        }
      })
      .catch((err) => {
        swal({
          title: "Attention",
          text: `${err.response.data.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };



  //   const resendOTP = () => {
//     axios
//       .get(
//         `https://backend.whalesai.com/api/v1/admin/resend-otp`,
//         {
//           headers: {
//             Authorization: `Bearer ${adminToken}`,
//           },
//         }
//       )
//       .then((res) => {
//         if (res.status === 200) {
//           swal({
//             title: "Success",
//             text: "OTP resent",
//             icon: "success",
//             button: "OK!",
//             className: "modal_class_success",
//           });
//           setForEnable(false);
//         }
//       })
//       .catch((err) => {
//         swal({
//           title: "Attention",
//           text: `${err.response.data.message}`,
//           icon: "warning",
//           button: "OK!",
//           className: "modal_class_success",
//         });
//       });
//   };


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    axios
      .post(
        `https://backend.whalesai.com/api/v1/admin/verify-otp`,
        {
          otp: data.get("otp"),
        },
        {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("AiAdmin", res.data.token);
          setCurrentAIAdmin(res?.data?.admin);
          if(currentAIAdmin){
            localStorage.removeItem('AdminEmail');
            navigate("/admin", {
              state: {
                currentAIAdmin: res?.data?.admin,
              },
            });
          }
        }
      })
      .catch((err) => {
        swal({
          title: "Attention",
          text: `${err.response.data.message}`,
          icon: "warning",
          button: "OK!",
          className: "modal_class_success",
        });
      });
  };

  return (
    <div className="handleTheLoginBody">
      <ThemeProvider theme={defaultTheme}>
        <Container
          component="main"
          maxWidth="xs"
          className="pt-5 forCard p-5 rounded mx-auto"
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={logo} className="handleLogoLogin mx-auto" alt="logo" />
            <p style={{fontSize: '20px'}} className="text-center fw-bold mt-2">
              Please check your email for OTP
            </p>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="otp"
                label="Enter OTP"
                name="otp"
                autoComplete="text"
                autoFocus
              />
              <div
                className="mx-auto text-center"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
                  Submit
                </Button>
                <Button
                  onClick={resendOTP}
                  disabled={!forEnable} // Disable the button based on 'forEnable'
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Re-Send OTP
                </Button>
              </div>
            </Box>
            <div className="text-center text-dark fw-bolder mt-3">
              <span>{minutes}</span>:
              <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
            </div>
          </Box>
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default Otp;
