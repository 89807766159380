import React, { useEffect } from 'react';
import PageHeading from '../../Components/PageHeading/PageHeading';
import './AboutUs.css';
import Banner from '../Home/Banner/Banner'
import MetaData from '../../Components/MetaData/MetaData';

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
        <MetaData
        pageTitle={
          "Best Generative AI Tool for Beginners | Easy Generative AI"
        }
        pageDescription={
          "Find the best generative AI tools for beginners to create text, images, audio, and more. Enjoy user-friendly platforms designed for easy learning and creativity."
        }
        canonical="https://whalesai.com/about-us"
      ></MetaData>
      <div className=''>
      <PageHeading title={'ABOUT US'} />
      <div className="container account-container pb-5 about-us px-4">
        <p>
          Welcome to DS Legends Pte. Ltd., a trailblazing Web3 and Artificial
          Intelligence (AI) company based in the heart of Singapore. At the
          forefront of technological innovation, we are dedicated to
          transforming the digital landscape by leveraging the power of
          decentralization and intelligent systems. Our mission is to provide
          cutting-edge solutions that enhance security, efficiency, and
          connectivity for individuals and organizations worldwide.
        </p>
        <h3>Who We Are</h3>
        <p>
          At DS Legends Pte. Ltd., our diverse and dynamic team comprises
          experts in blockchain technology, AI, data science, and cybersecurity.
          United by a shared vision, we strive to harness the full potential of
          emerging technologies to create impactful and sustainable solutions.
          Rooted in Singapore, a global hub for innovation and technology, we
          are perfectly positioned to lead the charge in the digital revolution.
        </p>
        <h3>Our Vision</h3>
        <p>
          We envision a future where digital interactions are decentralized,
          secure, and intelligently optimized. Our goal is to reshape the
          digital ecosystem into a more equitable, transparent, and efficient
          space. By integrating Web3 and AI, we aim to empower users, foster
          innovation, and drive global progress.
        </p>
        <h3>Our Mission</h3>
        <p>
          Our mission is to lead the development of Web3 and AI technologies,
          delivering advanced solutions that redefine digital experiences. We
          are committed to building user-friendly and accessible technologies
          that not only meet but exceed industry standards. Through relentless
          innovation, strategic partnerships, and a dedication to excellence, we
          strive to set new benchmarks in the tech industry.
        </p>
        <h3>What We Do</h3>
        <ul>
          <li>
            {' '}
            <h4>Blockchain Solutions: </h4>
            <p>
              {' '}
              We develop secure, scalable, and transparent blockchain
              applications that cater to decentralized finance (DeFi), supply
              chain management, digital identity verification, and more. Our
              blockchain solutions enhance trust and efficiency across various
              sectors.
            </p>
          </li>
          <li>
            {' '}
            <h4>AI & Machine Learning: </h4>
            <p>
              Utilizing the latest advancements in AI, we create intelligent
              systems capable of analyzing large datasets, predicting trends,
              automating processes, and generating actionable insights. Our AI
              solutions are customized to meet the specific needs of our
              clients, ensuring optimal impact and value.
            </p>
          </li>
          <li>
            {' '}
            <h4>Smart Contracts: </h4>
            <p>
              We design and deploy smart contracts that automate complex
              transactions and agreements, reducing the need for intermediaries
              and minimizing fraud risks. Our smart contracts are secure,
              reliable, and tailored to fit unique business requirements.
            </p>
          </li>
          <li>
            {' '}
            <h4>Decentralized Applications (DApps): </h4>
            <p>
              Our team develops innovative DApps that operate on blockchain
              networks, providing decentralized alternatives to traditional
              applications. These applications are designed with a focus on user
              privacy, security, and ease of use.
            </p>
          </li>
        </ul>
        <h3>Our Values</h3>
        <ul>
          <li>
            {' '}
            <h4>Innovation:</h4>
            <p>
              We are dedicated to exploring new frontiers, continuously pushing
              the boundaries of technology to stay ahead in the industry.
            </p>
          </li>
          <li>
            {' '}
            <h4>Integrity: </h4>
            <p>
              Trust is the cornerstone of our business. We maintain the highest
              standards of integrity in all our operations and interactions.
            </p>
          </li>
          <li>
            {' '}
            <h4>Collaboration:</h4>
            <p>
              We believe in the power of teamwork. By fostering a collaborative
              environment, we harness the collective expertise and creativity of
              our team and partners.
            </p>
          </li>
          <li>
            {' '}
            <h4>Customer-Centricity: </h4>
            <p>
              Our clients are at the core of everything we do. We are committed
              to understanding their needs and delivering solutions that surpass
              their expectations.
            </p>
          </li>
          <li>
            {' '}
            <h4>Sustainability:</h4>
            <p>
              We are mindful of our environmental impact and strive to develop
              technologies that promote sustainability and responsible
              consumption.
            </p>
          </li>
        </ul>
        <h3>Our Singapore Connection</h3>
        <p>
          As a company rooted in Singapore, we benefit from the city-state's
          vibrant tech ecosystem, strategic location, and strong regulatory
          framework. Singapore's commitment to innovation and excellence aligns
          perfectly with our values and mission, enabling us to thrive and
          expand our global footprint.
        </p>
        <h3>Join Us on the Journey</h3>
        <p>
          As we continue to explore and expand the possibilities of Web3 and AI,
          we invite you to join us on this exciting journey. Whether you’re
          looking to partner with us, invest in groundbreaking technology, or
          become part of our dynamic team, DS Legends Pte. Ltd. offers
          unparalleled opportunities to be at the forefront of digital
          transformation. For more information about our projects, services, and
          career opportunities, please visit https://dsl.sg/ Together, let’s
          shape the future of technology from the heart of Singapore.
        </p>
      </div>

      
    </div>
    <Banner/>
    </div>
  );
};

export default AboutUs;
