// import React from "react";
// import poster from '../../Assets/Images/Video-Poster/05.png'
// import Item from "antd/es/list/Item";
// import poster1 from './Image/tvp1.PNG'
// import poster2 from './Image/tvp2.PNG'
// import poster3 from './Image/tvp3.PNG'

// const Four = () => {
//   const data = [
//     {
//       title: "THE PSYCHOLOGY OF SUCCESS: UNLOCKING YOUR FULL POTENTIAL",
//       link: [
//         "https://backend.whalesai.com/public/hiwvideos/1080-1080-text-video.mp4",
//         // "https://backend.whalesai.com/public/videos/1920-1080-1oaC1AGA.mp4",
//         // "https://backend.whalesai.com/public/videos/1080-1920-1oaC1AGA.mp4"
//       ],
//       level: '1:1 1080 X 1080 PX',
//       poster: poster1
//     },
//     {
//       title: "THE SILENT SYMPHONY: EXPLORING NATURE'S HARMONIES.",
//       link: [
//         // "https://backend.whalesai.com/public/videos/1080-1080-fbOW21Pu.mp4",
//         "https://backend.whalesai.com/public/hiwvideos/1920-1080-text-video.mp4",
//         // "https://backend.whalesai.com/public/videos/1080-1920-fbOW21Pu.mp4"
//       ],
//       level: '16:9 1920 X 1080 PX',
//       poster: poster2
//     },
//     {
//       title: "HOSPITALITY TRAINING INSTITUTE OF MICHIGAN HOSTING THIRD ROUND OF BUSINESS MANAGEMENT CLASSES",
//       link: [
//         // "https://backend.whalesai.com/public/videos/1080-1080-1cig4kh6.mp4",
//         // "https://backend.whalesai.com/public/videos/1920-1080-1cig4kh6.mp4",
//         "https://backend.whalesai.com/public/hiwvideos/1080-1920-text-video.mp4"
//       ],
//       level: '9:16 1080 X 1920 PX',
//       poster: poster3
//     }
//   ];

//   const level = [
//     '1:1 1080 X 1080 PX',
//     '16:9 1920 X 1080 PX',
//     '9:16 1080 X 1920 PX',
//   ]
  

//   return (
//     <div className="text-white">
//       <div>
    
//     <div>
//       {
//         data.map((video, index) => <div key={index}>
//           <p>{video.title}</p>
//           <p>{video.level}</p>
          
//           {
//             video.link.map((i,j) => <div key={j}
//             style={{ marginBlock: "20px" }}
//             className="d-flex gap-2 flex-column justify-content-center mx-auto "
//           >
//             <video
//               controls
//               playsInline
//               poster={video.poster}
//               src={i}
//               className=""
//               style={{ width: "90%", height: '400px' }}
//               type="video/mp4"
//             />
//             {
//               // <p>{level[j]}</p>
//             }
//           </div>)
//           }
         
          
//         </div>
//       )
//       }
//      </div>

//     </div>
//     </div>
//   );
// };

// export default Four;









import React, { useRef } from "react";
import poster1 from './Image/tvp1.PNG';
import poster2 from './Image/tvp2.PNG';
import poster3 from './Image/tvp3.PNG';

const Four = () => {
  const data = [
    {
      title: "THE PSYCHOLOGY OF SUCCESS: UNLOCKING YOUR FULL POTENTIAL",
      link: ["https://backend.whalesai.com/public/hiwvideos/1080-1080-text-video.mp4"],
      level: '1:1 1080 X 1080 PX',
      poster: poster1,
    },
    {
      title: "THE SILENT SYMPHONY: EXPLORING NATURE'S HARMONIES.",
      link: ["https://backend.whalesai.com/public/hiwvideos/1920-1080-text-video.mp4"],
      level: '16:9 1920 X 1080 PX',
      poster: poster2,
    },
    {
      title: "HOSPITALITY TRAINING INSTITUTE OF MICHIGAN HOSTING THIRD ROUND OF BUSINESS MANAGEMENT CLASSES",
      link: ["https://backend.whalesai.com/public/hiwvideos/1080-1920-text-video.mp4"],
      level: '9:16 1080 X 1920 PX',
      poster: poster3,
    }
  ];

  const videoRefs = useRef([]);

  const handlePlay = (index) => {
    videoRefs.current.forEach((video, i) => {
      if (i !== index && video) {
        video.pause();
      }
    });
  };

  return (
    <div className="text-white">
      <div>
        {data.map((video, index) => (
          <div key={index}>
            <p>{video.title}</p>
            <p>{video.level}</p>
            {video.link.map((i, j) => (
              <div
                key={j}
                style={{ marginBlock: "20px" }}
                className="d-flex gap-2 flex-column justify-content-center mx-auto"
              >
                <video
                  controls
                  playsInline
                  poster={video.poster}
                  src={i}
                  className=""
                  style={{ width: "90%", height: '400px' }}
                  type="video/mp4"
                  ref={(el) => (videoRefs.current[index] = el)}
                  onPlay={() => handlePlay(index)}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Four;
