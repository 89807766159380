import React, { useContext } from "react";
import "./packages2.css";
import { UserAuthContext } from "../../Contexts/UserAuthContext";
import { useLocation, useNavigate } from "react-router-dom";

const packages = [
  {
    price: "30",
    credits: '1.00',
    main: "30",
    expire: "1",
    discount: "NO",
    // paylimit: "5",
    background: "#05C0A2",
  },
  {
    price: "48",
    main: "60",
    credits: '0.80',
    expire: "2",
    discount: "20%",
    // paylimit: "80",
    background: "#1D60EF",
  },
  {
    price: "63",
    main: "90",
    credits: '0.70',
    expire: "3",
    discount: "30%",
    // paylimit: "600",
    background: "#FA6918",
  },
  {
    price: "72",
    main: "120",
    credits: '0.60',
    expire: "4",
    discount: "40%",
    // paylimit: "600",
    background: "#d62626",
  },
];

const Packages = () => {
  const { AIuser, setCreditsData } = useContext(UserAuthContext);

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();

  const handleNavigate = (data, packageNo) => {
    // let targetPath;
    // if (pathname === '/pricing') {
    //   targetPath = '/purchase';
    // } 
    // else if (pathname === '/purchase') {
    //   targetPath = `/credits-package/${packageNo}`;
    // } else {
    //   targetPath = `/credits-package/${packageNo}`;
    // }

    // if (!AIuser?.email) {
    //   navigate('/sign-in', {
    //     state: { from: targetPath, data: { ...data, packageNo } },
    //     replace: true,
    //   });
    // } else {
    //   navigate(targetPath, {
    //     state: { data: { ...data, packageNo } },
    //     replace: true,
    //   });
    //   // set the packageNo in setCreditsData() along with the data
    //   setCreditsData({ ...data, packageNo });
    // }
    navigate('/let-us-do-it-for-you')


  };

  //   const handleNavigate = (data, packageNo) => {
  //     if (!AIuser?.email) {
  //       navigate(
  //         '/sign-in',
  //         {
  //           state: { from: '/payment', data: { ...data, packageNo } },
  //         },
  //         { replace: true }
  //       );
  //     } else {
  //       navigate(/credits-package/${packageNo}, {
  //         state: { data: { ...data, packageNo } },
  //         replace: true,
  //       });
  //       // set the packageNo in setCreditsData() along with the data
  //       setCreditsData({ ...data, packageNo });
  //     }
  //   };
  console.log("🚀 ~ file: Packages.js:71 ~ Packages ~ packages:", packages);

  return (
    <div className="row g-4 py-md-5 py-3">
      {packages.map((item, index) => (
        <div className="col-md-6 col-lg-3 col-12">
          <div style={{ borderRadius: "10px" }} className="bg-white">
            <div
              className="package-price text-center"
              style={{ background: `${item.background}` }}
            >
              <p>PACKAGE {index + 1}</p>
              <h2>USD {item.price}</h2>
              <p
                style={{
                  fontSize: "small",
                  // color: "yellow",
                  fontWeight: "500",
                }}
              >
                USD {item.credits} per day / platform
              </p>
            </div>
            <div>
              {/* <div className="each-row py-4">
                <p className="row-header mb-2">CREDITS</p>
                <p className="row-value mb-0">{item.credits} CREDITS</p>
              </div> */}
              <div className="each-row py-4">
                {/* <p className="row-header mb-2">EXPIRY</p> */}
                <p className="row-value mb-0">
                  {item.expire} SOCIAL MEDIA PLATFORM
                </p>
              </div>
              <div className="each-row py-4 border-0">
                <p className="row-header mb-2">ACTUAL PRICE: USD {item.main}</p>
                <p className="row-value mb-0 text-danger">
                  {" "}
                  {item.discount} DISCOUNT
                </p>
              </div>
              <div className="pay-button-container">
                <button
                  className="pay-button"
                    onClick={() => handleNavigate(item, index + 1)}
                >
                  PAY USD {item.price}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Packages;
