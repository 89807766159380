import React, { useEffect, useState } from "react";
import MocUp from "../../Components/PageHeading/MocUp";
import Calendar from "react-calendar";
import { Col, Modal, Button } from "react-bootstrap";
import swal from "sweetalert";
import { MdContentCopy, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { useNavigate } from "react-router-dom";
// import { FacebookData } from "./Data";
import StepButton from "../StepButton/StepButton";
import { FacebookData } from "../Calandars/Data";
import { FaInfoCircle } from "react-icons/fa";
import SampleVideo from "../../Components/ImageSample/VideoSample";
import LongText from "../../Components/ImageSample/LongText";
import ShortText from "../../Components/ImageSample/ShortTextModal";
import SampleImageModal from "../../Components/ImageSample/ImageSample";
import { SpeechBubbleBottomLeft } from "../SpeechBubble/Bubble";

const CheckContent = () => {
  const buttons = [
    "Facebook",
    "Instagram",
    "Threads",
    "Twitter",
    "LinkedIn",
    "TikTok",
    "YouTube",
    "Pinterest",
    "Medium",
    "Telegram",
  ];

  const buttonColors = [
    "#95B9C9",
    "#39ACEF",
    "#0699E8",
    "#488AC7",
    "#367ECA",
    "#6960ED",
    "#1669C7",
    "#0040C4",
    "#0000FE",
    "#151A8F",
  ];

  const uniqueContent = [
    "Short Article with hashtags",
    "Long Article with hashtags",
    "1:1 Image with text and hashtags",
    "1:1 Text Video with text and hashtags",
    "1:1 News Video with text and hashtags",
    "1:1 Comic Video with text and hashtags",
  ]

  const navigate = useNavigate();

  const [scheduleData, setScheduleData] = useState(null);
  const [scheduled, setScheduled] = useState(false);
  const [activeButton, setActiveButton] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [socialSchedule, setSocialSchedule] = useState(false);
  const [isChnaged, setIsChnaged] = useState(false);
  const [indexNumber, setIndexNumber] = useState(0);
  const [currentMonth, setCurrentMonth] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [isEnd, setIsEnd] = useState(false);
  const [contentToggle, setContentToggle] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalLong, setModalLong] = useState(false);
  const [modalShort, setModalShort] = useState(false);
  const [modalImage, setModalImage] = useState(false);

  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [time, setTime] = useState(() => {
    const foundSchedule = scheduleData?.find(
      (item) => item.platform === buttons[activeButton]
    );
    return foundSchedule
      ? foundSchedule?.contentCategory[indexNumber]?.timeOfPosting
      : "09:00";
  });

  const getRandomContent = () => {
    const randomIndex = Math.floor(Math.random() * uniqueContent.length);
    return uniqueContent[randomIndex];
  };

  const randomContent = getRandomContent();

  const handleDateChange = (date) => {
    setIsChnaged(false);
    setSelectedDate(date);
    const activePlatform = buttons[activeButton];
    const platformSchedule = scheduleData?.find(
      (item) => item.platform === activePlatform
    );
    console.log("platform Schedule", platformSchedule);
    if (platformSchedule) {
      const startDateParts = platformSchedule?.startingDate.split("-");
      const startDate = new Date(
        startDateParts[2],
        startDateParts[1] - 1,
        startDateParts[0]
      );
      const endDate = new Date(startDate);
      endDate.setFullYear(endDate.getFullYear() + 1);

      if (date >= startDate && date <= endDate) {
        const timeDiff = date.getTime() - startDate.getTime();
        const dayNumber = Math.floor(timeDiff / (1000 * 3600 * 24)) + 1;
        // setClickedDay(dayNumber);
        const handleButtonClickSWAL = (value) => {
          swal.close();
        };
        const okButton = document.createElement("button");
        okButton.textContent = "OK";
        okButton.className = "modal-button ok-button";
        okButton.addEventListener("click", () => handleButtonClickSWAL("OK"));
        setTime(
          platformSchedule?.contentCategory[dayNumber - 1]?.timeOfPosting
        );
        // setTodaysContent(platformSchedule?.contentCategory[dayNumber - 1]);
        // setInfo(true);
      } else {
      }
    } else {
    }
  };

  const handleActiveStartDateChange = ({ activeStartDate }) => {
    setActiveStartDate(activeStartDate);
    const currentMonth = activeStartDate.toLocaleString("default", {
      month: "long",
    });
    const currentYear = activeStartDate.getFullYear();
    setCurrentMonth(currentMonth);
    setCurrentYear(currentYear);
    console.log(`Changed Month: ${currentMonth}, Changed Year: ${currentYear}`);
  };

  const handleButtonClick = (index) => {
    setIsChnaged(false);
    const today = new Date();
    setActiveButton(index);
    setSelectedDate(today);
    setActiveStartDate(today);

    let timeToSet = "09:00";

    if (scheduleData) {
      const platformData = scheduleData;

      if (platformData) {
        setSocialSchedule(true);

        if (indexNumber === -1) {
          timeToSet = "09:00";
        } else {
          timeToSet = platformData.contentCategory[indexNumber]?.timeOfPosting;
        }
      } else {
        setSocialSchedule(false);
      }
    }

    setTime(timeToSet);
  };
  const renderTileContent = ({ date, view }) => {
    if (view === "month" && scheduleData?.length) {
      // Find the schedule for the current active platform
      const activePlatformSchedule = scheduleData?.find(
        (item) => item.platform === buttons[activeButton]
      );

      if (activePlatformSchedule) {
        const parseDate = (dateString) => {
          const [day, month, year] = dateString.split("-").map(Number);
          return new Date(year, month - 1, day);
        };

        const startDate = new Date();
        const endDate = new Date();

        endDate.setFullYear(endDate.getFullYear() + 1);

        const formatDate = (date) => {
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        };

        setDateStart(formatDate(startDate));
        setDateEnd(formatDate(endDate));
        // setIsEnd(true);

        // Log formatted dates
        console.log(formatDate(startDate), "tooooooo", formatDate(endDate));

        if (date >= startDate && date <= endDate) {
          return (
            <div>
              <MdContentCopy style={{ color: "black" }} />
            </div>
          );
        }
      }
    }
    return null;
  };

  //   const handleSchedulerAIClick = async () => {
  //     const platform = buttons[activeButton];

  //     // Open the initial SweetAlert dialog
  //     const swalInstance = swal({
  //       text: `Do you want our Scheduler AI to automatically generate and post daily content to ${
  //         platform === "Twitter"
  //           ? "Twitter/X"
  //           : platform === "Facebook"
  //             ? "Facebook Page"
  //             : platform === "LinkedIn"
  //               ? "LinkedIn Page"
  //               : platform
  //       }?`,
  //       icon: "warning",
  //       buttons: ["Cancel", "Yes"],
  //       className: "modal_class_success",
  //       closeOnClickOutside: false,
  //       closeOnEsc: false,
  //     });

  //     // Automatically trigger the "Yes" button click after a delay
  //     setTimeout(async () => {
  //       const willPost = true; // Simulating "Yes" button click
  //       if (willPost) {
  //         setScheduled(true);
  //         setScheduleData(FacebookData);
  //         swal({
  //           text: `Scheduler AI has planned contents for ${platform}. Please click on individual date to see what type of content is scheduled.`,
  //           icon: "success",
  //           className: "modal_class_success",
  //           timer: 2000,
  //         });
  //       }
  //     }, 3000); // Wait for 3 seconds before auto-confirmation
  //   };

  useEffect(() => {
    setScheduleData(FacebookData);
  }, []);

  console.log(scheduleData);

  // const click = () => {
  //   const modals = [
  //       setModalLong,
  //       setModalShort,
  //       setModalImage
  //     ];
  //     const randomIndex = Math.floor(Math.random() * modals.length);
  //     modals.forEach((setModal, index) => {
  //       setModal(index === randomIndex);
  //     });
  // }

  // useEffect(() => {
  //   window.scroll(0,0);
  //   const timer = setTimeout(() => {
  //       click();
  //   }, 3000);

  //   return () => clearTimeout(timer); 
  // }, []);


  const click = () => {
    const modals = [
      // setModalLong,
      // setModalShort,
      setModalImage
    ];
    
    // Randomly choose one modal to set to true
    const randomIndex = Math.floor(Math.random() * modals.length);
    modals.forEach((setModal, index) => {
      setModal(index === randomIndex);
    });
  
    // After 2 seconds, set all modals to false
    setTimeout(() => {
      modals.forEach(setModal => {
        setModal(false);
        setIsEnd(true);
        window.scroll(0, 800);
      });
      //setIsEnd(true);
    }, 3000); // 2 seconds before hiding the modal
  };
  
  useEffect(() => {
    window.scroll(0, 0);
    const timer = setTimeout(() => {
      click();
      
    }, 3000); // 3 seconds delay before showing the modal
  
    return () => clearTimeout(timer); 
  }, []);
  
  return (
    <div>
      <MocUp
        title="CALENDARS"
        description="Checking of contents by user"
        step="Step 3:"
      />
      <div className="quick-links-container custom-tabs d-flex gap-2">
        {buttons.map((label, index) => (
          <button
            key={index}
            className={`btn custom rounded-lg 
                ${activeButton === index ? "active" : ""}
                `}
            onClick={() => handleButtonClick(index)}
            style={{ backgroundColor: buttonColors[index], height: "50px" }}
          >
            <p className="tabs-item m-0">
              {" "}
              {label === "Twitter" ? (
                "Twitter/X"
              ) : label === "Facebook" ? (
                <>
                  Facebook{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      display: "block",
                      padding: "0px",
                    }}
                  >
                    Page or Group
                  </span>
                </>
              ) : label === "LinkedIn" ? (
                <>
                  LinkedIn{" "}
                  <span
                    style={{
                      fontSize: "10px",
                      display: "block",
                      padding: "0px",
                    }}
                  >
                    Page
                  </span>
                </>
              ) : (
                label
              )}
              {/* {label == "Twitter" ? "Twitter/X" : label} */}
            </p>
          </button>
        ))}
      </div>

      <SampleVideo setModalShow={setModalShow} modalShow={modalShow} value = '1' />
      <LongText setModalLong={setModalLong} modalLong={modalLong} value = '1' />
      <ShortText setModalShort={setModalShort} modalShort={modalShort} value = '1' />
      <SampleImageModal
          setModalImage={setModalImage}
          modalImage={modalImage}
          value = '1'
        />

      <section>
        <div className="tabs-title">
          {buttons[activeButton] === "Twitter"
            ? "Twitter/X"
            : buttons[activeButton] === "Facebook"
              ? "Facebook Page"
              : buttons[activeButton] === "LinkedIn"
                ? "LinkedIn Page"
                : buttons[activeButton]}
        </div>
        <div
          className="text-center text-white"
          style={{ fontSize: "10px", marginBottom: "20px" }}
        >
          {" "}
          {scheduleData?.length !== 0 &&
          Array.isArray(scheduleData) &&
          scheduleData?.some(
            (item) => item.platform === buttons[activeButton]
          ) ? (
            <div>
              <span>Scheduler AI has scheduled from</span> <br />{" "}
              <spa>
                {dateStart} to {dateEnd}
              </spa>
            </div>
          ) : (
            <span> Scheduling Not Done </span>
          )}
        </div>
      </section>

      {/* claendars */}
      <section>
        <div
          className="calendar mb-1 pb-0 mx-auto"
          style={{ position: "relative" }}
        >
          <div>
            <div className="justify-content-center">
              <Col xs="auto" className="calendar-col">
                <div
                  className="calendar-navigation"
                  style={{ position: "relative" }}
                >
                  <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    className="custom-calendar mx-auto"
                    next2Label={null}
                    prev2Label={null}
                    activeStartDate={activeStartDate}
                    onActiveStartDateChange={handleActiveStartDateChange}
                    tileContent={renderTileContent}
                  />
                </div>
              </Col>
            </div>
          </div>
        </div>
      </section>

      {/* below calendars  */}
      <section>
        {scheduleData?.length !== 0 &&
        Array.isArray(scheduleData) &&
        scheduleData?.some(
          (item) => item.platform === buttons[activeButton]
        ) ? (
          <button
            onClick={click}
            className=" mb-4 mx-auto d-flex btn btn-secondary"
          >
            Re-Scheduler AI
          </button>
        ) : (
          <button
            // onClick={handleSchedulerAIClick}
            className="ai-button rounded mx-auto d-flex"
          >
            Scheduler AI
          </button>
        )}

        <div className="text-center text-white pb-0 mb-0">
          <p className="">
            Country: Singapore
            <button
              onClick={() => {
                // setIsEditingCountry(true);
                // setisChanging(true);
              }}
              className="edit-button"
            >
              {" "}
              Edit
            </button>
          </p>
          {/* Time  */}
          <p className="pb-0 mb-0">
            Daily Posting Time: 09:00 (24 hours)
            <button
              onClick={() => {
                if (
                  !(
                    scheduleData?.length !== 0 &&
                    Array.isArray(scheduleData) &&
                    scheduleData?.some(
                      (item) => item.platform === buttons[activeButton]
                    )
                  )
                ) {
                  swal({
                    text: `Please adjust the timing of posting after scheduling the content.`,
                    icon: "warning",
                    // timer: 2000,
                    className: "modal_class_success",
                  });
                } else {
                  // setIsEditingTime(true);
                }
              }}
              style={{
                backgroundColor: !(
                  scheduleData?.length !== 0 &&
                  Array.isArray(scheduleData) &&
                  scheduleData?.some(
                    (item) => item.platform === buttons[activeButton]
                  )
                )
                  ? "#5C636A"
                  : "#39ACEF",
              }}
              className="edit-button mb-0"
            >
              {" "}
              Edit{" "}
            </button>
          </p>
        </div>

        <p className="server-sm">
          There may be slight variations in posting times due to factors such as
          server congestion.
        </p>

        {/* Buttons */}
        {
          isEnd && <div
          className="container mx-auto mb-3 "
          // style={{top: '-10%'}}
        >
          <SpeechBubbleBottomLeft>You can effortlessly create and schedule content to post automatically!</SpeechBubbleBottomLeft>
        </div>
        }
        <div
          className="text-center container mx-auto mb-2"
          // style={{width: '100%'}}
        >
          <button
            // onClick={handleShowPlatform}
            style={{ width: "100%" }}
            className="btn btn-warning automatically"
          >
            <span>START GENERATING CONTENTS</span>
            <span> AND POSTING AUTOMATICALLY</span>
          </button>
        </div>
        <div className=" container mx-auto d-flex justify-content-center mx-4">
          <button
            onClick={() => {
              navigate("/how-it-works-analysis");
            }}
            className="btn btn-info analysis rounded"
          >
            <p className="pb-0 pt-0 mt-0 mb-0">VIEW YOUR ANALYSIS </p>{" "}
          </button>
        </div>
      </section>

      <section>
        {isEnd && (
          <StepButton
            pre={`/how-it-works-calendars`}
            nex={`/how-it-works-subscribe-and-pay`}
          />
        )}
      </section>

      {/* modal section  */}
      <section>
        <Modal show={contentToggle} onHide={() => setContentToggle(false)}>
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body style={{ backgroundColor: "#011225", color: "#fff" }}>
            <div className=" text-left my-0 py-0">
              <div className="my-1">
                {uniqueContent?.map((i, j) => (
                  <div
                    className="d-flex justify-content-centre align-items-center my-1 gap-2"
                    key={j}
                  >
                    <div
                      onClick={() => {
                        // handleCheckboxClick(i);
                      }}
                      style={{ cursor: "pointer", fontSize: "16px" }}
                    >
                      {randomContent == i ? (
                        <MdOutlineCheckBox />
                      ) : (
                        <MdOutlineCheckBoxOutlineBlank />
                      )}
                    </div>
                    <div
                      style={{
                        background: buttonColors[j],
                        color: "white",
                        paddingLeft: "10px",
                      }}
                      className="btn schedule-info-unique pl-4 px-1 py-1 m-0 "
                    >
                      <span className="ml-2 text-left pl-4 content-unick">
                        {" "}
                        {i}
                      </span>
                      <div className="text-right" style={{ cursor: "pointer" }}>
                        <FaInfoCircle 
                        // onClick={() => handleShowSample(i)} 
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <Modal show={showContentType !== ""} onHide={closeModal} centered>
              <Modal.Header
                style={{ backgroundColor: "#011225", color: "#fff" }}
                closeButton
              >
                <Modal.Title>{showContentType}</Modal.Title>
              </Modal.Header>
            </Modal> */}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => {
                setContentToggle(false);
              }}
            >
              CLOSE
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </div>
  );
};

export default CheckContent;
