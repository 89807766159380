import React, { useContext, useEffect, useState } from 'react';

import PageHeading from '../../Components/PageHeading/PageHeading';
import { Pagination } from '@mui/material';
import { UserAuthContext } from '../../Contexts/UserAuthContext';
import axios from 'axios';
import moment from 'moment';
import AddPaypalModal from './AddPaypalModal';
import './Earnings.css';
import swal from 'sweetalert';
import RequestPaymentModal from './RequestPaymentModal';
import { Popover, Spin } from 'antd';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappIcon,
  WhatsappShareButton,
  PinterestIcon,
  PinterestShareButton,
  TelegramIcon,
  TelegramShareButton,
} from 'react-share';

const Earnings = () => {
  const { AIuser } = useContext(UserAuthContext);
  const [earningDetails, setEarningDetails] = useState([]);

  const [open, setOpen] = useState(false);
  const [requestPaymentOpen, setRequestPaymentOpen] = useState(false);

  const handleClickOpen = (details) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const itemsPerPage = 6; // Number of items to display per page

  const [currentPage, setCurrentPage] = useState(1);
  const [Earningtotal, setEarningTotal] = useState(0);
  const [Earningref, setEarningRef] = useState(0);
  const [requestedAmount, setRequestedAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);

  // Calculate the start and end indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const paginatedData = earningDetails?.slice(startIndex, endIndex);

  const handlePageChange = (event, page) => {
    // scroll to half of the page
    window.scrollTo(0, window.innerHeight / 3);
    setCurrentPage(page);
  };

  const handleClick = () => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = `<p></p> <p class="text-white">If you want to change your PayPal ID,<br/> Please contact at <span class="text-warning">support@whalesai.com</span></p> `;
    swal({
      // title: "Are you sure?",
      content: wrapper,
      icon: 'success',
      button: 'Ok',
      className: 'modal_class_success',
    });
  };

  const handleOpenPayReq = () => {
    if (Earningtotal < 100) {
      const wrapper = document.createElement('div');
      wrapper.innerHTML = `<p></p> <p class="text-white">You can request payment once you reach USD 100.</span></p> `;
      swal({
        content: wrapper,
        icon: 'warning',
        button: 'Ok',
        className: 'modal_class_success',
      });

      return;
    }

    if (!AIuser?.payId) {
      const wrapper = document.createElement('div');
      wrapper.innerHTML = `<p></p> <p class="text-white">Please add your PayPal ID first</span></p> `;
      swal({
        content: wrapper,
        icon: 'warning',
        button: 'Ok',
        className: 'modal_class_success',
      });
      return;
    }
    setRequestPaymentOpen(true);
  };

  const getEarning = async () => {
    const response = await axios.get(
      `https://backend.whalesai.com/api/v1/earnings/get/${AIuser?.email}`
    );
    setEarningDetails(response?.data?.result);
    setEarningTotal(response?.data?.totalEarning);
    setEarningRef(response?.data?.owners);
  };

  useEffect(() => {
    getEarning();
  }, [AIuser?._id]);


  const getEarningData = async () => {
    const response = await axios.get(
      `https://backend.whalesai.com/api/v1/earnings-payment/all/${AIuser?.email}`
    );
    setRequestedAmount(response?.data?.requestedAmount);
    setPaidAmount(response?.data?.paidAmount);
  };
  
  useEffect(() => {
    getEarningData();
  }, [AIuser?._id]);

  function formatNumber(number) {
    return number.toFixed(2);
  }

  return (
    <div className="earnings-main">
      <PageHeading title={'EARNINGS'} />

      <div className="earnings-container">
        <div className="container">
          <div className="row gy-3">
            <div className="col-md-6 col-12">
              <div className="each-column">
                <h3 className="mb-0">TOTAL EARNED</h3>
                <div className="d-flex align-items-end">
                  <span className="mb-1 me-1">USD</span>{' '}
                  <h2 className="mb-0 fs-1">{formatNumber(Earningtotal)}</h2>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="each-column">
                <h3 className="mb-0">TOTAL PAID OUT</h3>
                <div className="d-flex align-items-end">
                  <span className="mb-1 me-1">USD</span>{' '}
                  <h2 className="mb-0 fs-1">{formatNumber(paidAmount)}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="balance-container">
            <h4 className="balance-usd text-center">USD</h4>
            <h2 className="balance-amount text-center fs-1">
              {formatNumber(Earningtotal-paidAmount)}
            </h2>
            <h4 className="text-center fw-bold text-black">BALANCE</h4>
          </div>
          <div className="request-button-container">
            <button
              onClick={handleOpenPayReq}
              className="payment-request-button"
            >
              REQUEST PAYMENT
            </button>
          </div>
          {/* <div className="request-button-container">
          <p className="text-white text-center">You can request payment once you reach USD 100.</p>
        </div> */}
        </div>

        <div className="second-part">
          <div className="container">
            <div className="d-flex align-items-center">
              <p className="text-white mb-0">
                Number of signups with your affiiliate code:
              </p>{' '}
              <p className="signups-filed mb-0">
                {Earningref < 10 ? '0' + Earningref : Earningref}
              </p>
            </div>
            {/* EARNINGS */}
            {earningDetails?.length > 0 ? (
              <div>
                {' '}
                <div className="earnings-table-container">
                  <table className="earnings-table">
                    <thead>
                      <th>DATE</th>
                      <th>MEMBER CODE</th>
                      <th>EARNINGS</th>
                      <th>STATUS</th>
                    </thead>
                    <tbody>
                      {/* <tr>
                      <td>22-06-2023  20:34:23</td>
                      <td>FAODF</td>
                      <td>USD 30.33</td>
                      <td className="payment-status text-danger">UNPAID</td>
                    </tr> */}

                      {paginatedData?.map((details) => (
                        <tr>
                          <td>
                            {moment(details?.createdAt).format(
                              'DD-MM-YYYY HH:mm:ss'
                            )}
                          </td>
                          <td>{details?.ownerId}</td>
                          <td>USD {Number(details?.refAmount).toFixed(2)}</td>
                          {details?.status == "false" && <td className="text-start text-danger">UNPAID</td>}
                          {details?.status == "processing" && <td className="text-start text-danger">PROCESSING</td>}
                          {details?.status == "paid" && <td className="text-start text-danger">PAID</td>}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center text-light mt-3">
                  <Pagination
                    shape="rounded"
                    count={Math.ceil(earningDetails?.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            ) : (
              <div className="no-earnings-container text-white mt-3">
                <p>
                  No Earnings so far, please share you affiliate link to you
                  friends
                </p>
                <div className="d-flex align-items-center justify-content-between">
                  <p style={{ color: '#56BCF4' }}>
                    https://whalesai.com/{AIuser?.myReferralCode}
                  </p>{' '}
                  {/* SHARE OPTIONS */}
                  <Popover
                    overlayClassName="affiliate-video-popover"
                    content={
                      <div className="d-flex gap-2">
                        <TwitterShareButton
                          url={`https://whalesai.com/${AIuser?.myReferralCode}`}
                        >
                          <TwitterIcon size={40} round={true} />
                        </TwitterShareButton>
                        <LinkedinShareButton
                          url={`https://whalesai.com/${AIuser?.myReferralCode}`}
                        >
                          <LinkedinIcon size={40} round={true} />
                        </LinkedinShareButton>
                        <WhatsappShareButton
                          url={`https://whalesai.com/${AIuser?.myReferralCode}`}
                        >
                          <WhatsappIcon size={40} round={true} />
                        </WhatsappShareButton>
                        <FacebookShareButton
                          url={`https://whalesai.com/${AIuser?.myReferralCode}`}
                        >
                          <FacebookIcon size={40} round={true} />
                        </FacebookShareButton>
                        <PinterestShareButton
                          url={`https://whalesai.com/${AIuser?.myReferralCode}`}
                        >
                          <PinterestIcon size={40} round={true} />
                        </PinterestShareButton>
                        <TelegramShareButton
                          url={`https://whalesai.com/${AIuser?.myReferralCode}`}
                        >
                          <TelegramIcon size={40} round={true} />
                        </TelegramShareButton>
                      </div>
                    }
                    trigger="click"
                  >
                    <button className="share-button">SHARE</button>
                  </Popover>
                </div>
              </div>
            )}

            {AIuser?.payId ? (
              <div className="no-earnings-container text-white mt-3">
                <p>You will receive your earnings via</p>
                <div className="d-flex align-items-center justify-content-between">
                  <p style={{ color: '#E7C648' }}>{AIuser?.email}</p>{' '}
                  <button onClick={handleClick} className="share-button">
                    CHANGE
                  </button>
                </div>
              </div>
            ) : (
              <button
                className="btn btn-primary mb-3 mx-auto text-uppercase paypal-receive d-block ms-auto mt-3 "
                onClick={handleClickOpen}
              >
                <p className='paypal-receive default-space'>
                   <span className='default-space'>Add your Paypal to</span> <span className='default-space'>receive your Earnings</span>
                </p>
              </button>
            )}
            <p className="mt-4 text-center" style={{ color: '#E7C648' }}>
              All Affiliates will be paid on 16th of every month
            </p>
          </div>
        </div>
      </div>

      {/* OLD ONE */}
      {/* <div className="container account-container pb-5">
      <p className="text-center text-info">
          Total Earned: USD {Earningtotal} |{" "}
          <span className="text-danger">Total Paid: 0.00</span>
        </p>
        <p className="text-center text-white">Balance : USD 0.00 (Request Payment)</p>
        <p className="text-center text-warning">
          Number of signups with your affiliate code :
          <input 
          type="text"
          value={Earningref < 10 ? "0" + Earningref : Earningref}
          disabled
          className="affiBox"
          />
        </p>
        

        <div className="table-container">
          {earningDetails?.length > 0 ? (
            <table className="transaction-table">
              <thead>
                <tr>
                  <th className="text-start">DATE</th>
                  <th className="text-start">MEMBER CODE</th>
                  <th className="text-start">EARNINGS</th>
                  <th className="text-start">STATUS</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData?.map((details) => (
                  <tr style={{ borderBottom: "1px solid gray" }}>
                    <td className="text-start">
                      {moment(details?.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                    </td>
                    <td className="text-start">{details?.ownerId}</td>
                    <td className="text-start">
                      USD {Number(details?.refAmount).toFixed(2)}
                    </td>
                    <td className="text-start text-danger">Unpaid</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-white text-center">
              No Earnings so far, please share your affiliate link <br /> to your friends <span className="text-info">https://whalesai.com/{AIuser?.myReferralCode}</span>
            </p>
          )}
          <div className="d-flex align-items-center flex-column justify-content-center pt-3">
          {AIuser?.payId ? <p className="text-white mt-2 text-center">You will receive your earnings via PayPal at <span className="text-warning">{AIuser?.email}</span> <button className="changeBTN btn btn-success" onClick={handleClick} >CHANGE</button></p> :
            <button
              className="btn btn-primary mb-3 text-uppercase"
              onClick={handleClickOpen}
            >
              Add your Paypal to receive your Earnings
            </button>}
            <p className="text-white pt-2 text-center">All Affiliates will be paid on 16th of every month.</p>
          </div>
        </div>
        <div className="d-flex justify-content-center text-light mt-3">
          <Pagination
            shape="rounded"
            count={Math.ceil(earningDetails?.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </div> */}
      <AddPaypalModal
        handleClose={handleClose}
        open={open}
        handleClickOpen={handleClickOpen}
      />
      <RequestPaymentModal
        Earningtotal={Earningtotal}
        requestPaymentOpen={requestPaymentOpen}
        setRequestPaymentOpen={setRequestPaymentOpen}
        email={AIuser?.email}
        payId={AIuser?.payId}
      />
    </div>
  );
};

export default Earnings;
